import React, { useEffect, useState } from 'react';
import {
    useNavigate,
    useLocation,
    Link as ReactRouterLink
} from "react-router-dom";

import { Box, Grid, Typography, Button, Toolbar, CardContent, Card, Alert,  Stack, Pagination, Skeleton, CardActions, Collapse, CircularProgress, useMediaQuery } from '@mui/material';

import DateRangeIcon from '@mui/icons-material/DateRange';

import config from '../../config';

import lang from '../lang';
import Header from '../modules/header';
import Footer from '../modules/footer';

import withRoot from '../modules/withRoot';
import axios from 'axios';

import helper from '../helper';

import constants from '../constants.js';
import BlockFilter from '../modules/blockFilter';
import { set } from 'react-ga';

const { getLabel, getArrayLabel, createMetaInfo } = helper;

const { sxMarginTop } = helper;


function SearchCalls(props) {

    const { currentLanguage } = props;
    lang.setLanguage(currentLanguage.code);

    const isDesktop = useMediaQuery('(min-width: 1024px)');

    const navigate = useNavigate();

    const { search } = useLocation();
    const query = React.useMemo(() => new URLSearchParams(search), [search]);


    // const [typology, setTypology] = useState([]);
    // const [fundingSource, setFundingSource] = useState([]);


    const [showFilter, setShowFilter] = useState({
        label: lang.showFilter,
        status: true,
    });


    const page = parseInt(query.get('page') || '1', 10);

    const [meta, setMeta] = useState(null);
    const [calls, setCalls] = useState([]);
    const [loading, setLoading] = useState(true);

    const [areas, setAreas] = useState(undefined);
    const [macroBeneficiaries, setMacroBeneficiaries] = useState(undefined);
    const [macroSectors, setMacroSectors] = useState(undefined);


    
    const filter = {
        search: query.get('search') ? query.get('search') : null,
        who: query.get('who') ? query.get('who').split(',') : [],
        where: query.get('where') ? query.get('where') : currentLanguage.code.toUpperCase(),
        regions: query.get('regions') ? query.get('regions').split(',') : [],
        what: query.get('what') ? query.get('what').split(',') : [],
        type: query.get('type') ?? null,
        fundingSource: query.get('fundingSource') ? query.get('fundingSource') : null,
        status: query.get('status') ? query.get('status') : 'all'
    }
  



    function onChangePage(e, p) {

        let url = '/' + lang.getLanguage() + '/search-calls?';

        query.forEach(function (value, key) {
            if (key !== 'page') {
                url += `${key}=${value}&`;
            }
        });

        url += `page=${p}`;

        navigate(url);
    }



    function Variants() {
        return (

            <Card sx={{ width: 1 }}>
                <CardContent>
                    <Stack spacing={1}>
                        <Skeleton variant="text" />
                        <Skeleton variant="circular" width={40} height={40} />
                        <Skeleton variant="rectangular" height={118} />
                    </Stack>
                </CardContent>
            </Card>

        );
    }


    function CardBlock(props) {


        const { sx, item } = props;
        const ca = areas.find(e => e.code === item.geographic_area[0]);

        const isClose = (item.deadline != null) && (new Date(item.deadline).getTime() - new Date().getTime()) < 0;
        const title = item.title.replace(/\r\n/g, ' ').replace(/\n/g, ' ').replace(/\r/g, '');
        const url = '/' + lang.getLanguage() + '/call/' + item._id + '/' + encodeURIComponent(title);
 

        //console.log( item.ordine === 2 , item.title );


        let dateLabel = lang.nd;

        if (item.deadline){

            if (item.ordine === 2){
                dateLabel = lang.expired
            }
            else{
                dateLabel = new Date(item.deadline).toLocaleDateString(undefined, { year: 'numeric', month: '2-digit', day: '2-digit' });
            }
        }
        
        

        return (

            <Box sx={{ ...sx }}   >
                <Card sx={{ width: 1 }}>
                    <CardContent>

                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2, flexWrap: 'wrap' }} >

                            <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }} >
                                <DateRangeIcon sx={{ mr: 1, color: 'primary.light' }} />

                                <Box sx={{ mx: 0 }}>
                                    <Typography color={'primary.light'} variant="caption" display="block" >
                                        { lang.expirationDate}
                                    </Typography>

                                    <Typography color={'primary.light'} variant="body2" >
                                        {dateLabel}
                                    </Typography>
                                </Box>
                            </Box>

                            <Box>
                                {item.news ?
                                    <Box sx={{
                                        bgcolor: 'info.light',
                                        p: .3,
                                        borderRadius: 2,
                                        whiteSpace: 'nowrap',
                                        textAlign: 'center',
                                    }} >
                                        <Typography color={'primary.main'} variant="caption" display="block" >
                                            {lang.news}
                                        </Typography>
                                    </Box> : null
                                }


                                <Alert sx={{ border: 'none', fontSize: {xs : '.7rem', sm : '.8rem'}, p:0, m:0 }} variant="outlined" severity={isClose ? 'error' : 'success'}>
                                    {isClose ? lang.close : lang.open}
                                </Alert>
                            </Box>        
                        </Box>

                      


                        <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={1}>

                            <Typography color={'primary.light'} variant="h5" sx={{ textDecoration: 'none', fontWeight: 'bold' }}
                                component={ReactRouterLink} to={url}>
                                {item.title}
                            </Typography>

                            <Box sx={{ p: 0 }}>
                                <Typography component="div" variant="body2" color={'primary.light'} dangerouslySetInnerHTML={{ __html: `${item.beneficiary}` }}></Typography>
                            </Box>
                            {/* <Typography variant="body2" color={'primary.light'} >
                                    {item.beneficiary}
                                </Typography>
                                 */}
                            {/* {/<\/?[a-z][\s\S]*>/i.test(item.beneficiary) ? (
                                <Typography variant="body2" color={'primary.light'}  dangerouslySetInnerHTML={{ __html: `${item.beneficiary}` }}></Typography>
                            ) : (
                                <Typography variant="body2" color={'primary.light'} >
                                    {item.beneficiary}
                                </Typography>
                            )} */}


                            <Typography variant="body" color={'primary.light'} >
                                {lang.countryArray[item.geographic_area[0]]}
                                { item.regions && item.regions.length > 0 && ca && ' - ' + getArrayLabel(ca.regions, item.regions) }
                            </Typography>
                            

                            <Box sx={{ p: 0 }}>
                                <Typography component="div" variant="body" color={'primary.light'} dangerouslySetInnerHTML={{ __html: `${item.sector}` }}></Typography>
                            </Box>



                            {item.macroBeneficiaries &&
                                <Box sx={{ display: 'flex', overflow: 'auto', width: 1, py: 1 }}>

                                    {item.macroBeneficiaries.map((item, index) => (

                                        (item && getLabel(macroBeneficiaries, item) !== '-') ? (

                                            <Box key={index} variant="body" sx={{
                                                color: 'primary.main',
                                                bgcolor: 'success.light',
                                                p: 0.8,
                                                borderRadius: 2,
                                                mr: 1,
                                                whiteSpace: 'nowrap',
                                                // overflow: 'hidden'
                                            }} >
                                                {item && getLabel(macroBeneficiaries, item)}
                                            </Box>
                                        ) : null

                                    ))}

                                </Box>
                            }



                            {item.macroSectors &&
                                <Box sx={{ display: 'flex', overflow: 'auto', width: 1, pb: 2 }}>
                                    {item.macroSectors.map((item, index) => (

                                        (item && getLabel(macroSectors, item) !== '-') ? (
                                            <Box key={index} variant="body" sx={{
                                                color: 'primary.main',
                                                bgcolor: 'primary.light',
                                                p: 0.8,
                                                borderRadius: 2,
                                                mr: 1,
                                                whiteSpace: 'nowrap',
                                                //overflow: 'hidden'

                                            }} >
                                                {item && getLabel(macroSectors, item)}
                                            </Box>
                                        ) : null
                                    ))}
                                </Box>
                            }

                        </Stack>

                    </CardContent>

                    <CardActions >
                        <Box sx={{ width: 1, px: 1, display: 'flex', flexDirection: { xs : 'column', sm: 'row-reverse' }, justifyContent: { xs : 'center', sm: 'space-between'} }}>
                            <Button size='medium' sx={{mb : {xs : 1 , mb: 0} }} color='info' variant='contained' component={ReactRouterLink} to={url} >{lang.more}</Button>
                            <Button size='medium' sx={{mb : {xs : 1 , mb: 0} }} color='success' variant='contained' component={ReactRouterLink} to={'/' + currentLanguage.code + '/home#contactsRef'}>{lang.contacts}</Button>
                        </Box>
                    </CardActions>
                </Card>
            </Box>
        )
    }




    function SmallPagination() {

        return (
            <Pagination

                boundaryCount={1}
                color="info"
                count={meta ? meta.pages : 0} page={page}
                variant="outlined"
                onChange={onChangePage} />
        )
    }


    useEffect(() => {

        document.title =  lang.searchCalls + ' - ' + lang.myFundsApp;

        //console.log(window.location.protocol + '//' + window.location.host);

        createMetaInfo( 
            document.title, 
            lang.searchForEuropeanCalls,
            window.location.protocol + '//' + window.location.host + '/' + lang.getLanguage() + '/search-calls')

        setAreas(constants.areas);

        const macroBeneficiaries = [];

        for (const code in lang.macro.beneficiares) {

            if (Object.hasOwnProperty.call(lang.macro.beneficiares, code)) {
                const name = lang.macro.beneficiares[code];

                macroBeneficiaries.push({
                    code,
                    name,
                    label: name
                });
            }
        }

        setMacroBeneficiaries(macroBeneficiaries);


        const macroSectors = [];

        for (const code in lang.macro.sectors) {
            if (Object.hasOwnProperty.call(lang.macro.sectors, code)) {
                const name = lang.macro.sectors[code];
                macroSectors.push({
                    code,
                    name,
                    label: name
                });
            }
        }

        setMacroSectors(macroSectors);

    }, []);


    useEffect(() => {

        async function setData(f, p = undefined) {

            setLoading(true);

            console.log('filter', f);

            const { key: username, secret: password } = config.auth
            const { data: token } = await axios.post(config.apiUrl + '/api/auth/access_token', { username, password })

            const params = {
                title: f.search || undefined,
                macroBeneficiaries: f.who || undefined,

                geographic_area: f.where || undefined,
                regions: f.regions || undefined,

                macroSectors: f.what || undefined,
                status: f.status || undefined,
                type: f.type || undefined,
                funding_source: f.fundingSource || undefined,
                page: p ? p : page,
                limit: 25
            };

            console.log('params', params);


            try {

                const response = await axios.get(config.apiUrl + '/api/proposals', {
                    params,
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token.token}`
                    }
                })

                const { data, meta } = response.data;

                setCalls(data);
                setMeta(meta);

                setLoading(false);


            } catch (error) {
                console.log(error);

            }
        }

        setData(filter, page);

        window.scrollTo({ top: '65px', left: 0, behavior: 'smooth' });

    }, [search]);



    return (
        <>
            <Header {...props} />
            <Box sx={{ ...sxMarginTop, backgroundColor: 'transparent' }} >

                <Box sx={{ flexGrow: 1 }}>

                    {!isDesktop && (
                        <Box sx={{ p: 1, display: { xs: 'flex', md: 'none' }, flexDirection: 'column' }}>
                            <Button sx={{ width: '100%' }} variant="contained" size="large" onClick={e => {

                                const curshowFilter = {
                                    label: showFilter.status ? lang.showFilter : lang.hideFilter,
                                    status: !showFilter.status
                                };

                                setShowFilter(curshowFilter);

                            }}>
                                {showFilter.label}
                            </Button>

                            <Collapse in={showFilter.status}>
                                <Box sx={{ width: '100%', height: '100%', backgroundColor: 'rgba(27, 39, 102, .9)' }}>
                                    <BlockFilter {...props} filter={filter} />
                                </Box>
                            </Collapse>

                        </Box>
                    )}


                  
                        <Box sx={{ display: 'flex', flexDirection: 'row', backgroundColor: 'rgba(27, 39, 102, 1)' }}>
                            { isDesktop && (
                                <Box sx={{display: { xs: 'none', md: 'block' } }}>
                                    <Box sx={{
                                        position: 'fixed',
                                        backgroundImage: `url("/bg-search-lx.png")`,
                                        
                                        backgroundPosition: 'left top',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'cover',
                                        height: 'calc(100vh - 95px)',
                                        zIndex: '0',
                                        overflowY: 'auto',
                                    }}>
                                        <Box sx={{ width: '100%', height: 'calc(100vh - 95px)', backgroundColor: 'transparent' }}>
                                            <BlockFilter {...props} filter={filter} />
                                        </Box>
                                    </Box>
                                </Box>
                            )}

                            <Box id="top" sx={{ ml: { xs: 0, md: '320px' }, width: { xs: '100%', md: 'calc(100% - 320px)' }, backgroundColor: '#f2f3f7' }}>

                                <Box sx={{ minHeight: 600, p: 1, bgcolor: '#f2f3f7', borderBottom: '1px solid #ccc' }}>

                                    {loading ? (
                                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '600px' }} >
                                            <CircularProgress color="secondary" />
                                        </Box>
                                    ) : (
                                        <Box component='div' sx={{ p: 0, m: 0 }}>
                                            <Toolbar >

                                                <Grid container rowSpacing={3}>
                                                    <Grid item xs={12} md={6} lg={5}>
                                                        {meta && meta.total !== 0 && (
                                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                <Alert severity='info' sx={{ mr: 1, fontSize: '.7rem' }}>
                                                                    {meta && `${meta.offset + 1} - ${meta.offset + Math.min(meta.limit, meta.total - meta.offset)} / ${meta.total} `}
                                                                </Alert>

                                                                <Typography variant="h6" sx={{ whiteSpace: 'nowrap' }}   >
                                                                    {lang.callsAvailable}
                                                                </Typography>
                                                            </Box>
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={12} md={6} lg={7}>
                                                        {!loading && calls.length !== 0 && (
                                                            <Box sx={{ pb: 2, display: 'flex', justifyContent: { xs: 'center', md: 'flex-end' } }}>
                                                                <SmallPagination />
                                                            </Box>
                                                        )}
                                                    </Grid>
                                                </Grid>

                                            </Toolbar>


                                            <Box sx={{ p: 1 }}>
                                                {!loading && calls.map((item, index) => (
                                                    <CardBlock key={index} areas={areas} item={item} sx={{ mb: 3 }} />
                                                ))}

                                                {!loading && calls.length === 0 && (

                                                    <Box sx={{ height: '50vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        <Typography variant='h5' >{lang.noResults}</Typography>
                                                    </Box>
                                                )}

                                                {!loading && calls.length !== 0 && (
                                                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                                        <SmallPagination />
                                                    </Box>
                                                )}

                                                {loading && <Variants />}

                                            </Box>
                                        </Box>
                                    )}

                                </Box>

                            </Box>

                        </Box>
                   

                </Box>
            </Box>
            <Footer {...props} />

        </>


    )
}


export default withRoot(SearchCalls);