import { createTheme } from '@mui/material/styles';
import { green, grey, red } from '@mui/material/colors';
import { alpha, darken} from '@mui/material';

const rawTheme = createTheme({

	palette: {

		primary: {
			light: '#1b2766',

			blue : '#1b2766',
			royalblue : '#4c4ce5',

			'light-alpha-25': alpha('#1b2766', 0.25),
			'light-alpha-50': alpha('#1b2766', 0.50),

			main: '#ffffff',
			dark:  darken('#1b2766', '50%'),
			black:  '#222'
		},

	
		secondary: {
			light: '#ffffff',
			main: '#1b2766',
			dark:  darken('#1b2766', '80%')
		},

		warning: {
			main: '#ffc071',
			dark: '#ffb25e',
		},

		grey: {
			light: '#eeeeee',
			main: '#bdbdbd',
			dark: '#424242',
		}
	},
	
	typography: {
		fontFamily: "'Comfortaa', sans-serif",
		fontSize: 12,
		fontWeightLight: 300, // Work Sans
		fontWeightRegular: 400, // Work Sans
		fontWeightMedium: 700, // Roboto Condensed
	},
});

const fontHeader = {
	color: rawTheme.palette.text.primary,
	fontWeight: rawTheme.typography.fontWeightMedium,
	fontFamily: rawTheme.typography.fontFamily,
	textTransform: 'none',
};

const theme = {
	...rawTheme,
	palette: {
		...rawTheme.palette,
		background: {
			...rawTheme.palette.background,
			default: rawTheme.palette.common.white,
			placeholder: grey[200],
		},
	},
	typography: {
		...rawTheme.typography,
		fontHeader,
		h1: {
			...rawTheme.typography.h1,
			...fontHeader,
			letterSpacing: 0,
			fontSize: 58,
		},
		h2: {
			...rawTheme.typography.h2,
			...fontHeader,
			fontSize: 46,
		},
		h3: {
			...rawTheme.typography.h3,
			...fontHeader,
			fontSize: 40,
		},
		h4: {
			...rawTheme.typography.h4,
			...fontHeader,
			fontSize: 34,
		},
		h5: {
			...rawTheme.typography.h5,
			fontSize: 18,
			fontWeight: rawTheme.typography.fontWeightLight,
		},
		h6: {
			...rawTheme.typography.h6,
			...fontHeader,
			fontSize: 16,
		},
		subtitle1: {
			...rawTheme.typography.subtitle1,
			fontSize: 16,
		},
		body1: {
			...rawTheme.typography.body2,
			fontWeight: rawTheme.typography.fontWeightRegular,
			fontSize: 14,
		},
		body2: {
			...rawTheme.typography.body1,
			fontSize: 12,
		},
	},
};

export default theme;
