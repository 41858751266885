import React, { useEffect } from 'react';

import { Box, Fab, Stack, Typography} from '@mui/material';

import {  useLocation,  matchRoutes, Link as RouterLink} from "react-router-dom";


import Header from '../modules/header';
import Footer from '../modules/footer';
import withRoot from '../modules/withRoot';
import useMediaQuery from '@mui/material/useMediaQuery';


import lang from '../lang';
import glossaryList from '../glossaryList';
import GotoUp from '../modules/gotoUp';

import helper from '../helper';
import config from '../../config';


const {ProductHeroLayoutPage, Background, sxMarginTop, createMetaInfo} = helper;

const sxBackground = {
    backgroundImage: `url('${config.hostCDN}/glossary.png')`,
    backgroundColor: 'tranparent', // Average color of the background image.
    backgroundPosition: 'center',
}

function Glossary(props) {

    //const isDesktop = useMediaQuery('(min-width: 660px)');

    const { currentLanguage } = props;
	lang.setLanguage(currentLanguage.code);
	

    const routes = [{ path: "/:lang/glossary"}]
    const location = useLocation()
    const url = matchRoutes(routes, location)[0].pathname;

    
    useEffect(() => {
        
        document.title =  lang.glossary + ' - ' + lang.myFundsApp

        createMetaInfo( 
            document.title, 
            lang.metaDescription,
            window.location.protocol + '//' + window.location.host + '/' + lang.getLanguage() + '/glossary')


        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);


    const indexGlossary = [];

   
    for (const key in glossaryList[currentLanguage.code]) {

        if (Object.hasOwnProperty.call(glossaryList[currentLanguage.code], key)) {
            const item = glossaryList[currentLanguage.code][key];

            const list = [];
            for (const code in item) {
                list.push({code, value : item[code]})
            }

            indexGlossary.push({key, list})
        }
    }


    //console.log(indexGlossary);



    return (

        <>
            <Header {...props} />

            <Box sx={{...sxMarginTop}} id="glossary">

                <Box id='banner' >
                    <ProductHeroLayoutPage>
                        <Background sx={sxBackground} />
                        
                        <Typography align="center" variant="h3" component="h1" sx={{ color: 'primary.main' }}>
                            {lang.glossary}
                        </Typography>
                    </ProductHeroLayoutPage>
                </Box>

                <Box sx={{ pt: 6, m: 0 }}>

                    <Box sx={{ px:2 }} >

                        <Stack direction="row"
                              
                            spacing={{ xs: 1, sm: 2 }} useFlexGap 
                            flexWrap="wrap"
                            maxWidth={'lg'}
                            sx={{mx: 'auto' ,  mb: 5 }}
                                >

                            {indexGlossary.map((item, index) => (                                       

                                <Fab size='small'  
                                    key={index}
                                    title={item.key}
                                    component={RouterLink} 
                                    to={( url + '#' + item.key)} 
                                    scroll={el => { el.scrollIntoView(true); window.scrollBy({left:0, top: -200, behavior: 'smooth'}) }}>
                                        {item.key}
                                </Fab>
                                
                            ))}
                        </Stack>

                        <Box sx={{ mb: 10 }} >

                            <Stack maxWidth={'lg'} sx={{ mx: 'auto' }} spacing={3} direction="column">
                                {indexGlossary.map((item, index) => (
                                    <Stack>

                                        <Stack maxWidth={'lg'} spacing={5} direction="column">
                                            
                                            <Typography id={item.key} key={index} variant="h2" component="h2" sx={{ color: 'primary.light', fontSize: 58 , mb:3}}>
                                                {item.key}
                                            </Typography>

                                            {item.list.map( (world, index) => (
                                                <Typography key={index} variant="body" component="div" sx={{ color: 'primary.light', mb: 5}}>
                                                    <strong>{world.code}</strong>: {world.value}
                                                </Typography>
                                            ))} 

                                        </Stack>
                                    </Stack>
                                ))}
                            </Stack>
                        </Box>
                    </Box>
                </Box>

                <GotoUp  />                    
            </Box>
            
            <Footer {...props} />
        </>
    )
}


export default withRoot(Glossary);