import { styled} from '@mui/material/styles';
import { Box } from '@mui/material';


import lang from './lang';

const ProductHeroLayoutRoot = styled('div')(({ theme }) => ({
	color: theme.palette.common.white,
	position: 'relative',
	display: 'flex',
	alignItems: 'center',
	justifyContent : 'center',

	height: '120vh',
    minHeight: '940px',

	[theme.breakpoints.up('md')]: {
		minHeight: '800px',
		height: '90vh',
	},

	[theme.breakpoints.up('lg')]: {
		minHeight: '700px',
		height: '90vh',
	}

}));

const ProductHeroLayoutPage = styled('div')(({ theme }) => ({
	color: theme.palette.common.white,
	position: 'relative',
	
	display: 'flex',
	alignItems: 'center',
	justifyContent : 'center',

	height: '40vh',
    minHeight: '30vh',

	[theme.breakpoints.up('md')]: {
		height: '60vh',
	}
}));


const Background = styled(Box)({
	position: 'absolute',
	left: 0,
	right: 0,
	top: 0,
	bottom: 0,
	backgroundSize: 'cover',
	backgroundRepeat: 'no-repeat',
	zIndex: -5,
});


const sxMarginTop = {
    marginTop: {xs : '56px', lg : '95px'} ,
    position: 'relative'
}


const getLabel = function (array, element) {

	//console.log(array, element);

	if (array && element){
    	const o = array.find(item => item.code === element);
		return o ? o.name : lang.all;
	}
   
	return lang.all;

}


const getArrayLabel = function(array, elements) {

    const ret = [];

    if (!array){
        return lang.all;
    }

    for (const element of elements) {


        const o = array.find(item => ( item.code === element) );

        if (o) {
            ret.push(o.name ?? o.label)
        }
    }

    return ret.length ? ret.join(', ') : lang.all;
}



const createMetaInfo = function (title, description, url) {

	//document.title = title;

	const metaTitle = document.querySelector('meta[property="og:title"]');
	if (metaTitle) {
		metaTitle.setAttribute('content', title);
	}

	const metaTitleGoogle = document.querySelector('meta[itemprop="name"]');
	if (metaTitleGoogle) {
		metaTitleGoogle.setAttribute('content', title);
	}


	const metaDescription = document.querySelector('meta[name="description"]');
	if (metaDescription) {
		metaDescription.setAttribute('content', description);
	}

	const metaDescriptionFB = document.querySelector('meta[property="og:description"]');
	if (metaDescriptionFB) {
		metaDescriptionFB.setAttribute('content', description);
	}

	const metaDescriptionGoogle = document.querySelector('meta[itemprop="description"]');
	if (metaDescriptionGoogle) {
		metaDescriptionGoogle.setAttribute('content', description);
	}


	const metaUrl = document.querySelector('meta[property="og:url"]');
	if (metaUrl) {
		metaUrl.setAttribute('content', url);
	}


	const metaUrlGoogle = document.querySelector('meta[itemprop="url"]');
	if (metaUrlGoogle) {
		metaUrlGoogle.setAttribute('content', url);
	}
		
}




const helper = {
    ProductHeroLayoutRoot,
	ProductHeroLayoutPage,
    Background,
    sxMarginTop,
	getArrayLabel,
	getLabel,
	createMetaInfo
}

export default helper;
