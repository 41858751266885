import { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import {Box, Grid, Link, Typography} from '@mui/material';

import MarkAsUnreadIcon from '@mui/icons-material/MarkAsUnread';

import withRoot from '../withRoot';
import lang from '../../lang';
import { Container } from "@mui/system";
import config from "../../../config";

import axios from "axios";


function BlockCardV2(props){


	//const { url } = useRouteMatch();
	let { lang:langCode = undefined } = useParams();

	if (!langCode){
		langCode = lang.getLanguage().toString();
	}

	
	const {
		title='Title', 
		code, 
		image = config.hostCDN + '/newsletters/' + code + '/cover.png',
		date = '' 
	} = props;

	
	const download = config.hostCDN + '/newsletters/' + code + '/' + langCode + '.pdf';


	return (
		<Box sx={{  display: 'flex', flexDirection: 'column' }}>
			
			<img
				src={image}
				alt={title}
				title={title}
				sx={{width: '100%'}}
			/>
		
	
			<Box sx={{
			
				width: '100%',
				height: 'auto',
				backgroundColor: 'primary.light'
			
			}}>	

				<Box sx={{ display: 'flex', justifyContent: 'center', 	m:0,p:0 }}>

					<Link title={title} sx={{m:0, p:0, textDecoration: 'none'}} href={download} target={'_blank'}> 
						
						<Typography sx={{fontSize: '1.1rem', p:2}} variant="h2" component="h2" color="primary.main" gutterBottom>

							<div style={{
								display: 'flex',
								alignItems: 'center'
							}}>
								<MarkAsUnreadIcon style={{ fontSize: 50 }} sx={{mr:2}} />
								<Box>
									<Box component='span' sx={{display: 'block', mb:1}}>{title}</Box>
									<Box component='span' sx={{display: 'block', fontSize: '.9rem', fontWeight: 'normal'}}>{date}</Box>
								</Box>
							</div>  

						</Typography>                
					</Link>
				</Box>
			</Box>

		</Box>
	)
}



// function BlockCard(props){

// 	//const { url } = useRouteMatch();
// 	let { lang:langCode = undefined } = useParams();

// 	if (!langCode){
// 		langCode = lang.getLanguage().toString();
// 	}

	  
// 	const {title='Title', code} = props;

// 	const download = '/newsletters/' + code + '/' + langCode + '.pdf';

// 	const backgroundColor = 'primary.light';

// 	return (
	
// 		<Box sx={{
// 				display: 'flex',  
// 				justifyContent: 'center', 

// 				width: '96%',
// 				height: 'auto',

// 				backgroundColor,
// 				boxShadow: '10px 10px 0px 0px rgba(196,197,212,1)',
// 			}}
// 		>	
			
		

// 			<Box sx={{ display: 'flex', justifyContent: 'center' }}>


// 				<Link sx={{textDecoration: 'none'}} href={download} target={'_blank'}> 
					
// 					<Typography sx={{fontSize: '1.3rem', p:2}} variant="h2" component="h2" color="primary.main" gutterBottom>

// 						<div style={{
// 								display: 'flex',
// 								alignItems: 'center'
// 							}}>
// 								<MarkAsUnreadIcon style={{ fontSize: 50 }} sx={{mr:2}} />
// 								<span>{title}</span>
// 							</div>  

// 					</Typography>                
// 				</Link>
// 			</Box>
// 		</Box>
// 	)
// }


function Newsletter(props) {

	const [ newsletters, setNewsletters ] = useState([]);

	useEffect( () => {

		async function getData() {

			const { key: username, secret: password } = config.auth
			const { data: token } = await axios.post(config.apiUrl + '/api/auth/access_token', { username, password })

			try {
					
				const response = await axios.get(config.apiUrl + '/api/media/newsletters', {
					params : {},
					headers :   {
						'Content-Type': 'application/json',
						'Authorization': `Bearer ${token}`
					}
				})

				const { data } = response;

				console.log('response', data);

				setNewsletters(data.data);

			} catch (error) {
				console.log(error);
			}
		}

		getData();

	}, []);



    return (

        <Box id="newsletter">

            <Box sx={{width:'100%', flexGrow: 1, mb: 10, px:{ xs: 8,  sm: 5, md:20,  xl: 30 } }}>

				<Container>
					<Grid sx={{margin:"auto"}} container columnSpacing={{ xs: 0, sm: 2, md: 0 }} rowSpacing={5} >
						{newsletters.map( (item, index) => (
							<Grid item key={index} xs={12} sm={4}  >
								<BlockCardV2 title={item.number + ' - ' + item.name} date={item.date} code={item.code} image={item.image} index={index} />
							</Grid>
						))}
					</Grid>
				</Container>

            </Box>


        </Box>
    )
}

export default withRoot(Newsletter);
