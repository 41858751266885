import React, { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AccordionSummary from '@mui/material/AccordionSummary';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/Accordion';
import constants from '../front/constants.js'

import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

import {Typography,InputLabel, OutlinedInput, InputAdornment, IconButton, Select, InputBase} from '@mui/material';
import {RadioGroup, FormGroup, FormControlLabel, Checkbox, Radio, FormLabel} from '@mui/material';


import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import lang from './language';

import config from '../config';

const axios = require('axios').default;


function Filter(props) {

    const {beneficiaries, areas, sectors} = props.relates;

    const [regions, setRegions] = useState([]);
  
    const [values, setValues] = useState({
        search : '',
        age: '',
        year: '',
      });


    useEffect(() => {

        setValues({
            age: '',
            year: '',
        })    

    }, [] );


    function setLocalRegions(e) {

        console.log(e.target.value);
        const {value} = e.target;

        if (value !== 'all'){

            axios.get(config.apiUrl + '/api/areas/' + value  + '/regions')
                .then(function (response) {
                    
                    console.log("bandi filtrati",response.data.data);
                    setRegions(response.data.data);
                })
                .catch(function (error) {
                    console.log(error);
                });   
    
        }
        else{
            setRegions([]);
        }
        
    }


    function onRadioChange(e){

        //console.log();

        if (e.target.value === 'citizens'){
            setValues({
                ...values,
                age : ''
            })  
        }
        else if (['companies', 'smes'].includes( e.target.value) ){
            setValues({
                ...values,
                year : ''
            }) 
        }

        props.onChange(e)
    }



    return (

        <Box p='1' fullWidth display='flex' flex='1' justifyContent='start' style={{backgroundColor: '#eee', padding: '10px', height: '90vh', overflowY: 'scroll' }}>
            {/* <Box sx={{ p: 1, borderRight: '1px solid #dedede' }}> */}
            
            <div >

                <Typography  variant="h5" gutterBottom component="div">
                   {lang.filter}
                </Typography>

                <form onSubmit={props.onSubmitFilter}>
                    
                    <Box mb={2}>

                        <Box sx={{ p: 1, py: 2,  borderBottom: 1, mb: 3 }}>

                            <Box sx={{ display: 'flex', alignItems: 'center' }}>

                                <IconButton sx={{ p: '10px' }} aria-label="menu">
                                    <SearchIcon />
                                </IconButton>

                                <InputBase sx={{ ml: 1, flex: 1 }}
                                    name='title' 
                                    value={values.search ? values.search : ''}
                                    placeholder={lang.searchForEuropeanCalls}
                                    inputProps={{ 'aria-label': lang.searchForEuropeanCalls }}

                                    onChange={event => {

                                        const { value: search } = event.currentTarget;

                                        setValues({ ...values, search });

                                        if (search.length === 0 || search.length >= 3){
                                            props.onChange(event); 
                                        }
                                        
                                      
                                    }}
                                />
                            </Box>
                        </Box>

                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography><FormLabel component="legend">{lang.beneficiaries}</FormLabel> </Typography>
                            </AccordionSummary>

                            <AccordionDetails>
                                <Box p={2}>             
                                    <RadioGroup>
                                        {/* <FormControlLabel key={-1} control={<Radio name='beneficiaries' value='all' onChange={onRadioChange} />} label={lang.all} /> */}
                                        
                                        {beneficiaries && beneficiaries.length>0 && beneficiaries.map((option, index) => (
                                            <FormControlLabel key={index} control={<Radio name='beneficiaries' value={option.code} onChange={onRadioChange} />} label={option.label} />
                                        ))}
                                    </RadioGroup>
                                </Box>
                            </AccordionDetails>

                        </Accordion>
                    </Box>

                    {props.search.beneficiaries === 'citizens' && (
                        <Box mb={2} p={1}>
                        
                            <InputLabel htmlFor="age">{lang.age}</InputLabel>
                            <OutlinedInput
                                id='age'
                                type='number'
                                name='age'
                                
                                onChange={ e => { 
                                    setValues({
                                        ...values,
                                        age: e.target.value
                                    }); 

                                    props.onChange(e)} 
                                } 

                                inputProps={{ min: 0, max: 99 }}

                                value={values.age}
                                
                                endAdornment={
                                    <InputAdornment position="end">
                                    
                                        <IconButton
                                            
                                            onClick={e => {
                                                setValues({
                                                    ...values,
                                                    age: ''
                                                });

                                                e.target.name = 'age';
                                                e.target.value = '';

                                                props.onChange(e) 
                                            }}
                                        >   

                                            <ClearIcon />
                                            
                                        </IconButton>
                                    </InputAdornment>
                                }


                                />
                        </Box>
                    )}

                    {['companies', 'smes'].includes(props.search.beneficiaries)  && (
                        <Box mb={2}>
                            {/* <FormLabel component="legend">{lang.year}</FormLabel>*/}
                            <InputLabel htmlFor="year">{lang.year}</InputLabel>
                            <OutlinedInput
                                id='year' 
                                type='number'
                                name='year' 
                                
                                onChange={ e => { 
                                    
                                    setValues({
                                        ...values,
                                        year: e.target.value
                                    }); 

                                    props.onChange(e)} 
                                } 

                                inputProps={{ min: 1980, max: 3000, maxLength : 4 }} 
                                    
                                value={values.year}

                                endAdornment={
                                    <InputAdornment position="end">
                                    
                                        <IconButton
                                            onClick={e => {
                                                setValues({
                                                    ...values,
                                                    'year': ''
                                                });

                                                e.target.name = 'year';
                                                e.target.value = '';

                                                props.onChange(e) 
                                            }}
                                        >   

                                            <ClearIcon />
                                            
                                        </IconButton>
                                    </InputAdornment>
                                }

                                    />
                        </Box>
                    )}

                    
    
                    <Box mb={2}>
                        
                        <InputLabel htmlFor="area">{lang.areas}</InputLabel>
                        <Select
                            id="area"
                            
                            name="area"
                            fullWidth
                            onChange={ e => { props.onChange(e);  } }
                            // helperText="Please select your currency"
                            
                            >
                            
                            <MenuItem key='all' value='all'>{lang.all}</MenuItem>
                          
                            {constants.areas.map((option) => (
                                <MenuItem key={option.code} value={option.code}>
                                    {lang.countryArray[option.code]}
                                </MenuItem>
                            ))}

                        </Select>
                    </Box>


                    {(regions.length > 0) && (      

                        <Box mb={2}>
                            <FormLabel component="legend">{lang.regions}</FormLabel>                           
                            <FormGroup>
                                {regions.map((option, index) => (
                                    <FormControlLabel key={index} control={<Checkbox name='regions' value={option.code} onChange={props.onChange} />} label={option.name} />
                                ))}
                            </FormGroup>
                        </Box>
                    )}

                    <Box mb={2}>
                        <Accordion >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="sectors-content"
                                id="sectors-header"
                            >
                                <Typography><FormLabel component="legend">{lang.sectors}</FormLabel> </Typography>
                            </AccordionSummary>

                            <AccordionDetails>
                                <Box p={2}>             
                                    <FormGroup>
                                        {sectors.map((option, index) => (
                                            <FormControlLabel key={index} control={<Checkbox name='sectors' value={option.code} onChange={props.onChange} />} label={option.name} />
                                        ))}
                                    </FormGroup>
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    </Box>
        
                </form>

            </div>
            
        </Box>

    );
}



export default Filter;
