import React, { useEffect, useState } from 'react';
import { Link as ReactRouterLink, useNavigate, useParams, matchRoutes, useLocation } from "react-router-dom";
import { Box, Button, IconButton, MenuItem, Menu,  Stack, AppBar, Toolbar, Typography } from '@mui/material';

import MenuIcon from '@mui/icons-material/Menu';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { styled } from '@mui/material/styles';

//import helper from '../helper';
import lang from '../lang';
import glossaryList from '../glossaryList';
// import { NavLink } from 'react-router-dom';
import {Link as RouterLink} from "react-router-dom";
import { HashLink } from 'react-router-hash-link';


const LeftMenuBox = styled(Box)(({ theme }) => ({
    position: 'absolute',
    left: 0,
    top: 0,

    padding: 0,
    margin: 0,

    width: 65,
    height: '100%',

    backgroundColor: theme.palette.primary.light,
    zIndex: 1,
}));

const LeftMenuText = styled(Box)(({ theme }) => ({
    textTransform: 'uppercase',
    writingMode: 'tb-rl',
    transform: 'rotate(-180deg)'
}));


const BottomMenuBox = styled(AppBar)(({ theme }) => ({
    position: 'absolute',

    top: 'auto',
    bottom: 0,
  
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main,
    zIndex: 1,
}));

const BottomMenuText = styled(Box)(({ theme }) => ({
    textTransform: 'uppercase',
    color: theme.palette.primary.light,
}));


let leftMenuItem = [];
let submenuGlossary = [];

function LeftMenu(props) {
    const navigate = useNavigate();

    // const url = useMatch('/');
  
    const routes = [{ path: "/:lang/*" }]
    const location = useLocation()
    //console.log(matchRoutes(routes, location))

    const url = matchRoutes(routes, location)[0].pathname;
    

    const [anchorEl, setAnchorEl] = useState(null);
    const [submenuEL, setSubmenuEl] = useState(null);

    const [anchorMobileEl, setAnchorMobileEl] = useState(null);
    const [submenuMobileEL, setSubmenuMobileEl] = useState(null);


    let { lang:langCode = undefined } = useParams();
        
    if (!langCode){
        langCode = lang.getLanguage().toString();
    }


    if ( url.includes('media-4-you' )){

        leftMenuItem = [
            {
                name: lang.media4You,
                code: 'media4You' ,
                submenu:  [
                    {
                        name : lang.communicationCampaigns,
                        code : 'communicationCampaigns',
                        hashLink : '/' + langCode + '/media-4-you#communicationCampaigns',
                        view : true
                    }, {
                        name : lang.influencersAmbassadors,
                        code : 'influencersAmbassadors',
                        hashLink : '/' + langCode + '/media-4-you#influencersAmbassadors',
                        view : false
                    },{
                        name : lang.infographics,
                        code : 'infographics',
                        hashLink : '/' + langCode + '/media-4-you#infographics',
                        view : false
                    }, {
                        name : lang.leaflet,
                        code : 'leaflet',
                        hashLink : '/' + langCode + '/media-4-you#leaflet',
                        view : true
                    },
                    {
                        name : lang.mediaShow,
                        code : 'mediaShow',
                        hashLink : '/' + langCode + '/media-4-you#mediaShow',
                        view : true
                    }, {
                        name : lang.newsletter,
                        code : 'newsletter',
                        hashLink : '/' + langCode + '/media-4-you#newsletter',
                        view : true
                    },{
                        name : lang.onlineDebates,
                        code : 'onlineDebates',
                        hashLink : '/' + langCode + '/media-4-you#onlineDebates',
                        view : false
                    }, {
                        name : lang.pressReleases,
                        code : 'pressReleases',
                        hashLink : '/' + langCode + '/media-4-you#pressReleases',
                        view : true
                    },{
                        name : lang.referenceEvents,
                        code : 'referenceEvents',
                        hashLink : '/' + langCode + '/media-4-you#referenceEvents',
                        view : false
                    }, {
                        name : lang.webinar,
                        code : 'webinar',
                        hashLink : '/' + langCode + '/media-4-you#webinar',
                        view : false
                    }
                ]
            }
        ];

    }

    else if (url.includes('cohesion-policy-approach' )){
        leftMenuItem = [
            {
                name: lang.policyReccomendations,
                code: 'policyReccomendations' ,
                url : lang.catalogFile,
                blank : true
            }
        ];
    }

    else if (url.includes('glossary' )){

        let i = 0;
        const menu = [];
    
        for (const key in glossaryList[langCode]) {
    
            if (Object.hasOwnProperty.call(glossaryList[langCode], key)) {
                
                if (!Array.isArray(menu[i])){
                    menu[i] = [key];
                }
                else if (menu[i].length < 5) {
                    menu[i] = [...menu[i], key];
                }
                else{
                    menu[++i] = [key];
                }
            }
        }
    
        submenuGlossary = [...menu];
        
        leftMenuItem = [
            {
                name : lang.glossary,
                code: 'glossary',
                submenu: true
            }
        ]
    }
    else{

        let i = 0;
        const menu = [];
    
        for (const key in glossaryList[langCode]) {
    
            if (Object.hasOwnProperty.call(glossaryList[langCode], key)) {
                
                if (!Array.isArray(menu[i])){
                    menu[i] = [key];
                }
                else if (menu[i].length < 5) {
                    menu[i] = [...menu[i], key];
                }
                else{
                    menu[++i] = [key];
                }
            }
        }
    
        submenuGlossary = [...menu];
      
        const whereSearch =  `where=${langCode === 'en' ? 'IT' : langCode.toUpperCase()}`;


        leftMenuItem = [
            {
                name : lang.glossary,
                code: 'glossary',
                submenu: true
            }, {
                name : lang.latest_european_calls,
                code: 'eu_cohesion_policy_founds',
                submenu:  [
      
                    {
                        name : lang.fundings.european_regional_development_fund,
                        url : '/' + langCode + `/search-calls?${whereSearch}&fundingSource=european_regional_development_fund&page=1`,
                        view : true
                    },{
                        name : lang.fundings.cohesion_fund,
                        url : '/' + langCode + `/search-calls?${whereSearch}&fundingSource=cohesion_fund&page=1`,
                        view : true
                    },{
                        name : lang.fundings.european_social_fund,
                        url : '/' + langCode + `/search-calls?${whereSearch}&fundingSource=cohesion_fund&page=1`,
                        view : true
                    },{
                        name : lang.fundings.just_transition_fund,
                        url : '/' + langCode + `/search-calls?${whereSearch}&fundingSource=just_transition_fund&page=1`,
                        view : true
                    },{
                        name : lang.fundings.EU_solidarity_fund,
                        url : '/' + langCode + `/search-calls?${whereSearch}&fundingSource=EU_solidarity_fund&page=1`,
                        view : true
                    }
                ]
            }, {
                name: lang.euCalls,
                code: 'eu_calls',
                url : '/' + langCode + `/search-calls?${whereSearch}&status=open&page=1`
            }
        ];
    }

   
    const handleClick = (event) => {

        const { value } = event.currentTarget;

        const t = leftMenuItem.find(element => element.code === value);

        //console.log(value, t);

        if (t){
            setSubmenuEl(t.submenu);
            setAnchorEl(event.currentTarget);
        }
    };


    const handleClose = (code = null) => {

        if (typeof code === 'string') {

            const page = anchorEl.value;

            setAnchorEl(null);
            setSubmenuEl(null);


            switch (anchorEl.value) {

                case 'glossary':
                    navigate('/' + lang.getLanguage() + '/' + page + '#' + code);
                    break;

                case 'media4You':
                    navigate('/' + lang.getLanguage() + '/media-4-you/' + code);
                    break;    

                default:
                    alert('/' + lang.getLanguage() + '/' + page + '#' + code);
                    break;
            }
        }

        else {
            setAnchorEl(null);
            setSubmenuEl(null);
        }

    };


    useEffect(() => {
        //setLeftMenuItem(leftMenuItem);
    }, []);


    return (
        <>

            <Box sx={{ display: { xs: 'none', md: 'flex' } }} >

                <LeftMenuBox>

                    <Box sx={{ display: 'flex', flexDirection: 'column', py:1 }}>

                        <Box sx={{ my: .5, display: 'flex', width: '100%',  justifyContent:'center' }}>    
                            <MenuIcon />
                        </Box>
                     
                        {leftMenuItem && leftMenuItem.map((item, index) => (

                            <Box key={index} sx={{ my: 0.8, p: 0 }}>

                                {item.url ? (

                                    item.blank ? (

                                        <Button target={'blank'} component={ReactRouterLink} to={item.url} sx={{m:0, p:0}} > 
                                            <LeftMenuText component="span" sx={{ fontSize: '.7rem' }}>
                                                { item.name }
                                            </LeftMenuText>
                                        </Button>
                                    ) : (

                                        item.ref ? (
                                            <Button component={ HashLink } href={item.url + '#' + item.ref } sx={{m:0, p:0}}  >
                                                <LeftMenuText component="span" sx={{ fontSize: '.7rem' }}>
                                                    { item.name }
                                                </LeftMenuText>
                                            </Button>
                                        ) :

                                        (
                                            <Button component={ ReactRouterLink } to={item.url} sx={{m:0,p:0}}  >
                                                <LeftMenuText component="span" sx={{ fontSize: '.7rem' }}>
                                                    { item.name }
                                                </LeftMenuText>
                                            </Button>
                                        )

                                    )
                                 
                                ) : (
                                    <Button value={item.code} variant="text" onClick={ handleClick } sx={{m:0,p:0}} >
                                        <LeftMenuText component="span" sx={{ fontSize: '.7rem' }}>
                                            { item.name }
                                        </LeftMenuText>
                                    </Button>
                                )}
                             
                            </Box>

                        ))}
                    </Box>

                </LeftMenuBox>
            </Box>

            <Box sx={{ display: { xs: 'flex', md: 'none' } }} >

                <BottomMenuBox>
                    <Toolbar>
                        {/* <IconButton color="inherit" aria-label="open drawer">
                            <MenuIcon />
                        </IconButton> */}
                      
                        <Box sx={{ flexGrow: 1 }} />


                        <IconButton color="inherit" aria-label="open drawer" onClick={ event => { setAnchorMobileEl(event.currentTarget)}} >
                            <Typography variant='body2' sx={{textTransform: 'uppercase'}} >{lang.submenu}</Typography> <MoreVertIcon  />
                        </IconButton>    


                        {leftMenuItem && (
                            <Menu

                                open={Boolean(anchorMobileEl)}
                                anchorEl={anchorMobileEl}
                                onClose={ e => {
                                    setAnchorMobileEl(null);
                                    setSubmenuMobileEl(null);

                                }}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                            >
                            
                            {leftMenuItem.map((item, index) => (

                                <MenuItem key={index} sx={{ my: 1, p: 0 }}>
                                    <Button value={item.code} variant="text" onClick={ e => {
                                        setAnchorMobileEl(null);
                                        setSubmenuMobileEl(null);
                                        handleClick(e);
                                    } }>
                                        <BottomMenuText component="span" sx={{ fontSize: '.9rem' }}>{item.name}</BottomMenuText>
                                    </Button>
                                </MenuItem>
                            ))}

                            </Menu>

                        )}

                    </Toolbar>

                </BottomMenuBox>
            </Box>

            {submenuEL && (

                <Menu
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >


                    {anchorEl.value === 'glossary' && submenuGlossary.map( (item, index) => (
                                
                        <MenuItem key={index}>
                            <Stack sx={{ mx: 'auto' }} spacing={0} direction="row">
                                {item.map( (l, i) => (
                                    <Box key={i}  sx={{ width: '40px', color: 'primary.light', m: 0 }} >
                                        <HashLink
                                            style={{textDecoration: 'none', color:'primary.light'}}  
                                            onClick={() => handleClose( l ) }  
                                            scroll={el => { el.scrollIntoView(true); window.scrollBy({left:0, top: -200, behavior: 'smooth'}) }}  
                                            to={ '/' + langCode + '/glossary#' + l }>
                                                {l}
                                        </HashLink>
                                    </Box>
                                ))}
                            </Stack>
                        </MenuItem>
                    ))}
                      
                    
                    { Array.isArray(submenuEL) && submenuEL.map((subitem, index) => {

                        if ( !subitem.view ){
                            return false;
                        }

                        if (subitem.url){
                            return (
                                <MenuItem key={index} component={RouterLink} to={subitem.url} onClick={() => handleClose(subitem.code)}>{subitem.name}</MenuItem>
                            )
                        }
                            
                        else  if (subitem.hashLink){

                            return (
                                <MenuItem key={index} component={HashLink} to={subitem.hashLink} scroll={el => { el.scrollIntoView(true); window.scrollBy({left:0, top: -200, behavior: 'smooth'}) }} onClick={() => handleClose(subitem.code)}>{subitem.name}</MenuItem>

                            )
                        }
                        else{
                            return (
                                <MenuItem key={index}  onClick={() => handleClose(subitem.code)}>{subitem.name}</MenuItem>
                            )
                        }
                    })}

                </Menu>
            )}
        </>
    )
}



export default LeftMenu;
