import React, { useEffect,  useState } from 'react';

import { useNavigate } from "react-router-dom";

import config from '../../config';

import PropTypes from 'prop-types';

import Header from '../modules/header';
import Footer from '../modules/footer';
import withRoot from '../modules/withRoot';

import lang from '../lang';

import { Box, Button, Grid, Link, Typography } from '@mui/material';

import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';

import SearchIcon from '@mui/icons-material/Search';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';

import { FormControlLabel, TextField, Autocomplete } from '@mui/material';
import { useRadioGroup } from '@mui/material/RadioGroup';

import News from '../modules/news';
import Contacts from '../modules/contacts';

import helper from '../helper';
import { Send } from '@mui/icons-material';

import constants from '../constants.js';
import MyMap from '../modules/myMap.js';
	
const { ProductHeroLayoutRoot, Background, sxMarginTop, createMetaInfo } = helper;

const sxBackground = {
	backgroundImage: `url('${config.hostCDN}/home.png')`,
	backgroundColor: 'tranparent', // Average color of the background image.
	backgroundPosition: 'center',
}


function MyFormControlLabel(props) {
	const radioGroup = useRadioGroup();

	let checked = false;

	if (radioGroup) {
		checked = radioGroup.value === props.value;
	}

	return <FormControlLabel checked={checked} {...props} />;
}

MyFormControlLabel.propTypes = {
	value: PropTypes.any,
};

const flagSearch = true;
const flagMap = true;




function SearchV2(props) {

	const { currentLanguage } = props;
	lang.setLanguage(currentLanguage.code);

	const { onSearch, filterSelected, onChange } = props;
	
	const [optionsWho, setOptionsWho] = useState([]);
	const [optionsWhere, setOptionsWhere] = useState([]);
	const [optionsWhat, setOptionsWhat] = useState([]);


	useEffect(() => {	
		lang.setLanguage(currentLanguage.code);

		setOptionsWhere(constants.areas.map( e => ({ code : e.code, label : lang.countryArray[e.code] }))); 
		setOptionsWho( constants.macroBeneficiares.map( e => ({ code : e, label : lang.macro.beneficiares[e] })).sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase())) ); 
		setOptionsWhat(constants.macroSectors.map( e => ({ code : e, label : lang.macro.sectors[e] })).sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase())));

	}, 
	[currentLanguage]);


	// useEffect(() => {

	// 	setOptionsWhere(constants.areas.map( e => ({ code : e.code, label : lang.countryArray[e.code] }))); 
	// 	setOptionsWho( constants.macroBeneficiares.map( e => ({ code : e, label : lang.macro.beneficiares[e] })).sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase())) ); 
	// 	setOptionsWhat(constants.macroSectors.map( e => ({ code : e, label : lang.macro.sectors[e] })).sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase())));

	// }, []);


	return (

		<Box sx={{ p: 3, width: '100%', flexGrow: 1 }}>

			<Grid container spacing={1}>

				<Grid item xs={12} sm={6} lg={4} sx={{mb :{ xs: 2}}}>

					<Typography variant="h5" component="h3" sx={{ fontSize: '.9rem', mb: 2, whiteSpace: 'nowrap' }}>
						{lang.whoAreYou}
					</Typography>

					<Autocomplete
						options={ [...optionsWho.slice(1),optionsWho[0]] }
						value={filterSelected.who}


						onChange={(e, v, r) => {
							if (r === 'selectOption') {
								//setFilterSelected({...filterSelected, who : v})
								//filterSelected = { ...filterSelected, who: v }
								onChange(e, { who : v })
							}
						}}

						sx={{
							width: '100%',
							color: 'pripary.light',
							border: '1px solid #eee',
							backgroundColor: 'rgba(255,255,255,.5)'
						}}

						renderInput={(params) => (
							<TextField  {...params} variant="outlined" />
						)}
					/>

				</Grid>

				<Grid item xs={12} sm={6} lg={4} sx={{mb :{ xs: 2}}}>

					<Typography variant="h5" component="h3" sx={{ fontSize: '.9rem', mb: 2, whiteSpace: 'nowrap' }}>
						{lang.whereDoYouLive}
					</Typography>

					<Autocomplete
					
						options={ optionsWhere }

						value={filterSelected.where}

						onChange={(e, v, r) => {
							if (r === 'selectOption') {
								console.log('v', v);
								//setFilterSelected({...filterSelected, where : v})
								//filterSelected = { ...filterSelected, where: v }
								onChange(e, { where : v })
							}
						}}

						sx={{ width: '100%', color: 'pripary.light', border: '1px solid #eee', backgroundColor: 'rgba(255,255,255,.5)' }}
						renderInput={(params) => (
							<TextField {...params} variant="outlined" />
						)}

					/>
				</Grid>

				<Grid item xs={12} lg={4} sx={{mb :{ xs: 2}}}>

					<Typography variant="h5" component="h3" sx={{ fontSize: '.9rem', mb: 2, whiteSpace: 'nowrap' }}>
						{lang.whatAreYouInterestedIn}
					</Typography>

					<Autocomplete
					
						options={optionsWhat}

						value={filterSelected.what}

						onChange={(e, v, r) => {
							if (r === 'selectOption') {
								onChange(e, { what : v })
							}
						}}

						sx={{ width: '100%', color: 'pripary.light', border: '1px solid #eee', backgroundColor: 'rgba(255,255,255,.5)' }}
						renderInput={(params) => (
							<TextField {...params} variant="outlined" />
						)}
					/>
				</Grid>

			</Grid>


			<Box sx={{ display: 'flex', justifyContent: 'center', py: 5  }}>
				<Button sx={{width: {xs: '100%' , sm: 'auto' }, p: 2 }} variant="outlined" size="large" onClick={e => onSearch(e)}><ManageSearchIcon sx={{ fontSize: '52px' }} /> {lang.searchCalls}</Button>
			</Box>
		</Box>

	)
}




function Home(props) {

	const navigate = useNavigate();
	const { currentLanguage } = props;

	lang.setLanguage(currentLanguage.code);

	const langCode = currentLanguage.code === 'en' ? 'IT' : currentLanguage.code.toUpperCase();

	const [filterSelected, setFilterSelected] = useState( {
		who: undefined,
		where: { code : langCode , label : lang.countryArray[langCode] },
		what: undefined,
		search: ''
	});

	const [titles, setTitles] = useState({
		page : lang.myFundsApp,
		placeholder : lang.searchForEuropeanCalls,
		searchSubTitle : lang.searchSubTitle
	});



	function onChange(e, obj){

		// console.log('e', e);
		// console.log('e.target', e.target);
		// console.log('e.target.value', e.target.value);
		// console.log('e.target.name', e.target.name);

		// console.log('obj', obj);	


		setFilterSelected({...filterSelected, ...obj})
	
	}


	function onSearch(e) {

		const param = [];

		if (filterSelected.search.length > 0) {
			param.push("search=" + encodeURIComponent(filterSelected.search.trim()));
		}


		for (const p in filterSelected) {
			if (filterSelected.hasOwnProperty(p) && filterSelected[p]) {
				if (filterSelected[p].code) {
					param.push(encodeURIComponent(p) + "=" + encodeURIComponent(filterSelected[p].code));
				}
			}
		}

		navigate('/' + currentLanguage.code + '/search-calls?' + param.join("&"));
	}


	useEffect(() => {

		document.title = lang.metaTitle;

		createMetaInfo( 
            lang.metaTitle, 
            lang.metaDescription,
            window.location.protocol + '//' + window.location.host + '/' + lang.getLanguage() + '/home')


		window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

		setTitles({
			page : lang.myFundsApp,
			placeholder : lang.searchForEuropeanCalls,
			searchSubTitle : lang.searchSubTitle
		});


		setFilterSelected( filterSelected => {
			return {
				...filterSelected,
				where: { code : langCode , label : lang.countryArray[langCode] }
			}
		})

	}, [currentLanguage]);




	return (
		<>
			<Header {...props} />
			<Box sx={sxMarginTop} id="home">
				<>
					<Box id='banner' >
						<ProductHeroLayoutRoot>
							<Background sx={sxBackground} />

							{/* <LeftMenu {...props} /> */}
							
							<Box sx={{pt: 8, pb: 1  }} >
								<Box sx={{ my: 6 }} >
									<Typography align="center" variant="h3" component="h2" sx={{ mb: 0, color: 'primary.main', fontSize: { sm: '2.5rem', xs: '2rem' } }}>
										{titles.page}
									</Typography>

									{/* {currentLanguage.code !== 'it' &&
										<Typography align="center" variant="h5" component="h3" sx={{ letterSpacing: '6px', fontStyle: 'italic', color: 'primary.main' }}>
											{lang.yourToolToEUFunds}
										</Typography>
									} */}
								</Box>

								{flagSearch ?
									<Box sx={{ mb: {xs: '110px', lg:0} ,  display: 'flex', justifyContent: 'center', width: '100%' }}>		
									
										<Box sx={{width: { xs : '96%', lg: '800px'}, mx: 'auto' }} >
											<Box sx={{ p: 1, 
													borderColor: 'white', 
													backgroundColor: 'primary.main', 
													border: 1, 
													borderBottom: 0, 
													borderRadius: 1, 
													borderEndEndRadius: 0, 
													borderBottomLeftRadius: 0 
											}}>

												<Box component="form" sx={{ p: 1, display: 'flex', alignItems: 'center' }}>
													<IconButton sx={{ p: '10px' }} aria-label="menu">
														<SearchIcon />
													</IconButton>

													<InputBase sx={{ ml: 1, flex: 1 }}

														placeholder={titles.placeholder}
														inputProps={{ 'aria-label': titles.placeholder }}

														onChange={event => {
															setFilterSelected({...filterSelected, search : event.target.value})
															//filterSelected.search = event.target.value;
														}}

														onKeyDown={event => {
															if (event.key === 'Enter') {
																event.preventDefault();
																onSearch(event);
															}
														}}
													/>

												</Box>
											</Box>

											<Box sx={{ pt: 3, px: {xs: 1 , md : 3 }, backgroundColor: 'primary.light-alpha-50', border: 0, borderTop: 0, borderRadius: 2, borderTopRightRadius: 0, borderTopLeftRadius: 0 }}>

												<Typography align="center" variant="h6" component="h4"
													sx={{ textTransform: 'none', mb: 3, fontWeight: 'normal', 
													fontSize: {xs : '0.7rem', md: '1rem'}, 
													
													letterSpacing: '0.1rem', color: 'primary.main' }}>
													{titles.searchSubTitle}
												</Typography>

												<Divider sx={{ borderColor: 'primary.main', border: 1 }} />

												<SearchV2 {...props} filterSelected={filterSelected} onSearch={onSearch} onChange={onChange} />
											</Box>
										</Box>
									</Box>				

									:

									<Box>

										<Divider sx={{ borderColor: 'primary.main', border: 1 }} />

										<Typography align="center" variant="h5" component="h3" sx={{ letterSpacing: '6px', fontSize: '1.8rem', fontStyle: 'italic', color: 'primary.light' }}>
											{lang.doYouWantKnow}
										</Typography>

										<Box sx={{ display: 'flex', justifyContent: 'center', p: 5 }}>
											<Button color='secondary' sx={{ p: 2 }} variant="outlined" size="large"
												component={Link}
												href={'/' + currentLanguage.code + '/home#newslettersRef'}
											>
												{lang.subscribe} <Send sx={{ ml: 2, fontSize: '52px' }} />
											</Button>
										</Box>

									</Box>
								}

							</Box>

						</ProductHeroLayoutRoot>
					</Box>
								
					<Box id="news" sx={{py: {md: 5}}}>
						<News {...props} />
					</Box>

					{/* <Box id="trending">
						<Trending {...props} />
					</Box> */}

					<Box id='contactsRef' >
						<Contacts {...props} />
					</Box>

					{flagMap && <Box id='map'><MyMap {...props} /></Box>}
				</>
			</Box>
			<Footer {...props} />
		</>
	)
}


export default withRoot(Home);