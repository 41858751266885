import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import InsertLinkIcon from '@mui/icons-material/InsertLink';

import lang from './language';

function ItemList(props) {    


    const ondeleteClick = () => {
        console.log(props.maps.beneficiaries);
    }
    
    const card = (
        <React.Fragment>
            <CardContent>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    {props.data.geographic_area.map( item => (
                        <span style={{marginRight: '5px'}}> {item} - {props.maps.areas.get(item)} </span> 
                    ))}
                </Typography>
                <Typography variant="h5" component="div">
                    {props.data.title}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    {props.data.entity}
                </Typography>
                <Typography variant="body2">
                    {props.data.co_financing}
                    
                    <br />
                    {props.data.beneficiaries && props.data.beneficiaries.map( item => (
                        <span style={{marginRight: '5px'}} >{props.maps.beneficiaries.get(item)}</span> 
                    ))}

                    <br />
                    {props.data.sectors && props.data.sectors.map( item => (
                        <span style={{marginRight: '5px'}} >{props.maps.sectors.get(item)}</span> 
                    ))}
                </Typography>
            </CardContent>
            <CardActions>

                <Box component="div" sx={{
                        width : '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        p: 1,
                        m: 1,
                        bgcolor: 'background.paper',
                        borderRadius: 1,
                        }}>
                    

                    <Button variant="contained" target='blank' href={props.data.link}><InsertLinkIcon sx={{marginRight : '5px'}} /> {lang.goto} </Button>
                        

                    <div>
                        <Button sx={{ marginRight: '10px',  }} color="success" variant="contained" href={'/back/proposals/' + props.data._id}><EditIcon sx={{marginRight : '5px'}}/> {lang.edit}</Button> 
                        <Button color="error" variant="contained" onClick={ondeleteClick}><DeleteIcon sx={{marginRight : '5px'}}/> {lang.delete}</Button>
                    </div>    
                    
                   
                </Box>

                
              

                

            </CardActions>
        </React.Fragment>
    );

    return (
        <Box mb={2} sx={{ boxShadow: 1 }}>
            <Card variant="outlined">{card}</Card>
        </Box>
    );

}


export default ItemList;
