import React, {useEffect} from 'react';

import { Box, Typography, Divider, Container } from '@mui/material';



import config from '../../config';
import lang from '../lang';



import Header from '../modules/header';
import Footer from '../modules/footer';
import withRoot from '../modules/withRoot';


import TheProject from '../modules/theProject';
import TargetGroups from '../modules/targetGroups';
import InnovationMeasures from '../modules/innovationMeasures';

import Results from '../modules/results';
import Consortium from '../modules/consortium';


import helper from '../helper';
//const axios = require('axios').default;

const {ProductHeroLayoutPage, Background, sxMarginTop, createMetaInfo} = helper;


const sxBackground= {
	backgroundImage: `url('${config.hostCDN}/about.png')`,
	backgroundColor: 'tranparent', // Average color of the background image.
	backgroundPosition: 'center',
}


const titleStyle = { 
	color: 'primary.light', 
	mb: 3, 
	fontWeight: 700, 
	fontSize: {xs : '1.4rem', sm: '1.8rem'}
};


function About(props) {

	const { currentLanguage } = props;
	lang.setLanguage(currentLanguage.code);


    useEffect(() => {

        document.title = lang.about + ' - ' + lang.myFundsApp

		createMetaInfo( 
            lang.about, 
            lang.metaDescription,
            window.location.protocol + '//' + window.location.host + '/' + lang.getLanguage() + '/about')


		window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);


    return (

        <>
            <Header {...props} />
            <Box sx={sxMarginTop} id="about">
                <Box id='banner' >
                    <ProductHeroLayoutPage>
                        <Background sx={sxBackground} />
						<Typography align="center" variant="h3" component="h1" sx={{ color: 'primary.main' }}>
							{lang.about}
						</Typography>
                    </ProductHeroLayoutPage>
                </Box>

				<Container>
					<Box sx={{p: 0, pt: 6, m: 0 }}>

						<Box sx={{mb: 5 }} >
							<Typography  variant="h4" component="h2" sx={{...titleStyle, textAlign: { xs: 'left', sm : 'center' } }}>
								{lang.theProject}
							</Typography>

							<Typography variant="h5" component="h2" sx={{mb: 4, color: 'primary.light', textAlign: { xs: 'left', sm : 'center'} }}>
								{lang.aboutDescription}
							</Typography>	

							<Typography variant="h4" component="h2" sx={{...titleStyle, textAlign: { xs: 'left', sm : 'center'}, sm: 0}}>
								{lang.projectTargets}
							</Typography>
						</Box>

						<Box id="the-project">
							<TheProject {...props} titleStyle={titleStyle} />
						</Box>	
					</Box>
				</Container>

				<Box id="target-groups">
					<TargetGroups {...props} titleStyle={titleStyle} />
				</Box>		

				<Box id="innovation-measures">
					<InnovationMeasures {...props} titleStyle={titleStyle} />
				</Box>		

				<Box id="results">
					<Results {...props} titleStyle={titleStyle} />
				</Box>		

				<Divider sx={{width: { xs : '100%', sm : '80%'}, mx: 'auto', borderBottomWidth: 2, borderColor: 'primary.light' }} orientation="horizontal" variant="middle" flexItem /> 

				<Box id="consortium">
					<Consortium {...props} titleStyle={titleStyle} />
				</Box>		 	
				
			</Box>
            <Footer {...props} />
        </>
    )
}


export default withRoot(About);