import React, {useEffect} from 'react';

import { Box, Link, Typography } from '@mui/material';

import { Link as RouterLink } from "react-router-dom";

import Header from '../modules/header';
import Footer from '../modules/footer';

import withRoot from '../modules/withRoot';

import helper from '../helper';

import lang from '../lang';

const {sxMarginTop} = helper;


function NotFound(props) {


	const { currentLanguage } = props;
	lang.setLanguage(currentLanguage.code);


    useEffect(() => {

        document.title = lang.notFound + ' - ' + lang.myFundsApp

		helper.createMetaInfo( 
            lang.notFound, 
            lang.metaDescription,
            window.location.protocol + '//' + window.location.host + '/' + lang.getLanguage() + '/about')


		window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);




    return (

        <>
            <Header {...props} />
            <Box sx={sxMarginTop} id="notFound">

                <Box maxWidth='lg' sx={{mx: 'auto',  display : 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', textAlign: 'center', pt: 10, pb: 5 }}>

                    <Box sx={{mb: 5, display: 'flex', width: 1,  justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                        <Box component='img' sx={{width:'auto'}} src='/404-small.png' alt={lang.notFound} />    

                        <Box sx={{mt: 2}}>
                            <Typography variant='h5'>{lang.notFound}</Typography> 
                        </Box>

                        <Link component={RouterLink} to="/" sx={{mt: 2, textDecoration: 'none', color: 'primary.light' }}>{lang.goToHome} {'>'}</Link>

                    </Box>

                    <Box component='img' sx={{width:'90%'}} src='/404.png' alt={lang.notFound} />
                </Box>
            </Box>
            <Footer {...props} />
        </>
    )
}


export default withRoot(NotFound);