
const glossaryList =  {

    en : {

        "A": {
            
            "Acronym": "The acronym is the abbreviation used for the Project title or Project partners",
            "Action": "Set of activities for which Union funding is requested, by means of an individual financing Decision established following a call for proposals",
            "Action plan": "A document providing details on the nature of the actions to be implemented, their timeframe, the players involved, the costs (if any) and funding sources (if any).",
            "Actual cost": "Methods for determining / calculating the eligible costs.Cost which is real (actually incurred, identifiable and verifiable, recorded in the accounts, etc.) and not estimated or budgeted.",
            "Additionality": "Additionality is one of the principles driving the workings of the European Structural and Investment Funds. It stipulates that contributions from the Funds may not result in a reduction of national structural expenditure in those regions, but should be in addition to national public spending.",
            "Applicant": "The legally constituted organisation responsible for correctly administering and delivering the European funding project.",
            "Applicants Manual": "The Applicants Manual is intended to provide applicants detailed and specific information about the programme, project requirements, eligibility rules, application and assessment processes, project implementation principles and to guide them through the drafting of the project proposals.",
            "Audit authority": "The body responsible for ensuring that audits are carried out on the proper functioning of the management & control system of the operational programme and on an appropriate sample of operations based on the declared expenditure.",
        },
    
        "B": {
            "Budget": "EU Member States contribute to a common EU budget in order to achieve common objectives based on their gross national income. The current budget framework covers the period 2021-2027 and EU funds allocated to Cohesion Policy amount to EUR 392 billion.",
            "Beneficiary": "The legal entities who have signed the grant agreement (GA) with the Commission/Agency (i.e. participate in a project supported by an EU grant)." 
        },
    
        "C": {
            "Call for proposals": "Period of time in which applications can be submitted to the Joint Secretariat",
            "Certifying authority": "The CA is mainly responsible for drawing-up and submitting certified statement of expenditure and application for ERDF payment to the European Commission. Moreover, it is responsible for making the ERDF payments to the lead partner of approved projects.",
            "Committee of the Regions": "The Committee of the Regions, consisting of 353 members of elected politicians, is a consultative body that allows local and regional authorities to take part in EU legislative work. The Committee must be consulted when legislation is drafted in areas affecting regional or local life.",
            "Cohesion Fund": "The Cohesion Fund has been used to provide support for the poorer regions of Europe and stabilise their economies with a view to promoting growth, employment and sustainable development. The Fund contributes to financing environmental measures and trans-European transport networks.",
            "Cohesion policy": "Cohesion policy is the EU strategy that aims to strengthen economic and social cohesion by reducing disparities between European regions. It is implemented through specific funds such as the European Regional Development Fund (ERDF), the Cohesion Fund (CF), the European Social Fund Plus (ESF+) and the Just Transition Fund (JTF).",
            "Cohesion report": "The European Commission produces a cohesion report every three years to report on progress towards achieving economic and social cohesion across the European Union. It assesses the socio-economic situation and outlook in all EU regions based on economic, social and territorial indicators.",
            "Coordination Committee for the European Structural and Investment Funds (COESIF)": "It is a standing committee of the European Commission. Its function is to discuss issues relating to the implementation of the regulations governing the Structural and Investment Funds (ESIF).",
            "Control and audit": "It refers to the sound financial management of the European Structural and Investment Funds (ESIF). Member States must assure the Commission that the Funds are being spent effectively and in accordance with the Regulations. An audit authority must be designated for each operational programme.",	
            "Co-financing rate": "It refers to the contribution EU funding makes to a programme and expressed as a percentage of the total programme cost. Co-financing is usually subject to a maximum threshold, which is defined as a percentage of the total value of the programme, or part thereof. ",
            "Cross border cooperation": "EU regional policy programme which promotes projects whose partnership is constituted by partners coming from two or more states and who are located in the border area.",
        },
    
        "D": {
            "Decommitment": "Under the automatic decommitment principle, if a sum committed to a programme has not been claimed by the end of the second year following the programme's adoption, any unpaid money ceases to be available to that programme.",
            "Deliverable": "A deliverable is a side-product or service of the Project that contributes to the development of a Project's main output",
            "Dissemination": "Dissemination of results is the process of making the results and outputs of a Project available to the stakeholders and to a wider audience. Dissemination is essential for take-up, and take-up is crucial for the success of the Project and for the sustainability of outputs in the long term",
            "Double financing/ funding": "Double financing/funding is what happens when the same item (expenditure) is submitted for reimbursement to several donors in order to obtain payment for that item from all of them",
            "Durability of the projects results": "One of the basic requirements of any public funded project is to demonstrate at the application stage that the planned results to be achieved within the project will not be lost at the end of the funding period.",
        },
    
        "E": {
            "EU Solidarity Fund (EUSF)": "It is designed to allow a rapid, efficient and flexible response to emergency situations following major natural disasters. It intervenes mainly in natural disasters which have serious repercussions on living conditions, the natural environment or the economy in one or more regions of a Member State or a candidate country which has already begun accession negotiations.",
            "Economic and social cohesion": "As an expression of solidarity between the EU Member States and their regions, economic and social cohesion aims to achieve balanced socio-economic development throughout the EU. Economic and social cohesion is implemented through the cohesion policy of the EU	",
            "Eligibility of expenditure": "The criteria for eligibility of expenditure determine whether a cost qualifies for funding under the European Structural and Investment Funds (ESIF).",
            "Eligibility check": "The first step of a two-step evaluation procedure. Project proposals applying for ESIF funds have to pass the eligibility check successfully in order to be considered for funding.",
            "Eligibility criteria": "Mainly technical requirements operations applying for ESIF funds have to fulfill in order to pass the eligibility check.",
            "Eligibility period": "The eligibility period is the timeframe during which Project expenditure must be incurred and paid (unless simplified cost options are used) in order to qualify for reimbursement from the Programme funds",
            "Eligible costs": "Costs that are in line with the programme requirements and can be approved for co-financing from the ESIF.",
            "Equal opportunities": "Equality and non-discrimination is a cornerstone of EU law and policy-making. The EU is committed to developing measures to ensure equal opportunities and equality of treatment for people regardless of gender. This ethos applies in all areas of economic, social, cultural and family life.",
            "Europe 2020 Strategy": "It is the EU’s ten-year strategy for smart, sustainable and inclusive growth. In order to deliver on this objective, five ambitious targets have been set, covering employment, research and development, climate change and energy sustainability, education, and the fight against poverty and social exclusion.",
            "European Agricultural Fund for Rural Development (EAFRD)": "This fund supports European policy on rural development. To this end, it finances rural development programmes across the Member States and the regions of the Union. The EAFRD is included in the policy framework of the ESIF.",
            "European Code of Conduct on Partnership": "According to this Code, Member States are required to be transparent in the selection of partners, ensure that partners are involved in all stages of the process, support capacity building of partners, and create platforms for mutual learning and exchange of good practice.",
            "European Employment Strategy (EES)": "It helps European Union countries to create more and better jobs. Objectives, priorities and targets are agreed at EU level. Governments then coordinate their efforts to promote employment.",

            "European Groupings for Territorial Cooperation (EGTC)": "It aims to facilitate and promote cross-border, transnational and interregional cooperation in the EU. Their tasks include the implementation of programmes that are being co-financed by the EU or any other European cross-border cooperation project.",
            "European Maritime and Fisheries Fund (EMFF)": "The EMFF is one of the five European Structural and Investment (ESI) Funds and aims to help fishermen in the transition to sustainable fishing, support coastal communities in diversifying their economies, finance projects that create new jobs and improve quality of life along European coasts.",
            "European Regional Development Fund (ERDF)": "The ERDF aims to strengthen economic and social cohesion in the EU by correcting imbalances between its regions. It grants financial assistance for development projects in the poorer regions, promoting innovation and research; energy efficiency in small- and medium-sized enterprises, housing and public buildings; renewable energy; low-carbon strategies for urban areas. ",
            "European Social Fund (ESF)": "The ESF focuses on improving employment and education opportunities across the European Union. It also aims to improve the situation of the most vulnerable people at risk of poverty. It supports among other the reform of education and training systems, adaptation of skills and qualifications, up-skilling of the labour force, and the creation of new jobs.",
            "European Structural and Investment Funds (ESIF)": "The European Structural and Investment Funds (ESIF) finance the cohesion policy and include five different funds, which are all covered by Regulation (EU) No 1303/2013 ‘Common Provisions Regulation’.",

            "Equipment": "Equipment is a budget line that covers any tool, device, instrument, software, etc. purchased, rented or leased by a partner, necessary to achieve objectives of the project. This includes equipment already in possession by the partner organisation and used to carry out project activities (only pro-rata cost related to the project is eligible).", 
            "Evaluation": "Evaluation is the periodic assessment of the efficiency, effectiveness, impact, sustainability and relevance of a project/programme in the context of stated objectives. This assessment is usually undertaken as an independent examination with a view to drawing lessons that may guide future decision-making."
        },
    
    
        "F": {
            "Financial corrections": "Financial corrections are withdrawals of funding that take place when payments to EU-backed projects have been made in error due to irregularities such as fraud.",
            "Financial instruments": "Financial instruments are a resource-efficient way of using cohesion policy resources to support the Europe 2020 strategy. Targeting projects which could potentially become self-sustainable, they provide investment support through loans, guarantees, or equity.",
            "Flat-rate costs": "Methods for determining / calculating the eligible costs.Cost calculated by applying a percentage fixed in advance to other types of eligible costs."
        },
    
        "G": {
            "Grants": "Direct financial contributions donated from the EU budget, under specific rules and procedures, in order to finance activities that are in line with EU policies, i.e research and innovation, regional & urban development, employment & social inclusion, etc",
            "Global grants": "Global grants help small, non-governmental organisations, such as voluntary groups and community organisations, to obtain support from the European Social Fund (ESF) and the European Regional Development Fund (ERDF).",
            "Good Practice": "it is initiative (e.g. methodologies, projects, processes, techniques) undertaken in one of the programmes thematic priorities which has already proved successful and which has the potential to be transferred to a different geographic area.",
            "Guide for applicants": "The guide aims to assist potential applicants. It provides information about the programme, project requirements, eligibility rules, application and assessment processes and project implementation principles."
        },
    
        "I": {
            "INTERREG EUROPE": "The interregional cooperation programme covers all EU Member States, plus Norway and Switzerland, under the European Territorial Cooperation goal co-funded by the European Regional Development Fund (ERDF). It aims to improve regional development policies through exchanges of experiences and good practice, and to capitalise on regional know-how.",
            "Innovation": "Innovation, whether it relates to the development of new products, processes or organisational techniques innovation can help give economic operators a competitive edge. The EU places the fostering of innovation at the centre of its Europe 2020 strategy with the flagship initiative of the Innovation Union.",
            "Interregional cooperation": "It is aimed at enhancing EU regional development through transfers of know-how and exchanges of experiences between regions. Programmes are part-financed by the ERDF under the interregional cooperation goal INTERACT, ESPON and URBACT",
            "Integrated approach": "cross-sectoral approach, in which projects are linked to different programme subthemes, even though they have to clearly focus only on one",

            "Impacts": "Wider long-term effects on society (including the environment), the economy and science, enabled by the outcomes of R&I investments (long term). It refers to the specific contribution of the project to the work programme expected impacts described in the destination. Impacts generally occur sometime after the end of the project.",
            "In-kind contributions": "In-kind contributions are contributions in the form of provision of works, goods, services, land and real estate for which no cash payment supported by invoices or documents of equivalent probative value has been made.",
            "Indicator": "An indicator is the measurement of an objective to be met, a resource mobilised, an effect obtained, a gauge of quality or a context variable. It should be made up by a definition, a value and a measurement unit.",
            "Indirect costs": "Indirect costs are costs that cannot be assigned in full to the project, as they link to various activities of the partner organisation. As such costs cannot be connected directly to an individual activity, it is difficult to determine precisely the amount attributable to this activity (for instance, telephone, water, electricity expenses, etc.).",
            "Infrastructure and works": "Infrastructure and works is a budget line that covers costs related to investments in infrastructure that do not fall into the scope of other budget lines. This includes costs for site preparation, delivery, handling, installation, renovation, and purchase of land, when applicable.",

        },

        "L" : {
            "Legal entity": "Any natural or legal person created and recognised as such under national law, European Union law or international law.",
            "Lump sum cost": "Methods for determining / calculating the eligible costs. Global amount to cover one or several cost categories or all the costs of the project.",
            "Lead partner (LP)": "A lead partner is the project partner who takes the overall responsibility for the development and the implementation of a project. Each Lead Partner is expected to conclude the Partnership Agreement (with its project partners) and the Subsidy Contract (with the Managing Authority), ensure a sound cross-border/transnational project management as well as the project implementation, and transfer the due ERDF contributions to the single partners.",
        },
    
        "M": {
            "Managing authority": "The MA bears the responsibility for managing and implementing the operational programme; it acts as interface between the European Commission and the participating states and regions and ensures compliance of the programme with Community regulations and policies.",
            "Monitoring": "EU Structural and Investment (ESI) funding must be spent effectively and in accordance with its defined purpose. For this reason, extensive evaluation and monitoring procedures have been established through EU legislation to check that operational programmes using ESI funding are performing properly and delivering results that can be checked against agreed criteria.",
            "Monitoring committee": "Member States are required to appoint monitoring committees to check that operational programmes that use ESIF are being correctly implemented. These committees are chaired by managing authority and comprise regional, economic and social partners."
        },
    
        "N": {
            "N+2": "it relates to financing rules for the annual allocation of money from the ESIF. If the funding has not been spent by that date, the Commission can 'decommit' future budget allocations. Decommitments are made if funding is not spent, or requests for payments are not made, by the end of the second year (n+2).",
            "NUTS": "The nomenclature of territorial units for statistics (NUTS) was created by the European Office for Statistics (Eurostat) in order to create a single and coherent structure of territorial distribution.",
        },
    
        "O": {
            "Operational programme": "A document developed by EU countries and/or regions and approved by the Commission, which defines their priorities as well as the programming required to achieve these priorities.",
            "Outermost regions": "The five French overseas departments (Guadeloupe, French Guyana, Martinique, Réunion, Mayotte); the French overseas communities of Saint-Martin; the Spanish Autonomous Community of the Canary Islands; and the Portuguese autonomous regions of the Azores and Madeira.",

            "Objectives": "The goals of the work performed within the project, in terms of its research and innovation content. This will be translated into the project’s results. These may range from tackling specific research questions, demonstrating the feasibility of an innovation, sharing knowledge among stakeholders on specific issues. The nature of the objectives will depend on the type of action, and the scope of the topic.",
            "Outcomes": "The expected effects, over the medium term, of projects supported under a given topic. The results of a project should contribute to these outcomes, fostered in particular by the dissemination and exploitation measures. This may include the uptake, diffusion, deployment, and/or use of the project’s results by direct target groups. Outcomes generally occur during or shortly after the end of the project."
        },
    
        "P": {
            "Partnership": "Partnership working covers the whole programming process, from the preparatory stage through to the implementation and assessment of results. This approach should lead to better programme outcomes and help ensure that money from the ESIF is spent efficiently.,",
            "Partnership Agreement (PA)": "In order to secure the quality of the implementation of the project, as well as the satisfactory achievement of its goals, the lead partner and the partners have to conclude a partnership agreement that allows to extend the arrangements of the subsidy contract to the level of each partner.",
            "Payments": "When distributing EU Structural and Investment funding to operational programmes running between 2014 and 2020, the Commission can make three types of payments: Pre-financing payments, Interim payments and Payment of the final balance.",
            "Performance Framework": "The performance framework is a set of indicators in each operational programme according to which the Commission, in cooperation with the Member States, shall review the performance of the programmes in each Member State.",
            "Priority": "One of the actions in an operational programme comprising a group of operations which are related and have specific measurable goals. A Priority can cover one or more Specific Objectives.",
            "Programming": "refers to the administrative mechanism used to pursue the objectives of ESIF. Operational programmes ensure consistency and continuity over a seven-year period and relate to specific geographical areas. Programme aims include identifying strategic priorities and indicative actions, outlining financial allocations, and summarising management and control systems.",
            "Project Partner (Beneficiary)": "A Project Partner is a partner organisation involved in the Project implementation",
            "Progress Report": "The Progress Report is the report to be submitted by the Lead Partner at the end of each reporting period outlining performed activities within the period as well as associated eligible expenditure. It documents the progress of the project and serves as reimbursement request."
        },
    
        "R": {
            "Recovery of funds": "Circumstances in which the European Commission has to ask relevant Member States for a reimbursement (recovery) of Structural and Investment funding. Recovery procedures be activated in order to recoup the money. In relation to cohesion policy funds, a certifying authority draws up and sends the Commission an inventory of expenditure and requests for payment for each operational programme.",
            "RegioStars awards": "The objective of the RegioStars Awards is to identify good practices in regional development and to highlight original and innovative projects that could be inspiring to other regions.",
            "Results": "What is generated during the project implementation? This may include, for example, know-how, innovative solutions, algorithms, proof of feasibility, new business models, policy recommendations, guidelines, prototypes, demonstrators, databases and datasets, trained researchers, new infrastructures, networks, etc. Most project results (inventions, scientific works, etc.) are ‘Intellectual Property’, which may, if appropriate, be protected by formal ‘Intellectual Property Rights’.",
            "Result indicator": "A Result indicator is an indicator that describes the result is the change sought (in the reference situation) in view of the specific objective to be achieved",
            "Reporting period": "A reporting period is generally a six-month period, the deadlines for which will be set in the Contract and at the end of which the Lead Partners will have to submit a Progress Report. Reporting periods will be established for each Call for Proposals so that projects implemented within the same Call for Proposals will have the same reporting deadlines. The first and the last reporting periods may differ in length depending on the start/ end date of the project."
        },
    
    
        "S": {
    
            "Sustainable development": "The concept of sustainable development refers to a form of development policy which seeks to satisfy society's economic, social and environmental needs in terms of well-being in the short, medium and - above all - long term.",
            "Structural Funds": "Financial tools set up to implement the regional policy of the European Union. They aim to reduce regional disparities in terms of income, wealth and opportunities. There are two Structural Funds: the European Social Fund (ESF) and the European Regional Development Fund (ERDF). Structural Funds are governed by a single set of rules covering all five European Structural and Investment Funds (ESIF): European Regional Development Fund (ERDF), European Social Fund (ESF), Cohesion Fund (CF), and European Agricultural Fund for Rural Development (EAFRD) and European Maritime & Fisheries Fund (EMFF).",

            "SME": "Small or medium sized enterprise",
            "Subcontracting": "Subcontracting is a process whereby an agreement is entered into to provide goods or services relating to tasks required for the project which cannot be carried out by the beneficiary itself, concluded between a beneficiary and one or more subcontractors for the specific needs of a project. Subcontracting comes from the principle that the beneficiaries must have the operational capacity to implement the project, and is limited to those parts of the work which cannot be carried out by the beneficiary itself. It must not concern \"core\" parts of the project work, and only a limited part of the project can be concluded by a subcontractor where this is necessary for the project implementation. Project management must always be considered to be a core element of the project and must not be the subject of a subcontract.",

        },
    
        "T": {
            "Target group": "A target group is a collection of individuals and/or organisations directly and positively affected by the project outputs. Not necessarily receiving a financial grant and even not directly involved in the project, the target groups may exploit project outputs for their own benefit.",
            "Technical assistance": "Available to help stakeholders implement Commission-funded programmes and projects. Under the European Union's cohesion policy such financial support can be used to pay for preparation, management, evaluation, monitoring, audit and control.",
            "Territorial cohesion": "Strengthening economic and social cohesion by reducing disparities between regions in the EU is a clear objective of the EU",
            "Thematic objectives": "The European Structural and Investment Funds, in particular the European Regional Development Fund (ERDF), the European Social Fund (ESF) and the Cohesion Fund, support various investment priorities, also known as thematic objectives.",
            "Trans-European Networks (TENs)": "Consist of cross-border transport and energy infrastructures. Their purpose is to support and enhance the functioning of the European single market by facilitating the free movement of goods, people and services.",
            "Transnational cooperation": "its main aim is to promote the cooperation and a better integration among large groups of European regions which have similar characteristics",
            "Travel and accommodation costs": "Travel and Accommodation is the budget line that covers travel costs, accommodation costs, costs of meals, visa costs, and/or daily allowances. It applies only to staff of the partner organisation and relates to delivery of the project.",
            "Tools": "A tool is a means for achieving a specific task. Tools should be jointly developed at transnational level and prove an innovative character. Tools can be tangible (physical or technical objects) and intangible (methods, concepts or services). They consist amongst others of analytical tools, management tools, technical tools, software tools, monitoring tools, decision support tools etc.  To be effective, a tool must be tailored to end users’ needs and the respective framework conditions and has to be comprehensive and durable."
        },
    
        "U": {
            "URBACT - Urban Development network programme": "European exchange and learning programme financed by the Commission under its territorial cooperation goal as part of its programmes on interregional cooperation. The aim is to stimulate innovation in urban regeneration by encouraging towns and cities to identify, transfer and disseminate good practice.",
            "Unit cost": "Methods for determining / calculating the eligible costs. A fixed amount per unit. The unit cost can be determined by the commission (e.g. SME owner’s unit cost) or calculated by the beneficiary based on its usual accounting practices (e.g. average personnel cost, internally invoiced goods and services, etc)."
        },

        "V": {
            "Value for money": "The term Value for Money refers to judgement on whether sufficient impact is being achieved for the money spent.",
            "Value-Added Tax (VAT)": "Value-Added Tax (VAT) is consumption tax that is placed on a product whenever value is added at a stage of production and at final sale. Only VAT which is non-recoverable under national VAT legislation may be an eligible expenditure.",
        },
    
        "W": {
            "Work plan": "a plan of activities to be conducted within the lifetime of the project"
        }
    },

    it : {

        A: {
            "Accordo di partenariato": "contratto firmato tra il capofila e i partner del progetto per definire la strategia e le priorità di ogni Stato membro, oltre che le modalità d’impiego per una crescita intelligente, sostenibile e inclusiva che segua le linee guida europee.",
            "Acronimo": "abbreviazione, spesso utilizzata dai partner per dare un titolo al progetto.",
            "Addizionalità": "principio che guida il funzionamento dei fondi strutturali ed europei. Implica che gli aiuti pubblici non devono sostituire le spese private, ma stimolare nuovi investimenti o attività.",
            "Ammissibilità delle spese": " criterio che determina quali spese sono valide e rimborsabili in un progetto finanziato con fondi strutturali o d’investimento europei (fondi SIE).",
            "Approccio integrato": "approccio multidisciplinare, che lega i progetti a diversi sottotemi, accomunati da un unico obiettivo. Prevede l’utilizzo di strumenti finanziari complessi e multilivello.",
            "Assistenza tecnica": "sostegno finanziario per pagare la preparazione, la gestione, la valutazione, il monitoraggio, l’audit e il controllo di un progetto, al fine di rafforzarne le capacità istituzionali e gestionali.",
            "Attrezzature": "attrezzi, dispositivi, strumenti, software, ecc. acquistati, affittati o noleggiati da un partner e necessari per il raggiungimento degli obiettivi programmatici.",
            "Autorità di audit": "organismo incaricato di garantire il corretto funzionamento del sistema di gestione e controllo del programma operativo, sulla base delle spese dichiarate.",
            "Autorità di gestione (AdG)": "è responsabile della gestione operativa e finanziaria del programma; funge da ponte tra la Commissione Europea e gli Stati membri aderenti al progetto e garantisce la conformità ai regolamenti e alle politiche comunitarie.",
            "Autorità di certificazione (CA)": "redige la dichiarazione certificata delle spese e la domanda di pagamento del FESR, da consegnare alla Commissione Europea. Riguardo al FESR, effettua i pagamenti dei progetti approvati al partner capofila.",
            "Attività": "è l’insieme delle azioni fisiche o materiali da realizzare nell’ambito del progetto al fine di conseguire i risultati attesi. Ogni attività è caratterizzata da una durata, da un costo e dall’insieme delle risorse allocate.",
            // "Azione": "Insieme di attività per le quali è richiesto il finanziamento dell'Unione, mediante una decisione di finanziamento individuale stabilita a seguito di un invito a presentare proposte.",
        },

        B: {
            Beneficiari: "soggetti giuridici od organizzazioni che ricevono un finanziamento per realizzare un progetto; oppure, persone od organizzazioni direttamente coinvolte nell’attività realizzata (beneficiari diretti o gruppi target); o ancora, persone od organizzazioni che beneficeranno dell’azione nel lungo periodo e in modo indiretto (beneficiari ultimi).",
            Bilancio: "bilancio comunitario dell’UE per raggiungere obiettivi condivisi. È finanziato dai contributi degli Stati membri e da altre risorse, come i dazi doganali e le quote IVA raccolte. L’attuale quadro di bilancio riguarda il periodo 2021-2027. I fondi UE destinati alle politiche di coesione ammontano a 392 miliardi di euro.",
            "Buona pratica/Best practice": "è un’iniziativa significativa e replicabile (una metodologia di lavoro, un progetto, una tecnica, ecc.) che, in varie occasioni, ha dimostrato di produrre risultati migliori rispetto a quelli attesi. Viene adottata nell’ambito delle priorità tematiche dei programmi.",
        },

        C: {
            "Capofila/Lead partner": "Partner che si assume la responsabilità complessiva dello sviluppo e dell’attuazione del progetto. È tenuto a sottoscrivere l’accordo di partenariato con gli altri partner e il contratto di sovvenzione con l’autorità di gestione. Deve, inoltre, garantire una solida gestione transnazionale del progetto, nonché trasferire i contributi FESR ricevuti ai singoli partner.",

            "Codice europeo di condotta sul partenariato": "Quadro di riferimento per i partner, volto a garantire che gli Stati membri siano coinvolti in tutte le fasi del progetto, dallo sviluppo delle capacità necessarie all’attuazione dello stesso alla creazione di piattaforme per l’apprendimento reciproco e lo scambio di best practice.",
            
            "Coesione socio-economica": "Espressione di solidarietà tra gli Stati membri. Mira a raggiungere uno sviluppo sociale ed economico equilibrato in tutta l’Unione. La coesione è attuata tramite le politiche di coesione europee.",
            
            "Coesione territoriale": "È uno degli obiettivi delle politiche di coesione dell’Unione Europea, che mirano a ridurre le disparità tra le regioni degli Stati membri. I fondi diretti e indiretti (o strutturali) sono gli strumenti chiave per realizzare la coesione territoriale.",
            
            "Comitato delle regioni": "Composto da 353 membri di politici eletti, è un organo consultivo che consente alle autorità locali e regionali di partecipare al lavoro legislativo dell’Unione. Il Comitato viene convocato nel caso in cui occorra elaborare una legge a carattere regionale o locale.",
            
            "Comitato di coordinamento per i fondi strutturali e di investimento europei (COESIF)": "È un comitato permanente della Commissione Europea. Ha il compito di discutere circa le questioni relative all’attuazione delle normative che disciplinano i Fondi strutturali e di investimento (fondi SIE). Il Comitato si riunisce solitamente una volta al mese ed è presieduto dai funzionari provenienti dagli Stati membri e dalla Commissione Europea.",
            
            "Comitato di monitoraggio": "Monitora e controlla che i programmi operativi utilizzino correttamente i fondi SIE. È presieduto dall’autorità di gestione e comprende i partner regionali e socio-economici.",
            
            "Contributi in natura": "Opere, beni, servizi, terreni e immobili per i quali non è stato richiesto alcun pagamento in contanti supportato da fatture o documenti di valore probatorio equivalente.",
            
            "Controllo di ammissibilità": "Procedura di valutazione suddivisa in due fasi; pena la perdita del contributo.",
            
            "Controllo e audit": "Autorità che garantisce la sana gestione finanziaria dei fondi strutturali e di investimento europei (fondi SIE). I fondi dovranno essere spesi in modo efficace e in conformità con i regolamenti.",
            
            "Cooperazione interregionale": "Ha lo scopo di migliorare lo sviluppo regionale dell’Unione, tramite il trasferimento di conoscenze e lo scambio di know-how tra regioni. I programmi cofinanziati dal FESR nell’ambito della cooperazione interregionale sono: INTERACT (sostegno e scambio di best practice tra le autorità responsabili dell’attuazione dei programmi di cooperazione), ESPON (osservatorio di pianificazione territoriale) e URBACT (creazione e sostegno di reti di città e sviluppo urbano).",
            
            "Cooperazione transfrontaliera": "Promuove progetti il cui partenariato è costituito da partner provenienti da due o più Stati confinanti.",
            
            "Cooperazione transnazionale": "Promuove la cooperazione e una migliore integrazione tra gruppi di regioni europee con caratteristiche simili.",
            
            "Costi ammissibili": "Costi in linea con i requisiti del programma. Ovvero, spese effettivamente sostenute e ammissibili al rimborso da parte della Commissione Europea. Tutte le spese devono essere effettive, legittime e comprovabili.",
            
            "Costi indiretti": "Costi non interamente assegnabili al progetto, poiché l’importo attribuibile a tali spese (canone telefonico, acqua, elettricità, ecc.) non può essere determinato con precisione.",
            
            "Costo effettivo": "Costo reale effettivamente sostenuto, identificabile e verificabile tramite un registro contabile.",
            
            "Costo forfettario": "Metodo di finanziamento per determinare/calcolare i costi ammissibili. Si basa su una valutazione standard delle attività da svolgere nell’ambito del progetto.",
            
            "Costo unitario": "Metodo di finanziamento per determinare/calcolare i costi ammissibili, suddivisi per unità di output o risultato raggiunto.",
            
            "Criteri di ammissibilità": "Requisiti tecnici richiesti per l’ottenimento dei fondi SIE.",
            

        },

        D: {
            "Deliverable": "è un prodotto secondario o un servizio che contribuisce allo sviluppo dell’output principale del progetto.",

           "Disimpegno automatico": "principio secondo il quale una somma andrà perduta e non sarà più disponibile, se non richiesta entro la fine del secondo anno successivo all’adozione del programma.", 

           "Diffusione/Dissemination": "insieme delle attività di comunicazione (comunicati stampa, brochure, registrazioni audio-video, social media, ecc.) volte a fornire informazioni chiave sulla qualità, la rilevanza e l’efficacia dei risultati raggiunti.",

           "Doppio finanziamento": "accade quando la stessa voce di spesa viene presentata due o più volte a diversi soggetti e riceve la sovvenzione in modo sovrapposto o duplicato." ,

            "Durabilità dei risultati del progetto": "requisito di base di ogni progetto finanziato con fondi pubblici, secondo il quale i risultati raggiunti non devono andare persi oltre la fine della durata del finanziamento.",
        },

        F: {
            "Fondo di coesione": "il Fondo di coesione è stato creato per ridurre le disparità economiche e sociali tra gli Stati membri; promuovere uno sviluppo sostenibile; migliorare il sistema di reti transeuropee e le infrastrutture di trasporto.",
            "Fondo europeo agricolo per lo sviluppo rurale (FEASR)": "il Fondo europeo agricolo per lo sviluppo rurale (FEASR) mira a innovare il settore agricolo, promuovere la salvaguardia dell’ambiente e migliorare la qualità della vita nelle zone rurali.",
            "Fondo europeo di sviluppo regionale (FESR)": "il Fondo europeo di sviluppo regionale (FESR) mira a rafforzare la coesione economica, sociale e territoriale nell’Unione Europea, correggendo gli squilibri tra le sue regioni.",
            "Fondo europeo per gli affari marittimi e la pesca (FEAMP)": "il Fondo europeo per gli affari marittimi e la pesca (FEAMP) sostiene la pesca nelle acque interne, l’acquacoltura e il settore marittimo, offrendo sostegno per uno sviluppo sostenibile.",
            "Fondo di solidarietà dell’Unione Europea (FSUE)": "il Fondo di solidarietà dell’Unione Europea (FSUE) è nato per rispondere alle grandi calamità naturali ed esprimere solidarietà nei confronti delle regioni UE colpite. Dal 2002, è stato utilizzato per 109 disastri naturali, come inondazioni, incendi boschivi, terremoti, tempeste, siccità e in risposta alle emergenze sanitarie pubbliche.",
            "Fondo sociale europeo Plus (FSE+)": "Il Fondo sociale europeo Plus (FSE+) è il principale strumento dell’Unione per aiutare le persone a trovare un lavoro (o un lavoro migliore) e garantire opportunità di vita più eque per tutti. Offre un contributo importante in materia di occupazione, società e competenze.",
            "Fondi strutturali": "fondi comunitari creati per attuare le politiche di coesione dell’Unione Europea, volte a ridurre le disparità di sviluppo fra le regioni degli Stati membri. I principali sono due: il Fondo sociale europeo Plus (FSE+) e il Fondo europeo di sviluppo regionale (FESR).",
            "Fondi strutturali e di investimento europei (fondi SIE)": "rappresentano il principale strumento della politica di investimento dell’Unione Europea. Comprendono 5 fondi: il Fondo europeo di sviluppo regionale (FESR), il Fondo sociale europeo Plus (FSE+), il Fondo di coesione, il Fondo europeo agricolo per lo sviluppo rurale (FEASR) e il Fondo europeo per gli affari marittimi e la pesca (FEAMP)."
            
        },

        G: {
            "Gruppi europei di cooperazione territoriale (GECT)": "Hanno lo scopo di promuovere la cooperazione transfrontaliera, transnazionale e interregionale in Europa. I GECT consentono ai partner di attuare progetti comuni, condividere conoscenze e migliorare il coordinamento territoriale.",
            
            "Gruppi target": "individui e/o enti, privati o pubblici, interessati ai risultati del progetto. Tutti i gruppi target, non ricevendo alcuna sovvenzione, possono trarre vantaggio dai risultati del progetto.",
            
            "Guida per i candidati": "manuale di informazioni contenente il programma, i requisiti, le regole di ammissibilità, le modalità di candidatura e i principi di attuazione di un progetto."
            
        },

        I: {
            "Impatto": "effetto ad ampio raggio e di lungo termine sulla società, l’ambiente, l’economia e la scienza, reso possibile dai risultati del progetto; si verifica sempre dopo la sua conclusione.",
            "Imposta sul valore aggiunto (IVA)": "imposta indiretta che riguarda il valore aggiunto della produzione e lo scambio di beni o servizi. Solo l’IVA non recuperabile, secondo la legislazione nazionale, può essere una spesa ammissibile.",
            "Indicatore": "indica la misura dell’obiettivo da raggiungere; si compone di una definizione, un valore e una metrica, che dev’essere specifica, pertinente e rilevata al momento giusto.",
            "Infrastrutture e lavori": "voce di bilancio che copre i costi relativi agli investimenti in infrastrutture: preparazione del sito, consegna, movimentazione, installazione, ristrutturazione e acquisto di terreni.",
            "Innovazione": "sviluppo di nuovi prodotti, processi o tecniche organizzative, in grado di garantire un vantaggio competitivo.",
            "Invito a presentare proposte": "periodo di tempo entro il quale poter presentare le domande al segretariato congiunto.",
            "Interreg": "strumento tramite cui l’Unione Europea sostiene la cooperazione tra regioni e Paesi. Sviluppa i servizi comuni e rafforza la solidarietà tra i popoli."
        },

        M: {
           "Manuale del candidato": "ha lo scopo di fornire ai candidati informazioni precise e dettagliate sul programma, i requisiti del progetto, le regole di ammissibilità, le modalità di candidatura, valutazione e i principi di attuazione del progetto.",

            "Monitoraggio": "insieme delle procedure di valutazione e controllo dei programmi operativi. Mira a fornire indicazioni sullo stato di avanzamento lavori e le eventuali mancanze o criticità.",
        },

        N: {
           "N+2/N+3": "norma relativa ai fondi strutturali, che vincola l’utilizzo dei fondi (presentazione della domanda di pagamento) entro il secondo o terzo anno successivo a quello del loro impegno (allocazione dei fondi). I fondi non utilizzati vengono disimpegnati, ovvero tornano all’Unione Europea e non sono più disponibili per i beneficiari.",

            "NUTS": "acronimo indicante la nomenclatura delle unità territoriali per la statistica (NUTS, appunto), creato dall’Ufficio europeo di statistica (Eurostat) con l’obiettivo di creare una struttura unica e rendere coerente la distribuzione territoriale.",

        },

        O: {
            "Obiettivi": "risultati perseguiti nell’ambito del progetto. Possono riguardare specifiche domande, o provare a dimostrare la fattibilità di un’innovazione.",

            "Obiettivi tematici": "i fondi strutturali e di investimento europei (fondi SIE) sostengono diverse priorità, note anche come obiettivi tematici.",
        },

        P: {
            "Pagamenti": "nel distribuire i fondi strutturali e i fondi di investimento europei (fondi SIE) ai programmi operativi, la Commissione Europea può effettuare 3 tipi di pagamenti: di prefinanziamento, intermedi e del saldo finale.",
            "Pari opportunità": "l’uguaglianza e la non discriminazione sono un obiettivo del diritto e delle politiche di coesione dell’Unione Europea. Tutti gli organi responsabili della gestione dei fondi UE, specie la Commissione, si impegnano a sviluppare misure che garantiscano pari opportunità e parità di trattamento alle persone, indipendentemente dal sesso. Questo principio si applica in tutti i settori della vita: economica, sociale, culturale e familiare.",
            "Partenariato": "insieme di soggetti pubblici e privati che, nell’ambito dei rispettivi ruoli, concertano la programmazione e l’attuazione degli interventi finalizzati allo sviluppo economico, del territorio e all’integrazione sociale. Il lavoro di partenariato copre l’intero processo di programmazione, dalla fase preparatoria a quella dell’attuazione, fino alla valutazione dei risultati. Questo approccio mira a garantire che il denaro dei fondi SIE sia speso in modo efficace ed efficiente.",
            "Partner di progetto": "organizzazione partner coinvolta nell’attuazione del progetto. Partecipa al progetto di cui il partner capofila o il lead partner è responsabile per la realizzazione. Ogni partner di progetto è obbligato alla realizzazione delle attività preposte ed è responsabile nei confronti dell’istituzione finanziatrice.",
            "Periodo di ammissibilità": "arco temporale entro il quale le spese concernenti il progetto devono essere sostenute e pagate (a meno che non si utilizzino opzioni di costo semplificate) per avere diritto al rimborso dei fondi previsti dal programma.",
            "Periodo di rendicontazione": "generalmente, periodo di 6 mesi le cui scadenze vengono stabilite dal contratto e al termine delle quali il lead partner deve presentare un progress report. I periodi di rendicontazione sono stabiliti per ogni bando in modo che i progetti realizzati nell’ambito di quel bando abbiano le stesse scadenze di rendicontazione. Il primo e l’ultimo periodo di rendicontazione possono avere una durata diversa, a seconda della data di inizio e fine del progetto.",
            "Persona giuridica": "Qualsiasi persona fisica o giuridica riconosciuta come tale dal diritto nazionale, dell’Unione Europea e internazionale.",
            "Piano d’azione": "documento strategico che fornisce dettagli sulla natura delle azioni da intraprendere, il loro calendario, gli attori coinvolti, i costi e le fonti di finanziamento (fondi strutturali o SIE).",
            "Piano di lavoro": "documento strategico che descrive le attività da svolgere entro un determinato periodo di tempo. Spesso, è integrato nella proposta di progetto come guida.",
            "PMI": "piccole o medie imprese.",
            "Politiche di coesione dell’Unione Europea": "mirano a ridurre le disparità tra le regioni degli Stati membri. Vengono attuate nei cicli di programmazione tramite fondi specifici, come: il Fondo europeo di sviluppo regionale (FESR), il Fondo di coesione, il Fondo sociale europeo Plus (FSE+), il Fondo per una transizione giusta, il Fondo di solidarietà dell’Unione Europea, ecc.",
            "Priorità": "azione prioritaria, specifica e misurabile, correlata a un obiettivo progettuale.",
            "Programma operativo": "documento strategico, elaborato dagli Stati membri e approvato dalla Commissione Europea, che definisce le priorità di ogni ciclo di programmazione. Include un’analisi del contesto e individua obiettivi, strategie, assi prioritari, linee di intervento e azioni.",
            "Programmazione": "iter organizzativo in più fasi, finalizzato all’attuazione, su base pluriennale, dell’azione congiunta degli organismi dell’Unione e degli Stati membri. Identifica le priorità strategiche e ne definisce i sistemi di gestione e controllo."
        },

        Q :{
            "Quadro dei risultati": "detto anche “quadro logico”, è un programma suddiviso in livelli (obiettivi specifici, generali, attività, ecc.) e indicatori (valori di base, valori-obiettivo, fonti di verifica e condizioni) che danno un framework di riferimento alla Commissione Europea e agli Stati membri in merito ai risultati raggiunti.",
        },

        R: {
            "Rapporto sulla coesione": "quadro informativo, prodotto ogni 3 anni, sui progressi compiuti nell’ambito delle politiche di coesione dell’Unione Europea. Valuta la situazione socio-economica delle regioni europee sulla base di indicatori economici, sociali e territoriali.",
            "Recupero dei fondi": "circostanza in cui la Commissione Europea chiede agli Stati membri un rimborso o recupero dei fondi strutturali e d’investimento europei (fondi SIE). Un’autorità di certificazione redige e invia alla Commissione un inventario delle spese e delle richieste di pagamento per ogni programma operativo.",
            "Relazione sullo stato di avanzamento": "il cosiddetto progress report, redatto dal lead partner e da presentare alla fine di ogni periodo di rendicontazione, descrive le attività svolte e le relative spese ammissibili, documentando i progressi del progetto. Può servire come base per richiedere un rimborso.",
            "Regioni ultraperiferiche": "i cinque dipartimenti francesi d’oltremare (Guadalupa, Guyana francese, Martinica, Riunione e Mayotte); le comunità francesi d’oltremare di Saint-Martin; la comunità autonoma spagnola delle Isole Canarie; le regioni autonome portoghesi delle Azzorre e di Madeira.",
            "REGIOSTARS": "i premi REGIOSTARS vengono erogati a coloro che hanno adottato le best practice delle politiche di coesione dell’Unione Europea e realizzano progetti originali o innovativi, fonti di ispirazione per altre le regioni d’Europa.",
            "Reti transeuropee (TEN)": "infrastrutture transfrontaliere di trasporto ed energia. Sostengono e migliorano il funzionamento del mercato unico europeo, facilitando la libera circolazione di persone, beni e servizi.",
            "Rettifiche finanziarie": "finanziamenti ritirati, poiché effettuati per errore o a causa di irregolarità come frodi.",
            "Richiedente": "organizzazione legalmente responsabile della corretta amministrazione e della realizzazione del progetto finanziato dalla Commissione Europea.",
            "Risultati": "ciò che il progetto produce in termini concreti e tangibili, anche definibile come “output” o “prodotto” (ad esempio soluzioni innovative, algoritmi, prove di fattibilità, nuovi modelli di business, linee guida, prototipi, banche dati, nuove infrastrutture, ecc.)"
        },


        S: {
            
            "Sovvenzioni": "contributi finanziari diretti, provenienti dal bilancio dell’Unione Europea. Vengono erogati secondo regole e procedure specifiche per attività in linea con le politiche di coesione, come ricerca e sviluppo (R&S), occupazione e inclusione sociale.",
            "Sovvenzioni globali": "aiutano le organizzazioni non governative (ONG) di piccole dimensioni, come gruppi volontari e organizzazioni comunitarie, a ottenere gli aiuti del Fondo sociale europeo Plus (FSE+) e del Fondo europeo di sviluppo regionale (FESR).",
            "Spese di viaggio e alloggio": "voce di bilancio che copre le spese di viaggio, di alloggio, i costi dei pasti, dei visti e le indennità giornaliere. Si applica solo al personale delle organizzazioni partner e si riferisce ai costi sostenuti per la realizzazione del progetto.",
            "Strategia Europa 2020": "strategia decennale dell’Unione Europea per una crescita intelligente, sostenibile e inclusiva. Definisce cinque obiettivi nel campo dell’occupazione, della ricerca e dell’innovazione, del cambiamento climatico e dell’energia, dell’istruzione e della lotta contro la povertà.",
            "Strategia europea per l’occupazione (SEO)": "lanciata nel 1997, aiuta i Paesi dell’Unione Europea a creare nuovi posti di lavoro. Gli sforzi di riforma in materia di mercato del lavoro e politiche sociali, nonché gli obiettivi e le priorità, sono concordati a livello europeo dai governi degli Stati membri.",
            "Strumento": "mezzo per impiegare le risorse derivanti dalle politiche di coesione dell’Unione Europea. Ogni strumento tangibile (fisico o tecnico) e intangibile (metodo, concetto o servizio) va sviluppato a livello transnazionale e deve dimostrare un carattere innovativo, senonché adatto alle richieste e agli scopi comunitari.",
            "Strumento finanziario": "mezzo per sostenere la Strategia Europa 2020. Mira a progetti autosufficienti, che forniscano un sostegno agli investimenti sotto forma di prestiti, garanzie o capitale netto.",
            "Subappalto": "accordo per la fornitura di beni o servizi tra un beneficiario e uno subappaltatore. È retto dal principio secondo cui il beneficiario (del finanziamento) deve avere la capacità operativa per attuare il progetto.",
            "Sviluppo sostenibile": "politica di sviluppo che mira a soddisfare le esigenze economiche, sociali e ambientali della società in termini di benessere sul breve, medio e lungo termine."
        },

        T: {
            "Tasso di cofinanziamento": "il termine si riferisce al contributo apportato dagli aiuti comunitari a un dato programma. È espresso in punti percentuali rispetto al costo complessivo dello stesso. Il livello di co-finanziamento è caratterizzato da una soglia massima, che rappresenta la percentuale del valore totale del programma o di parte di esso. La Commissione Europea stabilisce i tassi di co-finanziamento per ogni programma.",

        },

        U: {
            "URBACT": "programma di cooperazione territoriale, volto a promuovere lo sviluppo urbano sostenibile nelle città di tutta Europa. Si concentra su quattro obiettivi: 1. migliorare la capacità delle città di attuare politiche urbane sostenibili; 2. migliorare la progettazione delle stesse; 3. attuarle; 4. sviluppare e condividere best practice in merito. È finanziato dal Fondo europeo di sviluppo regionale (FESR).",

        },

        V: {	
            "Value for money": "termine che esprime un giudizio positivo sul denaro speso. Sta a indicare che il progetto sta ottenendo un impatto sufficiente in proporzione a quanto è costato.",

            "Valutazione": "giudizio oggettivo, sistematico e periodico su efficienza, impatto, sostenibilità e rilevanza di un progetto. Tenta di fornire informazioni credibili e utili che possano servire da insegnamenti per i processi decisionali futuri.",

        }		
    },

    ro :{

        "A": {
            "Abordare integrată": "Abordare transsectorială, în care proiectele sunt legate de diferite subteme ale programului, chiar dacă trebuie să se concentreze în mod clar doar pe una dintre ele.",
            "Adiționalitate": "Adiționalitatea este unul din principiile care guvernează alocarea Fondurilor Structurale și de Investiții Europene. Acesta stipulează ideea conform căreia contribuțiile din fonduri nu pot duce la o reducere a cheltuielilor structurale naționale în acele regiuni, ci ar trebui să se adauge cheltuielilor publice naționale.",
            "Autoritate de certificare": "Autoritate de certificare este responsabilă de garantarea corectitudinii și probității declarațiilor de cheltuieli și a cererilor de plată ale  FEDR înaintea trimiterii acestora către Comisia Europeană. Mai mult de atât, este responsabilă pentru efectuarea plăților FEDR către partenerul principal al proiectelor aprobate.",
            "Autoritatea de management": "AM poartă responsabilitatea gestionării și implementării programului operațional; aceasta acționează ca interfață între Comisia Europeană și statele și regiunile participante și asigură conformitatea programului cu regulamentele și politicile comunitare.",
            "Autoritate de audit": "Organismul responsabil cu asigurarea efectuării auditurilor privind buna funcționare a sistemului de management și control al programului operațional și pe un eșantion adecvat de operațiuni, bazat pe cheltuielile declarate.",
            "Acronim": "Acronimul este abrevierea folosită pentru titlul proiectului sau partenerii proiectului.",
            "Acțiune": "Set de activități pentru care se solicită finanțare din partea Uniunii, printr-o Decizie de finanțare individuală stabilită în urma unei cereri de finanțare.",
            "Acordul de parteneriat (AP)": "Pentru a asigura calitatea punerii în aplicare a proiectului, precum și realizarea satisfăcătoare a obiectivelor acestuia, partenerul principal și partenerii trebuie să încheie un acord de parteneriat care să permită extinderea modalităților contractului de subvenție la nivelul fiecărui partener.",
            "Apel de proiecte": "Perioada de timp în care cererile de finanțare pot fi depuse la Secretariatul Comun",
            "Asistență tehnică": "Disponibilă pentru a ajuta părțile interesate să pună în aplicare programele și proiectele finanțate de Comisie. În cadrul politicii de coeziune a Uniunii Europene, acest tip de sprijin financiar poate fi utilizat pentru a plăti pregătirea, gestionarea, evaluarea, monitorizarea, auditul și controlul.",
        },
    
        "B": {
            "Buget": "Statele membre ale UE contribuie la finanțarea bugetului UE în vederea realizării obiectivelor comune, în funcție de venitul lor național brut (VNB).Cadrul bugetar actual acoperă perioada 2021-2027, iar fondurile UE alocate Politicii de Coeziune ajung la 392 de miliarde EUR.",
            "Beneficiar": "Persoanele juridice care au semnat Acordul de Grant (AG) cu Comisia/Agenție (adică participă la un proiect susținut de un grant UE).",
            "Bună practică": "Reprezintă o inițiativă (de exemplu, metodologie, proiect, proces, tehnică) întreprinsă în cadrul uneia dintre prioritățile tematice ale programelor, care s-a dovedit deja a fi de succes și care are potențialul de a fi transferată într-o altă zonă geografică.",
        },
    
        "C": {
            "Cadrul de performanță": "Cadrul de performanță este un set de indicatori din cadrul fiecărui program operațional, în funcție de care Comisia, în cooperare cu statele membre, analizează performanța programelor în fiecare stat membru.",
            "Cost forfetar": "Metode de determinare/calculare a costurilor eligibile. Suma globală pentru a acoperi una sau mai multe categorii de costuri sau toate costurile proiectului.",
            "Cost real": "Metode de determinare/calculare a costurilor eligibile. Costul care este real (afectuat efectiv, identificabil și verificabil, înregistrat în conturi etc.) și neestimat sau bugetat.",
            "Costuri forfetare": "Metode de determinare/calculare a costurilor eligibile. Costul calculat prin aplicarea unui procent stabilit în avans la alte tipuri de costuri eligibile.",
            "Comitetul de coordonare a Fondurilor structurale și de investiții europene (COESIF)": "Este un comitet permanent al Comisiei Europene. Rolul său este acela de a discuta pe marginea unor aspecte legate de punerea în aplicare a reglementărilor privind fondurile structurale și de investiții europene (fondurile ESI).",
            "Cooperare transfrontalieră": "Program de politică regională al UE care promovează proiecte al căror parteneriat este constituit din parteneri care provin din două sau mai multe state și care se află în zona de frontieră.",
            "Control și audit": "Termenul „control și audit” se referă la buna gestionare financiară a Fondurilor structurale și de investiții europene (fondurile ESI). Statele membre trebuie să ofere Comisiei Europene garanția că fondurile sunt cheltuite în mod eficient și în conformitate cu reglementările în vigoare. Trebuie desemnată o autoritate de audit pentru fiecare program operational. ",
            "Comitetul Regiunilor": "Comitetul Regiunilor, format din 353 de membri politici aleși, este un organism consultativ care permite autorităților locale și regionale să participe la procesul legislativ al UE. Comitetul Regiunilor trebuie consultat de fiecare dată când este elaborată o nouă legislație cu impact asupra vieții locale sau regionale.",
            "Comitetul de monitorizare": "Statele membre au obligația de a numi comitete de monitorizare pentru a verifica dacă programele operaționale care utilizează ESI sunt puse în aplicare în mod corect. Aceste comitete sunt prezidate de autoritatea de gestionare și sunt formate din parteneri regionali, economici și sociali.",
            "Cooperarea interregională": "Aceasta vizează consolidarea dezvoltării regionale a UE prin transferuri de know-how și schimburi de experiență între regiuni. Programele sunt cofinanțate de FEDR în cadrul obiectivului de cooperare interregională sunt: INTERACT (sprijin și schimb de bune practici între autoritățile însărcinate cu punerea în aplicare a programelor de cooperare), ESPON (observatorul de planificare teritorială) și URBACT (crearea și sprijinirea rețelelor de orașe și a dezvoltării urbane).",
            "Codul european de conduită privind parteneriatul": "In conformitate cu acest cod, Statele Membre sunt obligate să fie transparente în selectarea partenerilor, să se asigure că partenerii sunt implicați în toate etapele procesului, să sprijine consolidarea capacităților partenerilor și să creeze platforme pentru învățarea reciprocă și schimbul de bune practici.",
            "Coeziunea economică și socială": "Ca expresie a solidarității între statele membre ale UE și regiunile acestora, coeziunea economică și socială își propune să realizeze o dezvoltare socio-economică echilibrată în întreaga UE. Coeziunea economică și socială este implementată prin politica de coeziune a UE",
            "Contribuții în natură": "Contribuțiile în natură sunt contribuții sub formă de furnizare de lucrări, bunuri, servicii, terenuri și bunuri imobiliare pentru care nu s-a efectuat nicio plată în numerar justificată prin facturi sau documente cu valoare probatorie echivalentă.",
            "Costuri eligibile": "Costurile care sunt în conformitate cu cerințele programului și care pot fi aprobate pentru cofinanțare din partea ESIF.",
            "Corecții financiare": "Corecțiile financiare sunt retrageri de fonduri care au loc atunci când plățile pentru proiectele susținute de UE au fost efectuate în mod eronat din cauza unor nereguli, cum ar fi frauda.",
            "Criterii de eligibilitate": "În principal sunt cerințele tehnice pe care trebuie să le îndeplinească operațiunile care solicită fonduri ESIF pentru a trece de verificarea eligibilității.",
            "Costuri indirecte": "Costurile indirecte sunt costuri care nu pot fi atribuite în totalitate proiectului, deoarece sunt legate de diverse activități ale organizației partenere. Deoarece astfel de costuri nu pot fi legate direct de o activitate individuală, este dificil să se determine cu precizie suma care poate fi atribuită acestei activități (de exemplu, cheltuieli cu telefonul, apa, electricitatea etc.). ",
            "Coeziunea teritorială": "Consolidarea coeziunii economice și sociale prin reducerea disparităților dintre regiunile din UE este un obiectiv clar al UE",
            "Cooperare transnațională": "Principalul său scop este de a promova cooperarea și o mai bună integrare între grupuri mari de regiuni europene care au caracteristici similare.",
            "Cheltuieli de călătorie și cazare": "Călătoria și cazarea reprezintă linia bugetară care acoperă costurile de călătorie, costurile de cazare, costurile meselor, costurile vizelor și/sau diurnele. Se aplică numai personalului organizației partenere și sunt conectate la realizarea proiectului.",
            "Cost unitar": "Metode de determinare/calculare a costurilor eligibile. O sumă fixă pe unitate. Costul unitar poate fi determinat de comisie (de exemplu, costul unitar al proprietarului IMM) sau calculat de beneficiar pe baza practicilor sale contabile obișnuite (de exemplu, costul mediu al personalului, bunuri și servicii facturate intern etc.).",
        },
    
        "D": {
            "Dezangajare": "În temeiul principiului dezangajării automate, dacă pentru o sumă alocată unui program nu s-a primit nicio cerere de plată până la sfârșitul celui de-al doilea an de la adoptarea acestuia, orice fonduri necheltuite încetează să mai fie disponibile pentru programul respectiv.",
            "Diseminare": "Diseminarea rezultatelor este procesul prin care rezultatele unui proiect sunt făcute disponibile pentru părțile interesate și pentru un public mai larg. Diseminarea este esențială pentru răspândirea rezultatelor, iar răspândirea și preluarea informațiilor este crucială pentru succesul proiectului și pentru sustenabilitatea rezultatelor pe termen lung.",
            "Dublă Finanțare": "Finanțarea dublă este ceea ce se întâmplă atunci când același articol (cheltuială) este depus spre rambursare mai multor donatori pentru a obține plata pentru acel articol de la toți.",
            "Durabilitatea rezultatelor proiectelor": "Una dintre cerințele de bază ale oricărui proiect cu finanțare publică este să demonstreze în faza de depunere că rezultatele planificate care urmează să fie atinse în cadrul proiectului nu se vor pierde la sfârșitul perioadei de finanțare. ",
            "Dezvoltare durabilă": "Conceptul de dezvoltare durabilă se referă la o formă de politică de dezvoltare care urmărește să satisfacă nevoile economice, sociale și de mediu ale societății în ceea ce privește bunăstarea pe termen scurt, mediu și - mai ales - lung.",
        },
    
        "E": {
            "Entitate juridică": "Orice persoană fizică sau juridică creată și recunoscută ca atare în temeiul legislației naționale, al dreptului Uniunii Europene sau al dreptului internațional.",
            "EU Solidarity Fund (EUSF)": "It is designed to allow a rapid, efficient and flexible response to emergency situations following major natural disasters. It intervenes mainly in natural disasters which have serious repercussions on living conditions, the natural environment or the economy in one or more regions of a Member State or a candidate country which has already begun accession negotiations.",
            "Eligibilitatea cheltuielilor": "Criteriile de eligibilitate a cheltuielilor determină dacă un cost se califică pentru finanțare prin Fondurile structurale și de investiții europene (ESIF).",
            "Egalitatea de șanse": "Egalitatea și nediscriminarea reprezintă o piatră de temelie a legislației și a elaborării politicilor UE. UE se angajează să dezvolte măsuri pentru a asigura egalitatea de șanse și de tratament pentru persoane indiferent de gen. Această etică se aplică în toate domeniile vieții economice, sociale, culturale și familiale.",
            "Echipamente": "Echipamentul este o linie bugetară care acoperă orice unealtă, dispozitiv, instrument, software etc. achiziționate, închiriate sau luate în leasing de către un partener, necesare pentru a atinge obiectivele proiectului. Aceasta include echipamentele aflate deja în posesia organizației partenere și utilizate pentru desfășurarea activităților proiectului (sunt eligibile doar costurile pro-rata legate de proiect). ",
            "Efecte": "Efectele preconizate, pe termen mediu, ale proiectelor sprijinite în cadrul unui anumit subiect. Rezultatele unui proiect ar trebui să contribuie la aceste rezultate, încurajate în special de măsurile de diseminare și exploatare. Acest proces poate include adoptarea, difuzarea, implementarea și/sau utilizarea rezultatelor proiectului de către grupurile țintă directe. Rezultatele apar, în general, în timpul sau la scurt timp după încheierea proiectului.",
            "Evaluare": "Evaluarea reprezintă aprecierea periodică a eficienței, eficacității, impactului, durabilității și relevanței unui proiect/program în contextul obiectivelor declarate. Această evaluare se realizează, de obicei, sub forma unei examinări independente, în vederea extragerii de învățăminte care pot ghida luarea deciziilor viitoare.",
        },
    
    
        "F": {
            "Fondul european agricol pentru dezvoltare rurală (FEADR)": "Acest fond sprijină politica europeană în domeniul dezvoltării rurale. În acest scop, finanțează programe de dezvoltare rurală în toate statele membre și în regiunile Uniunii. FEADR este inclus în cadrul politic al FEIS.",
            "Fonduri structurale": "Instrumente financiare create pentru a pune în aplicare politica regională a Uniunii Europene. Acestea au ca scop reducerea disparităților regionale în ceea ce privește veniturile, bogăția și oportunitățile. Există două fonduri structurale: Fondul social european (FSE) și Fondul european de dezvoltare regională (FEDR). Fondurile structurale sunt guvernate de un set unic de norme care acoperă toate cele cinci fonduri structurale și de investiții europene (ESIF): Fondul european de dezvoltare regională (FEDR), Fondul social european (FSE), Fondul de coeziune (FC) și Fondul european agricol pentru dezvoltare rurală (FEADR) și Fondul european pentru pescuit și afaceri maritime (FEPAM).",
            "Fondurile structurale și de investiții europene (ESIF)": "Fondurile structurale și de investiții europene (ESIF) finanțează politica de coeziune și includ cinci fonduri diferite, care sunt toate reglementate de Regulamentul (UE) nr. 1303/2013 “Regulamentul privind dispozițiile comune”.",
            "Fondul european pentru pescuit și afaceri maritime (EMFF)": "EMFF este unul dintre cele cinci fonduri structurale și de investiții europene (ESI) și are ca scop sprijinirea pescarilor în tranziția către un pescuit durabil, sprijinirea comunităților de coastă în diversificarea economiilor lor, finanțarea proiectelor care creează noi locuri de muncă și îmbunătățirea calității vieții de-a lungul coastelor europene.",
            "Fondul European de Dezvoltare Regională (FEDR)": "FEDR are ca scop consolidarea coeziunii economice și sociale în Uniunea Europeană prin corectarea dezechilibrelor dintre regiunile sale. Fondul acordă asistență financiară pentru proiecte de dezvoltare în regiunile mai sărace. Acesta promovează inovarea și cercetarea; eficiența energetică în întreprinderile mici și mijlocii, în locuințe și în clădirile publice; energia regenerabilă; strategiile cu emisii reduse de dioxid de carbon pentru zonele urbane. ",
            "Fondul social european (FSE)": "FSE se concentrează pe îmbunătățirea oportunităților de ocupare a forței de muncă și de educație în întreaga Uniune Europeană. De asemenea, urmărește să îmbunătățească situația celor mai vulnerabile persoane expuse riscului de sărăcie. Acesta sprijină, printre altele, reforma sistemelor de educație și de formare, adaptarea competențelor și calificărilor, creșterea nivelului de calificare a forței de muncă și crearea de noi locuri de muncă.",
            "Fond de coeziune": "Fondul de coeziune a fost folosit pentru sprijinirea regiunilor mai sărace ale Europei și stabilizarea lor economică, obiectivul său fiind promovarea creșterii, a locurilor de muncă și a dezvoltării durabile. Fondul de coeziune contribuie la finanțarea măsurilor de mediu și a rețelelor transeuropene de transport.",

        },
    
        "G": {
            "Ghid pentru solicitanți": "Ghidul își propune să ajute potențialii solicitanți. Acesta oferă informații cu privire la program, la cerințele proiectelor, la regulile de eligibilitate, la procesele de aplicare și de evaluare și la principiile de implementare a proiectelor.",
            "Grupări Europene de Cooperare Teritorială (GECT)": "Au ca scop facilitarea și promovarea cooperării transfrontaliere, transnaționale și interregionale în UE. Printre sarcinile acestora se numără punerea în aplicare a programelor care sunt cofinanțate de UE sau a oricărui alt proiect european de cooperare transfrontalieră.",
            "Grup țintă": "Un grup-țintă este un ansamblu de persoane și/sau organizații afectate în mod direct și pozitiv de rezultatele proiectului. Fără a primi neapărat o subvenție financiară și chiar fără a fi implicate direct în proiect, grupurile țintă pot exploata rezultatele proiectului în interes propriu.",
        },
    
        "I": {
            "INTERREG EUROPE": "Programul de cooperare interregională acoperă toate statele membre ale UE, plus Norvegia și Elveția, în cadrul obiectivului de cooperare teritorială europeană cofinanțat de Fondul european de dezvoltare regională (FEDR). Scopul său principal este de a îmbunătăți politicile de dezvoltare regională prin schimburi de experiență și bune practici. De asemenea, programul își propune să valorifice know-how-ul regional și bunele practici deja identificate la nivel european.	",
            "Instrumente financiare": "Instrumentele financiare reprezintă o modalitate eficientă de utilizare a resurselor Politicii de Coeziune pentru a sprijini Strategia Europa 2020. Acestea vizează proiecte care ar putea deveni autosuficiente și oferă sprijin pentru investiții prin împrumuturi, garanții sau capitaluri proprii.",
            "Inovare": "Inovarea, fie că se referă la dezvoltarea de noi produse, procese sau tehnici de organizare, poate contribui la oferirea unui avantaj competitiv operatorilor economici. UE plasează încurajarea inovării în centrul strategiei sale Europa 2020, prin inițiativa emblematică “O Uniune a inovării”.",
            "Indicator de rezultat": "Un indicator de rezultat este un indicator care descrie rezultatul ca fiind schimbarea căutată (în situația de referință) în perspectiva obiectivului specific care trebuie atins",
            "IMM": "Întreprindere mică sau mijlocie",
            "Impacturi": "Efecte mai ample pe termen lung asupra societății (inclusiv asupra mediului), economiei și științei, permise de rezultatele investițiilor în C&I (pe termen lung). Se referă la contribuția specifică a proiectului la efectele preconizate ale programului de lucru descrise în destinație. În general, impacturile apar la un moment dat după încheierea proiectului.",
            "Indicator": "Un indicator reprezintă măsurarea unui obiectiv care trebuie atins, a unei resurse mobilizate, a unui efect obținut, a unui indicator de calitate sau a unei variabile de context. Acesta ar trebui să fie alcătuit dintr-o definiție, o valoare și o unitate de măsură.",
            "Infrastructură și lucrări": "Infrastructură și lucrări este o linie bugetară care acoperă costurile legate de investițiile în infrastructură care nu intră în sfera de aplicare a altor linii bugetare. Aceasta include costurile pentru pregătirea amplasamentului, livrarea, manipularea, instalarea, renovarea și achiziționarea de terenuri, dacă este cazul.",
            "Instrumente": "Un instrument este un mijloc de realizare a unei sarcini specifice. Instrumentele ar trebui să fie dezvoltate în comun la nivel transnațional și să dovedească un caracter inovator. Instrumentele pot fi tangibile (obiecte fizice sau tehnice) și intangibile (metode, concepte sau servicii). Acestea constau, printre altele, în instrumente analitice, instrumente de gestionare, instrumente tehnice, instrumente software, instrumente de monitorizare, instrumente de sprijinire a deciziilor etc.  Pentru a fi eficient, un instrument trebuie să fie adaptat la nevoile utilizatorilor finali și la condițiile-cadru respective și trebuie să fie cuprinzător și durabil.",
        },

        "L" : {
            "Livrabil": "Un livrabil este un produs secundar sau serviciu al Proiectului care contribuie la dezvoltarea rezultatului principal al Proiectului."
        },
    
        "M": {
            "Manualul solicitanților": "Manualul solicitanților este menit să ofere solicitanților informații detaliate și specifice despre program, cerințele proiectului, regulile de eligibilitate, procesele de aplicare și evaluare, principiile de implementare a proiectelor și să îi ghideze în procesul de elaborare a propunerilor de proiecte.",
            "Monitorizare": "Fondurile structurale și de investiții ale UE (ESI) trebuie să fie cheltuite în mod eficient și în conformitate cu scopul său definit. Din acest motiv, prin legislația UE au fost stabilite proceduri extinse de evaluare și de monitorizare pentru a verifica dacă programele operaționale care utilizează fonduri ESI funcționează în mod corespunzător și oferă rezultate care pot fi verificate în raport cu criteriile convenite."
        },
    
        "N": {
            "N+2": "Se referă la normele de finanțare pentru alocarea anuală a banilor din FSE. În cazul în care fondurile nu au fost cheltuite până la data respectivă, Comisia poate “dezangaja” viitoarele alocări bugetare. Dezangajările se fac dacă finanțarea nu este cheltuită sau dacă nu se fac cereri de plată până la sfârșitul celui de-al doilea an (n+2).",
            "NUTS": "Nomenclatorul unităților teritoriale pentru statistică (NUTS) a fost creat de Oficiul European de Statistică (Eurostat) pentru a crea o structură unică și coerentă de distribuție teritorială.",
        },
    
        "O": {
            "Obiective": "Scopurile activității desfășurate în cadrul proiectului, din punctul de vedere al conținutului de cercetare și inovare. Acestea se vor traduce în rezultatele proiectului. Acestea pot varia de la abordarea unor întrebări de cercetare specifice, demonstrarea fezabilității unei inovații, schimbul de cunoștințe între părțile interesate cu privire la probleme specifice. Natura obiectivelor va depinde de tipul de acțiune și de domeniul de aplicare al subiectului.",
            "Obiective tematice": "Fondurile structurale și de investiții europene, în special Fondul european de dezvoltare regională (FEDR), Fondul social european (FSE) și Fondul de coeziune, sprijină diferite priorități de investiții, cunoscute și sub denumirea de obiective tematice.",
        },
    
        "P": {
            "Partener de proiect (beneficiar)": "Un Partener de proiect este o organizație parteneră implicată în implementarea Proiectului",
            "Parteneriat": "Lucrul în parteneriat acoperă întregul proces de programare, de la etapa pregătitoare până la punerea în aplicare și evaluarea rezultatelor. Această abordare ar trebui să ducă la rezultate mai bune ale programelor și să contribuie la asigurarea cheltuirii eficiente a banilor din FESI.",
            "Perioada de eligibilitate": "Perioada de eligibilitate este intervalul de timp în care trebuie efectuate și plătite cheltuielile proiectului (cu excepția cazului în care se utilizează opțiuni de costuri simplificate) pentru a se califica pentru rambursare din fondurile programului",
            "Perioada de raportare": "O perioadă de raportare este, în general, o perioadă de șase luni, ale cărei termene limită vor fi stabilite în Contract și la sfârșitul căreia Partenerii principali vor trebui să prezinte un Raport de progres. Perioadele de raportare vor fi stabilite pentru fiecare Apel de finanțare, astfel încât proiectele implementate în cadrul aceluiași Apel de finanțare să aibă aceleași termene de raportare. Prima și ultima perioadă de raportare pot avea o durată diferită în funcție de data de începere/finalizare a proiectului.",
            "Plan de acțiune": "Un document care oferă detalii despre natura acțiunilor care urmează să fie implementate, perioada lor de timp, actorii implicați, costurile (dacă există) și sursele de finanțare (dacă există).",
            "Plăți": "La distribuirea fondurilor structurale și de investiții ale UE către programele operaționale care se desfășoară între 2014 și 2020, Comisia poate efectua trei tipuri de plăți: Plăți de prefinanțare, Plăți intermediare și Plata soldului final. ",
            "Politică de coeziune": "Politica de coeziune este strategia UE care urmărește să consolideze coeziunea economică și socială prin reducerea decalajelor dintre regiunile europene. Este implementat prin fonduri specifice precum Fondul European de Dezvoltare Regională (FEDR), Fondul de Coeziune (FC), Fondul Social European Plus (FSE+) și Fondul pentru o Tranziție Justă (JTF).",
            "Plan de lucru": "Un plan de activități care urmează să se desfășoare pe durata de viață a proiectului",
            "Programare": "Se referă la mecanismul administrativ utilizat pentru a urmări obiectivele ESIF. Programele operaționale asigură coerența și continuitatea pe o perioadă de șapte ani și se referă la zone geografice specifice. Obiectivele programelor includ identificarea priorităților strategice și a acțiunilor orientative, conturarea alocărilor financiare și rezumarea sistemelor de gestionare și control.",
            "Program operațional": "Un document elaborat de țările și/sau regiunile UE și aprobat de Comisie, care definește prioritățile acestora, precum și programarea necesară pentru realizarea acestor priorități.",
            "Prioritate": "Una dintre acțiunile dintr-un program operațional care cuprinde un grup de operațiuni care sunt legate între ele și au obiective specifice măsurabile. O Prioritate poate acoperi unul sau mai multe Obiective Specifice.",
            "Premiile RegioStars": "Obiectivul premiilor RegioStars este de a identifica bunele practici în domeniul dezvoltării regionale și de a evidenția proiecte originale și inovatoare care ar putea fi o sursă de inspirație pentru alte regiuni.",
            "Partener principal (LP)": "Un partener principal este partenerul de proiect care își asumă responsabilitatea globală pentru dezvoltarea și implementarea unui proiect. Se așteaptă ca fiecare partener principal să încheie Acordul de parteneriat (cu partenerii săi de proiect) și Contractul de subvenție (cu Autoritatea de management), să asigure o bună gestionare transfrontalieră/transnațională a proiectului, precum și implementarea proiectului și să transfere contribuțiile FEDR datorate partenerilor unici.",
        },
    
        "R": {
            "Raport de progres": "Raportul de progres este raportul care trebuie prezentat de către partenerul principal la sfârșitul fiecărei perioade de raportare și care prezintă activitățile realizate în perioada respectivă, precum și cheltuielile eligibile asociate. Acesta documentează progresul proiectului și servește drept cerere de rambursare.",
            "Raport privind coeziunea": "Odată la trei ani, Comisia Europeană elaborează un raport privind coeziunea, pentru a comunica progresele înregistrate în ceea ce privește realizarea coeziunii economice și sociale la nivelul Uniunii Europene. Raportul analizează situația și perspectiva socio-economică la nivelul tuturor regiunilor UE, pe baza unor indicatori economici, sociali și teritoriali. ",
            "Rată de cofinanțare": "Termenul „rată de cofinanțare” se referă la contribuția pe care finanțarea UE o are în cadrul unui program, fiind exprimată sub forma unui procent din costul total al programului. Cofinanțarea este, de regulă, supusă unui prag maxim, definit ca procent din valoarea totală a programului sau a unei părți din acesta.",
            "Recuperarea fondurilor": "Circumstanțe în care Comisia Europeană trebuie să solicite statelor membre relevante o rambursare (recuperare) a fondurilor structurale și de investiții. Procedurile de recuperare sunt activate în vederea recuperării banilor. În ceea ce privește fondurile politicii de coeziune, o autoritate de certificare întocmește și trimite Comisiei un inventar al cheltuielilor și al cererilor de plată pentru fiecare program operațional.",
            "Rezultate": "Ce se generează în timpul implementării proiectului? Acestea pot include, de exemplu, know-how, soluții inovatoare, algoritmi, dovezi de fezabilitate, noi modele de afaceri, recomandări de politici, orientări, prototipuri, demonstratoare, baze de date și seturi de date, cercetători calificați, noi infrastructuri, rețele etc. Majoritatea rezultatelor proiectului (invenții, lucrări științifice etc.) reprezintă “proprietate intelectuală”, care poate fi protejată, dacă este cazul, prin “drepturi de proprietate intelectuală” oficiale.",
            "Regiuni ultraperiferice": "Cele cinci departamente franceze de peste mări (Guadelupa, Guyana Franceză, Martinica, Réunion, Mayotte); comunitățile franceze de peste mări din Saint-Martin; Comunitatea Autonomă Spaniolă a Insulelor Canare; și regiunile autonome portugheze Azore și Madeira.",
            "Rețele transeuropene (TEN)": "Constau în infrastructuri transfrontaliere de transport și energie. Scopul lor este de a sprijini și de a îmbunătăți funcționarea pieței unice europene prin facilitarea liberei circulații a bunurilor, persoanelor și serviciilor.",
            "Raportul calitate-preț": "Termenul “valoare pentru bani” se referă la aprecierea dacă se obține un impact suficient pentru banii cheltuiți.",
        },
        
        "S": {
    
            "Solicitant": "Organizația legal constituită responsabilă cu administrarea și livrarea corectă a proiectului cu finanțare europeană.",
            "Strategia Europa 2020": "Este strategia pe zece ani a UE pentru o creștere inteligentă, durabilă și favorabilă incluziunii. Pentru a îndeplini acest obiectiv, au fost stabilite cinci obiective ambițioase, care vizează ocuparea forței de muncă, cercetarea și dezvoltarea, schimbările climatice și sustenabilitatea energetică, educația și lupta împotriva sărăciei și a excluziunii sociale.",
            "Strategia europeană de ocupare a forței de muncă (EES)": "Aceasta ajută țările Uniunii Europene să creeze locuri de muncă mai multe și mai bune. Obiectivele, prioritățile și țintele sunt convenite la nivelul UE. Guvernele își coordonează apoi eforturile pentru a promova ocuparea forței de muncă.",
            "Subvenții": "Contribuții financiare directe acordate de la bugetul UE, în conformitate cu norme și proceduri specifice, pentru a finanța activități care sunt în conformitate cu politicile UE, de exemplu, cercetare și inovare, dezvoltare regională și urbană, ocuparea forței de muncă și incluziune socială etc.",
            "Subvenții globale": "Subvențiile globale ajută organizațiile neguvernamentale mici, cum ar fi grupurile de voluntariat și organizațiile comunitare, să obțină sprijin din partea Fondului Social European (FSE) și a Fondului European de Dezvoltare Regională (FEDER).",
            "Subcontractare": "Subcontractarea este un proces prin care se încheie un acord de furnizare de bunuri sau servicii legate de sarcini necesare pentru proiect care nu pot fi efectuate de către beneficiarul însuși, încheiat între un beneficiar și unul sau mai mulți subcontractanți pentru nevoile specifice ale unui proiect. Subcontractarea provine din principiul conform căruia beneficiarii trebuie să aibă capacitatea operațională de a implementa proiectul și se limitează la acele părți ale activității care nu pot fi realizate de către beneficiarul însuși. Aceasta nu trebuie să se refere la părțile “de bază” ale activității proiectului și numai o parte limitată a proiectului poate fi încheiată de un subcontractant, în cazul în care acest lucru este necesar pentru punerea în aplicare a proiectului. Managementul proiectului trebuie să fie întotdeauna considerat ca fiind un element central al proiectului și nu trebuie să facă obiectul unui subcontract.",
        },
    
        "T": {
            "Taxa pe valoarea adăugată (TVA)": "Taxa pe valoarea adăugată (TVA) este o taxă pe consum care se aplică unui produs ori de câte ori se adaugă valoare într-o etapă de producție și la vânzarea finală. Numai TVA care nu este recuperabilă în conformitate cu legislația națională privind TVA poate fi o cheltuială eligibilă."
        },
    
        "U": {
            "URBACT - Programul pentru rețeaua de dezvoltare urbană": "Program european de schimb și învățare finanțat de Comisie în baza obiectivului de cooperare teritorială, fiind inclus în programele sale privind cooperarea interregională. Scopul său este acela de a stimula inovarea în materie de regenerare urbană, prin încurajarea municipalităților în identificarea, transferul și diseminarea bunelor practici.",
        },

        "V": {
            "Verificarea eligibilității": "Prima etapă a unei proceduri de evaluare formată din două etape. Propunerile de proiecte care solicită fonduri ESIF trebuie să treacă cu succes verificarea eligibilității pentru a fi luate în considerare spre finanțare."
        }
    },

    gr : {

        "A": {
            "Ακρωνύμιο": "Το ακρωνύμιο είναι η συντομογραφία που χρησιμοποιείται για τον τίτλο του Έργου ή τους εταίρους του Έργου.",
            "Ανάκτηση κεφαλαίων": "Είναι περιστάσεις στις οποίες η Ευρωπαϊκή Επιτροπή πρέπει να ζητήσει από τα αρμόδια κράτη μέλη την επιστροφή (ανάκτηση) των διαρθρωτικών και επενδυτικών κεφαλαίων. Οι διαδικασίες ανάκτησης ενεργοποιούνται προκειμένου να επιστραφούν τα χρήματα. Σε σχέση με τα ταμεία της πολιτικής συνοχής, μια αρχή πιστοποίησης συντάσσει και αποστέλλει στην Επιτροπή κατάλογο των δαπανών και των αιτήσεων πληρωμής για κάθε επιχειρησιακό πρόγραμμα.",
            "Αναφορά Προόδου": "Η Αναφορά Προόδου είναι η έκθεση που πρέπει να υποβάλλεται από τον Επικεφαλής Εταίρο στο τέλος κάθε περιόδου υποβολής αναφορών, στην οποία περιγράφονται οι δραστηριότητες που ολοκληρώθηκαν εντός της περιόδου, καθώς και οι σχετικές επιλέξιμες δαπάνες. Η αναφορά καταγράφει την πρόοδο του έργου και χρησιμεύει ως αίτηση επιστροφής χρημάτων.",
            "Αναφορά/ Έκθεση για τη Συνοχή": "Η Ευρωπαϊκή Επιτροπή συντάσσει έκθεση για τη συνοχή κάθε τρία χρόνια για να αναφέρει την πρόοδο προς την επίτευξη της οικονομικής και κοινωνικής συνοχής σε ολόκληρη την Ευρωπαϊκή Ένωση. Αξιολογεί την κοινωνικοοικονομική κατάσταση και τις προοπτικές σε όλες τις περιφέρειες της ΕΕ με βάση οικονομικούς, κοινωνικούς και εδαφικούς δείκτες.",
            "Αξιολόγηση": "Αξιολόγηση είναι η περιοδική εκτίμηση της αποδοτικότητας, της αποτελεσματικότητας, του αντίκτυπου, της βιωσιμότητας και της συνάφειας ενός έργου/προγράμματος στο πλαίσιο των καθορισμένων στόχων. Η αξιολόγηση αυτή πραγματοποιείται συνήθως ως ανεξάρτητη διαδικασία με σκοπό την εξαγωγή συμπερασμάτων που μπορούν να οδηγήσουν στη λήψη μελλοντικών αποφάσεων.",
            "Αποδέσμευση": "Σύμφωνα με την αρχή της αυτόματης αποδέσμευσης, εάν ένα ποσό που έχει δεσμευτεί για ένα πρόγραμμα δεν έχει ζητηθεί μέχρι το τέλος του δεύτερου έτους μετά την έγκριση του προγράμματος, τα μη καταβληθέντα χρήματα παύουν να είναι διαθέσιμα για το εν λόγω πρόγραμμα. ",
            "Απόκεντρες περιοχές": "Είναι τα πέντε Γαλλικά υπερπόντια διαμερίσματα (Γουαδελούπη, Γαλλική Γουιάνα, Μαρτινίκα, Ρεϋνιόν, Μαγιότ)- οι Γαλλικές υπερπόντιες κοινότητες του Αγίου Μαρτίνου- η ισπανική Αυτόνομη Κοινότητα των Καναρίων Νήσων- και οι Πορτογαλικές αυτόνομες περιοχές των Αζορών και της Μαδέρας.",
            "Αποτελέσματα": "Τι παράγεται κατά την υλοποίηση του έργου; Αυτό μπορεί να περιλαμβάνει, για παράδειγμα, τεχνογνωσία, καινοτόμες λύσεις, αλγορίθμους, αποδείξεις σκοπιμότητας, νέα επιχειρηματικά μοντέλα, προτάσεις πολιτικής, κατευθυντήριες γραμμές, πρωτότυπα, επιδείξεις, βάσεις δεδομένων και σύνολα δεδομένων, καταρτισμένους ερευνητές, νέες υποδομές, δίκτυα κ.λπ. Τα περισσότερα αποτελέσματα του έργου (εφευρέσεις, επιστημονικές εργασίες κ.λπ.) αποτελούν \"πνευματική ιδιοκτησία\", η οποία μπορεί, κατά περίπτωση, να προστατεύεται από τα επίσημα \"Δικαιώματα Πνευματικής Ιδιοκτησίας\".",
            "Αρχή ελέγχου": "είναι ο φορέας που είναι υπεύθυνος για τη διασφάλιση της διενέργειας ελέγχων σχετικά με την ορθή λειτουργία του συστήματος διαχείρισης και ελέγχου του επιχειρησιακού προγράμματος και για το κατάλληλο δείγμα πράξεων με βάση τις δηλωθείσες δαπάνες.",
            "Αρχή πιστοποίησης": "Η ΑΠ είναι κυρίως υπεύθυνη για τη σύνταξη και την υποβολή πιστοποιημένης δήλωσης δαπανών και αίτησης πληρωμής από το ΕΤΠΑ στην Ευρωπαϊκή Επιτροπή. Επιπλέον, είναι υπεύθυνη για την πραγματοποίηση των πληρωμών από το ΕΤΠΑ στον επικεφαλής εταίρο των εγκεκριμένων έργων. ",
        },
    
        "B": {
            "Βιώσιμη ανάπτυξη": "Η έννοια της βιώσιμης ανάπτυξης αναφέρεται σε μια μορφή αναπτυξιακής πολιτικής που επιδιώκει την ικανοποίηση των οικονομικών, κοινωνικών και περιβαλλοντικών αναγκών της κοινωνίας με όρους ευημερίας βραχυπρόθεσμα, μεσοπρόθεσμα και κυρίως μακροπρόθεσμα.",
            "Βραβεία RegioStars": "Στόχος των βραβείων RegioStars είναι να εντοπίσουν καλές πρακτικές στην περιφερειακή ανάπτυξη και να αναδείξουν πρωτότυπα και καινοτόμα έργα που θα μπορούσαν να αποτελέσουν πηγή έμπνευσης για άλλες περιφέρειες.",
        },
    
        "Δ": {
            "Δείκτης": "Ένας δείκτης είναι η μέτρηση ενός στόχου που πρέπει να επιτευχθεί, ενός πόρου που χρησιμοποιείται, ενός αποτελέσματος που επιτυγχάνεται, ενός δείκτη ποιότητας ή μιας μεταβλητής του περιεχομένου.",
            "Δράση": "είναι ένα σύνολο δραστηριοτήτων για τις οποίες ζητείται χρηματοδότηση από την Ευρωπαϊκή Ένωση μέσω ατομικής απόφασης χρηματοδότησης, που εκδίδεται κατόπιν της πρόσκλησης υποβολής προτάσεων.",
            "Δείκτης αποτελεσμάτων": "Ο δείκτης αποτελεσμάτων είναι ένας δείκτης που περιγράφει το αποτέλεσμα, είναι η επιδιωκόμενη αλλαγή (στην κατάσταση αναφοράς) σε σχέση με τον ειδικό στόχο που πρέπει να επιτευχθεί.",
            "Διάδοση": "Η διάδοση των αποτελεσμάτων είναι η διαδικασία παροχής των αποτελεσμάτων και των εκροών ενός έργου στα ενδιαφερόμενα μέρη και στο ευρύτερο κοινό. Η διάδοση είναι απαραίτητη για την ανάληψη, και η ανάληψη είναι ζωτικής σημασίας για την επιτυχία του έργου και για τη βιωσιμότητα των αποτελεσμάτων μακροπρόθεσμα.",
            "Διακρατική συνεργασία": "Κύριος στόχος της είναι η προώθηση της συνεργασίας και της καλύτερης ενσωμάτωσης μεταξύ μεγάλων ομάδων Ευρωπαϊκών περιφερειών που έχουν παρόμοια χαρακτηριστικά.",
            "Διαπεριφερειακή συνεργασία": "Αποσκοπεί στην ενίσχυση της περιφερειακής ανάπτυξης της ΕΕ μέσω της μεταφοράς τεχνογνωσίας και της ανταλλαγής εμπειριών μεταξύ των περιφερειών. Τα προγράμματα που συγχρηματοδοτούνται από το ΕΤΠΑ στο πλαίσιο του στόχου της διαπεριφερειακής συνεργασίας είναι: INTERACT (υποστήριξη και ανταλλαγή ορθών πρακτικών μεταξύ των αρχών που είναι επιφορτισμένες με την εφαρμογή προγραμμάτων συνεργασίας), ESPON (παρατηρητήριο εδαφικού σχεδιασμού) και URBACT (δημιουργία και υποστήριξη δικτύων πόλεων και αστικής ανάπτυξης). ",
            "Διαρθρωτικά Ταμεία": "Είναι χρηματοδοτικά εργαλεία που δημιουργήθηκαν για την εφαρμογή της περιφερειακής πολιτικής της Ευρωπαϊκής Ένωσης. Στόχος τους είναι η μείωση των περιφερειακών ανισοτήτων όσον αφορά το εισόδημα, τον πλούτο και τις ευκαιρίες. Υπάρχουν δύο διαρθρωτικά ταμεία: το Ευρωπαϊκό Κοινωνικό Ταμείο (ΕΚΤ) και το Ευρωπαϊκό Ταμείο Περιφερειακής Ανάπτυξης (ΕΤΠΑ). Τα διαρθρωτικά ταμεία διέπονται από ένα ενιαίο σύνολο κανόνων που καλύπτουν και τα πέντε Ευρωπαϊκά Διαρθρωτικά και Επενδυτικά Ταμεία (ΕΔΕΤ): Το Ευρωπαϊκό Ταμείο Περιφερειακής Ανάπτυξης (ΕΤΠΑ), το Ευρωπαϊκό Κοινωνικό Ταμείο (ΕΚΤ), το Ταμείο Συνοχής (ΤΣ) και το Ευρωπαϊκό Γεωργικό Ταμείο Αγροτικής Ανάπτυξης (ΕΓΤΑΑ) και το Ευρωπαϊκό Ταμείο Θάλασσας και Αλιείας (ΕΤΘΑ).",
            "Διασυνοριακή συνεργασία": "Είναι ένα πρόγραμμα περιφερειακής πολιτικής της ΕΕ που προωθεί έργα των οποίων η σύμπραξη αποτελείται από εταίρους που προέρχονται από δύο ή περισσότερα κράτη που συνορεύουν μεταξύ τους.",
            "Διατήρηση των αποτελεσμάτων του έργου": "Μία από τις βασικές απαιτήσεις κάθε έργου που χρηματοδοτείται από το κράτος είναι να αποδεικνύεται κατά το στάδιο της αίτησης ότι τα προγραμματισμένα αποτελέσματα που θα επιτευχθούν στο πλαίσιο του έργου δεν θα χαθούν στο τέλος της περιόδου χρηματοδότησης.",
            "Διαχειριστική αρχή": "Η ΔΑ φέρει την ευθύνη για τη διαχείριση και την υλοποίηση του επιχειρησιακού προγράμματος, ενεργεί ως ενδιάμεσος φορέας μεταξύ της Ευρωπαϊκής Επιτροπής και των συμμετεχόντων κρατών και περιφερειών και διασφαλίζει τη συνέπεια του προγράμματος με τους κοινοτικούς κανονισμούς και πολιτικές.",
            "Διευρωπαϊκά Δίκτυα (ΔΕΔ)": "Αποτελούνται από διασυνοριακές υποδομές μεταφορών και ενέργειας. Σκοπός τους είναι να υποστηρίξουν και να ενισχύσουν τη λειτουργία της ευρωπαϊκής ενιαίας αγοράς διευκολύνοντας την ελεύθερη κυκλοφορία αγαθών, προσώπων και υπηρεσιών.",
            "Δικαιούχος": "Δικαιούχοι είναι οι νομικές οντότητες που έχουν υπογράψει τη συμφωνία επιχορήγησης με την Επιτροπή/τον Οργανισμό (δηλ. συμμετέχουν σε έργο που υποστηρίζεται από επιχορήγηση της ΕΕ).",
            "Διπλή χρηματοδότηση": "Η διπλή χρηματοδότηση είναι αυτό που συμβαίνει όταν το ίδιο στοιχείο (δαπάνη) υποβάλλεται για αποζημίωση σε διάφορους χρηματοδότες προκειμένου να πληρωθεί το στοιχείο αυτό από όλους αυτούς.",
        },
    
        "E": {
            "Εγχειρίδιο αιτούντων": "Το Εγχειρίδιο αιτούντων παρέχει στους υποψήφιους λεπτομερείς και συγκεκριμένες πληροφορίες σχετικά με το πρόγραμμα, τις απαιτήσεις των έργων, τους κανόνες επιλεξιμότητας, τις διαδικασίες υποβολής αιτήσεων και αξιολόγησης, τις αρχές υλοποίησης των έργων και τους καθοδηγεί κατά τη σύνταξη των προτάσεων έργων. ",
            "Εδαφική συνοχή": "Η ενίσχυση της οικονομικής και κοινωνικής συνοχής μέσω της μείωσης των ανισοτήτων μεταξύ των περιφερειών της ΕΕ αποτελεί ξεκάθαρο στόχο της ΕΕ.",
            "Έλεγχος επιλεξιμότητας": "Είναι το πρώτο βήμα μιας διαδικασίας αξιολόγησης δύο σταδίων. Οι προτάσεις έργων προς χρηματοδότηση από το ΕΚΤ πρέπει να περάσουν επιτυχώς τον έλεγχο επιλεξιμότητας προκειμένου να εξεταστούν για χρηματοδότηση.",
            "Έλεγχος και επιθεώρηση": "Αναφέρεται στη ορθή δημοσιονομική διαχείριση των πόρων των Ευρωπαϊκών Διαρθρωτικών και Επενδυτικών Ταμείων (ΕΔΕΤ).",
            "Έμμεσες δαπάνες": "Οι έμμεσες δαπάνες είναι δαπάνες που δεν μπορούν να αποδοθούν πλήρως στο έργο, καθώς συνδέονται με διάφορες δραστηριότητες του οργανισμού-εταίρου. Καθώς οι δαπάνες αυτές δεν μπορούν να συνδεθούν άμεσα με μια μεμονωμένη δραστηριότητα, είναι δύσκολο να προσδιοριστεί με ακρίβεια το ποσό που αποδίδεται σε αυτή τη δραστηριότητα (για παράδειγμα, έξοδα τηλεφώνου, νερού, ηλεκτρικού ρεύματος κ.λπ.). ",
            "Έξοδα ταξιδιού και διαμονής": "Ταξίδι και διαμονή είναι ένα μέρος του προϋπολογισμού που καλύπτει τα έξοδα ταξιδιού, τα έξοδα διαμονής, τα έξοδα διατροφής, τα έξοδα θεώρησης ή/και τα ημερήσια επιδόματα. Αφορά μόνο το προσωπικό του οργανισμού-εταίρου και σχετίζεται με την υλοποίηση του έργου.",
            "Εξοπλισμός": "Ο εξοπλισμός είναι ένα προϋπολογιστικό έξοδο που καλύπτει κάθε εργαλείο, συσκευή, όργανο, λογισμικό κ.λπ. που αγοράζεται, ενοικιάζεται ή μισθώνεται από έναν εταίρο και είναι απαραίτητο για την επίτευξη των στόχων του έργου. Αυτό περιλαμβάνει εξοπλισμό που έχει ήδη στην κατοχή του ο οργανισμός-εταίρος και χρησιμοποιείται για την εκτέλεση των δραστηριοτήτων του έργου (μόνο το αναλογικό κόστος που σχετίζεται με το έργο είναι επιλέξιμο).",
            "Επιλέξιμες δαπάνες": "ΕΊναι οι δαπάνες που είναι σύμφωνες με τις απαιτήσεις του προγράμματος και μπορούν να εγκριθούν για συγχρηματοδότηση από το ΕΔΕΤ.",
            "Επιλεξιμότητα των δαπανών": "Τα κριτήρια επιλεξιμότητας των δαπανών καθορίζουν εάν μια δαπάνη πληροί τις προϋποθέσεις για χρηματοδότηση από τα Ευρωπαϊκά Διαρθρωτικά και Επενδυτικά Ταμεία (ΕΔΕΤ).",
            "Αντίκτυπος": "Είναι οι ευρύτερες μακροπρόθεσμες επιπτώσεις στην κοινωνία (συμπεριλαμβανομένου του περιβάλλοντος), την οικονομία και την επιστήμη, που ενεργοποιούνται από τα αποτελέσματα των επενδύσεων R&I (μακροπρόθεσμα). Αναφέρεται στη συγκεκριμένη συνεισφορά του έργου στις αναμενόμενες επιπτώσεις του προγράμματος εργασίας που περιγράφονται στον προορισμό. Ο αντίκτυπος εμφανίζονται γενικά κάποια στιγμή μετά το τέλος του έργου.",
            "Επιτροπή παρακολούθησης": "Τα κράτη μέλη υποχρεούνται να ορίζουν επιτροπές παρακολούθησης για να ελέγχουν την ορθή εφαρμογή των επιχειρησιακών προγραμμάτων που χρησιμοποιούν το ESIF. Οι επιτροπές αυτές διευθύνονται υπό την αιγίδα της διαχειριστικής αρχής και αποτελούνται από περιφερειακούς, οικονομικούς και κοινωνικούς εταίρους.",
            "Επιτροπή των Περιφερειών": "Η Επιτροπή των Περιφερειών, αποτελούμενη από 353 μέλη εκλεγμένων πολιτικών, είναι ένα συμβουλευτικό όργανο που επιτρέπει στις τοπικές και περιφερειακές αρχές να συμμετέχουν στο νομοθετικό έργο της ΕΕ. Πρέπει να ζητείται η γνώμη της Επιτροπή όταν συντάσσεται νομοθεσία σε τομείς που επηρεάζουν την περιφερειακή ή τοπική ζωή.",
            "Επιχειρησιακό πρόγραμμα": "Είναι ένα έγγραφο που αναπτύσσεται από τις χώρες ή/και τις περιφέρειες της ΕΕ και εγκρίνεται από την Επιτροπή. Το έγγραφο αυτό καθορίζει τις προτεραιότητες, καθώς και τον προγραμματισμό που απαιτείται για την επίτευξη των προτεραιοτήτων των χωρών.",
            "Επιχορηγήσεις": "Άμεσες χρηματοδοτικές συνεισφορές που χορηγούνται από τον προϋπολογισμό της ΕΕ, βάσει συγκεκριμένων κανόνων και διαδικασιών, για τη χρηματοδότηση δραστηριοτήτων που συνάδουν με τις πολιτικές της ΕΕ, π.χ. έρευνα και καινοτομία, περιφερειακή και αστική ανάπτυξη, απασχόληση και κοινωνική ένταξη, κ.λπ.",
            "Εργαλεία": "Ένα εργαλείο είναι ένα μέσο για την επίτευξη μιας συγκεκριμένης εργασίας. Τα εργαλεία πρέπει να αναπτύσσονται από κοινού σε διακρατικό επίπεδο και να έχουν καινοτόμο χαρακτήρα.  Τα εργαλεία μπορεί να είναι υλικά (φυσικά ή τεχνικά αντικείμενα) και άυλα (μέθοδοι, έννοιες ή υπηρεσίες). Αποτελούνται, μεταξύ άλλων, από αναλυτικά εργαλεία, εργαλεία διαχείρισης, τεχνικά εργαλεία, εργαλεία λογισμικού, εργαλεία παρακολούθησης, εργαλεία υποστήριξης αποφάσεων κ.λπ. Για να είναι αποτελεσματικό, ένα εργαλείο πρέπει να είναι προσαρμοσμένο στις ανάγκες των τελικών χρηστών και στις αντίστοιχες συνθήκες πλαισίου και πρέπει να είναι ολοκληρωμένο και ανθεκτικό.",
            "Εταίρος του έργου (δικαιούχος)": "Ο δικαιούχος του έργου είναι ένας οργανισμός-εταίρος που συμμετέχει στην υλοποίηση του έργου.",
            "Ευρωπαϊκά Διαρθρωτικά και Επενδυτικά Ταμεία (ΕΔΕΤ)": "Τα Ευρωπαϊκά Διαρθρωτικά και Επενδυτικά Ταμεία (ΕΔΕΤ) χρηματοδοτούν την πολιτική συνοχής και περιλαμβάνουν πέντε διαφορετικά ταμεία, τα οποία καλύπτονται όλα από τον κανονισμό (ΕΕ) αριθ. 1303/2013 \"Κανονισμός κοινών διατάξεων\".",
            "Ευρωπαϊκή Εδαφική Συνεργασία (INTERREG EUROPE)": "Το πρόγραμμα διαπεριφερειακής συνεργασίας καλύπτει όλα τα κράτη μέλη της ΕΕ, καθώς και τη Νορβηγία και την Ελβετία, στο πλαίσιο του στόχου της Ευρωπαϊκής Εδαφικής Συνεργασίας που συγχρηματοδοτείται από το Ευρωπαϊκό Ταμείο Περιφερειακής Ανάπτυξης (ΕΤΠΑ). Στόχος του είναι επίσης να αξιοποιήσει την περιφερειακή τεχνογνωσία και τις ορθές πρακτικές που έχουν ήδη εντοπιστεί σε ευρωπαϊκό επίπεδο.	",
            "Ευρωπαϊκή Στρατηγική για την Απασχόληση (ΕΣΑ)": "Βοηθά τις χώρες της Ευρωπαϊκής Ένωσης να δημιουργήσουν περισσότερες και καλύτερες θέσεις εργασίας. Οι στόχοι, οι προτεραιότητες και οι στόχοι συμφωνούνται σε επίπεδο ΕΕ. Στη συνέχεια, οι κυβερνήσεις συντονίζουν τις προσπάθειές τους για την προώθηση της απασχόλησης",
            "Ευρωπαϊκό Γεωργικό Ταμείο Αγροτικής Ανάπτυξης (ΕΓΤΑΑ)": "Το ταμείο αυτό υποστηρίζει την ευρωπαϊκή πολιτική για την αγροτική ανάπτυξη. Για το σκοπό αυτό, χρηματοδοτεί προγράμματα αγροτικής ανάπτυξης σε όλα τα κράτη μέλη και τις περιφέρειες της Ένωσης. Το ΕΓΤΑΑ εντάσσεται στο πλαίσιο πολιτικής του ΕΔΕΤ.",
            "Ευρωπαϊκό Κοινωνικό Ταμείο (ΕΚΤ)": "Το ΕΚΤ επικεντρώνεται στη βελτίωση των ευκαιριών απασχόλησης και εκπαίδευσης σε ολόκληρη την Ευρωπαϊκή Ένωση. Στόχος του είναι επίσης να βελτιώσει την κατάσταση των πιο ευάλωτων ατόμων που κινδυνεύουν από τη φτώχεια. Υποστηρίζει, μεταξύ άλλων, τη μεταρρύθμιση των συστημάτων εκπαίδευσης και κατάρτισης, την προσαρμογή των δεξιοτήτων και των προσόντων, την αναβάθμιση των δεξιοτήτων του εργατικού δυναμικού και τη δημιουργία νέων θέσεων εργασίας.",
            "Ευρωπαϊκό Ταμείο Θάλασσας και Αλιείας (ΕΤΘΑ)": "Το ΕΤΘΑ είναι ένα από τα πέντε Ευρωπαϊκά Διαρθρωτικά και Επενδυτικά Ταμεία (ΕΔΕΤ) και έχει ως στόχο να βοηθήσει τους αλιείς στη μετάβαση στη βιώσιμη αλιεία, να στηρίξει τις παράκτιες κοινότητες στη διαφοροποίηση των οικονομιών τους, να χρηματοδοτήσει έργα που δημιουργούν νέες θέσεις εργασίας και να βελτιώσει την ποιότητα ζωής κατά μήκος των ευρωπαϊκών ακτών. ",
            "Ευρωπαϊκό Ταμείο Περιφερειακής Ανάπτυξης (ΕΤΠΑ)": "Το ΕΤΠΑ αποσκοπεί στην ενίσχυση της οικονομικής και κοινωνικής συνοχής στην Ευρωπαϊκή Ένωση με τη διόρθωση των ανισοτήτων μεταξύ των περιφερειών της. Το Ταμείο παρέχει χρηματοδοτική βοήθεια για αναπτυξιακά έργα στις φτωχότερες περιφέρειες. Προωθεί την καινοτομία και την έρευνα - την ενεργειακή απόδοση στις μικρομεσαίες επιχειρήσεις, τις κατοικίες και τα δημόσια κτίρια - τις ανανεώσιμες πηγές ενέργειας και τις στρατηγικές χαμηλών εκπομπών διοξειδίου του άνθρακα για τις αστικές περιοχές. ",
            "Ευρωπαϊκοί Όμιλοι Εδαφικής Συνεργασίας (ΕΟΕΣ)": "Έχει ως στόχο να διευκολύνει και να προωθήσει τη διασυνοριακή, διακρατική και διαπεριφερειακή συνεργασία στην ΕΕ. Τα καθήκοντά τους περιλαμβάνουν την υλοποίηση προγραμμάτων που συγχρηματοδοτούνται από την ΕΕ ή οποιοδήποτε άλλο ευρωπαϊκό σχέδιο διασυνοριακής συνεργασίας.",
            "Ευρωπαϊκός κώδικας δεοντολογίας για τις Συμπράξεις": "Σύμφωνα με τον εν λόγω κώδικα, τα κράτη μέλη οφείλουν να είναι διαφανή κατά την επιλογή των εταίρων, να διασφαλίζουν ότι οι εταίροι συμμετέχουν σε όλα τα στάδια της διαδικασίας, να υποστηρίζουν την ανάπτυξη ικανοτήτων των εταίρων και να δημιουργούν πλατφόρμες αμοιβαίας μάθησης και ανταλλαγής ορθών πρακτικών.",
            "Εφάπαξ κόστος": "Μέθοδοι προσδιορισμού/υπολογισμού των επιλέξιμων δαπανών. Το συνολικό ποσό για την κάλυψη μιας ή περισσότερων κατηγοριών δαπανών ή όλων των δαπανών του έργου.",
        },
    
        "Θ" :{
            "Θεματικοί στόχοι": "Τα Ευρωπαϊκά Διαρθρωτικά και Επενδυτικά Ταμεία, ιδίως το Ευρωπαϊκό Ταμείο Περιφερειακής Ανάπτυξης (ΕΤΠΑ), το Ευρωπαϊκό Κοινωνικό Ταμείο (ΕΚΤ) και το Ταμείο Συνοχής, υποστηρίζουν διάφορες επενδυτικές προτεραιότητες, γνωστές και ως θεματικοί στόχοι."
        },
        
        "Ι" : {
            "Ισότητα ευκαιριών": "Είναι ένας ακρογωνιαίος λίθος της νομοθεσίας και της χάραξης πολιτικής της ΕΕ. Η ΕΕ δεσμεύεται να αναπτύξει μέτρα για τη διασφάλιση ίσων ευκαιριών και ίσης μεταχείρισης για τα άτομα ανεξαρτήτως φύλου. Αυτή η φιλοσοφία ισχύει σε όλους τους τομείς της οικονομικής, κοινωνικής, πολιτιστικής και οικογενειακής ζωής."
        },
        
        "K" : {
            "Καινοτομία": "Η καινοτομία, είτε αφορά την ανάπτυξη νέων προϊόντων, διαδικασιών ή οργανωτικών τεχνικών που μπορούν να συμβάλουν στο να αποκτήσουν οι οικονομικοί φορείς ανταγωνιστικό πλεονέκτημα. Η ΕΕ θέτει την προώθηση της καινοτομίας στο επίκεντρο της στρατηγικής \"Ευρώπη 2020\" με την εμβληματική πρωτοβουλία της Ένωσης Καινοτομίας.",
            "Καλή πρακτική": "Είναι μια πρωτοβουλία (π.χ. μεθοδολογίες, έργα, διαδικασίες, τεχνικές) που αναλαμβάνεται σε μια από τις θεματικές προτεραιότητες του προγράμματος, η οποία έχει ήδη αποδειχθεί επιτυχής και η οποία έχει τη δυνατότητα να μεταφερθεί σε μια άλλη γεωγραφική περιοχή.",
            "Κατ' αποκοπή κόστος": "Μέθοδοι προσδιορισμού/υπολογισμού των επιλέξιμων δαπανών. Κόστος που υπολογίζεται με την εφαρμογή ενός ποσοστού που καθορίζεται εκ των προτέρων σε άλλα είδη επιλέξιμων δαπανών.",
            "Κριτήρια επιλεξιμότητας": "Είναι κυρίως τεχνικές απαιτήσεις που πρέπει να πληρούν οι επιχειρήσεις που υποβάλλουν αίτηση για κονδύλια του ΕΚΤ προκειμένου να περάσουν τον έλεγχο επιλεξιμότητας.",
        },


        "Μ" : {
            "ΜΜΕ": "Μικρομεσαία επιχείρηση",
            "Μοναδιαίο κόστος": "Είναι μέθοδοι προσδιορισμού/υπολογισμού των επιλέξιμων δαπανών. Ένα σταθερό ποσό ανά μονάδα. Το μοναδιαίο κόστος μπορεί να καθορίζεται από την Επιτροπή (π.χ. μοναδιαίο κόστος του ιδιοκτήτη ΜΜΕ) ή να υπολογίζεται από τον δικαιούχο με βάση τις συνήθεις λογιστικές πρακτικές του (π.χ. μέσο κόστος προσωπικού, εσωτερικά τιμολογούμενα αγαθά και υπηρεσίες κ.λπ.)",
        },

        "N" : {
            "N+2": "Αφορά τους κανόνες χρηματοδότησης για την ετήσια κατανομή των χρημάτων από το ESIF. Εάν η χρηματοδότηση δεν έχει δαπανηθεί μέχρι την ημερομηνία αυτή, η Επιτροπή μπορεί να \"αποδεσμεύσει\" τις μελλοντικές πιστώσεις του προϋπολογισμού. Οι αποδεσμεύσεις πραγματοποιούνται εάν η χρηματοδότηση δεν δαπανηθεί ή δεν υποβληθούν αιτήσεις πληρωμών μέχρι το τέλος του δεύτερου έτους (ν+2).",
            "Νομικό πρόσωπο": "Είναι κάθε φυσικό ή νομικό πρόσωπο που έχει δημιουργηθεί και αναγνωριστεί ως τέτοιο βάσει του εθνικού δικαίου, του δικαίου της Ευρωπαϊκής Ένωσης ή του διεθνούς δικαίου.",
        },

        "Ο" : {
            "Οδηγός για τους υποψηφίους": "Ο οδηγός έχει ως στόχο να βοηθήσει τους δυνητικούς υποψηφίους. Παρέχει πληροφορίες σχετικά με το πρόγραμμα, τις απαιτήσεις του έργου, τους κανόνες επιλογής, τις διαδικασίες υποβολής αιτήσεων και αξιολόγησης και τις αρχές υλοποίησης του έργου.",
            "Οικονομική και κοινωνική συνοχή": "Η οικονομική και κοινωνική συνοχή, ως έκφραση της αλληλεγγύης μεταξύ των κρατών μελών της ΕΕ και των περιφερειών τους, αποσκοπεί στην επίτευξη ισορροπημένης κοινωνικοοικονομικής ανάπτυξης σε ολόκληρη την ΕΕ. Η οικονομική και κοινωνική συνοχή υλοποιείται μέσω της πολιτικής συνοχής της ΕΕ",
            "Ολοκληρωμένη προσέγγιση": "Είναι μια διατομεακή προσέγγιση, κατά την οποία τα έργα συνδέονται με διάφορα επιμέρους θέματα του προγράμματος, παρόλο που πρέπει σαφώς να επικεντρώνονται μόνο σε ένα από αυτά.",
            "Ομάδα-στόχος": "Η ομάδα-στόχος είναι ένα σύνολο ατόμων ή/και οργανώσεων που επηρεάζονται άμεσα και θετικά από τα αποτελέσματα του έργου. Οι ομάδες-στόχοι, δεν λαμβάνουν απαραίτητα οικονομική επιχορήγηση και ακόμη και αν δεν συμμετέχουν άμεσα στο έργο, μπορούν να εκμεταλλευτούν τα αποτελέσματα του έργου προς όφελός τους. ",
            "Ονοματολογία Εδαφικών Στατιστικών Μονάδων (ΟΕΣΜ)": "Η Ονοματολογία Εδαφικών Στατιστικών Μονάδων (ΟΕΣΜ) δημιουργήθηκε από την Ευρωπαϊκή Στατιστική Υπηρεσία (Eurostat) με σκοπό τη δημιουργία μιας ενιαίας και συγκροτημένης δομής εδαφικής κατανομής.",
        },

        "Π" : {
            "Πραγματικό κόστος": "Μέθοδοι προσδιορισμού/υπολογισμού των επιλέξιμων δαπανών. Κόστος το οποίο είναι πραγματικό (αντικειμενικά πραγματοποιηθέν, αναγνωρίσιμο και επαληθεύσιμο, καταγεγραμμένο στους λογαριασμούς κ.λπ.) και όχι εκτιμώμενο ή προϋπολογισμένο.",
            "Παγκόσμιες επιχορηγήσεις": "Οι παγκόσμιες επιχορηγήσεις βοηθούν μικρές, μη κυβερνητικές οργανώσεις, όπως εθελοντικές ομάδες και κοινοτικές οργανώσεις, να λάβουν στήριξη από το Ευρωπαϊκό Κοινωνικό Ταμείο (ΕΚΤ) και το Ευρωπαϊκό Ταμείο Περιφερειακής Ανάπτυξης (ΕΤΠΑ).",
            "Παραδοτέο": "Ένα παραδοτέο είναι ένα προϊόν ή υπηρεσία του Έργου που συμβάλλει στην ανάπτυξη του κύριου αποτελέσματος του Έργου.",
            "Παρακολούθηση": "Η χρηματοδότηση της ΕΕ για τις διαρθρωτικές και επενδυτικές δράσεις (ESI) πρέπει να δαπανάται αποτελεσματικά και σύμφωνα με τον καθορισμένο σκοπό της. Για το λόγο αυτό, μέσω της νομοθεσίας της ΕΕ έχουν θεσπιστεί εκτεταμένες διαδικασίες αξιολόγησης και παρακολούθησης, προκειμένου να ελέγχεται ότι τα επιχειρησιακά προγράμματα που λαμβάνουν χρηματοδότηση από το ESI λειτουργούν ορθά και παράγουν αποτελέσματα που μπορούν να ελεγχθούν με βάση τα συμφωνηθέντα κριτήρια.",
            "Περίοδος αναφοράς": "Μια περίοδος αναφοράς είναι γενικά μια εξάμηνη περίοδος, οι προθεσμίες για την οποία θα καθορίζονται στη Σύμβαση και στο τέλος της οποίας οι Επικεφαλής Εταίροι θα πρέπει να υποβάλουν Αναφορά Προόδου. Οι περίοδοι υποβολής της αναφοράς θα καθορίζονται για κάθε πρόσκληση υποβολής προτάσεων, έτσι ώστε τα έργα που υλοποιούνται στο πλαίσιο της ίδιας πρόσκλησης να έχουν τις ίδιες προθεσμίες υποβολής αναφορών. Η πρώτη και η τελευταία περίοδος υποβολής αναφορών μπορεί να διαφέρουν σε διάρκεια ανάλογα με την ημερομηνία έναρξης/λήξης του έργου.",
            "Περίοδος επιλεξιμότητας": "Είναι το χρονικό διάστημα κατά τη διάρκεια του οποίου πρέπει να πραγματοποιηθούν και να πληρωθούν οι δαπάνες του έργου (εκτός εάν χρησιμοποιούνται απλουστευμένες επιλογές κόστους) προκειμένου να είναι επιλέξιμες για επιστροφή από τα κεφάλαια του προγράμματος.",
            "Πλαίσιο επιδόσεων": "Το πλαίσιο επιδόσεων είναι ένα σύνολο δεικτών σε κάθε επιχειρησιακό πρόγραμμα σύμφωνα με το οποίο η Επιτροπή, σε συνεργασία με τα κράτη μέλη, επανεξετάζει τις επιδόσεις των προγραμμάτων σε κάθε κράτος μέλος.",
            "Πληρωμές": "Κατά τη διανομή της Διαρθρωτικής και Επενδυτικής χρηματοδότησης της ΕΕ σε επιχειρησιακά προγράμματα που εκτελούνται μεταξύ 2014 και 2020, η Επιτροπή μπορεί να πραγματοποιήσει τρεις τύπους πληρωμών: Πληρωμές προχρηματοδότησης, Ενδιάμεσες πληρωμές και Πληρωμή του τελικού υπολοίπου.",
            "Πολιτική Συνοχής": "Η Πολιτική Συνοχής είναι η στρατηγική της ΕΕ που αποσκοπεί στην ενίσχυση της οικονομικής και κοινωνικής συνοχής μέσω της μείωσης των ανισοτήτων μεταξύ των ευρωπαϊκών περιφερειών. Εφαρμόζεται μέσω ειδικών ταμείων, όπως το Ευρωπαϊκό Ταμείο Περιφερειακής Ανάπτυξης (ΕΤΠΑ), το Ταμείο Συνοχής (ΤΣ), το Ευρωπαϊκό Κοινωνικό Ταμείο Plus (ΕΚΤ+) και το Ταμείο Δίκαιης Μετάβασης (ΤΔΜ).",
            "Ποσοστό συγχρηματοδότησης": "Αναφέρεται στη συνεισφορά της χρηματοδότησης της ΕΕ σε ένα πρόγραμμα και εκφράζεται ως ποσοστό του συνολικού κόστους του προγράμματος.",
            "Πρόγραμμα δικτύου αστικής ανάπτυξης-URBACT": "Χρηματοδοτείται από την Επιτροπή στο πλαίσιο του στόχου της εδαφικής συνεργασίας ως μέρος των προγραμμάτων της για τη διαπεριφερειακή συνεργασία. Στόχος είναι η ενίσχυση της καινοτομίας για την αστική αναζωογόνηση με την ενθάρρυνση των πόλεων να εντοπίζουν, να μεταφέρουν και να διαδίδουν καλές πρακτικές.",
            "Προγραμματισμός": "Ο προγραμματισμός αναφέρεται στον διοικητικό μηχανισμό που χρησιμοποιείται για την επιδίωξη των στόχων του ESIF. Τα επιχειρησιακά προγράμματα εξασφαλίζουν τη συνοχή και τη συνέχεια κατά τη διάρκεια μιας επταετούς περιόδου και σχετίζονται με συγκεκριμένες γεωγραφικές περιοχές. Οι στόχοι του προγράμματος περιλαμβάνουν τον προσδιορισμό των στρατηγικών προτεραιοτήτων και των ενδεικτικών δράσεων, την ανάλυση των χρηματοδοτικών κατανομών και τη συνοπτική παρουσίαση των συστημάτων διαχείρισης και ελέγχου.",
            "Προκήρυξη υποβολής προτάσεων": "Είναι η περίοδος κατά την οποία οι αιτήσεις μπορούν να υποβληθούν στην Κοινή Γραμματεία.",
            "Προτεραιότητα": "Είναι μία από τις δράσεις ενός επιχειρησιακού προγράμματος που περιλαμβάνει μια ομάδα δράσεων οι οποίες συνδέονται μεταξύ τους και έχουν συγκεκριμένους μετρήσιμους στόχους. Μια προτεραιότητα μπορεί να καλύπτει έναν ή περισσότερους Συγκεκριμένους Στόχους.",
            "Προϋπολογισμός": "Τα κράτη μέλη της ΕΕ συνεισφέρουν στον κοινό προϋπολογισμό της ΕΕ για την επίτευξη κοινών στόχων με βάση το ακαθάριστο εθνικό τους εισόδημα. Το τρέχον δημοσιονομικό πλαίσιο καλύπτει την περίοδο 2021-2027 και τα κονδύλια της ΕΕ που διατίθενται για την Πολιτική συνοχής ανέρχονται σε 392 δισ. ευρώ.",
        },

        "Σ" : {

            "Στόχοι": "Είναι οι στόχοι των εργασιών που εκτελούνται στο πλαίσιο του έργου, όσον αφορά το περιεχόμενο της έρευνας και της καινοτομίας. Αυτό θα αποτυπωθεί στα αποτελέσματα του έργου. Αυτά μπορεί να κυμαίνονται από την αντιμετώπιση συγκεκριμένων ερευνητικών ερωτημάτων, την απόδειξη της σκοπιμότητας μιας καινοτομίας, την ανταλλαγή γνώσεων μεταξύ των ενδιαφερομένων μερών για συγκεκριμένα θέματα. Η φύση των στόχων θα εξαρτηθούν από το είδος της δράσης και από το πεδίο εφαρμογής του θέματος.",
            "Στρατηγική \"Ευρώπη 2020\"": "Πρόκειται για τη δεκαετή στρατηγική της ΕΕ για έξυπνη, βιώσιμη και χωρίς αποκλεισμούς ανάπτυξη. Για την επίτευξη αυτού του στόχου, έχουν τεθεί πέντε φιλόδοξοι στόχοι, οι οποίοι καλύπτουν την απασχόληση, την έρευνα και την ανάπτυξη, την κλιματική αλλαγή και την ενεργειακή βιωσιμότητα, την εκπαίδευση και την καταπολέμηση της φτώχειας και του κοινωνικού αποκλεισμού.",
            "Συμπληρωματικότητα": "Η Συμπληρωματικότητα είναι μία από τις αρχές που διέπουν τη λειτουργία των Ευρωπαϊκών Διαρθρωτικών και Επενδυτικών Ταμείων.",
            "Σύμπραξη": "Η σύμπραξη καλύπτει ολόκληρη τη διαδικασία προγραμματισμού, από το προπαρασκευαστικό στάδιο έως την εφαρμογή και την αξιολόγηση των αποτελεσμάτων. Η προσέγγιση αυτή αναμένεται να οδηγήσει σε καλύτερα αποτελέσματα του προγράμματος και να συμβάλει στη διασφάλιση της αποτελεσματικής χρήσης των χρημάτων του ΕΔΕΤ.",
            "Συμφωνία Σύμπραξης (ΣΣ)": "Προκειμένου να διασφαλιστεί η ποιότητα της υλοποίησης του έργου, καθώς και η ικανοποιητική επίτευξη των στόχων του, ο επικεφαλής εταίρος και οι εταίροι πρέπει να συνάψουν Συμφωνία Σύμπραξης που επιτρέπει την επέκταση των ρυθμίσεων της σύμβασης επιδότησης για κάθε εταίρο.",
            "Συνεισφορές σε είδος": "Οι συνεισφορές σε είδος είναι συνεισφορές με τη μορφή παροχής έργων, αγαθών, υπηρεσιών, γης και ακινήτων για τις οποίες δεν υποστηρίζεται πληρωμή σε μετρητά μέσω τιμολογίων ή εγγράφων ισοδύναμης αποδεικτικής αξίας.",
            "Συντονιστής εταίρος (LP)": "Ο συντονιστής εταίρος είναι ο εταίρος του έργου που αναλαμβάνει τη συνολική ευθύνη για την ανάπτυξη και την υλοποίηση ενός έργου. Κάθε Συντονιστής Εταίρος καλείται να συνάψει τη Συμφωνία Εταιρικής Σχέσης (με τους εταίρους του έργου) και τη Σύμβαση Επιδότησης (με τη διαχειριστική αρχή), να διασφαλίσει την ορθή διασυνοριακή/διακρατική διαχείριση, καθώς και την υλοποίηση του έργου και να μεταφέρει τις απαιτούμενες συνεισφορές του ΕΤΠΑ στους μεμονωμένους εταίρους.",
            "Συντονιστική Επιτροπή για τα Ευρωπαϊκά Διαρθρωτικά και Επενδυτικά Ταμεία (ΕΔΕΤ)": "Είναι μια μόνιμη επιτροπή της Ευρωπαϊκής Επιτροπής. Η λειτουργία της είναι να συζητά θέματα σχετικά με την εφαρμογή των κανονισμών που διέπουν τα Διαρθρωτικά και Επενδυτικά Ταμεία (ΕΔΕΤ).",
            "Σχέδιο δράσης": "Είναι ένα έγγραφο που παρέχει λεπτομέρειες σχετικά με τη φύση των δράσεων που πρέπει να υλοποιηθούν, το χρονοδιάγραμμά τους, τους εμπλεκόμενους φορείς, το κόστος (εάν υπάρχει) και τις πηγές χρηματοδότησης (εάν υπάρχουν).",
            "Σχέδιο εργασιών": "Είναι ένα σχέδιο δραστηριοτήτων που θα πραγματοποιηθούν κατά τη διάρκεια ζωής του έργου.",
            "Σχέση ποιότητας και τιμής": "Ο όρος \"Σχέση ποιότητας και τιμής\" αναφέρεται στην εκτίμηση του κατά πόσον επιτυγχάνεται επαρκής αντίκτυπος για τα χρήματα που δαπανώνται.",
        },

        "T" : {
            "Ταμεία συνοχής": "Το Ταμείο Συνοχής χρησιμοποιήθηκε για την παροχή στήριξης στις φτωχότερες περιοχές της Ευρώπης και τη σταθεροποίηση των οικονομιών τους με στόχο την προώθηση της ανάπτυξης, της απασχόλησης και της βιώσιμης ανάπτυξης. Το Ταμείο συμβάλλει στη χρηματοδότηση περιβαλλοντικών μέτρων και διευρωπαϊκών δικτύων μεταφορών.",
            "Ταμείο Αλληλεγγύης της ΕΕ (ΤΑΕΕ)": "Έχει σχεδιαστεί για να επιτρέπει την ταχεία, αποτελεσματική και ευέλικτη ανταπόκριση σε καταστάσεις έκτακτης ανάγκης μετά από μεγάλες φυσικές καταστροφές. Παρεμβαίνει κυρίως σε φυσικές καταστροφές που έχουν σοβαρές επιπτώσεις στις συνθήκες διαβίωσης, το φυσικό περιβάλλον ή την οικονομία σε μία ή περισσότερες περιοχές ενός κράτους μέλους ή μιας υποψήφιας χώρας που έχει ήδη αρχίσει ενταξιακές διαπραγματεύσεις.",
            "Τεχνική βοήθεια": "Είναι διαθέσιμη για να βοηθήσει τους ενδιαφερόμενους φορείς να υλοποιήσουν προγράμματα και έργα που χρηματοδοτούνται από την Επιτροπή. Στο πλαίσιο της πολιτικής συνοχής της Ευρωπαϊκής Ένωσης, η εν λόγω χρηματοδοτική στήριξη μπορεί να χρησιμοποιηθεί για να πληρώσει την προετοιμασία, τη διαχείριση, την αξιολόγηση, την παρακολούθηση, και τον λογιστικό έλεγχο.",
        },

        "Υ" : {
            "Υπεργολαβία": "Η υπεργολαβία είναι μια διαδικασία με την οποία συνάπτεται συμφωνία για την παροχή αγαθών ή υπηρεσιών που αφορούν εργασίες που απαιτούνται για το έργο και δεν μπορούν να εκτελεστούν από τον ίδιο τον δικαιούχο, η οποία συνάπτεται μεταξύ ενός δικαιούχου και ενός ή περισσότερων υπεργολάβων για τις συγκεκριμένες ανάγκες ενός έργου. Η υπεργολαβία απορρέει από την αρχή ότι οι δικαιούχοι πρέπει να έχουν την επιχειρησιακή ικανότητα να υλοποιήσουν το έργο και περιορίζεται στα τμήματα των εργασιών που δεν μπορούν να εκτελεστούν από τον ίδιο τον δικαιούχο. Δεν πρέπει να αφορά \"βασικά\" τμήματα των εργασιών του έργου και μόνο ένα περιορισμένο τμήμα του έργου μπορεί να ολοκληρωθεί από υπεργολάβο, εφόσον αυτό είναι απαραίτητο για την υλοποίηση του έργου. Η διαχείριση του έργου πρέπει πάντα να θεωρείται ως βασικό στοιχείο του έργου και δεν πρέπει να αποτελεί αντικείμενο υπεργολαβίας.",
            "Υποδομές και έργα": "Υποδομές και τα έργα είναι ένα κονδύλι του προϋπολογισμού που καλύπτει τις δαπάνες που σχετίζονται με επενδύσεις σε υποδομές που δεν εμπίπτουν στο πλαίσιο εφαρμογής άλλων κονδυλίων του προϋπολογισμού. Περιλαμβάνει δαπάνες για την προετοιμασία του ιστοσελίδας, την παράδοση, τη διαχείριση, την εγκατάσταση, την ανακαίνιση και την αγορά γης, κατά περίπτωση.",
            "Υποψήφιος": "Είναι ο νομικά συγκροτημένος οργανισμός που είναι υπεύθυνος για την ορθή διαχείριση και υλοποίηση του ευρωπαϊκού χρηματοδοτικού έργου.",
        },

        "Φ" :{
            "Φόρος Προστιθέμενης Αξίας (ΦΠΑ)": "Ο φόρος προστιθέμενης αξίας (ΦΠΑ) είναι φόρος κατανάλωσης που επιβάλλεται σε ένα προϊόν κάθε φορά που προστίθεται αξία σε ένα στάδιο παραγωγής και στην τελική πώληση. Μόνο ο ΦΠΑ που δεν είναι ανακτήσιμος βάσει της εθνικής νομοθεσίας περί ΦΠΑ μπορεί να είναι επιλέξιμη δαπάνη."
        },

        "Χ" : {
            "Χρηματοοικονομικές διορθώσεις": "Οι χρηματοοικονομικές διορθώσεις είναι οι αποσύρσεις της χρηματοδότησης που πραγματοποιούνται όταν οι πληρωμές σε έργα που υποστηρίζονται από την ΕΕ έχουν γίνει εκ παραδρομής λόγω παρατυπιών, όπως η απάτη.",
            "Χρηματοοικονομικά μέσα": "Τα χρηματοοικονομικά μέσα είναι ένας αποδοτικός τρόπος χρήσης των πόρων της πολιτικής συνοχής για τη στήριξη της στρατηγικής \"Ευρώπη 2020\". Στοχεύοντας σε έργα που θα μπορούσαν δυνητικά να γίνουν αυτοσυντηρούμενα, παρέχουν επενδυτική στήριξη μέσω δανείων, εγγυήσεων ή ιδίων κεφαλαίων.",
        }
    },

    lt : {

        "A": {
            "Akronimas": "Akronimas yra projekto pavadinimo arba projekto partnerių santrumpa.",
            "Ataskaitinis laikotarpis": "Ataskaitinis laikotarpis paprastai yra šešių mėnesių laikotarpis, kurio terminai bus nustatyti Sutartyje ir kuriam pasibaigus Pagrindiniai partneriai turės pateikti Pažangos ataskaitą. Kiekvienam kvietimui teikti paraiškas bus nustatyti ataskaitiniai laikotarpiai, kad pagal tą patį kvietimą teikti paraiškas įgyvendinamiems projektams būtų taikomi vienodi ataskaitų teikimo terminai. Pirmasis ir paskutinis ataskaitiniai laikotarpiai gali skirtis priklausomai nuo projekto pradžios ir pabaigos datos.",
            "Atokiausi regionai": "penki Prancūzijos užjūrio departamentai (Gvadelupa, Prancūzijos Gviana, Martinika, Reunjonas, Majotas); Prancūzijos užjūrio Saint-Martin bendruomenės; Ispanijos autonominė Kanarų salų bendruomenė; ir Portugalijos autonominiai Azorų ir Madeiros regionai.",
            "Audito institucija" : "įstaiga, atsakinga už tai, kad būtų atliktas tinkamo veiksmų programos valdymo ir kontrolės sistemos veikimo ir atitinkamos operacijų imties, pagrįstos deklaruotomis išlaidomis, auditas.",
            
        },
    
        "B": {
            "Biudžetas": "ES valstybės narės prisideda prie bendro ES biudžeto, kad pasiektų bendrus tikslus, pagrįstus jų bendrosiomis nacionalinėmis pajamomis. Dabartinė biudžeto programa apima 2021–2027 m. laikotarpį, o Sanglaudos politikai skirtos ES lėšos siekia 392 mlrd. eurų.",
            "Bendro finansavimo norma": "Nurodo ES finansavimo įnašą į programą ir išreiškiamas procentais nuo visų programos išlaidų. Bendram finansavimui paprastai taikoma didžiausia riba, kuri apibrėžiama kaip procentinė dalis visos programos ar jos dalies vertės.",
        },
    
    
    
        "D": {
            "Darbo planas": "veiklos planas, kuris turi būti atliktas per visą projekto laikotarpį.",
            "Darnus vystymasis": "Darnaus vystymosi sąvoka reiškia vystymosi politikos formą, kuria siekiama patenkinti visuomenės ekonominius, socialinius ir aplinkos poreikius gerovės požiūriu trumpuoju, vidutiniu ir, svarbiausia, ilgalaikiu laikotarpiu.",
            "Dotacijos": "tiesioginiai finansiniai įnašai iš ES biudžeto pagal konkrečias taisykles ir procedūras, siekiant finansuoti veiklą, atitinkančią ES politiką, ty mokslinius tyrimus ir inovacijas, regionų ir miestų plėtrą, užimtumą ir socialinę įtrauktį ir kt.",
            "Dvigubas finansavimas / finansavimas": "Dvigubas finansavimas / finansavimas yra tai, kas atsitinka, kai ta pati prekė (išlaidos) pateikiama kompensuoti keliems gavėjams, siekiant gauti apmokėjimą už tą prekę iš visų.",
        },
    
        "E": {
            "Ekonominė ir socialinė sanglauda": "ES valstybių narių ir jų regionų solidarumo išraiška ekonomine ir socialine sanglauda siekiama visoje ES pasiekti subalansuotą socialinę ir ekonominę plėtrą. Ekonominė ir socialinė sanglauda įgyvendinama per ES sanglaudos politiką",
            "ES solidarumo fondas (ESSF)": "jis skirtas greitai, veiksmingai ir lanksčiai reaguoti į ekstremalias situacijas po didelių stichinių nelaimių. Jis daugiausia veikia stichinių nelaimių atveju, kurios turi rimtų pasekmių gyvenimo sąlygoms, gamtinei aplinkai arba ekonomikai vienoje ar keliose valstybės narės arba šalies kandidatės, jau pradėjusios stojimo derybas, regionuose. ",
            "Europos jūrų reikalų ir žuvininkystės fondas (EJRŽF)": "EJRŽF yra vienas iš penkių Europos struktūrinių ir investicijų (ESI) fondų ir siekia padėti žvejams pereiti prie tvarios žvejybos, remti pakrančių bendruomenes įvairinti savo ekonomiką, finansuoti projektus, kuriais kuriamos naujos darbo vietos. Pagerinti gyvenimo kokybę Europos pakrantėse.",
            "Europos partnerystės elgesio kodeksas": "pagal šį kodeksą valstybės narės turi būti skaidrios atrinkdamos partnerius, užtikrinti, kad partneriai dalyvautų visuose proceso etapuose, remti partnerių gebėjimų stiprinimą ir kurti abipusio mokymosi platformas ir keitimasis gerąja patirtimi.",
            "Europos regioninės plėtros fondas (ERPF)": "ERPF siekia stiprinti ekonominę ir socialinę sanglaudą Europos Sąjungoje, ištaisydamas jos regionų disbalansą. Fondas teikia finansinę paramą plėtros projektams skurdesniuose regionuose. Jis skatina inovacijas ir mokslinius tyrimus; energijos vartojimo efektyvumą mažose ir vidutinėse įmonėse, būstuose ir visuomeniniuose pastatuose; atsinaujinančią energiją; mažo anglies dioksido kiekio technologijų strategijos miestų vietovėms.",
            "Europos socialinis fondas (ESF)": "ESF daugiausia dėmesio skiria užimtumo ir švietimo galimybių gerinimui visoje Europos Sąjungoje. Juo taip pat siekiama pagerinti labiausiai pažeidžiamų žmonių, kuriems gresia skurdas, padėtį. Jis, be kita ko, remia švietimo ir mokymo sistemų reformą, įgūdžių ir kvalifikacijų pritaikymą, darbo jėgos kvalifikacijos kėlimą ir naujų darbo vietų kūrimą.",
            "Europos struktūriniai ir investicijų fondai (ESIF)": "Europos struktūriniai ir investicijų fondai (ESIF) finansuoja Sanglaudos politiką ir apima penkis skirtingus fondus, kuriems visiems taikomas Reglamentas (ES) Nr. 1303/2013 „Bendrųjų nuostatų reglamentas“.",
            "Europos struktūrinių ir investicijų fondų koordinavimo komitetas (COESIF)": "tai nuolatinis Europos Komisijos komitetas. Jos funkcija – aptarti klausimus, susijusius su struktūrinius ir investicijų fondus (ESIF) reglamentuojančių reglamentų įgyvendinimu. ",
            "Europos teritorinio bendradarbiavimo grupės (ETBG)": "ja siekiama palengvinti ir paskatinti tarpvalstybinį, tarptautinį ir tarpregioninį bendradarbiavimą ES. Jų užduotis – įgyvendinti programas, kurias bendrai finansuoja ES ar bet kuris kitas Europos tarpvalstybinio bendradarbiavimo projektas.",
            "Europos užimtumo strategija (EES)": "ji padeda Europos Sąjungos šalims sukurti daugiau ir geresnių darbo vietų. Dėl tikslų, prioritetų ir uždavinių susitariama ES lygiu. Tada Vyriausybės koordinuoja savo pastangas skatinti užimtumą. ",
            "Europos žemės ūkio fondas kaimo plėtrai (EŽŪFKP)": "šis fondas remia Europos kaimo plėtros politiką. Šiuo tikslu ji finansuoja kaimo plėtros programas visose valstybėse narėse ir Sąjungos regionuose. EŽŪFKP yra įtrauktas į ESI fondų politikos sistemą.",
        },
    
        "I": {
            
            "Infrastruktūra ir darbai": "Infrastruktūra ir darbai – tai biudžeto eilutė, kuri apima išlaidas, susijusias su investicijomis į infrastruktūrą, kurios nepatenka į kitų biudžeto eilučių apimtį. Tai apima vietos paruošimo, pristatymo, tvarkymo, įrengimo, renovacijos ir žemės pirkimo išlaidas, jei taikoma.",
            "Inovacijos": "inovacijos, nesvarbu, ar jos susijusios su naujų produktų, procesų ar organizacinių metodų kūrimu, gali padėti ūkio subjektams suteikti konkurencinį pranašumą. ES strategijoje „Europa 2020“ su pavyzdine iniciatyva „Inovacijų sąjunga“ daugiausia dėmesio skiria inovacijų skatinimui. ",
            "INTERREG EUROPE": "Tarpregioninio bendradarbiavimo programa apima visas ES valstybes nares, taip pat Norvegiją ir Šveicariją pagal Europos teritorinio bendradarbiavimo tikslą, bendrai finansuojamą iš Europos regioninės plėtros fondo (ERPF). Pagrindinis jos tikslas – tobulinti regioninės plėtros politiką keičiantis patirtimi ir gerąja praktika. Taip pat siekiama pasinaudoti regioninėmis žiniomis ir gerąja patirtimi, jau nustatyta Europos lygmeniu.",
            "Integruotas požiūris": "tarpsektorinis požiūris, kai projektai yra susieti su skirtingomis programos potemėmis, nors jie turi aiškiai sutelkti dėmesį tik į vieną",
            "Išlaidų tinkamumas": "Išlaidų tinkamumo kriterijai nustato, ar išlaidos atitinka Europos struktūrinių ir investicijų fondų (ESIF) finansavimo reikalavimus.",
            "Įnašai natūra": "įnašai natūra – tai darbai, prekės, paslaugos, žemė ir nekilnojamasis turtas, už kuriuos nebuvo atliktas sąskaitomis faktūromis ar lygiavertės įrodomosios vertės dokumentais pagrįstas piniginis mokėjimas.",
            "Įranga": "Įranga yra biudžeto eilutė, apimanti visus partnerio įsigytus, išsinuomotus ar išsinuomotus įrankius, įrenginius, instrumentus, programinę įrangą ir kt., reikalingus projekto tikslams pasiekti. Tai apima įrangą, kurią jau turi organizacija partnerė ir kuri naudojama projekto veiklai vykdyti (tinkamos tik proporcingos su projektu susijusios išlaidos).",
            "Įrankiai": "įrankis yra priemonė konkrečiai užduočiai atlikti. Priemonės turėtų būti kartu kuriamos tarptautiniu lygmeniu ir turi būti naujoviškos. Priemonės gali būti apčiuopiamos (fiziniai ar techniniai objektai) ir nematerialūs (metodai, koncepcijos ar paslaugos). Jas, be kita ko, sudaro analitinės priemonės, valdymo įrankiai, techninės priemonės, programinės įrangos įrankiai, stebėjimo įrankiai, sprendimų palaikymo įrankiai ir tt Kad priemonė būtų veiksminga, ji turi būti pritaikyta galutinių vartotojų poreikiams ir atitinkamoms bendrosioms sąlygoms bei turi būti išsami ir patvarus.",
            "Įsipareigojimų panaikinimas": "taikant automatinio įsipareigojimų panaikinimo principą, jei programai skirtos sumos nereikalaujama iki antrųjų metų nuo programos priėmimo pabaigos, bet kokie nesumokėti pinigai nustoja būti prieinami tai programai.",
            "Įvertinimas": "vertinimas – tai periodiškas projekto/programos efektyvumo, efektyvumo, poveikio, tvarumo ir tinkamumo vertinimas, atsižvelgiant į nustatytus tikslus. Šis vertinimas paprastai atliekamas kaip nepriklausomas egzaminas, siekiant pasimokyti, kad būtų galima vadovautis priimant būsimus sprendimus.",
        },

        "F" : {
            "Faktinės išlaidos": "tinkamų finansuoti išlaidų nustatymo / apskaičiavimo metodai. Realios (faktiškai patirtos, identifikuojamos ir patikrinamos, įtrauktos į apskaitą ir kt.) išlaidos, neįvertintos ar įtrauktos į biudžetą.",
            "Finansinės pataisos": "finansinės pataisos – tai finansavimo panaikinimas, kai mokėjimai ES remiamiems projektams buvo atlikti per klaidą dėl pažeidimų, pavyzdžiui, sukčiavimo.",
            "Finansinės priemonės": "finansinės priemonės yra išteklius tausojantis būdas Sanglaudos politikos išteklius panaudoti siekiant paremti strategiją „Europa 2020“. Nukreipdami į projektus, kurie gali tapti savarankiški, jie teikia investicijų paramą paskolomis, garantijomis ar nuosavu kapitalu.",
            "Fiksuoto dydžio išlaidos": "tinkamų finansuoti išlaidų nustatymo / apskaičiavimo metodai. Išlaidos apskaičiuojamos taikant iš anksto nustatytą procentą kitoms tinkamų finansuoti išlaidų rūšims.",
        },

        "G" : {
            "Geroji praktika": "tai iniciatyva (pvz., metodikos, projektai, procesai, metodai), vykdoma pagal vieną iš programos teminių prioritetų, kuri jau pasiteisino ir gali būti perkelta į kitą geografinę sritį.",
        },

    
    
        "J" : {
            "Juridinis asmuo": "Bet kuris fizinis arba juridinis asmuo, įsteigtas ir tokiu pripažintas pagal nacionalinę teisę, Europos Sąjungos teisę arba tarptautinę teisę."
        },

        "K" : {
            "Kelionės ir apgyvendinimo išlaidos": "Kelionės ir apgyvendinimas yra biudžeto eilutė, apimanti kelionės išlaidas, apgyvendinimo išlaidas, maitinimo išlaidas, vizos išlaidas ir (arba) dienpinigius. Jis taikomas tik organizacijos partnerių darbuotojams ir yra susijęs su projekto vykdymu.",
            "Kontrolė ir auditas": "tai susijęs su patikimu Europos struktūrinių ir investicijų fondų (ESIF) finansų valdymu. Valstybės narės turi užtikrinti, kad Komisijos skirtos lėšos būtų naudojamos veiksmingai ir laikantis Reglamentų. Kiekvienai veiksmų programai turi būti paskirta audito institucija. ",
            "Kvietimas teikti pasiūlymus": "laikotarpis, per kurį paraiškos gali būti pateiktos Jungtiniam sekretoriatui",
        },

        "L" : { 

            "Lėšų susigrąžinimas": "Aplinkybės, kai Europos Komisija turi prašyti atitinkamų valstybių narių kompensuoti (susigrąžinti) struktūrinių ir investicijų finansavimą. Norint susigrąžinti pinigus, suaktyvinamos išieškojimo procedūros. Kalbant apie Sanglaudos politikos lėšas, tvirtinančioji institucija parengia ir siunčia Komisijai kiekvienos veiksmų programos išlaidų sąrašą ir mokėjimo prašymus.",
            "Lygios galimybės" : "Lygybė ir nediskriminavimas yra ES teisės ir politikos formavimo kertinis akmuo. ES yra įsipareigojusi plėtoti priemones, skirtas užtikrinti lygias galimybes ir vienodą požiūrį į žmones, nepaisant lyties. Šis principas galioja visose ekonominio, socialinio, kultūrinio ir šeimos gyvenimo srityse.",
        },

        "M" : {
            "Mokėjimai": "skirstydama ES struktūrinių ir investicijų finansavimą 2014–2020 m. vykdomoms veiksmų programoms, Komisija gali atlikti trijų tipų mokėjimus: išankstinio finansavimo mokėjimus, tarpinius mokėjimus ir galutinio likučio mokėjimą.",
            "MVĮ": "Maža ar vidutinė įmonė",
        },

        "N" : {
            "Netiesioginės išlaidos": "Netiesioginės išlaidos – tai išlaidos, kurių negalima visiškai priskirti projektui, nes jos yra susijusios su įvairia organizacijos, partnerės veikla. Kadangi tokių išlaidų negalima tiesiogiai susieti su individualia veikla, sunku tiksliai nustatyti šiai veiklai priskirtiną sumą (pvz., išlaidos telefonui, vandeniui, elektrai ir pan.).",
            "N+2": "jis susijęs su finansavimo taisyklėmis dėl metinio lėšų paskirstymo iš ESI fondų. Jei lėšos iki tos datos nepanaudotos, Komisija gali „atšaukti“ būsimus biudžeto asignavimus. Įsipareigojimai panaikinami, jei finansavimas neišleidžiamas arba mokėjimų prašymai nepateikiami iki antrųjų metų pabaigos (n+2).",
            "NUTS": "teritorinių statistinių vienetų nomenklatūrą (NUTS) sukūrė Europos statistikos biuras (Eurostatas), siekdamas sukurti vieną ir nuoseklią teritorinio pasiskirstymo struktūrą.",
        },

        "P" : {
            "Pagrindinis partneris (VP)2": "pagrindinis partneris yra projekto partneris, kuris prisiima visą atsakomybę už projekto vystymą ir įgyvendinimą. Tikimasi, kad kiekvienas vadovaujantis partneris sudarys partnerystės sutartį (su projekto partneriais) ir paramos sutartį (su vadovaujančia institucija), užtikrins patikimą tarpvalstybinį / tarptautinį projektų valdymą ir projekto įgyvendinimą bei perves mokėtinus ERPF įnašus. vienišiems partneriams.",
            "Paramos gavėjas": "juridiniai asmenys, pasirašę dotacijos sutartį (GA) su Komisija/agentūra (ty dalyvaujantys ES dotacija remiamame projekte).",
            "Pareiškėjas": "Teisiškai įsteigta organizacija, atsakinga už tinkamą Europos Sąjungos finansavimo projekto administravimą ir įgyvendinimą.",
            "Partnerystė": "Partnerystė apima visą programavimo procesą nuo parengiamojo etapo iki įgyvendinimo ir rezultatų vertinimo. Šis metodas turėtų padėti pasiekti geresnių programos rezultatų ir padėti užtikrinti, kad ESI fondų pinigai būtų naudojami efektyviai.",
            "Partnerystės sutartis (PS)": "siekiant užtikrinti projekto įgyvendinimo kokybę ir patenkinamą jo tikslų įgyvendinimą, pagrindinis partneris ir partneriai turi sudaryti partnerystės sutartį, leidžiančią pratęsti subsidijos skyrimo sąlygas. sutartis iki kiekvieno partnerio lygio. ",
            "Pažangos ataskaita": "Pažangos ataskaita – tai ataskaita, kurią pagrindinis partneris turi pateikti kiekvieno ataskaitinio laikotarpio pabaigoje, nurodant per laikotarpį atliktą veiklą ir susijusias tinkamas išlaidas. Tai dokumentuoja projekto eigą ir yra prašymas kompensuoti išlaidas.",
            "Poveikis": "Platesnis ilgalaikis poveikis visuomenei (įskaitant aplinką), ekonomikai ir mokslui, kurį įgalina investicijų į mokslinius tyrimus ir inovacijas rezultatai (ilgalaikis). Tai reiškia konkretų projekto indėlį į darbo programos numatomą poveikį, aprašytą paskirties vietoje. Poveikis paprastai atsiranda po projekto pabaigos.",
            "Pridėtinės vertės mokestis (PVM)": "Pridėtinės vertės mokestis (PVM) yra vartojimo mokestis, kuris apmokestinamas produktui, kai pridedama vertė gamybos etape ir galutinio pardavimo metu. Reikalavimus atitinkančios išlaidos gali būti tik PVM, kuris pagal nacionalinius PVM teisės aktus yra negrąžinamas.",
            "Prioritetas": "vienas iš veiksmų programos veiksmų, apimančių operacijų grupę, kurios yra susijusios ir turi konkrečius išmatuojamus tikslus. Prioritetas gali apimti vieną ar daugiau konkrečių tikslų.",
            "Programavimas": "reiškia administracinį mechanizmą, naudojamą ESI fondų tikslams pasiekti. Veiksmų programos užtikrina nuoseklumą ir tęstinumą septynerių metų laikotarpiu ir yra susijusios su konkrečiomis geografinėmis vietovėmis. Programos tikslai apima strateginių prioritetų ir orientacinių veiksmų nustatymą, finansinių asignavimų apibūdinimą ir valdymo bei kontrolės sistemų apibendrinimą.",
            "Projekto partneris (naudos gavėjas)": "Projekto partneris yra organizacija partnerė, dalyvaujanti Projekto įgyvendinime.",
            "Projekto rezultatų patvarumas": "vienas iš pagrindinių bet kurio valstybės finansuojamo projekto reikalavimų yra paraiškos pateikimo etape parodyti, kad projekte numatyti rezultatai nebus prarasti finansavimo laikotarpio pabaigoje.",
        },


        "R" : {
            "RegioStars apdovanojimai": "RegioStars apdovanojimų tikslas – nustatyti gerąją regioninės plėtros praktiką ir pabrėžti originalius bei novatoriškus projektus, kurie galėtų įkvėpti kitus regionus.",
            "Regionų komitetas": "Regionų komitetas, kurį sudaro 353 išrinktų politikų nariai, yra konsultacinė institucija, leidžianti vietos ir regionų valdžios institucijoms dalyvauti ES teisėkūros veikloje. Su Komitetu būtina konsultuotis rengiant teisės aktus srityse, turinčiose įtakos regiono ar vietos gyvenimui.",
            "Reikalavimus atitinkančios išlaidos": "išlaidos, kurios atitinka programos reikalavimus ir gali būti patvirtintos bendram finansavimui iš ESI fondų.",
            "Rezultatai": "Kas sukuriama projekto įgyvendinimo metu? Tai gali apimti, pavyzdžiui, praktinę patirtį, novatoriškus sprendimus, algoritmus, pagrįstumo įrodymus, naujus verslo modelius, politikos rekomendacijas, gaires, prototipus, demonstravimo priemones, duomenų bazes ir duomenų rinkinius, apmokytus tyrėjus, naują infrastruktūrą, tinklus ir kt. Dauguma projektų rezultatų (išradimai, mokslo darbai ir kt.) yra „intelektinė nuosavybė“, kuri, jei reikia, gali būti apsaugota oficialiomis „intelektinės nuosavybės teisėmis“.",
            "Rezultato indikatorius": "rezultato rodiklis yra rodiklis, apibūdinantis rezultatą, siekiamą pakeitimą (referencinėje situacijoje), atsižvelgiant į konkretų tikslą, kurį reikia pasiekti.",
            "Rodiklis": "rodiklis yra tikslo, kurį reikia pasiekti, sutelktų išteklių, gauto efekto, kokybės matuoklio arba kontekstinio kintamojo matavimas. Jį turėtų sudaryti apibrėžimas, vertė ir matavimo vienetas.",
        },

        "S" : {
            "Sanglaudos fondai": "Sanglaudos fondas buvo naudojamas remti skurdesnius Europos regionus ir stabilizuoti jų ekonomiką, siekiant skatinti augimą, užimtumą ir tvarų vystymąsi. Fondas prisideda prie aplinkosaugos priemonių ir transeuropinių transporto tinklų finansavimo.",
            "Sanglaudos politika": "Sanglaudos politika yra ES strategija, kuria siekiama stiprinti ekonominę ir socialinę sanglaudą mažinant skirtumus tarp Europos regionų. Ji įgyvendinama per specialius fondus, tokius kaip Europos regioninės plėtros fondas (ERPF), Sanglaudos fondas (SF), Europos socialinis fondas plius (ESF+) ir Teisingo perėjimo fondas (JTF).",
            "Sanglaudos ataskaita": "Europos Komisija kas trejus metus rengia Sanglaudos ataskaitą, kurioje pranešama apie pažangą, padarytą siekiant ekonominės ir socialinės sanglaudos visoje Europos Sąjungoje. Jame, remiantis ekonominiais, socialiniais ir teritoriniais rodikliais, vertinama socialinė ir ekonominė padėtis ir perspektyvos visuose ES regionuose.",
            "Sklaida": "Rezultatų sklaida – tai procesas, kai projekto rezultatai ir produkcija pasiekiami suinteresuotosioms šalims ir platesnei auditorijai. Sklaida yra būtina norint įsisavinti, o įsisavinimas yra labai svarbus projekto sėkmei ir rezultatų tvarumui ilgalaikėje perspektyvoje.",
            "Stebėsena": "ES struktūrinių ir investicijų (ESI) lėšos turi būti naudojamos efektyviai ir pagal apibrėžtą paskirtį. Dėl šios priežasties ES teisės aktais buvo nustatytos išsamios vertinimo ir stebėsenos procedūros, kuriomis siekiama patikrinti, ar veiklos programos, naudojančios ESI finansavimą, tinkamai veikia ir duoda rezultatus, kuriuos galima patikrinti pagal sutartus kriterijus.",
            "Stebėsenos komitetas": "Valstybės narės turi paskirti stebėsenos komitetus, kurie tikrintų, ar veiksmų programos, kuriose naudojamos ESIF, yra tinkamai įgyvendinamos. Šiems komitetams pirmininkauja Vadovaujanti institucija, juos sudaro regioniniai, ekonominiai ir socialiniai partneriai.",
            "Strategija „Europa 2020“": "tai dešimties metų ES pažangaus, tvaraus ir integracinio augimo strategija. Siekiant šio tikslo, buvo nustatyti penki plataus užmojo tikslai, apimantys užimtumą, mokslinius tyrimus ir plėtrą, klimato kaitą ir energijos tvarumą, švietimą ir kovą su skurdu ir socialine atskirtimi.",
            "Struktūriniai fondai": "finansinės priemonės, sukurtos Europos Sąjungos regioninei politikai įgyvendinti. Jomis siekiama sumažinti regioninius skirtumus pajamų, turto ir galimybių požiūriu. Yra du struktūriniai fondai: Europos socialinis fondas (ESF) ir Europos regioninės plėtros fondas (ERPF). Struktūriniai fondai yra valdomi pagal vieną taisyklių rinkinį, apimantį visus penkis Europos struktūrinius ir investicijų fondus (ESIF): Europos regioninės plėtros fondą (ERPF), Europos socialinį fondą (ESF), Sanglaudos fondą (SF) ir Europos žemės ūkio fondą kaimo plėtrai. (EŽŪFKP) ir Europos jūrų reikalų ir žuvininkystės fondo (EJRŽF).",
            "Subranga": "Subrangos sutartis yra procesas, kurio metu sudaromas susitarimas dėl prekių ar paslaugų, susijusių su projektui reikalingomis užduotimis, kurių negali atlikti pats gavėjas, tiekimo ar paslaugų, sudarytas tarp gavėjo ir vieno ar daugiau subrangovų specifiniams projekto poreikiams tenkinti. . Subrangos sutartis kyla iš principo, kad naudos gavėjai turi turėti veiklos pajėgumų projektui įgyvendinti, ir apsiriboja tomis darbo dalimis, kurių negali atlikti pats naudos gavėjas. Ji neturi būti susijusi su „pagrindinėmis“ projekto darbo dalimis, o subrangovas gali sudaryti tik ribotą projekto dalį, kai tai būtina projektui įgyvendinti. Projekto valdymas visada turi būti laikomas pagrindiniu projekto elementu ir negali būti subrangos sutarties objektas.",
        },

        "T" : {
            "Tarptautinis bendradarbiavimas": "pagrindinis jo tikslas yra skatinti bendradarbiavimą ir geresnę integraciją tarp didelių Europos regionų grupių, turinčių panašių savybių.",
            "Tarpvalstybinis bendradarbiavimas": "ES regioninės politikos programa, skatinanti projektus, kurių partnerystę sudaro partneriai iš dviejų ar daugiau valstybių ir kurie yra pasienio zonoje.",
            "Tarpregioninis bendradarbiavimas": "juo siekiama stiprinti ES regioninę plėtrą perduodant praktinę patirtį ir keičiantis patirtimi tarp regionų. Programos iš dalies finansuojamos iš ERPF pagal tarpregioninio bendradarbiavimo tikslą: INTERACT (už bendradarbiavimo programų įgyvendinimą atsakingų institucijų parama ir keitimasis gerąja patirtimi), ESPON (teritorijų planavimo observatorija) ir URBACT (kuria ir parama). miestų tinklams ir miesto plėtrai).",
            "Tikslinė grupė": "tikslinė grupė yra asmenų ir (arba) organizacijų, tiesiogiai ir teigiamai paveiktų projekto rezultatų, visuma. Nebūtinai gaudamos finansinę dotaciją ir net nedalyvaudamos projekte, tikslinės grupės gali panaudoti projekto rezultatus savo naudai.",
            "Tinkamumo patikrinimas": "pirmasis dviejų etapų vertinimo procedūros žingsnis. Projektų pasiūlymai, pretenduojantys gauti ESIF lėšas, turi sėkmingai išlaikyti tinkamumo patikrinimą, kad būtų svarstomi dėl finansavimo.",
            "Tinkamumo kriterijai": "daugiausia techniniai reikalavimai, veiksmai atitinkantys ESI fondų finansavimo reikalavimus, kad atitiktų tinkamumo patikrinimą.",
            "Tinkamumo laikotarpis": "Tinkamumo laikotarpis – tai laikotarpis, per kurį turi būti patirtos ir apmokėtos Projekto išlaidos (nebent naudojami supaprastinti išlaidų variantai), kad būtų galima gauti kompensaciją iš Programos lėšų.",
            "Tikslai": "Projekte atliekamo darbo tikslai, atsižvelgiant į jo mokslinių tyrimų ir inovacijų turinį. Tai bus paversta projekto rezultatais. Tai gali skirtis nuo konkrečių mokslinių tyrimų klausimų sprendimo, naujovių pagrįstumo demonstravimo, dalijimosi žiniomis tarp suinteresuotųjų šalių konkrečiais klausimais. Tikslų pobūdis priklausys nuo veiksmo tipo ir temos apimties.",
            "Techninė pagalba": "galima padėti suinteresuotosioms šalims įgyvendinti Komisijos finansuojamas programas ir projektus. Pagal Europos Sąjungos Sanglaudos politiką tokia finansinė parama gali būti naudojama pasirengimui, valdymui, vertinimui, stebėsenai, auditui ir kontrolei apmokėti.",
            "Teminiai tikslai": "Europos struktūriniai ir investicijų fondai, ypač Europos regioninės plėtros fondas (ERPF), Europos socialinis fondas (ESF) ir Sanglaudos fondas, remia įvairius investicinius prioritetus, dar vadinamus teminiais tikslais.",
            "Teritorinė sanglauda": "aiškus ES tikslas – stiprinti ekonominę ir socialinę sanglaudą mažinant skirtumus tarp ES regionų.",
            "Transeuropiniai tinklai (TEN)": "susideda iš tarpvalstybinės transporto ir energetikos infrastruktūros. Jų tikslas – remti ir stiprinti Europos bendrosios rinkos veikimą, palengvinant laisvą prekių, žmonių ir paslaugų judėjimą.",
            "Tvirtinančioji institucija": "TI daugiausia atsakinga už patvirtintos išlaidų ataskaitos ir paraiškos dėl ERPF mokėjimo parengimą ir pateikimą Europos Komisijai. Be to, ji yra atsakinga už ERPF mokėjimus pagrindiniam patvirtintų projektų partneriui.	",
        },

        "U" : {
            "URBACT – Miestų plėtros tinklo programa": "Europos mainų ir mokymosi programa, finansuojama Komisijos pagal teritorinio bendradarbiavimo tikslą, kuri yra tarpregioninio bendradarbiavimo programų dalis. Tikslas – skatinti naujoves miestų regeneravimo srityje, skatinant miestus nustatyti, perduoti ir skleisti gerąją patirtį."
        },

        "V" : {
            "Vadovas pareiškėjams": "vadovu siekiama padėti potencialiems pareiškėjams. Jame pateikiama informacija apie programą, projektų reikalavimus, tinkamumo taisykles, paraiškų teikimo ir vertinimo procesus bei projektų įgyvendinimo principus.",
            "Vadovaujančioji institucija" : "VI yra atsakinga už veiksmų programos valdymą ir įgyvendinimą; ji veikia kaip sąsaja tarp Europos Komisijos ir dalyvaujančių valstybių bei regionų ir užtikrina programos atitiktį Bendrijos teisės aktams ir politikai.	",
            "Vieneto kaina": "tinkamų finansuoti išlaidų nustatymo / apskaičiavimo metodai. Fiksuota suma už vienetą. Vieneto kainą gali nustatyti komisiniai (pvz., MVĮ savininko vieneto savikaina) arba apskaičiuoti naudos gavėjo, remdamasis savo įprasta apskaitos praktika (pvz., vidutinėmis personalo sąnaudomis, vidaus sąskaitomis faktūromis prekėmis ir paslaugomis ir kt.).",
            "Vienkartinės išmokos išlaidos": "tinkamų finansuoti išlaidų nustatymo / apskaičiavimo metodai. Bendra suma vienai ar kelioms išlaidų kategorijoms arba visoms projekto išlaidoms padengti.",
            "Visuotinės dotacijos": "visuotinės dotacijos padeda mažoms nevyriausybinėms organizacijoms, pavyzdžiui, savanorių grupėms ir bendruomeninėms organizacijoms, gauti paramą iš Europos socialinio fondo (ESF) ir Europos regioninės plėtros fondo (ERPF).",
            "Veiklos rezultatų planas": "Veiklos planas yra kiekvienos veiksmų programos rodiklių rinkinys, pagal kurį Komisija, bendradarbiaudama su valstybėmis narėmis, peržiūri programų rezultatus kiekvienoje valstybėje narėje.	",
            "Veiksmas": "veiklos, kuriai prašoma Europos Sąjungos finansavimo, visuma pagal individualų finansavimo sprendimą, priimtą paskelbus kvietimą teikti paraiškas",
            "Veiksmų planas ": "dokumentas, kuriame pateikiama išsami informacija apie įgyvendinamų veiksmų pobūdį, jų laiką, dalyvaujančius veikėjus, išlaidas (jei tokių yra) ir finansavimo šaltinius (jei yra).",
            "Veiksmų programa": "ES šalių ir (arba) regionų parengtas ir Komisijos patvirtintas dokumentas, kuriame apibrėžiami jų prioritetai ir šiems prioritetams pasiekti reikalingas programavimas.",
            "Vertė už pinigus": "terminas \"vertė už pinigus\" reiškia sprendimą, ar išleidžiami pinigai pasiekiami pakankamai.",
        }
    },


}



export default glossaryList;