import React from 'react';
import { Box, Typography, Grid, List, ListItem,  ListItemIcon, ListItemText } from '@mui/material';



import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import lang from '../lang';
import config from '../../config';




function TargetGroups(props) {

    const {titleStyle} = props; 

    return (
        <Box sx={{background: '#efefef'}}>

            <Box maxWidth={'lg'} sx={{margin: 'auto',  p: 2 }}>

                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}   >
                        <Box sx={{position: 'relative'}} >
                            <Box
                                component="img"
                                sx={{width: '100%',boxShadow: '10px 10px 5px 0px rgba(196,197,212,.5)',zIndex:'1'}}
                                alt={lang.targetGroups}
                                src={config.hostCDN + "/groups-of-multicolored-wooden-people.png"}
                                />
                        </Box>
                    </Grid>

                    
                    <Grid item xs={12} md={6}  >

                        <Box sx={{py:2, px : { md : 2}}} >
                         
                            <Typography variant="h4" component="h2" sx={{...titleStyle, textAlign: { xs: 'left', sm : 'center', md : 'left'} }}>
                                {lang.targetGroups}
                            </Typography>

                        
                            <List>
                                {lang.targetGroupsList.map ((item, index) => (

                                    <ListItem  key={index} disablePadding >
                                        <Box sx={{py:2, display: 'flex', alignItems:'flex-start',  width: 1 }}> 

                                            <ListItemIcon>
                                                <ArrowCircleRightOutlinedIcon sx={{ fontSize: 48, color: 'primary.light'  }} />
                                            </ListItemIcon>
                                            <ListItemText primary={<Typography variant="body" display="block" sx={{ color: 'primary.light' }}>{item}</Typography>} />
                                            
                                        </Box>
                                        
                                    </ListItem>
                                ))}
                            </List>
                            
                        </Box>

                    </Grid>
                    
                </Grid>

            </Box>
        </Box>
    )
}


export default TargetGroups;

