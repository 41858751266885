import React from 'react';

import { Box, Typography, Grid, Divider, Container, Fab } from '@mui/material';
import lang from '../lang';
import config from '../../config';


function InformativeMeasures(props) {

    const {titleStyle} = props;

    titleStyle.color = 'primary.main';

    return (

        <Box sx={{bgcolor: 'primary.light', px:0, }}>
            
            <Box maxWidth='xl' sx={{ mx : 'auto', 
                backgroundImage  : {xs : 'none',  md: 'url(' + config.hostCDN + '/informativeMeasuresBg.png)'} ,
                backgroundRepeat: 'no-repeat',
                
                backgroundPosition: 'top right',
                py: 6,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
            }}>

                <Container maxWidth="md"  >

                    <Typography variant="h4" component="h2" sx={{...titleStyle, mb: { xs: 3, md : 5} , textAlign: { xs: 'left', sm : 'center'} }}>
                        {lang.informativeMeasures}
                    </Typography>

                    <Grid container spacing={0} maxWidth="md"  sx={{  mx: "auto" , mb: {xs:0, sm:3} }}>

                        {[0, 1, 2, 3].map((itemGroup, indexGroup) => (
                            <Grid key={indexGroup} item xs={12} sm={6}>

                                <Box sx={{mb: 3, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}} >
                                    <Box sx={{maxWidth: '289px'}}> 
                                        <Box sx={{mb: 2, position: 'relative',
                                            width: '289px',
                                            height: '289px',

                                            backgroundImage : `url(${config.hostCDN}/InformativeMeasures/${itemGroup + 1}.png)`, 
                                            backgroundPosition: 'center',
                                            backgroundSize: 'cover',

                                            }} >
                                            
                                            {/* <Box component='img' sx={{width : 1}} src={`${config.hostCDN}/InformativeMeasures/${itemGroup + 1}.png`} alt='InformativeMeasures' /> */}

                                            <Fab variant="extended" size="medium" sx={{position: 'absolute', top: '10px', right: '10px', color: 'primary.light', bgcolor: 'primary.main', fontSize: '1rem'}} >
                                                {itemGroup + 1}
                                            </Fab>

                                            <Typography variant="h5" component="h5" sx={{ position: 'absolute', left: '10px', bottom: '10px', fontSize: '1rem', color: 'primary.light' }}>
                                                {lang.informativeMeasuresList[itemGroup].title}
                                            </Typography>

                                        </Box>
                                    
                                    
                                        {lang.informativeMeasuresList[itemGroup].list.map((item, index) => (

                                            <Box key={index} sx={{ display: 'flex', justifyContent: 'flex-start' }} >

                                                <Typography align='left' variant="h5" component="h5" sx={{minWidth: '68px', mb: 1, mr: 2, fontSize: '3rem', color: 'primary.main' }}>{ (itemGroup + 1).toString()}.{index + 1}</Typography>
                                                <Divider style={{ background: 'white' }} sx={{ mr: 2, borderRightWidth: 4 }} orientation="vertical" variant="middle" flexItem />
                                                <Typography align='left' variant="h5" component="h5" sx={{ mb: 1, py: 1, fontSize: '1.1rem', color: 'primary.main' }}>
                                                    {item}
                                                </Typography>

                                            </Box>
                                        ))}
                                    </Box>
                                </Box>

                            </Grid>
                        ))}
                        
                    </Grid>

                </Container>
            </Box>
        </Box>    
    )
}


export default InformativeMeasures;

