import React, { useEffect, useMemo, useRef, useState } from 'react';
import config from '../../config';

import serialize from 'form-serialize';
import ReCAPTCHA from "react-google-recaptcha";


import { Box, Button, Grid, FormControlLabel, InputBase, Checkbox, Typography, Snackbar, Link, Alert, IconButton, Container } from '@mui/material';

import lang from '../lang';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { BookmarkBorder, Check, CheckBoxOutlineBlank } from '@mui/icons-material';

function Contacts(props) {


    let { lang: langCode = undefined } = useParams();

    if (!langCode) {
        langCode = lang.getLanguage().toString();
    }


    const recaptchaRef = useRef(null); 
    const recaptchaSitekey = '6LeUClQbAAAAALNUZaOKN-nk0dJ-jU88T1JX-rLX';

    const socials = useMemo( () => {
    
        //const fs = {fontSize: 42};
    
        return [{
            name : 'Facebook',
            url : 'https://www.facebook.com/ReferenceEUproject',
            icon : '/assets/facebook.svg'

        }, 
      
        {
            name : 'LinkedIn',
            url : 'https://www.linkedin.com/company/reference-eu-project',
            icon : '/assets/linkedin.svg'
        },{
            name : 'Twitter',
            url : 'https://twitter.com/EuReference',
            icon : '/assets/twitter.svg'
        }, {
            name : 'YouTube',
            url : 'https://www.youtube.com/channel/UCxzb2KnonA8y8A-p2P4xUHQ',
            icon : '/assets/youtube.svg'
        }, {
            name : 'Email',
            url : 'mailto:reference.euproject@gmail.com',
            icon : '/assets/email.svg'
        }]
    },[]);

    const [disabledContact, setDisabledContact] = useState(false);


    //const [open, setOpen] = useState(false);
    //const [snackbarType, setSnackbarType] = useState('success');
    //const [snackbarInfo, setSnackbarInfo] = useState(null);

    const [snackbarData, setSnackbarData] = useState(null);


    // const handleClose = (event, reason) => {
    //     if (reason === 'clickaway') {
    //         return;
    //     }
    
    //     setOpen(false);
    // }


    const onSubmitAction = async e =>{
		
		e.preventDefault();

		const data = serialize(e.target, { hash: true });
		const token = await recaptchaRef.current.executeAsync();

        const url = config.apiUrl + '/api/tools/sendmail';
       
        //console.log('data', data);

        try {
            setDisabledContact(true);
        
            const response = await axios.post( url, {...data, 'g-recaptcha-response' : token} )    
            console.log(response);
            e.target.reset();

            setSnackbarData({
                info : lang.messageSent,
                type : 'success'
            })

            setDisabledContact(false);

        } catch (error) {

            
            setSnackbarData({
                info : error,
                type : 'error'
            });

            setDisabledContact(false);

        }       
	}


    function PrivacyStatement(props){
        return(
            <Box 
                sx={{fontSize:'.72rem'}}
                component={'span'}
                dangerouslySetInnerHTML={{ __html: lang.acceptPrivacyStatement.replace('%s', '/' + langCode + config.privacy) }}></Box>   
        )
    }



    function ContactForm(props){

        return(

            <>
            
                <Snackbar
                    anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                    open={ snackbarData }
                    autoHideDuration={3000}
                    onClose={ e => setSnackbarData(null) }>

                    <Alert onClose={ e => setSnackbarData(null)} severity={snackbarData ? snackbarData.type : 'success'}  variant="filled" sx={{ width: '100%' }}>
                        {snackbarData ? snackbarData.info : null}
                    </Alert>
                </Snackbar>

                
                <Box sx={{ width:'100%', py: 1.5  }} >
            
                    <Box sx={{display: 'flex', flexDirection: 'column', width: { xs : '100%'}, px:2 }}>

        
                        <Box component="form" onSubmit={onSubmitAction}>
                            <Grid container justifyContent="flex-end">
                                
                                <Grid item xs={1} sm={2} >

                                    <Box sx={{pt: 8, display:'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: '100%' }} >

                                        {socials.map( (item, index) => (
                                            <IconButton key={index} href={item.url} target={'_blank'} title={item.name} component={Link} color="primary" aria-label="">
                                                <Box sx={{width: '32px', height: '32px', m: 0, p: 0}} component='img' src={item.icon} alt={item.name} /> 
                                            </IconButton>
                                        ))}
                                    </Box>
                                </Grid>

                                <Grid item xs={11} sm={10} >


                                    <Box sx={{pt:1, pl : { xs: 2, sm: 0}  }}>

                                        
                                        <Typography gutterBottom variant="h4" component="h4" sx={{fontSize:{xs: '1.6rem', md: '2rem'}, color: 'primary.main', fontWeight:'normal' }} >
                                            {lang.contacts}
                                        </Typography>

                                        <Typography gutterBottom variant="body" component="div" sx={{ color: 'primary.main', fontWeight:'normal', mb: 2 }} >
                                            {lang.letMakeACall}
                                        </Typography>


                                        <Box component="div" sx={{mb: 2, p: '2px 4px', display: 'flex', alignItems: 'center', bgcolor: 'white' ,  borderRadius: 2 }}>
                                            <InputBase sx={{ ml: 1, flex: 1, p: '4px'}}
                                                name="name"
                                                placeholder={lang.yourName + '*'}
                                                required
                                                inputProps={{ 'aria-label': lang.yourName }}
                                            />                            
                                        </Box>

                                        <Box component="div" sx={{mb: 2, p: '2px 4px', display: 'flex', alignItems: 'center', bgcolor: 'white' ,  borderRadius: 2 }}>
                                            <InputBase sx={{ ml: 1, flex: 1, p: '4px'}}
                                                name="email"

                                                placeholder={lang.yourEmail + '*'}
                                                required
                                                inputProps={{ 'aria-label': lang.yourEmail }}
                                            />                            
                                        </Box>

                                        <Box component="div" sx={{mb: 2, p: '2px 4px', display: 'flex', alignItems: 'center', bgcolor: 'white' ,  borderRadius: 2 }}>
                                            <InputBase sx={{ ml: 1, flex: 1, p: '4px'}}
                                                name="phone"
                                                placeholder={lang.phone}

                                                inputProps={{ 'aria-label': lang.phone }}
                                            />                            
                                        </Box>

                                        <Box component="div" sx={{mb: 2, p: '2px 4px', display: 'flex', alignItems: 'center', bgcolor: 'white' ,  borderRadius: 2 }}>
                                            <InputBase sx={{ ml: 1, flex: 1, p: '4px'}}
                                                name="message"
                                                required
                                                multiline
                                                rows="5"
                                                placeholder={lang.message}
                                                inputProps={{ 'aria-label': lang.message }}
                                            />                            
                                        </Box>
                                        
                                        <ReCAPTCHA
                                            
                                            onChange={ e => {}}
                                            ref={recaptchaRef}
                                            sitekey={recaptchaSitekey}
                                            size="invisible"
                                        />

                                    </Box>
                                </Grid>        

                                <Grid item xs={12} sm={10} >
                                    <FormControlLabel required control={<Checkbox icon={<CheckBoxOutlineBlank sx={{color: 'primary.main'}} />}  checkedIcon={<Check sx={{color: 'primary.main'}}/>}  />} label={<PrivacyStatement />} />
                                    <Typography variant='body2' component={'div'} sx={{fontSize:'.72rem'}}>{lang.requiredFields}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={10} >
                                    <Box sx={{ mt: 2}} >
                                        <Button  size="large" disabled={disabledContact}  variant="outlined" type='submit'>{lang.send}</Button> 
                                    </Box>
                                </Grid>
                            </Grid>  
                        </Box>
                    </Box>
                </Box>
            </>
        )
    }

    function ContactImage(props){

        return(
            <Box sx={{ width:'100%'}} >
                
                <Box sx={{ 
                    backgroundImage : `url(${ config.hostCDN + '/bg-contact.png'})` ,
                    backgroundPosition : 'left top',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'contain',
                    with: '100%',
                    minHeight: '540px',                    
                }} />
            </Box>
        )
    }
  
    // useEffect(() => {
    //     const handleScroll = () => {
    //         console.log('Utente sta scrollando');
            
    //         // Qui puoi aggiungere il codice che vuoi eseguire quando l'utente scrolla la pagina
    //     };

    //     window.addEventListener('scroll', handleScroll);

    //     // Pulizia: rimuovi l'ascoltatore di eventi quando il componente viene smontato
    //     return () => {
    //         window.removeEventListener('scroll', handleScroll);
    //     };
    // }, []); // Passa un array vuoto come secondo argomento per eseguire l'effetto solo al montaggio e smontaggio del componente

    useEffect(() => {
        setSnackbarData(null);  
    }, []);

    return (

        <Box sx={{bgcolor: 'primary.light', color: 'primary.main'}}> 
            <Container maxWidth="lg"  >
                <Grid container spacing={0} >
                    <Grid item xs={12} md={7} lg={6} >
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', m: 0 }}>
                            <ContactForm />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={5} lg={6} >
                        <Box sx={{display: { xs: 'none', md: 'flex' }, justifyContent: 'flex-start', m: 0, p: 0 }}><ContactImage /></Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}


export default Contacts;
