import { Avatar, AvatarGroup, Box, Typography } from '@mui/material';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import ReactMapGL, { FullscreenControl, GeolocateControl, MapProvider, Marker, NavigationControl } from "react-map-gl";

import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax

import lang from '../lang';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import config from '../../config';


const mapboxtoken = process.env.REACT_APP_MAPBOXGL_TOKEN ?? 'fTIyKxsYU1L62XwouQze'; // Mapbox access

mapboxgl.accessToken = mapboxtoken;




function CustomMarker(props) {

    const { longitude, latitude, code, name, flag, size } = props;

    let { lang:langCode = undefined } = useParams();

	if (!langCode) {
		langCode = lang.getLanguage().toString();
	}


    const navigate = useNavigate();


    //console.log('CustomMarker', props);

    const avatatarStyles = { width: 26, height: 26, border: '1px solid #aaa' }

    const avatar = (
        <AvatarGroup title={name} total={size} sx={{cursor: 'pointer'}}>
            <Avatar sx={avatatarStyles}
                alt={name}
                src={flag}
            >{name.charAt(0)}</Avatar>
        </AvatarGroup>    
    );

    return (
    
        <Marker

            title={name}
            onClick={e => { navigate('/' + langCode + '/search-calls?where=' + code  ); }}

            longitude={longitude}
            latitude={latitude}
            anchor="center"
            color={"#f00"}
        >
            {avatar}

        </Marker>
       
    )
}


export default function MyMap(props) {

    const mapRef = useRef();
    const mapContainerRef = useRef();

    //const boxRef = useRef();

    //const [display, setDisplay] = useState('block');

    const [isHovered, setIsHovered] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);


    const [markers, setMarkers] = useState(null);

    const position = useMemo(() => ({
        latitude: 48.15818200494567,
        longitude: 15.883345781380712,
        zoom : 3.821498316637438
    }), []);

  

    const countries = useMemo(() => [
        {
            code : 'IT',
            flag : 'https://flagcdn.com/w160/it.png',
            latitude: 42.105421, 
            longitude: 13.273249,
            size : 10
        },
        {
            code : 'RO',
            flag : 'https://flagcdn.com/w160/ro.png',
            latitude: 45.7621313,
            longitude: 23.806471,
            size : 10
        },
        {
            code : 'GR',
            flag : 'https://flagcdn.com/w160/gr.png',
            latitude: 38.6815758,
            longitude: 20.7253906,
            size : 10
        },
        {
            code : 'LT',
            flag : 'https://flagcdn.com/w160/lt.png',
            latitude: 55.4577508,
            longitude: 23.2151411,
            size : 10
        }

    ], []);


    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };


    useEffect(() => {


        async function loadData() {

        
            const { key: username, secret: password } = config.auth
            let { data: token } = await axios.post(config.apiUrl + '/api/auth/access_token', { username, password })
    
            const params = {}
    
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token.token}`
            }
    
            try {
                const { data } = await axios.get(config.apiUrl + '/api/proposals/summery', {
                    params,
                    headers
                })

                //console.log(data);

                countries.forEach(element => {                  
                    element.size = data[element.code] ?? 0;
                });

                setMarkers(countries);

            } catch (error) {
                console.log(error);
            }
        }

        loadData();


    }, []);



    const onLoadMap = () => {

        console.log('onLoadMap');

        const divMap = document.querySelector('.mapboxgl-map');

        if (divMap){
            
            //console.log(divMap);
            divMap.addEventListener('mouseleave', handleMouseLeave);
            setIsLoaded(true);
        }
    }


    return (
        <Box ref={mapContainerRef} style={{ width: "100%", height: "80%", position: 'relative' }} >

            <Box 
                className={`box ${isHovered ? 'fade-out' : ''}`}
                onMouseEnter={handleMouseEnter}
            >
                <Typography sx={{ display: {xs: 'inherit', lg:'none'}, fontSize: '.9rem', p: 1, testOverflow: 'ellipsis', textAlign: 'center' }} variant="body2" color="primary.main" gutterBottom>
                    {lang.tapOnMap}
                </Typography>

            </Box>

            <MapProvider  >

                <ReactMapGL
                 
                    ref={mapRef}
                    mapLib={mapboxgl}
                    initialViewState={{...position}}
                    style={{ width: "100%", height: (isHovered || !isLoaded) ? "calc(80vh - 90px)" : "calc(50vh - 90px)" }}


                    mapStyle={"https://api.maptiler.com/maps/outdoor-v2/style.json?key=" + mapboxgl.accessToken}

                    onLoad={onLoadMap}

                    //onMouseOut ={(e) => { setDisplay('block') }}

                    //onDblClick={() => { }}

                    // onMove={(e) => {console.log(e.viewState);}}
                    // onZoom={(e) => { console.log(e.viewState)}}

                    scrollZoom={false}
                >

                    {markers && markers.map((marker, index) => (
                        <CustomMarker 
                            //onMouseMove ={() => { setDisplay('none') }}  
                            key={index} {...marker} name={lang.countryArray[marker.code]}  />
                    ))}


                    <GeolocateControl />
                    <FullscreenControl />

                    <NavigationControl visualizePitch={true} />
                </ReactMapGL>

            </MapProvider>
        </Box>
    )
}