import React, { useState, useEffect } from 'react';


import { Button, Container, InputAdornment, IconButton, Link, TextField } from '@mui/material';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';


import serialize from 'form-serialize';

import config from '../config';
import lang from './language'
import { Box } from '@mui/system';


function Login(props) {


    const [showPassword, setShowPassword] = useState(false);

    function onClickShowPassword(e) {
        setShowPassword(!showPassword);
    }


    const onSubmitAction = e => {

        e.preventDefault();

        const data = serialize(e.target, { hash: true });
        //console.log(data);

        props.onSubmitAction(data);
    }

    

    return (

        <Container component="main" maxWidth="xs">

            <Box sx={{ my: 15 }} >

                <img style={{width: '370px', margin: 'auto'}} src={config.hostCDN + '/logo.png'} alt='Reference' />
            
                <form onSubmit={onSubmitAction}>
                    <Box component="div" sx={{ mb: 3 }} >
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="username"
                            label="Username"
                            name="username"
                            autoComplete="username"
                            autoFocus
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type={showPassword ? 'text' : 'password'}
                            id="password"
                            autoComplete="current-password"
                            InputProps={{

                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={onClickShowPassword}
                                            onMouseDown={(e) => e.preventDefault()}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Box>

                    {/* <ReCAPTCHA
                                    ref={this.recaptchaRef}
                                    sitekey={this.recaptchaSitekey}
                                    size="invisible"
                                    onChange={this.onRecaptchaChange}
                                /> */}
                    <Box component="div" sx={{ mb: 3 }} >
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                        >
                            {lang.sign_in}
                        </Button>
                    </Box>

                    {/* <Box component="div" sx={{ mb: 3 }} >

                        <Link to="/forgot_password" variant="body2">
                            {lang.forgot_password}
                        </Link>

                    </Box> */}
                </form>
            </Box>

        </Container>

    )
}


export default Login

