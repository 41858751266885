import React, {useEffect} from 'react';

import { Box, Typography } from '@mui/material';

import Header from '../modules/header';
import Footer from '../modules/footer';

import withRoot from '../modules/withRoot';

import helper from '../helper';
import lang from '../lang';


const {sxMarginTop} = helper;


function Privacy(props) {

    const { currentLanguage } = props;
    lang.setLanguage(currentLanguage.code);

    
    useEffect(() => {      

        document.title = lang.privacyPolicy + ' - ' + lang.myFundsApp

        helper.createMetaInfo(
            lang.privacyPolicy,
            lang.metaDescription,
            window.location.protocol + '//' + window.location.host + '/' + lang.getLanguage() + '/privacy')

        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);



    return (

        <>
            <Header {...props} />

            <Box sx={{ m:'auto', p: 3, py:5, maxWidth:'lg', ...sxMarginTop}} >
               
               <Typography variant="h2" component="h1" sx={{ fontWeight: 'normal', mb: 3, color: 'primary.black' }}>
                    {lang.privacyPolicy}
               </Typography>

               <Box dangerouslySetInnerHTML={{ __html: lang.privacyPolicyTextLong }} sx={{color: 'primary.light'}} />

            </Box>

            <Footer {...props} />
        </>
    )
}


export default withRoot(Privacy);