
import config from '../config';
import lang from './language/';

import React, { useState, useEffect, Fragment } from 'react';
import constants from '../front/constants.js'
import {
	BrowserRouter,
	
	Routes,
	Route,
	Navigate,
	//Redirect
} from "react-router-dom";


import {Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Box, Grid} from '@mui/material';

import Header from './header';
import Main from './main';

import Filter from './filter';
import Detail from './detail';


const axios = require('axios').default;

function Back(props){

    
	const [openDialog, setOpenDialog] = useState(false);
    const [search, setSearch] = useState({
		'beneficiaries' : []
	});  

	const [data, setData] = useState([]);
	const [metadata, setMetadata] = useState({});

	
	const [beneficiaries, setBeneficiaries] = useState([]);
	const [areas, setAreas] = useState([]);
	const [sectors, setSectors] = useState([]);


    function dialogExit() {
		
        return (
            <Dialog
                open={openDialog}
                onClose={ e => setOpenDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                disableBackdropClick
                disableEscapeKeyDown
            >
                <DialogTitle id="alert-dialog-title">{lang.exit_to_app}</DialogTitle>
    
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {lang.exit_to_app_question}
                    </DialogContentText>
                    </DialogContent>
                <DialogActions>
                <Button onClick={e => setOpenDialog(false)}>{lang.cancel}</Button>
                <Button onClick={e => {
                    setOpenDialog(false)
                    
                    sessionStorage.clear();
                    const newurl = window.location.protocol + "//" + window.location.host + '/back/';
                    window.location = newurl;
    
                }} autoFocus>
                    {lang.ok}
                </Button>
                </DialogActions>
            </Dialog>
        );
    }



	function searchAction (search) {
	
		const query = new URLSearchParams(window.location.search);
		const page = parseInt(query.get('page') || '1', 10);


		if (props.token === null){
			return;
		}

		const params = {
			...search,
			page,
			limit : 25
		};
	
		const headers = {  
			'Content-Type': 'application/json', 
			Authorization: `Bearer ${props.token}` 
		}  
	
		axios.get(config.apiUrl + '/api/proposals' , { 
			params,
			headers
		}).then(function (response) {

			setData(response.data.data);
			setMetadata(response.data.meta);

		}).catch(function (error) {
			//console.log(error);

			localStorage.clear();

			const newurl = window.location.protocol + "//" +
			window.location.host + '/back/';
			//window.history.pushState({ path: newurl }, "", newurl);

		}); 
	}


	function onChangePage(p){

		const newurl = window.location.protocol + "//" +
                   window.location.host + 
                   window.location.pathname + '?page=' + p;

		window.history.pushState({ path: newurl }, "", newurl);

		searchAction(search);
	}


	function logout(){
		setOpenDialog(true);
	}

    function onSubmitFilter(e){
        console.log('onSubmitFilter', e.target);
    }


	useEffect(() => {

		const token = sessionStorage.getItem('token');

		const params = { 
			sort : 'name'
		}

		const headers = {  
			'Content-Type': 'application/json', 
			'Authorization': `Bearer ${token}` 
		} 


		// axios.get(config.apiUrl + '/api/beneficiaries', { 
		//     params,
		//     headers
		// }).then(function (response) {
			
		//     setBeneficiaries([
		// 		{code: 'all', name: lang.all},
		// 		...response.data.data
		// 	]);
		// }).catch(function (error) {
		// 	localStorage.clear();

		// 	const newurl = window.location.protocol + "//" +
		// 	window.location.host + '/back';
		// 	//window.history.push(newurl);

		//     console.log(error);

		// }); 
	
		setBeneficiaries( constants.macroBeneficiares.map( e => ({ code : e, label : lang.macro.beneficiares[e] })));
		setAreas(constants.areas);
		
		
		   

		axios.get(config.apiUrl + '/api/sectors', {  
		    params, 
		    headers 
		}).then(function (response) {
		    setSectors([...response.data.data]);
		}).catch(function (error) {
			console.log(error);

		    const newurl = window.location.protocol + "//" +
			window.location.host + '/back';
			//window.history.push(newurl);

		});    



		const query = new URLSearchParams(window.location.search);
		
		const s = {
			beneficiaries : query.get('beneficiaries') ?? undefined,
			age : query.get('age') ?? undefined,
			year : query.get('year') ?? undefined,
			title : query.get('title') ?? undefined,
		}

		if (query.get('area')){
			s.area = query.get('area')
		}

		if (query.get('regions')){
			s.regions = query.get('regions')
		}

		if (query.get('sectors')){
			s.sectors = query.get('sectors')
		}

	
		setSearch(s);
		searchAction(s);
    }, [] );


	function onChange(event){
    
		const {name, checked, value} = event.target;

		//console.log('onChange', name, checked, value );	


		let newSearch = {...search};

		if (name === 'title'){

			if ( value.length === 0){
				delete newSearch.title;
			}
			else{
				newSearch = {
					...newSearch,
					'title' : value
				};
			}
		}

		else if (name === 'beneficiaries' ){
			console.log('onChange', name, checked, value, newSearch)
			/*
			let data = newSearch[name];

			if (checked){
				data.push(value);
			
			}else{
				data = [...data.filter(item => item !== value)];	
			}

			newSearch = {
				...newSearch,
				'beneficiaries' : data
			}
			*/

			delete newSearch.age;
			delete newSearch.year;
		
			if (value !== 'all'){
				newSearch = {
					...newSearch,
					'beneficiaries' : value,
				}
			}
			else{
				delete newSearch.beneficiaries;

				newSearch = {
					...newSearch
				}
			}
			
		}

		else if (name === 'age' ){

			delete newSearch.year;

			if (value !== ''){
				newSearch = {
					...newSearch,
					'age' : value
				};
			}

			else{
				delete newSearch.age;
			}
			

		}
		else if ( name === 'year'){

			delete newSearch.age;
		
			if (value.toString().length === 4){
				newSearch = {
					...newSearch,
					'year' : value
				};
			}
			else{
				delete newSearch.year;
			}
		}
		else if (name === 'area' ){

			delete newSearch.regions;

			if (value === 'all'){
				delete newSearch.geographic_area;

			}
			else{
				newSearch = {
					...newSearch,
					'geographic_area' : value
				}
			}
			
		}
		else if (name === 'regions' ){

			let data = newSearch[name] || [];

			if (checked){
				data.push(value);
			
			}else{
				const r = data.filter(item => item !== value);
				data = [...r];
				//return old;
			}

			newSearch = {
				...newSearch,
				'regions' : data
			}
		}
		else if (name === 'sectors' ){

			let data = newSearch[name] || [];

			if (checked){
				data.push(value);
			
			}else{
				const r = data.filter(item => item !== value);
				data = [...r];
				//return old;
			}

			newSearch = {
				...newSearch,
				'sectors' : data
			}
		}



		const params = new URLSearchParams(newSearch);

		const newurl = window.location.protocol + "//" +
                   window.location.host + 
                   window.location.pathname + '?page=1&' + params;


		//window.history.pushState({ path: newurl }, "", newurl);

		setSearch(newSearch);
		searchAction(newSearch);

	}


    return(
        <Fragment>
            {dialogExit()}

            <Header onClickExit={logout} ></Header>

            <Routes>	    

				<Route exact path='/' element={<Navigate to="/back/proposals" />} />

                {/* <Route exact path="/back/" render={()=> <Route to="/back/proposals" />}/> */}
				
                <Route exact path="/proposals" element={
					<Box sx={{ flexGrow: 1}}>
						<Grid container spacing={2}>
							<Grid item md={4} lg={3} xl={2} >
								<Filter token={props.token} search={search} onSubmitFilter={onSubmitFilter} onChange={onChange} relates={{
									beneficiaries,
									areas,
									sectors
								}}  /> 
							</Grid>
							<Grid item md={8} lg={9} xl={10}>
								<Main token={props.token} search={search} data={{data,metadata}} onChangePage={onChangePage}></Main>
							</Grid>
						</Grid>
					</Box>
				} />
				
                <Route path="/proposals/:id" element={<Detail />} />

            </Routes>
        </Fragment>
    )
}

export default Back

