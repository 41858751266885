import React, { Fragment,  useMemo, useState } from 'react'

import { Link as RouterLink, useLocation,  useParams } from "react-router-dom";

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';

import config from '../../config';
import lang from '../lang';

import { Collapse, Link, List, ListItemButton,  ListItemText, Typography } from '@mui/material';

import Quizes from './quizes';

import CookiePanel from './cookiePanel';
import { useCookies } from 'react-cookie';
import { grey } from '@mui/material/colors';
import { ArrowDropDown, ExpandLess, ExpandMore } from '@mui/icons-material';

const rightLink = {
    fontSize: '.9rem',
    color: 'primary.light',
    whiteSpace: 'nowrap',
    textDecoration: 'none',
    textTransform: 'none',  
    fontWeight: 'bold',
    '&:hover': {
        color: 'primary.dark',
    }
};

const subMenuCss = {
    bgcolor: grey[100],
    borderLeftWidth: '5px',
    borderLeftStyle: 'solid',
    borderLeftColor: 'primary.light',
    p: 1,
    pl: 4
};


function Header(props) {

    const { flags, currentLanguage, setCurrentlanguage } = props;

    const [anchorElNav, setAnchorElNav] = useState(null);
    const [anchorElLanguage, setAnchorElLanguage] = useState(null);
    const [cookies] = useCookies(['ccCookie']);

    //const navigate = useNavigate();
    const { pathname, hash } = useLocation();


    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenLanguageMenu = (event) => {
        setAnchorElLanguage(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseLanguageMenu = () => {
        setAnchorElLanguage(null);
    };

    let { lang: langCode = undefined } = useParams();

    if (!langCode) {
        langCode = lang.getLanguage().toString();
    }


    //console.log(langCode, lang.getLanguage(), currentLanguage) ;

    if (langCode !== lang.getLanguage().toString()) {

        const flag = flags.find(element => element.code === langCode)

        if (flag) {
            lang.setLanguage(flag.code);

            localStorage.setItem('language', flag.code);
            setCurrentlanguage(flag);
        }
    }


    const pages = useMemo(() => [

        {
            id: 'search-calls',
            url: '/search-calls?where=' + (langCode.toUpperCase() === 'EN' ? 'IT' : langCode.toUpperCase()),
            ref: '',
            name: lang.searchCalls
        }, {
            id: 'contacts',
            url: '/home',
            ref: 'contactsRef',
            name: lang.contacts
        },{
            id: 'glossary',
            url: '/glossary',
            ref: '',
            name: lang.glossary
        }, /*{
            id: 'citizens-voice',
            url: '/citizens-voice',
            ref : '',
            name: lang.citizensVoice
        }, */
        {
            id: 'policy-reccomendations',
            url: '/cohesion-policy-approach',
            ref: '',
            name: lang.policyReccomendations
        },
        {
            id: 'about',
            url: '/about',
            ref: '',
            name: lang.about,
            subMenu: [
                {
                    id: 'about',
                    url: '/about',
                    ref: '',
                    name: lang.aboutReference,
                },{
                    id: 'media-4-you',
                    url: '/media-4-you',
                    ref: '',
                    name: lang.media4You
                },
            ]
        }
    ], [langCode]);


    // useEffect(() => {

    // }, []);


    function getUrl(page) {
        return '/' + langCode + page.url + (page.ref ? '#' + page.ref : '');
    }

    function getAction(page) {
        
        if ('/' + langCode + page.url === pathname && hash) {

            const id = hash.replace('#', '');
            const element = document.getElementById(id);

            if (element){

                const header = document.getElementById('header');
                
                let h = 65;
                if (header){
                    h = Math.ceil( header.getBoundingClientRect().height);
                }
                
                const p = element.getBoundingClientRect();
                const y = p.top + window.scrollY - h;
                
                window.scrollTo({top: y, behavior: 'smooth'});       
            }

            return false;

        }

        return  false;
    }
 


    function MenuMobileV2() {


        const [open, setOpen] = React.useState(true);

        const handleClick = () => {
            setOpen(!open);
        };


        return (
            <Menu
                id="menu-appbar"
                component='div'
                anchorEl={anchorElNav}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}

                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}

                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{  width: '100%',   display: { xs: 'block', lg: 'none' } }}
                className='sub-menu-desktop'
            >

                <List disablePadding sx={{ maxWidth: '280px' , width: '100%' }} component="nav"  >
                 
                {pages.map((page, index) => (
                    
                    <Fragment key={index}>
                        <ListItemButton 
                            component={ page.subMenu ? null : RouterLink} 
                            to={page.subMenu ? null : getUrl(page)} 
                            sx={{ width: '100%' }} 
                            onClick={page.subMenu ? handleClick : e => { handleCloseNavMenu(); getAction(page)}}>
                                
                            <Box sx={{width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                <ListItemText primary={<Typography sx={{...rightLink}} >{page.name}</Typography>} />
                                {page.subMenu ? (open ? <ExpandLess /> : <ExpandMore />) : null}
                            </Box>
                        </ListItemButton>

                        {page.subMenu ?
                            <Collapse in={open} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    {page.subMenu.map((subPage, subPageIndex) => (

                                        <ListItemButton key={subPageIndex} 
                                            sx={{...subMenuCss}}
                                            component={RouterLink} 
                                            to={getUrl(subPage)}
                                            onClick={handleCloseNavMenu}
                                            >
                                            <ListItemText primary={<Typography sx={{...rightLink}} >{subPage.name}</Typography>} />
                                        </ListItemButton>

                                    ))}

                                </List>
                            </Collapse>
                        : null }
                    </Fragment>
                ))}

                </List>
            </Menu>
        )
    }


    function MenuDesktop() {

        const [anchorEl, setAnchorEl] = React.useState(null);
        const open = Boolean(anchorEl);

        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };
        const handleClose = () => {
            setAnchorEl(null);
        };

        return (
            <Box sx={{ ml: 3, flexGrow: 1, display: { xs: 'none', lg: 'flex' } }}>
                {pages.map((page, index) => {

                    return (

                        <Fragment key={index} >
                          
                            <Button key={page.id} sx={{...rightLink, mx: 2, p:0 }}
                                component={RouterLink}
                                to={getUrl(page)}
                                onClick={ e => getAction(page)}

                                endIcon={page.subMenu ? <ArrowDropDown /> : null}
                                onMouseEnter={ page.subMenu ? handleClick : null}  
                            >
                               
                                <Typography sx={{...rightLink}} component="span" >{page.name}</Typography>
                            </Button>

                            {page.subMenu &&
                                <Menu
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose} 
                                    sx={{ mt: 1 }}
                                    className='sub-menu-desktop'
                                    >
                                    
                                    {page.subMenu.map((subPage, subPageIndex) => (
                                        <MenuItem key={subPageIndex} 
                                            sx={{...subMenuCss}}
                                            component={RouterLink} 
                                            to={getUrl(subPage)}
                                            onClick={handleCloseNavMenu}
                                            >
                                                <Typography sx={{...rightLink}} >{subPage.name}</Typography>

                                        </MenuItem>

                                    ))}
                                </Menu>
                            }


                        </Fragment>
                    )
                })}
            </Box>
        )
    }


    return (

        <>
            {!cookies.ccCookie ? <CookiePanel /> : <Quizes {...props} />}

            {/* <CookiePanel /> */}

            
            <AppBar id="header" position="fixed">

                <Box sx={{ mx: { xs: 0, lg: 5 } }}>
                    <Toolbar disableGutters>

                        <Box sx={{ width: '100%', display: { xs: 'flex', lg: 'none' }, flexDirection: 'row', justifyContent: 'space-between' }}>

                            <Box sx={{ display: 'flex', justifyContent: 'flex-start', px: 1 }} >

                                <IconButton
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleOpenNavMenu}
                                    sx={{ color: 'primary.light' }}
                                >
                                    <MenuIcon />
                                </IconButton>

                                <MenuMobileV2 />

                            </Box>

                            <Button sx={{ m: 0, p: 0 }} href='/'><Box component='img' sx={{ width: '218px' }} src={config.hostCDN + '/logoRev2.svg'} alt={lang.myFundsApp} /></Button>

                            <Box sx={{ display: 'flex', justifyContent: 'center', px: 3 }}>

                                <Tooltip title={currentLanguage.name}>
                                    <IconButton onClick={handleOpenLanguageMenu} sx={{ p: 0 }}>
                                        <Avatar sx={{ width: 24, height: 24, boxShadow: '1' }} alt="Remy Sharp" src={currentLanguage.image} />
                                    </IconButton>
                                </Tooltip>

                                <Menu
                                    sx={{ mt: '45px' }}
                                    anchorEl={anchorElLanguage}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(anchorElLanguage)}
                                    onClose={handleCloseLanguageMenu}
                                >

                                    {flags.map((flag, index) => (

                                        <Link key={index} href={'/' + flag.code + '/home'} style={{ textDecoration: 'none', display: 'block', color: 'primary.light' }}>
                                            <MenuItem onClick={e => {
                                                handleCloseLanguageMenu();
                                            }} key={flag.code}>

                                                <Avatar src={flag.image} sx={{ mr: 1 }} />

                                                <Typography variant="body" component="span" sx={{ flexGrow: 1, color: 'primary.light' }}>
                                                    {flag.name}
                                                </Typography>

                                            </MenuItem>
                                        </Link>
                                    ))}
                                </Menu>

                            </Box>
                        </Box>

                        <Box sx={{ my: 2, mr: 0, display: { xs: 'none', lg: 'flex' } }}>
                            <Button sx={{ m: 0, p: 0 }} component={RouterLink} to={'/' + langCode + '/home'}>
                                <Box component='img' sx={{ width: '218px' }} src={config.hostCDN + '/logoRev2.svg'} alt={lang.myFundsApp} />
                            </Button>
                        </Box>

                        <MenuDesktop />


                        

                        <Box sx={{ flexGrow: 0 }}>
                            <Box sx={{ flexGrow: 1, display: { xs: 'none', lg: 'flex' } }}>
                                
                                <Tooltip title={currentLanguage.name}>
                                    <IconButton onClick={handleOpenLanguageMenu} sx={{ p: 0 }}>
                                        <Avatar alt={currentLanguage.code} sx={{boxShadow: 2}} src={currentLanguage.image} />
                                    </IconButton>
                                </Tooltip>

                                <Menu
                                    sx={{ mt: '45px' }}
                                    id="menu-appbar"
                                    anchorEl={anchorElLanguage}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(anchorElLanguage)}
                                    onClose={handleCloseLanguageMenu}
                                >
                                    {flags.map((flag, index) => (

                                        <Link key={index} href={/*url.replace(langCode, flag.code)*/ '/' + flag.code + '/home'} style={{ textDecoration: 'none', display: 'block', color: 'primary.light' }}>
                                            <MenuItem onClick={e => {
                                                handleCloseLanguageMenu();
                                            }} key={flag.code}>
                                                <Avatar src={flag.image} sx={{ mr: 1 }} />

                                                <Typography variant="body" component="span" sx={{ flexGrow: 1, color: 'primary.light' }}>
                                                    {flag.name}
                                                </Typography>


                                            </MenuItem>
                                        </Link>

                                    ))}

                                    {/* {flags.forEach((flag) => (
                                        <MenuItem key={flag.code} onClick={ e => {
                                            handleCloseLanguageMenu();  
                                        }}>
                                            {console.log(flag.code)}
                                            <Avatar src={flag.image} sx={{mr:1}} /> {flag.name}
                                        </MenuItem>
                                        
                                    ))} */}

                                </Menu>
                            </Box>
                        </Box>

                    </Toolbar>
                </Box>

            </AppBar>

            
        </>

    )
}



export default Header;