import {Box, Divider, Container, Grid, Typography, InputBase, IconButton, Paper, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Link} from '@mui/material';

import lang from '../lang';

import serialize from 'form-serialize';
import React, { useState } from 'react';
import axios from 'axios';
import config from '../../config';
import { useParams } from 'react-router-dom';
import Newsletters from './newsletter';




function Footer(props) {

    // const recaptchaRef = useRef(null); 
    // const recaptchaSitekey = '6LeUClQbAAAAALNUZaOKN-nk0dJ-jU88T1JX-rLX';

    let { lang: langCode = undefined } = useParams();

    if (!langCode) {
        langCode = lang.getLanguage().toString();
    }





    return (

        <>
            <Box sx={{bgcolor:'#fff', zIndex: '1', position:'relative' }}>
                <Divider variant="fullWidth" sx={{margin:0, borderBottomWidth: 8, borderColor: 'primary.light' }} orientation="horizontal" flexItem />
                
                <Box component="footer" sx={{ p:{xs: 2, md: 10 }}}>
                    
                    <Container>

                        <Grid container sx={{mb: 4}}>
                            <Grid item xs={12} md={6} lg={3} sx={ { mb:{ xs: 3, lg: 0} }} >
                                <Newsletters />
                            </Grid>

                            <Grid item xs={12} md={6} lg={9} >

                                <Box sx={{display: 'flex', flexDirection: 'column',  flexWrap: 'nowrap', px: {xs : 0, md: 2, lg:4}}}> 
                                    <Box sx={{mb:2}}>
                                        <img style={{marginTop: '10px', width: '250px' }} src={config.hostCDN + '/footer.png' }  alt='Reference' /> 
                                    </Box>

                                    <Typography gutterBottom variant="h6" component="div" sx={{fontSize:'0.8rem', color: 'primary.light', fontWeight:'normal', textTransform: 'none'  }} >
                                        {lang.footerDisclaimer}
                                        <br /> <br />
                                        Project Number: 2020CE16BAT205
                                    </Typography>
                                </Box>

                                
                            </Grid>
                        </Grid>   

                        <Box sx={{display:"flex", justifyContent: { xs : 'space-between', md : 'center' }}}>
                            <Link sx={{ mr : { md : 2}}} href={'/' +  langCode + '/privacy'} color="inherit" variant="body2">
                                {lang.privacyPolicy}
                            </Link>  
                            <Link sx={{ ml : { md : 2}}} href={'/' +  langCode + '/cookies'} color="inherit" variant="body2">
                                {lang.cookiesPolicy}
                            </Link> 
                        </Box>
                    </Container>            

                </Box>
            </Box>
        </>
    )
}



export default Footer;