import React, { Fragment, useEffect, useState } from 'react';


import { useParams } from "react-router-dom";

import { Box, Typography, Grid, Link, List, ListItem, Avatar, ListItemText, Divider, ListItemIcon,  CardMedia, Paper, Stack, Button, Skeleton, IconButton } from '@mui/material';

import FolderIcon from '@mui/icons-material/Folder';


import Header from '../modules/header';
import Footer from '../modules/footer';
import withRoot from '../modules/withRoot';

import NewspaperIcon from '@mui/icons-material/Newspaper';
import ImageIcon from '@mui/icons-material/Image';

import lang from '../lang';

import helper from '../helper';


import config from '../../config';

import axios from 'axios';
import { Download, MoreHoriz } from '@mui/icons-material';

const { ProductHeroLayoutPage, Background, sxMarginTop } = helper;

const sxBackground = {
	backgroundImage: `url('${config.hostCDN}/media-4-you.png')`,
	backgroundColor: 'tranparent', // Average color of the background image.
	backgroundPosition: 'center',
}


// function BlockCard(props) {

// 	//const { url } = useRouteMatch();
// 	let { lang: langCode = undefined } = useParams();

// 	if (!langCode) {
// 		langCode = lang.getLanguage().toString();
// 	}


// 	const { title = 'Title', code, status = 'd' } = props;


// 	let backgroundColor = '#ccc';

// 	if (status === 'enabled') {
// 		backgroundColor = 'primary.light'
// 	}


// 	return (

// 		<Box sx={{
// 			display: 'flex',
// 			justifyContent: 'flex-start',

// 			width: '100%',
// 			height: 'auto',
// 			backgroundColor,
// 			borderRadius: 2,
// 			px: 2.3
// 		}}
// 		>


// 			<Box>

// 				{status === 'enabled' &&
// 					<Link component={ReactRouterLink} sx={{ textDecoration: 'none' }}
// 						to={{ pathname: '/' + langCode + code }}>

				

// 						<Typography sx={{ fontSize: '1.3rem' }} variant="h2" component="h2" color="primary.main" gutterBottom>

// 							<div style={{
// 								display: 'flex',
// 								alignItems: 'center',
// 								flexWrap: 'wrap',
// 								height: 80
// 							}}>
// 								<Download style={{ fontSize: 50 }} sx={{ mr: 2 }} />
// 								<span>{title}</span>
// 							</div>

// 						</Typography>
// 					</Link>
// 				}
// 				{status !== 'enabled' &&

// 					<Typography sx={{ fontSize: '1.3rem' }} variant="h2" component="h2" color="primary.main" gutterBottom>

// 						<div style={{
// 							display: 'flex',
// 							alignItems: 'center',
// 							flexWrap: 'wrap',
// 							height: 80
// 						}}>
// 							<CheckIcon style={{ fontSize: 50 }} sx={{ mr: 2 }} />
// 							<span> {title}</span>
// 						</div>

// 					</Typography>
// 				}
// 			</Box>
// 		</Box>
// 	)
// }



function EventCard(props) {

	//const { url } = useRouteMatch();
	let { lang: langCode = undefined } = useParams();

	if (!langCode) {
		langCode = lang.getLanguage().toString();
	}


	const { item } = props;


	const name = item.name[langCode ?? 'it'];
	const image = config.hostCDN + '/events/covers/' + item.code + '.png'	

	return (
		<Box title={name} sx={{ width: '100%' }}>

			<Box sx={{boxShadow: 4}}>
				<Link href={item.link} target='_blank' sx={{textDecoration: 'none'}}  >

					<CardMedia
						component="img"
						width={'100%'}
						
						image={image}
						alt={name}
						title={name}
					/>

					<Box sx={{ height: '76px', p : 1}} variant="body2" bgcolor="primary.main" component="h2" color="primary.light">

						<Box style={{
							display: 'flex',
							width: '100%',
							height: '100%',
							alignItems: 'center',
							justifyContent: 'center'
							
						}}>
							<Typography sx={{ fontSize: '.8rem', textDecoration: 'none'  }} >
								{name}
							</Typography>
						</Box>

					</Box>

				</Link>
			</Box>
		</Box>
	)
}



function ComunicationCampaignsBox(props) {

	const { elem } = props;

	const image = config.hostCDN + '/comunicationCampaigns/covers/' + elem.code + '.png'

	return (
		<Box title={elem.title} sx={{ maxWidth: '300px' }}>

			<Box sx={{boxShadow: 4}}>
				<Link href={elem.url} target='blank' sx={{textDecoration: 'none'}}  >

					<CardMedia
						component="img"
						width={'100%'}
						height={220}
						image={image}
						alt={elem.name}
						title={elem.name}
					/>

					<Box sx={{ height: '76px', p : 1}} variant="body2" bgcolor="primary.light" component="h2" color="primary.main">

						<Box style={{
							display: 'flex',
							width: '100%',
							height: '100%',
							alignItems: 'center'
							
						}}>
							{/* <ImageIcon style={{ fontSize: 50 }} sx={{ mr: 1 }} /> */}
							
							<Typography sx={{ fontSize: '.8rem', textDecoration: 'none', textAlign: 'center', width: 1  }} >
								{elem.title}
							</Typography>
							
						</Box>

					</Box>

				</Link>
			</Box>
		</Box>
	)
}	



function SuccessStoriesBox(props) {

	const { elem } = props;

	//console.log(elem);

	const image = config.hostCDN + '/successStories/' + elem.code + '.webp'

	return (
		<Box title={elem.title} sx={{ maxWidth: '300px' }}>

			<Box sx={{boxShadow: 4}}>
				<Link href={elem.url} target='blank' sx={{textDecoration: 'none'}}  >
						<CardMedia
							component="img"
							width={'100%'}
							height={220}
							image={image}
							alt={elem.name}
							title={elem.name}
						/>

				</Link>
			</Box>
		</Box>
	)
}	



function VideoPaper(props) {

	const { elem } = props;

	return (
		<Paper elevation={3} sx={{ maxWidth: {xs : '100%', sm : '320px' }  }}>
			<Link href={elem.url} target='blank' >

				<CardMedia
					component="img"
					width={'90%'}
					image={config.hostCDN + elem.image}
					alt={elem.name}
					title={elem.name}
				/>
			</Link>
		</Paper>
	)
}


function NewslettersBlock(props) {
	let { lang: langCode = undefined } = useParams();

	if (!langCode) {
		langCode = lang.getLanguage().toString();
	}

	const { number, name = 'Title', code, date } = props.item;
	const download = `${config.hostCDN}/newsletters/${code}/${langCode}.pdf`;
	const dateFormatted = (new Date(date)).toLocaleDateString(langCode, { year: 'numeric', month: 'long' }).trim();

	return (
		<Fragment>
			<ListItem alignItems="flex-start">
				<ListItemIcon>
					<Avatar>
						<FolderIcon />
					</Avatar>
				</ListItemIcon>

				<ListItemText
					primary={
						<Link sx={{ textDecoration: 'none' }} href={download} target={'_blank'}>
							<Typography
								sx={{ display: 'block' }}
								component="span"
								variant="body2"
								color="text.primary"
							>
								{number} - {name}
							</Typography>

							<Typography
								sx={{ display: 'block' }}
								component="span"
								variant="body2"
								color="text.secondary"
							>
								{dateFormatted.charAt(0).toUpperCase() + dateFormatted.slice(1).toLowerCase()}
							</Typography>
						</Link>
					}
				/>
			</ListItem>

			<Divider variant="inset" component="li" />
		</Fragment>
	)
}

function PressReleasesBlock(props) {
	let { lang: langCode = undefined } = useParams();

	if (!langCode) {
		langCode = lang.getLanguage().toString();
	}

	const { number, name = "Title", date } = props.item;
	const download = `${config.hostCDN}/pressreleases/${date}/${langCode}.pdf`;
	const dateFormatted = (new Date(date)).toLocaleDateString(langCode, { year: 'numeric', month: 'long' }).trim();

	return (
		<>
			<ListItem alignItems="flex-start">
				<ListItemIcon>
					<Avatar>
						<NewspaperIcon />
					</Avatar>
				</ListItemIcon>

				<ListItemText
					primary={
						<Link sx={{ textDecoration: 'none' }} href={download} target={'_blank'}>
							<Typography
								sx={{ display: 'block' }}
								component="span"
								variant="body2"
								color="text.primary"
							>
								{lang.pR}{number} - {name}
							</Typography>

							<Typography
								sx={{ display: 'block' }}
								component="span"
								variant="body2"
								color="text.secondary"
							>
								{dateFormatted.charAt(0).toUpperCase() + dateFormatted.slice(1).toLowerCase()}
							</Typography>
						</Link>
					}
				/>
			</ListItem>

			<Divider variant="inset" component="li" />
		</>
	)
}

function TwoBlock(props) {

	const {
		title = '',
		titleDescription = undefined,
		image = undefined,
		subTitle = undefined,
		body = undefined


	} = props;

	return (
		<Fragment>
			<Typography variant="h4" component="h2" sx={titleStyle}>
				{title}
			</Typography>

			{titleDescription &&
				<Typography variant="body2" component="p" sx={titleStyle}>
					{titleDescription}
				</Typography>
			}


			<Grid container spacing={3}>

				<Grid item sm={6}>


					{image ?
						<Box>
							<img style={{ width: '100%' }} src={image} alt='Reference' />
						</Box>
						:
						<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', bgcolor: '#ddd', height: 'auto', minHeight: 250 }}>
							<ImageIcon sx={{ fontSize: 100 }} title={title} />
						</Box>

					}



				</Grid>
				<Grid item sm={6}>

					{subTitle &&
						<Typography variant="h5" component="h3" sx={{ fontSize: '1.4rem', fontWeight: '600', mb: 2.5 }}>
							{subTitle}
						</Typography>
					}

					{body &&
						<Typography variant="body2" component="p" sx={titleStyle}>
							{body}
						</Typography>
					}
				</Grid>
			</Grid>
		</Fragment>
	)
}


function LeafletBlock(props) {

	const { currentLanguage } = props;

	useEffect(() => {
		lang.setLanguage(currentLanguage.code);
	}, [currentLanguage]);

	return (

		<Box id="leaflet" sx={{
			boxShadow: 3,
			maxWidth: { xs : '100%', sm : '320px', md : '320px' } , mx: { sm : 'auto', md: 'unset' } ,  
			width: '100%', mb: 5, display: 'flex', flexDirection: 'column', justifyContent: 'center'  }}>

			<CardMedia
				component="img"
				height={220}
				image={config.hostCDN + '/leafletCover.png'}
				alt={lang.leaflet}
				title={lang.leaflet}
				sx={{width: '100%',  mx : 'auto', borderRadius: 0 }}
			/>

			<Button sx={{width: '100%', mx : 'auto', py: 2, borderRadius: 0  }} variant="contained" color='secondary' 
				
				onClick={() => {
					const link = document.createElement("a");
					link.download = config.hostCDN + '/leaflets' + lang.leafletdownload;
					link.href = config.hostCDN + '/leaflets' + lang.leafletdownload;
					link.target = '_blank';
					link.click();
				}}>

				
					<Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>

						<Download sx={{ fontSize: '2.4rem', mr: 2 }} />
						<Typography sx={{ fontSize: '1.2rem', m: 0, p: 0 }} variant='h2'  component="span" color="primary.main" gutterBottom>
							{lang.leaflet}
						</Typography>
					</Box>

			</Button>
		</Box>
	)
}


const titleStyle = { 
	color: 'primary.light', 
	mb: 3, 
	fontWeight: 700, 
	fontSize: {xs : '1.4rem', sm: '1.8rem'},
};



function Media4You(props) {
	let { lang: langCode = undefined } = useParams();

	if (!langCode) {
		langCode = lang.getLanguage().toString();
	}

	const { currentLanguage } = props;

	const pageModules = {
		successStories : "show",	
		communicationCampaigns: "show",
		influencersAmbassadors: "hide",
		infographics: "hide",
		leaflet: "show",
		mediaShow: "show",
		newsletter: "show",
		onlineDebates: "hide",
		pressReleases: "show",
		referenceEvents: "hide",
		webinar: "hide",
	};

	const [events, setEvents] = useState([]);

	const [videos, setVideos] = useState([]);
	const [videosCount, setVideosCount] = useState(1);

	const [newsletters, setNewsletters] = useState([]);
	
	const [communicationCampaigns, setCommunicationCampaigns] = useState();
	const [communicationCampaignsCount, setCommunicationCampaignsCount] = useState(1);

	const [successStories, setSuccessStories] = useState();
	const [successStoriesCount, setSuccessStoriesCount] = useState(1);
	
	const [pressReleases, setPressReleases] = useState([]);
	


	useEffect(() => { 
		lang.setLanguage(currentLanguage.code); 

	}, [currentLanguage]);



	useEffect(() => {
		document.title = lang.media4You + ' - ' + lang.myFundsApp

		helper.createMetaInfo(
			document.title,
			lang.metaDescription,
			window.location.protocol + '//' + window.location.host + '/' + lang.getLanguage() + '/media-4-you');
		

		//window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

		async function getData() {

			const { key: username, secret: password } = config.auth
			const { data: token } = await axios.post(config.apiUrl + '/api/auth/access_token', { username, password })

			if (token) {

				try {
					const response = await axios.get(config.apiUrl + '/api/media/events?limit=6', {
						params: {},
						headers: {
							'Content-Type': 'application/json',
							'Authorization': `Bearer ${token}`
						}
					})

					const { data } = response;
					setEvents([...data.data]);

				} catch (error) {
					console.log(error);
				}


				try {
					const response = await axios.get(config.apiUrl + '/api/media/successStories?limit=12', {
						params: {},
						headers: {
							'Content-Type': 'application/json',
							'Authorization': `Bearer ${token}`
						}
					})

					const { data } = response;

					setSuccessStories(data.data);

					//console.log(data.data);

					//setVideos(videos.sort((a, b) => {	return parseInt(b.code) - parseInt(a.code) }));

				} catch (error) {
					console.log(error);
				}



				try {
					const response = await axios.get(config.apiUrl + '/api/media/videos?limit=12', {
						params: {},
						headers: {
							'Content-Type': 'application/json',
							'Authorization': `Bearer ${token}`
						}
					})

					const { data } = response;
					const videos = data.data;

					setVideos(videos.sort((a, b) => {	return parseInt(b.code) - parseInt(a.code) }));

				} catch (error) {
					console.log(error);
				}

				try {
					const response = await axios.get(config.apiUrl + '/api/media/newsletters?limit=6', {
						params: {},
						headers: {
							'Content-Type': 'application/json',
							'Authorization': `Bearer ${token}`
						}
					})

					const { data } = response;

					setNewsletters([...data.data]);

				} catch (error) {
					console.log(error);
				}


				try {
					const response = await axios.get(config.apiUrl + '/api/media/pressreleases?limit=6&language=' + langCode, {
						params: {},
						headers: {
							'Content-Type': 'application/json',
							'Authorization': `Bearer ${token}`
						}
					})

					const { data } = response;
					setPressReleases(data.data);
				} catch (error) {
					console.error(error);
				}


				try {

					const response = await axios.get(config.apiUrl + '/api/media/communicationCampaigns?limit=20', {
						params: {},
						headers: {
							'Content-Type': 'application/json',
							'Authorization': `Bearer ${token}`
						}
					})

					const { data } = response;
					setCommunicationCampaigns([...data.data]);

				} catch (error) {

					console.log(error);
				}
			}
		}

		getData();

	}, []);



	return (

		<div>
			<Header {...props} />
			<Box sx={{...sxMarginTop}} id="media-for-you">

				<Box id='banner' >
					<ProductHeroLayoutPage>

						<Background sx={sxBackground} />
						
						{/* <LeftMenu {...props} /> */}

						<Typography align="center" variant="h3" component="h1" sx={{ color: 'primary.main' }}>
							{lang.media4You}
						</Typography>

					</ProductHeroLayoutPage>
				</Box>


				<Box sx={{ display: 'flex', justifyContent: 'center'}}>
					<Box maxWidth='lg' sx={{flexGrow: 1, mb: 0, py: 4 }}>

						<Grid sx={{ mx: "auto" }} container columnSpacing={{ xs: 0, sm: 2, md: 0 }} rowSpacing={0} >

							{/** FIRST column */}
							<Grid item xs={12} md={8}  >
								<Box sx={{ p:2 }}>

									{ (pageModules.successStories === "show") && successStories ?  (
										<Box id="successStories" maxWidth='sm' sx={{mx : 'auto',  mb: 5 }}>

											<Typography variant="h4" component="h2" sx={titleStyle}>
												{lang.successStories}
											</Typography>

											<Grid container columnSpacing={{ xs: 0, sm: 2, md: 2 }} rowSpacing={2}>
												{successStories.slice(0, successStoriesCount * 4 ).map((item, index) => (
													<Grid key={index} item xs={12} sm={6}>
														<Box sx={{ display: 'flex', justifyContent: 'center'}}>
															<SuccessStoriesBox elem={item} />
														</Box>
													</Grid>
												))}
											</Grid>

											<Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }} >
												{ ((successStoriesCount * 4 ) < (successStories.length ))  &&
													

													<IconButton  color="secondary"  title={lang.more} aria-label="more" onClick={() => setSuccessStories(successStoriesCount + 1)}>
														<Stack spacing={0} alignItems="center">
															<Typography variant="button" sx={{fontSize: '.9rem'}}>
																{lang.more}
															</Typography>

															<MoreHoriz />
														</Stack>
													</IconButton>
												}
											</Box>
											
										</Box>
										) : 
											
										<Skeleton sx={{ mb: 5 }} variant="rectangular" width={'100%'} height={400} />
										
									}

									{ (pageModules.communicationCampaigns === "show") && communicationCampaigns ?  (
										<Box id="communicationCampaigns" maxWidth='sm' sx={{mx : 'auto',  mb: 5 }}>

											<Typography variant="h4" component="h2" sx={titleStyle}>
												{lang.communicationCampaigns}
											</Typography>

											<Grid container columnSpacing={{ xs: 0, sm: 2, md: 2 }} rowSpacing={2}>
												{communicationCampaigns.slice(0, communicationCampaignsCount * 4 ).map((item, index) => (
													<Grid key={index} item xs={12} sm={6}>
														<Box sx={{ display: 'flex', justifyContent: 'center'}}>
															<ComunicationCampaignsBox elem={item} />
														</Box>
													</Grid>
												))}
											</Grid>

											<Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }} >
												{ ((communicationCampaignsCount * 4 ) < (communicationCampaigns.length ))  &&
													

													<IconButton  color="secondary"  title={lang.more} aria-label="more" onClick={() => setCommunicationCampaignsCount(communicationCampaignsCount + 1)}>
														<Stack spacing={0} alignItems="center">
															<Typography variant="button" sx={{fontSize: '.9rem'}}>
																{lang.more}
															</Typography>

															<MoreHoriz />
														</Stack>
													</IconButton>
												}
											</Box>
											
										</Box>
										) : 
											
										<Skeleton sx={{ mb: 5 }} variant="rectangular" width={'100%'} height={400} />
										
									}

									

									{pageModules.referenceEvents === "show" && (
										<Box id="referenceEvents" maxWidth='sm' sx={{mx : 'auto',  mb: 5 }}>

											<Typography variant="h4" component="h2" sx={titleStyle}>
												{lang.events}
											</Typography>


											<Stack spacing={2} sx={{ mt: 2 }}>

												{events.map((item, index) => (
													<Box key={index} sx={{ display: 'flex', justifyContent: 'center' }}>
														<EventCard item={item} />
													</Box>
												))}

											</Stack>


											

										</Box>
									)}


									{pageModules.webinar === "show" && (
										<Box id="webinar" sx={{ mb: 8 }}>
											<TwoBlock title={lang.webinar} image={config.hostCDN + '/black-student.png'} />
										</Box>
									)}

									{pageModules.onlineDebates === "show" && (
										<Box id="onlineDebates" sx={{ mb: 8 }}>
											<TwoBlock
												title={lang.onlineDebates}
												image={config.hostCDN + '/rear-view-of-attractive-caucasian-bearded-businessman-sitting-in-cafe-and-having-debate-with-colleague-over-internet.png'} />
										</Box>
									)}

								</Box>
							</Grid>


							{/** SECOND column */}
							<Grid item xs={12} md={4}  >

								<Box sx={{ py:2, px: { xs: 2}  }}>

									{ pageModules.mediaShow === "show" && (

										<Box id="mediaShow" maxWidth='sm' sx={{mx : 'auto',  mb: 5 }}>
											<Typography variant="h4" component="h2" sx={titleStyle}>
												{lang.mediaShow}
											</Typography>

											<Box sx={{ maxWidth: { md: '320px'} }} >

												<Grid container sx={{ mx: "auto" }} columnSpacing={{ xs: 0, sm: 2, md: 0 }} rowSpacing={2} >
												
													{videos.slice(0, videosCount * 4 ).map((item, index) => (
														<Grid key={index} item xs={12} sm={6} md={12}>
															<Box sx={{ display: 'flex', justifyContent: 'center', width: '100%'}}>
																<VideoPaper elem={item} />
															</Box>
														</Grid>
													))}
												</Grid>

												<Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }} >
													{ ((videosCount * 4 ) < (videos.length ))  &&
														
														<IconButton color="secondary"  title={lang.more} aria-label="more" onClick={() => setVideosCount(videosCount + 1)}>
															<Stack spacing={0} alignItems="center">
																<Typography variant="button" sx={{fontSize: '.9rem'}}>
																	{lang.more}
																</Typography>

																<MoreHoriz />
															</Stack>
														</IconButton>
													}
												</Box>

												

		
											</Box>

											{/* </Stack> */}
										</Box>
									)}

									<Divider sx={{ mb: 4 }} />

									{pageModules.leaflet === "show" && (
										<LeafletBlock {...props} />
									)}

									<Divider sx={{ mb: 4 }} />

									{pageModules.newsletter === "show" && (

										<Box id="newsletter" maxWidth='sm' sx={{mx: 'auto', mb: 8 }}>

											<Typography variant="h4" component="h2" sx={titleStyle}>
												{lang.newsletter}
											</Typography>

											<List sx={{ width: '100%', bgcolor: 'background.paper', mb: 3 }}>
												{newsletters.map((item, index) => (
													<NewslettersBlock key={index} item={item} />
												))}
											</List>

										</Box>
									)}

									{pageModules.pressReleases === "show" && (
										<Box id="pressReleases" maxWidth='sm' sx={{mx: 'auto', mb: 8 }}>

											<Typography variant="h4" component="h2" sx={titleStyle}>
												{lang.pressReleases}
											</Typography>


											<List sx={{ width: '100%', bgcolor: 'background.paper', mb: 3 }}>
												{pressReleases.map((item, index) => (
													<PressReleasesBlock key={index} item={item} />
												))}
											</List>

											{/* <Box sx={{ display: 'flex', justifyContent: 'flex-end' }} >
												<IconButton color="secondary" onClick={() => navigate('media-4-you/pressReleases')} >
													<MoreHorizIcon />
												</IconButton>
											</Box> */}
										</Box>
									)}


								</Box>
							</Grid>
						</Grid>
					</Box>
				</Box>

			</Box>

			<Footer {...props} />
		</div>
	)
}


export default withRoot(Media4You);