import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Send, SentimentDissatisfiedOutlined, SentimentSatisfiedAltOutlined } from '@mui/icons-material';

import config from "../../config";
import lang from '../lang';
import { useCookies } from "react-cookie";
import serialize from "form-serialize";


let mySet1 = new Set();
let quizes = [];

//localStorage.clear();
const secondPopup = 60 * 10;

function Quizes(props) {

    //console.log("Quizes", props);

    const navigate = useNavigate();

    const { currentLanguage } = props;

    const [cookies] = useCookies(['userId']);

    const [openModal, setOpenModal] = useState(false);
    const [dataModal, setDataModal] = useState({});

    const [disableSend, setDisableSend] = useState(false);
    
    //console.log("Quizes", currentLanguage);
    // let { lang: langCode = undefined } = useParams();
    // if (!langCode) {
    //     langCode = lang.getLanguage().toString();
    // }


    const myAction = () => {

        const min = 0;
        const max = quizes.length - 1;

        mySet1 = localStorage.getItem(cookies.userId) ? new Set( JSON.parse(localStorage.getItem(cookies.userId)) ) : new Set(); 


        if (mySet1.size >= quizes.length) {
            return;
        }


        //console.log("mySet1", mySet1);

        let index = Math.floor(Math.random() * (max - min + 1)) + min;
        let c = 0;

        while (mySet1.has(index) && c < quizes.length) {

            //console.log(index);
            index++;

            index = index % quizes.length;
            c++;
        }


        mySet1.add(index);

    
    
        const quiz = quizes[index];


        setDataModal({
            id : quiz._id,
            type: quiz.type,
            value:  quiz.type === 'Links' ? quiz.link :  null,
            message:  quiz.type === 'Links' ? quiz.text :  null,
            showSuccess: false,
            showError: false,
            showMessage: false,
            code : quiz.question.en, 
            question: quiz.question[currentLanguage.code],
            answers: [...quiz.answers.map(item => {
                const ret = {
                    ...item,
                    label: item[currentLanguage.code]
                }

                delete ret[currentLanguage.code];

                return ret;
            }).sort(() => .5 - Math.random())]
        });

        

        setOpenModal(true);
    }



    async function sendDataToServer(id, question, answer){


        const { key: username, secret: password } = config.auth
        let { data: token } = await axios.post(config.apiUrl + '/api/auth/access_token', { username, password })

        //const params = {answer}

        const {userId} = cookies;

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token.token}`
        }

        try {
            let { data } = await axios.put(config.apiUrl + '/api/quizes/' + id + '/log', {userId, question, answer}, {headers})
            //console.log(data);

            const iterator1 = mySet1.values();
            const a = [];
    
            for (const value of iterator1) {
                a.push(value);
            }
    
            localStorage.setItem(cookies.userId, JSON.stringify(a))

    
        } catch (error) {
            console.log(error);
        }

       
       
    };


    function clearStorage(){
        
        const items = {...localStorage};

        for (const key in items) {
            if (Object.hasOwnProperty.call(items, key)) {
                if ( key !== 'language' && key !== 'token' && key !== cookies.userId){
                    localStorage.removeItem(key);
                }
                
            }
        }
    }


    async function loadQuizes() {

        const { key: username, secret: password } = config.auth
        let { data: token } = await axios.post(config.apiUrl + '/api/auth/access_token', { username, password })
        
        const params = {}

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token.token}`
        }

        let { data: dataa } = await axios.get(config.apiUrl + '/api/quizes', {
            params,
            headers
        })


        const { data: qzs } = dataa;
        
        //quizes = [...qzs.filter( item => item.type === 'Newsletter') ];
        quizes = [...qzs];

        //console.log(quizes);
    }
  


    useEffect(() => {

        clearStorage();
        loadQuizes();
        //console.log("data",dataModal);
        
        const timer = setTimeout(myAction, secondPopup * 1000 );
        //const timer = setTimeout(myAction, 5 );
        return () => clearTimeout(timer);

    }, []);



  

    return (

        <Dialog
                open={openModal}
                onClose={(event, reason) => {
                    if (reason && reason === "backdropClick") {
                        return;
                    }
                    setOpenModal(false)
                }}

                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <Box style={{
                     width: "100%",
                     height: "10px",
                     display: "flex",
                     justifyContent: "flex-end",
                }}>
                 <Box component='img' 
                     sx={{
                        position: "fixed",
                        width: "0.9rem",
                        height: "0.9rem",
                        margin: "0.5rem",
                        cursor: "pointer",
                    }} 
                    onClick={ e => setOpenModal(false)}
                    src={config.hostCDN + '/close-x.png' }  /> 
                </Box>
                <DialogTitle id="alert-dialog-title">
                    {dataModal.question}
                </DialogTitle>
                
                <DialogContent>

                    {dataModal.showMessage && (
                        <DialogContentText color='error' sx={{ my: 2 }} id="alert-dialog-description" >
                            {lang.choosefollowing}
                        </DialogContentText>
                    )}


                    {dataModal.type === 'Links' && (

                        <Box sx={{display:'fles', justifyContent: 'center', w: '100%', my: 2  }}>
                            <Button color='secondary' size="large" variant="contained" 
                                
                                onClick={ () => {
                                  
                                    //history.push('/' + langCode + '/' + dataModal.value);
                                    //const newurl = '/' + langCode + '/' + dataModal.value;
                                    //window.history.pushState({ path: newurl }, "", newurl);

                                    const iterator1 = mySet1.values();
                                    const a = [];
                            
                                    for (const value of iterator1) {
                                        a.push(value);
                                    }
                            
                                    localStorage.setItem(cookies.userId, JSON.stringify(a))
    
                                    setOpenModal(false);

                                    //window.location.href = '/' + currentLanguage.code + '/' + dataModal.value
                                    navigate('/' + currentLanguage.code + '/' + dataModal.value);


                                }}
                                


                                //component={ReactRouterLink} 
                                //to={'/' + langCode + '/' + dataModal.value } 

                             >
                                   
                                <Box sx={{display:'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <Box component='span'> {lang[dataModal.message]}</Box> <Send sx={{m: 0, ml: 2 }} />
                                </Box>

                            </Button>

                        </Box>
                     
                    )}                    

                    
                    {dataModal.answers && (
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            
                            name="radio-buttons-group"

                            value={dataModal.value}
                            onChange={(event) => {
                            
                                setDataModal({
                                    ...dataModal,
                                    value: event.target.value,
                                    showMessage: false,
                                    showSuccess: false,
                                    showError: false
                                })
                            }}

                        >

                            { dataModal.answers.map( (item, index) => (
                                // item.label
                                
                                  <FormControlLabel  key={index} value={item.code} control={<Radio color="info" />} label={item.label} />
                            ))}

                        </RadioGroup>
                    )}


                    {dataModal.type === 'Newsletter' && (

                        <Box sx={{display:'fles', flexDirection: 'column', justifyContent: 'center', w: '100%', my: 2  }}>

                            <form onSubmit={ async e => {

                                e.preventDefault();

                                setDisableSend(true);

                                const data = serialize(e.target, { hash: true });	
                                const url = config.apiUrl + '/api/mailchimp';

                                try {

                                    const { key: username, secret: password } = config.auth
                                    let { data: token } = await axios.post(config.apiUrl + '/api/auth/access_token', { username, password })


                                    const headers = {
                                        'Content-Type': 'application/json',
                                        'Authorization': `Bearer ${token.token}`
                                    }

                                    const response = await axios.post( url, {...data}, headers );        
                                   
                                    const iterator1 = mySet1.values();
                                    const a = [];
                            
                                    for (const value of iterator1) {
                                        a.push(value);
                                    }
                            
                                    localStorage.setItem(cookies.userId, JSON.stringify(a))
    
                                    setOpenModal(false);
    

                                    const timer = setTimeout(myAction, secondPopup * 1000);
                                    return () => clearTimeout(timer);

                                    
                                } catch (error) {
                                    console.log(error);
                                }
                            
                                setDisableSend(false);

                            }}> 

                                <TextField
                                    margin="dense"
                                    required
                                    label={lang.yourEmail}
                                    name="email"
                                    type="email"
                                    fullWidth
                                    variant="standard"
                                    placeholder={lang.yourEmail + ' *'}
                                    sx={{mb:3}}
                                />

                                <Button disabled={disableSend} color='secondary' size="large" variant="contained" fullWidth autoFocus type="submit">
                                    <Box sx={{display:'flex', justifyContent: 'center', alignItems: 'center'}}>
                                        <Box component='span'> {lang.subscribe}</Box>  <Send sx={{m: 0, ml: 2 }} />
                                    </Box>
                                </Button>

                            </form>
                        </Box>

                    )}    


                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                    
                        {dataModal.showError && (
                            <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', my: 2 }}>
                                <Box sx={{ display: 'flex', justifyContent: 'center', w: '100%', mb: 1 }}>
                                    <SentimentDissatisfiedOutlined color='error' sx={{ fontSize: 60 }} />
                                </Box>

                                {dataModal.correctAnswer && (
                                    <Box sx={{mb:2}}>
                                        <Typography sx={{mb:1, fontWeight: 'bold', fontSize: '0.9rem' }}>{lang.correctAnswer}</Typography>    
                                        <Typography>{dataModal.correctAnswer.label}</Typography>
                                    </Box>
                                )}
                                

                                <Typography sx={{w:'100%', textAlign:'center',  fontWeight: 'bold'}} >{lang.learnAboutCohesionPolicy}</Typography>
                            </Box>
                        )}

                        {dataModal.showSuccess && <SentimentSatisfiedAltOutlined color='success' sx={{ my: 1, fontSize: 80 }} />}

                    </Box>

                </DialogContent>

                <DialogActions>

       

                    { (dataModal.type !== 'Newsletter' && dataModal.type !== 'Links') && 
                    
                        <Button color='secondary' size="large" variant="contained" 

                        onClick={() => {

                            if (dataModal.type === 'Multiple Choice') {

                                if (dataModal.value) {
                                    if (!dataModal.showSuccess && !dataModal.showError) {

                                        const correctAnswer = dataModal.answers.find(item => item.correct)

                                        // console.log(correctAnswer.code === dataModal.value);
                                        // console.log(correctAnswer);

                                        setDataModal({
                                            ...dataModal,
                                            showMessage: false,
                                            showSuccess: correctAnswer.code === dataModal.value,
                                            showError: correctAnswer.code !== dataModal.value,
                                            correctAnswer
                                        })

                                        sendDataToServer(dataModal.id, dataModal.code, dataModal.value);
                                    }
                                    else {

                                        setOpenModal(false);

                                        const timer = setTimeout(myAction, secondPopup * 1000);
                                        return () => clearTimeout(timer);
                                    }
                                }
                                else {
                                    setDataModal({
                                        ...dataModal,
                                        showMessage: true,
                                        showSuccess: false,
                                        showError: false
                                    })
                                }
                            }

                            else if (dataModal.type === 'Help us to improve'){
                                if (dataModal.value) {

                                    sendDataToServer(dataModal.id, dataModal.code, dataModal.value);
                                    
                                    setOpenModal(false);

                                    const timer = setTimeout(myAction, secondPopup * 1000);
                                    return () => clearTimeout(timer);
                                }
                                else {
                                    setDataModal({
                                        ...dataModal,
                                        showMessage: true,
                                        showSuccess: false,
                                        showError: false
                                    })
                                }

                            }

                            else if (dataModal.type === 'Links'){


                                const iterator1 = mySet1.values();
                                const a = [];
                        
                                for (const value of iterator1) {
                                    a.push(value);
                                }
                        
                                localStorage.setItem(cookies.userId, JSON.stringify(a))

                                setOpenModal(false);

                                const timer = setTimeout(myAction, secondPopup * 1000);
                                return () => clearTimeout(timer);
                            }
                        }}

                        autoFocus

                        sx={{ width: '90%', mx: 'auto', mb: 2 }}

                        // sx={{
                        //     width:"80%",
                        //     backgroundColor: "#1b2766",
                        //     borderRradius:"5px",
                        //     color: "#fff",
                        //     margin:"auto",
                        //     marginTop: "-1rem",
                        //     marginBottom: "1rem"
                        // }
                        // }    
                    >

                        {lang.confirm}

                    </Button>}

                </DialogActions>
                

            </Dialog>
    )
}




export default Quizes;