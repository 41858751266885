import React, { Fragment} from 'react';

import { Box, Button, Card,  CardContent, CardActions, Grid, Typography, Modal, Fade } from '@mui/material';

import { styled } from '@mui/material/styles';

import lang from '../lang';
import config from '../../config';



function BlockCard(props){

    const {
        type='dark',
        last=false,  
        title='Title',
        maxWidth='auto',
        descriptions='descriptions',
        long=undefined,
        sx={}
    } = props;


    const Block = styled(Box)(({ theme }) => ({
        ...theme.typography.body2,
        padding: theme.spacing(1),
        maxHeight: { md: '12rem' },
        textAlign: 'left',
        color: theme.palette.text.secondary,
    }));
    


    const BlockContainer = styled(Block)(({ theme }) => ({
        backgroundColor: last ? '#283B98' : ( type === 'dark' ? theme.palette.primary.light : theme.palette.primary.royalblue)
    }));


    const minHeight = { md : !last ? '11.6rem' : 'auto'} ;

    function TransitionsModal(props) {

        const style = {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            maxHeight: '80%',
            overflow: 'auto',
            bgcolor: 'background.paper',
            borderRadius: 5,
            boxShadow: 24,
            p: 3,
        };

        const [open, setOpen] = React.useState(false);
        const handleOpen = () => setOpen(true);
        const handleClose = () => setOpen(false);
      
        return (

            <Box sx={{ ...props.sx }}>

                <Button sx={{py:2}} color='success' variant='contained' size="medium" onClick={handleOpen}>{lang.more}</Button>

                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                    // BackdropComponent={Backdrop}
                    // BackdropProps={{
                    //     timeout: 500,
                    // }}
                >
                    <Fade in={open}>
                        <Box sx={style}>
                            <Typography variant="h6" component="h2" sx={{fontSize: '1.5rem'}} >
                                {title}
                            </Typography>

                            <Typography sx={{ mt: 2 }}>
                                <Box component='div' dangerouslySetInnerHTML={{ __html: long }}  ></Box>
                            </Typography>
                        </Box>
                    </Fade>
                </Modal>
            </Box>
        );
    }


    return (

        <BlockContainer sx={{ ...sx, display: 'flex', justifyContent:'center', alignItems: 'center', p:3}} >
            <Card sx={{ maxWidth }}>
         
                <CardContent>
                    <Typography gutterBottom variant="h5" component="h3" sx={{fontSize:'1.2rem', fontWeight:'bold', minHeight: '3.5rem'  }}>
                        {title}
                    </Typography>
                    <Typography sx={{ minHeight }} variant="body2" color="text.secondary" dangerouslySetInnerHTML={{ __html: descriptions }}></Typography>
                </CardContent>
                <CardActions>
                    <Box sx={{ width:'100%', display:'flex', justifyContent:'center', p:1 }}>
                        <TransitionsModal sx={{mr: 1}}/>
                    </Box>
                </CardActions>
            </Card>
        </BlockContainer>
    )
}



function News(props) {

    return (
        
        <Box sx={{ 
                backgroundImage : `url(${ config.hostCDN + "/bg-news.png"})` ,
                backgroundPosition : 'left top',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover'
            }}>

            <Box margin="auto" maxWidth="md" sx={{ flexGrow: 1, bgcolor: 'primary.light'}}>
                <Grid container spacing={0}>
                    
                    {lang.funds.map( (item, index) => (
                        <Grid key={index} item xs={12} md={index >= 4 ? 12 : 6} >
                            <BlockCard type={[0,3,4].includes(index) ? 'dark' : 'light' } last={index === 4} title={item.name} descriptions={item.short} long={item.long} />
                        </Grid>    
                    ))}
    
                </Grid>
            </Box>
        </Box>
    )
}

export default News;

