import React, { Fragment } from 'react';
import { Box, Button, Container, Grid, Link, Stack, Typography } from '@mui/material';
import lang from '../lang';
import config from '../../config';


function BlueBlock(props) {

    const { key = '', title = 'Title', body, sx = {} } = props;

    return (
        <Box key={key} sx={{
          
            border: 2,
            borderColor: 'primary.light',
            backgroundColor: 'primary.light',
            borderRadius: 5,
            p: 2,
            textAlign: 'center',
            ...sx
        }}>

            <Typography variant="h5" component="h4" sx={{ p: 0, fontWeight: 'bold', mb: 2, color: 'primary.main' }}>
                {title}
            </Typography>

            <Typography variant="body" component="div" sx={{ p: 0, fontSize: '.8rem', color: 'primary.main' }}>
                {body}
            </Typography>

        </Box>
    )
}


function ConsortiumBox(props) {

    const {children, sx = null } = props;

    return (
        <Box sx={{
            border: 2,
            borderColor: 'primary.light',
            backgroundColor: 'primary.light',
            borderRadius: 5,
            p: 2,
            textAlign: 'center',
            ...sx
        }}>
            {children}
        </Box>
    )
}






function Consortium(props) {

    const {titleStyle} = props;

    return (

        <Container maxWidth='lg' sx={{pt: 3, mb: 5}}>
            <Box >

                <Typography variant="h4" component="h2"  sx={{...titleStyle, textAlign: { xs: 'left', sm : 'center'} }}>
                    {lang.consortium}
                </Typography>


                <Box sx={{display : { xs: 'block', md: 'none'  }}}  >

                    <Stack spacing={2} >
                        {lang.consortiumList.map((item, index) => (

                            <Fragment key={index}> 
                                <Button sx={{width: '100%'}} key={index} component={Link} href={item.url} target="_blank" >
                                    <Box component='img' sx={{mx: 'auto', width: { xs: '100%', sm : '50%' } }} src={item.image} alt={item.name} />
                                </Button>
                                
                                <BlueBlock title={item.name} body={item.description} />
                            </Fragment>
                        ))}
                    
                        <ConsortiumBox><Box component='img' sx={{ width: { xs: '100%', sm : '50%' }}} src={config.hostCDN + '/img-consortium-rev2.png'} alt='Reference' /></ConsortiumBox>
                        
                    </Stack>
                </Box>

                
                
                <Box  sx={{display : { xs: 'none', md: 'block'  }}}  >

                    <Box sx ={{mb : 5}}>
                        <Stack spacing={5} direction="row" >
                            {lang.consortiumList.map((item, index) => (
                                <Button sx={{width: '100%'}} key={index} component={Link} href={item.url} target="_blank" >
                                    <Box component='img' style={{mx: 'auto', width: '100%' }} src={item.image} alt={item.name} />
                                </Button>
                            ))}
                        </Stack>
                    </Box>


                    <Grid container maxWidth='lg' spacing={2} sx={{mx: {xl : 'auto' }}}  >
                        <Grid item md={4} >
                            <ConsortiumBox sx={{width: '100%', height: '100%', borderRadius: '0px 0px 0px 200px',
                                }}>
                                <Box sx={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Box component='img' sx={{ width: '100%'}} src={config.hostCDN + '/img-consortium-rev2.png'} alt='Reference' />
                                </Box>
                            </ConsortiumBox>        
                        </Grid>

                        <Grid item md={8} >
                            <Grid container spacing={2} >
                                {lang.consortiumList.map((item, index) => (

                                <Grid item md={6} key={index}>  
                                    <BlueBlock sx={{p: 1, minHeight:"200px"}} title={item.name} body={item.description} />
                                </Grid>
                                ))}

                            </Grid>
                        </Grid>   
                    </Grid>      
                </Box>                        
            </Box>
        </Container>
    )
}


export default Consortium;

