import React, { useEffect, useState } from 'react';

import { Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';

import Header from '../modules/header';
import Footer from '../modules/footer';

import withRoot from '../modules/withRoot';

import helper from '../helper';
import lang from '../lang';
import { useCookies } from 'react-cookie';
import CookieSetPanel from '../modules/cookieSetPanel';


const { sxMarginTop } = helper;


function Cookies(props) {

    
    const { currentLanguage } = props;
    lang.setLanguage(currentLanguage.code);


    const [cookies] = useCookies([]);

    const [category, setCategory] = useState(lang.noChoice);
    const [openCookieSetPanel, setOpenCookieSetPanel] = useState(false);



    useEffect(() => {

        document.title = lang.cookiesPolicy + ' - ' + lang.myFundsApp

        helper.createMetaInfo(
            lang.cookiesPolicy,
            lang.metaDescription,
            window.location.protocol + '//' + window.location.host + '/' + lang.getLanguage() + '/cookies')

        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

        if (cookies.ccCookie) {
            setCategory(cookies.ccCookie.categories.join(', '));
        }

        //console.log(cookies);

    }, []);



    return (

        <div>
            <Header {...props} />

            {openCookieSetPanel && <CookieSetPanel onClose={categories => {

                //console.log(categories);
                setCategory(categories.join(', '));
                setOpenCookieSetPanel(false);

            }} />}

            <Box sx={{ m: 'auto', p: 3, py: 5, maxWidth: 'lg', ...sxMarginTop }} >

                <Typography variant="h2" component="h1" sx={{ fontWeight: 'normal', mb: 3, color: 'primary.black' }}>
                    {lang.cookiesPolicy}
                </Typography>

                <Box sx={{ color: 'primary.light' }}>


                    <Box id="cookieTexts" sx={{fontWeight: 'normal'}}  component='div' dangerouslySetInnerHTML={{ __html: lang.cookieTexts.mainText }} mb={3} />


                    <Box component='div' mb={3}>
                        <Box component='div' mb={3}>
                            <Box component={'span'} sx={{ fontWeight: 'bold' }}>{lang.cookieTexts.youCurrentStatus}</Box> {category}
                        </Box>

                        <Button color='secondary' onClick={e => setOpenCookieSetPanel(true)} variant="contained">{lang.cookieTexts.buttonText}</Button>
                    </Box>


                    <Box sx={{ border: '1px solid #666', p: 3, color: 'primary.black' }}>
                        <Typography variant='body1' sx={{ fontWeight: 'bold', color: 'primary.black' }}>
                            {lang.cookieTexts.necessary}
                        </Typography>
                        <Typography mb={2} variant='body2' sx={{ color: 'primary.black' }}>
                            {lang.cookieTexts.necessaryCookies}
                        </Typography>

                        <TableContainer component={Paper}>

                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        {lang.cookieTexts.tableColHead.map((e, i) => <TableCell key={i}>{e}</TableCell>)}
                                    </TableRow>
                                </TableHead>
                                <TableBody>

                                    {lang.cookieTexts.tableBody.map((e, i) => (
                                        <TableRow key={i}>
                                            {e.map((item, ie) => <TableCell key={ie} >{item}</TableCell>)}
                                            
                                        </TableRow>
                                    ))}




                                    {/* <TableRow>
                                        <TableCell>userId</TableCell>
                                        <TableCell>{window.location.host}</TableCell>
                                        <TableCell>Uniquely identifies a user</TableCell>
                                        <TableCell>One year</TableCell>
                                        <TableCell>HTTP Cookie</TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell>ccCookie</TableCell>
                                        <TableCell>{window.location.host}</TableCell>
                                        <TableCell>Stores the user's cookie consent status for the current domain</TableCell>
                                        <TableCell>Six months</TableCell>
                                        <TableCell>HTTP Cookie</TableCell>
                                    </TableRow> */}

                                 
                                </TableBody>
                            </Table>
                        </TableContainer>

                    </Box>

                </Box>



            </Box>

            <Footer {...props} />
        </div>
    )
}


export default withRoot(Cookies);