import lang from '../lang';

import { Send } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, InputBase, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from 'react';
import serialize from 'form-serialize';
import config from '../../config';
import axios from 'axios';
import { useParams } from 'react-router-dom';




function Newsletters(props){


    //const { url } = useRouteMatch();
    let { lang: langCode = undefined } = useParams();

    if (!langCode) {
        langCode = lang.getLanguage().toString();
    }


    const [openModal, setOpenModal] = useState(false);
    const [disableSend, setDisableSend] = useState(false);



    const DialogAlert = () =>{
    
        return (
    
            <Dialog
                open={openModal}
                onClose={() => setOpenModal(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {lang.subscribe}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {lang.subscribeDescribe}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color='secondary' onClick={() => setOpenModal(false)} autoFocus>
                        {lang.ok}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }


    const onSubmitAction = async e => {
		
		e.preventDefault();

        setDisableSend(true);

        const data = serialize(e.target, { hash: true });	
        const url = config.apiUrl + '/api/mailchimp';

        try {

            const { key: username, secret: password } = config.auth
            let { data: token } = await axios.post(config.apiUrl + '/api/auth/access_token', { username, password })


            const headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token.token}`
            }

            const response = await axios.post( url, {...data, lang: langCode}, headers )        
           
            
            e.target.reset();
            setOpenModal(true);
            
        } catch (error) {
            console.log(error);
        }
    
        setDisableSend(false);
    }


    return (

        <>
            <DialogAlert />
            <a id="newslettersRef"></a>
            <Typography gutterBottom variant="h4" component="div" sx={{mb: 1, fontSize:'1.6rem', color: 'primary.light', textTransform:'none', fontWeight:'normal' }} >
                {lang.subscribe}
            </Typography>

            <Typography 
                dangerouslySetInnerHTML={{ __html: lang.subscribeText }}
                gutterBottom variant="body" component="div" sx={{mb: 3, fontSize:'.8rem', color: 'primary.light', textTransform:'none', fontWeight:'normal' }} >
               
            </Typography>

        
    
            <Paper variant="outlined" component="form" onSubmit={onSubmitAction} sx={{color: 'primary.light', mb: 1}}>

                <Box component="div" sx={{p: '2px 4px', display: 'flex', alignItems: 'center'}}>
                    
                    <InputBase sx={{ ml: 1, flex: 1, p: '8px'}}
                        name="email"
                        placeholder={lang.yourEmail + '*'}
                        required
                        inputProps={{ 'aria-label' : lang.yourEmail }}
                        
                    />                          

                    <IconButton disabled={disableSend} type="submit" sx={{p:1, color: 'primary.light'}} aria-label="Send">
                        <Send />
                    </IconButton>
                </Box>
            </Paper>

            <Typography sx={{color: 'primary.light', fontSize:'.7rem', fontWeight:'normal', textTransform:'none', mr: 1}} >
                {lang.requiredFields}
            </Typography>
        
        </>
    )
}



export default Newsletters;

