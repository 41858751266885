import React, { useState, useEffect } from 'react';

import {
	Route,
	Routes,
	//Switch
} from "react-router-dom";


import {Snackbar, Alert} from '@mui/material';

import config from './config';

import './App.css';

import Front from './front/front';

import Login  from './back/login';
import Back from './back/back';

const axios = require('axios').default;

function App() {

	const [loggedIn, setLoggedIn] = useState(false);
	const [token, setToken] = useState(null);
	const [openSnackbar, setOpenSnackbar] = useState(false);	
	
	
	useEffect(() => {


		const token = sessionStorage.getItem('token');

		if (token !== null){
			setToken(token);
			setLoggedIn(true);
		}
		else{
			setToken(null);
			setLoggedIn(false);
		}
		
	}, []);


	function login(){
	
		return (
			<div>
				<Snackbar
					anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
					autoHideDuration={4000}
					open={openSnackbar}
					onClose={e => setOpenSnackbar(false) }	>
						
					<Alert  severity="error" sx={{ mt:12, width: '100%' }}>Incorrect login</Alert>
				</Snackbar>
				
				<Login onSubmitAction={ data => {

					localStorage.clear();

					axios.post(config.apiUrl + '/api/auth/login', data ).then(function (response) {

						const {token} = response.data  
						
						sessionStorage.setItem('token', token );
						sessionStorage.setItem('status', true );

						setToken( token);
						setLoggedIn(true);


						const newurl = window.location.protocol + "//" + window.location.host + '/back/';
	
						window.history.pushState({ path: newurl }, "", newurl);

						//searchAction (search);
			
					}).catch(function (error) {
						console.log(error);
						setOpenSnackbar(true) 

					}); 

				}} />

		</div>
		);
	}

	
	return (
		<Routes>
			<Route path="/back/*" element={loggedIn ? <Back token={token} setToken={setToken} setLoggedIn={setLoggedIn} /> : login()} />
			<Route path="*" element={<Front />} />
		</Routes>
	);
}


export default App;
