import { Button, createTheme, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { makeStyles } from "@mui/styles"

import lang from '../lang';
import { v4 as uuidv4 } from 'uuid';
import CookieSetPanel from "./cookieSetPanel";


//const customiseButtonColor = grey[500];

const useStyles = makeStyles({

    newPosOfDialog: {
        position: "absolute",
        top: "49%",
        left: "49%",
        transform: "translate(-50%, -50%)",
    }
});

  
function CookiePanel(props) {


    const classes = useStyles();

    const [cookies, setCookie] = useCookies([]); 

    const [open, setOpen ] = useState(false) ;
    const [openCustomise, setOpenCustomise ] = useState(false) ;


    function handleClose( e, r) {

        if (r && r === 'backdropClick'){
            return;
        }

        setOpen(false);
    }


    function onAcceptButton() {
        
        const date = (new Date()).getDate();

        const ccCookie = {
            "categories": [
                "necessary",
                "analytics",
                "targeting"
            ],
            
            "revision":0,
            "data":null,
            "rfc_cookie":false,
            "consent_date": date,
            "consent_uuid": uuidv4(),
            "last_consent_update": date
        };

       
        const expires = new Date();
        expires.setDate(new Date().getDate() + 185);

        setCookie('ccCookie', ccCookie, { 
            path: '/',
            expires
        });

        setOpen(false);
    }


    function onCustomiseButton(){
        setOpen(false);
        setOpenCustomise(true);
    }


    useEffect(() => {

        if (!cookies.ccCookie){
            setOpen(true);
        }
        else{

            //const c = {...cookies};
            //console.log(c);
        }

    }, []);



    return (

        <>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"

                classes={{
                    paper: classes.newPosOfDialog
                }}

                maxWidth={'xs'}

            >
                <DialogTitle color="secondary" id="alert-dialog-title">
                    {lang.cookiePanel.title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {lang.cookiePanel.content}
                    
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="success" variant="contained" onClick={onAcceptButton} autoFocus>{lang.cookiePanel.acceptButton}</Button>
                    <Button color="grey" variant="outlined" onClick={onCustomiseButton}>{lang.cookiePanel.customiseButton}</Button>
                </DialogActions>
            </Dialog>

            {!open && openCustomise && <CookieSetPanel/>}
        </>
    )

}



export default CookiePanel;
