import React, {useEffect} from 'react';


import { Link as ReactRouterLink, useParams, matchRoutes, useLocation } from "react-router-dom";

import { Box, Typography, Button, Grid, Link } from '@mui/material';


import LeftMenu from '../modules/leftmenu';

import Header from '../modules/header';
import Footer from '../modules/footer';
import withRoot from '../modules/withRoot';


import lang from '../lang';

import helper from '../helper';
//import Leaflet from '../modules/media/newsletter';
import Newsletter from '../modules/media/newsletter';
import config from '../../config';
//const axios = require('axios').default;

const {ProductHeroLayoutPage, Background, sxMarginTop} = helper;

const sxBackground= {
	backgroundImage: `url('${config.hostCDN}/media-4-you.png')`,
	backgroundColor: 'tranparent', // Average color of the background image.
	backgroundPosition: 'center',
}


function Media4YouContent(props) {

	const routes = [{ path: "/:lang/media-4-you/*" }]
    const location = useLocation()
    const url = matchRoutes(routes, location)[0].pathname;
    
    const { lang: langCode = undefined , view } = useParams();


	console.log(url);
	console.log(langCode, view);
	console.log(lang[view]);


    useEffect(() => {
        document.title = 'Reference - ' + lang.media4You + ' - ' + lang[view]; 
		window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

		

    }, []);



    return (

        <div>
            <Header {...props} />
			<Box sx={sxMarginTop} id="media-for-you"> 

                <Box id='banner' >
                    <ProductHeroLayoutPage>

                        <Background sx={sxBackground} />
                        <LeftMenu {...props} />
						<Typography align="center" variant="h3" component="h1" sx={{ color: 'primary.main' }}>
							{lang.media4You}
						</Typography>
						
                    </ProductHeroLayoutPage>
                </Box>

				<Box sx={{w: '100%', display: 'flex', justifyContent: 'center', p: 2, pt: 8 }}>
					
					<Box sx={{width: '100%', mb: 5 }} >
						<Typography align="center" variant="h5" component="h5" sx={{mb: 1, textTransform:'uppercase', fontSize: '.8rem', fontStyle: 'italic', color: 'primary.light' }}>
							{lang.media4You}
						</Typography>

						<Typography align="center" variant="h3" component="h2" sx={{mb:3, color: 'primary.light' }}>
							{lang[view]}
						</Typography>

						{('leaflet' === view) && (

							<Box sx={{  p: 5 }}>
                
								<Box sx={{ width: '100%', height: 900, mb: 4 }}>
									<object data={config.hostCDN + '/leaflets' + lang.leafletdownload} type="application/pdf" width="100%" height="100%">
									</object>
								</Box>

								<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
									
									<Button size="large" color="secondary" variant="contained" component={ ReactRouterLink } 
										to={{pathname : '/' + langCode + '/media-4-you'}}> {lang.media4You}</Button>

									<Button onClick={() => {
										const link = document.createElement("a");
										link.download = config.hostCDN + '/leaflets' + lang.leafletdownload;
										link.href = config.hostCDN + '/leaflets' + lang.leafletdownload;
										link.click();
									}}
					
										size="large"
										color="secondary"
										variant="contained">{lang.downloadFile}
					
									</Button>

								</Box>
							</Box>
						)}

						{('newsletter' === view) && (
							<div>

								<Newsletter />

								<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
										
									<Button size="large" color="secondary" variant="contained" component={ ReactRouterLink } 
										to={{pathname : '/' + langCode + '/media-4-you'}}> {lang.media4You}</Button>


								</Box>
							</div>	
						)}
						
					</Box>
				</Box>


                {/* <Box id="informative-video">
					<InformativeVideos {...props} />
				</Box>		 */}


				{/* <Box id="communication-campaigns">
					<CommunicationCampaigns {...props} />
				</Box>		

				<Box id="webinar">
					<ImageDouble
						type='left' 
						topTitle={lang.media4You}
						title={lang.webinar} 
						imageUrl='/black-student-having-online-webinar-greeting-remot-2021-11-11-18-23-42-utc.png'
						name=''
						description=''
						 />
				</Box>		 */}

				{/* <Box id="onlineDebates">
					<ImageDouble
						type='right' 
						topTitle={lang.media4You}
						title={lang.onlineDebates} 
						imageUrl='/rear-view-of-attractive-caucasian-bearded-businessman-sitting-in-cafe-and-having-debate-with-colleague-over-internet.png'
						name=''
						description=''
						/>
				</Box>		 */}

				{/* <Box id="reference-events" >
					
					<FourBlock 	
						type='simple'
						topTitle={lang.media4You}
						title={lang.referenceEvents}  
						downTitle=''
					/>
				</Box> */}

				{/* <Box id="infographics">
					<ImageDouble
						type='left' 
						topTitle={lang.media4You}
						title={lang.infographics} 
						imageUrl='/infografics.png'
						name=''
						description=''
						/>
				</Box>	 */}


				{/* <Box id="leaflet">
					<Box sx={{backgroundColor : '#eee',   p: 5 }}>
						<Box sx={{ mb: 5 }} >
							<Typography align="center" variant="h5" component="h5" sx={{ mb: 1, textTransform: 'uppercase', fontSize: '.8rem', fontStyle: 'italic', color: 'primary.light' }}>
								{lang.media4You}
							</Typography>

							<Typography align="center" variant="h3" component="h2" sx={{ mb: 3, color: 'primary.light' }}>
								{lang.leaflet}
							</Typography>
							<Box >
								<img style={{ width: '100%' }} src={lang.leafletContent} alt='Reference' />
							</Box>
						</Box>
						<Button onClick={() => {
							const link = document.createElement("a");
							link.download = lang.leafletdownload;
							link.href = lang.leafletdownload;
							link.click();
						}}

											size="large"
											color="secondary"
											variant="contained">{lang.downloadFile}

						</Button>
					</Box>
				</Box>	 */}

				{/* <Box id="press-releases">
					<ImageDouble
						type='left' 
						topTitle={lang.media4You}
						title={lang.pressReleases} 
						imageUrl='/worker-reading-news-with-tablet.png'
						name=''
						description=''
						/>
				</Box>		 */}

				{/* <Box id="newsletter" >
					
					<Box sx={{backgroundColor : '#eee',   p: 5 }}>
					

						<Box sx={{ mb: 5 }} >
							<Typography align="center" variant="h5" component="h5" sx={{ mb: 1, textTransform: 'uppercase', fontSize: '.8rem', fontStyle: 'italic', color: 'primary.light' }}>
								{lang.media4You}
							</Typography>

							<Typography align="center" variant="h3" component="h2" sx={{ mb: 3, color: 'primary.light' }}>
								{lang.newsletter}
							</Typography>

						</Box>

					
						<Box sx={{ mb: 5 }} >
							<Typography align="center" variant="h4" component="h4" sx={{mb:3, textTransform:'none', fontSize: '1.5rem',  color: 'primary.light' }}>
								{lang.newsletterContent[0].name}
							</Typography>

						
							<Typography  variant="body" component="div" dangerouslySetInnerHTML={{ __html: lang.newsletterContent[0].description }} sx={{ mb: 1, fontSize:'1rem',  color: 'primary.light' }}></Typography>


						</Box>

						<Box sx={{ mb: 5 }} >
							<Typography align="center" variant="h4" component="h4" sx={{mb:3, textTransform:'none', fontSize: '1.5rem',  color: 'primary.light' }}>
								{lang.newsletterContent[1].name}
							</Typography>

						
							<Typography  variant="body" component="div" dangerouslySetInnerHTML={{ __html: lang.newsletterContent[1].description }} sx={{ mb: 1, fontSize:'1rem',  color: 'primary.light' }}></Typography>


						</Box>
						

						
						<ImageDouble
							type='left' 
							imageUrl= {lang.newsletterContent[2].url} 
							name=	{lang.newsletterContent[2].name}
							description= {lang.newsletterContent[2].description}
							/>

						<ImageDouble
							type='right' 
							
							imageUrl= {lang.newsletterContent[3].url} 
							name=	{lang.newsletterContent[3].name}
							description= {lang.newsletterContent[3].description}
							/>

					</Box>
				</Box> */}


				{/* <Box id="influencers-ambassadors" >
					
					<SixBlock
						topTitle={lang.media4You}
						title={lang.influencersAmbassadors}  
					/>
					
				</Box> */}

				{/* <Box id="media-show" >
					
					<FourBlock 	
						type='title'
						topTitle={lang.media4You}
						title={lang.mediaShow}  
					/>
				</Box> */}
				
            </Box>

            <Footer {...props} />
        </div>
    )
}


export default withRoot(Media4YouContent);