import React from 'react';


import { Box, Container, Grid, Typography } from '@mui/material';
//import { styled } from '@mui/material/styles';


import Filter1Icon from '@mui/icons-material/Filter1Rounded';
import Filter2Icon from '@mui/icons-material/Filter2Rounded';
import Filter3Icon from '@mui/icons-material/Filter3Rounded';
import Filter4Icon from '@mui/icons-material/Filter4Rounded';

import lang from '../lang';

//const titleStyle = { color: 'primary.light', mb: 0, fontWeight: 300, fontSize: '1.4rem', textAlign: {sm: 'center'}  };

function TheProject(props) {



    function BlockCard(props){

        const {title='Title', index} = props;

        return (
        
            <Box sx={{
                    width: 1,
                    display: 'flex',  
                    justifyContent: 'center', 
                    backgroundColor: 'primary.light',
                    boxShadow: '15px 15px 0px 0px rgba(196,197,212,1)',
                    minHeight: { xs: '280px',  md: '250px',  lg: '300px'},
                }}
            >

                <Box sx={{p:2}} >

                    <Box sx={{mb: 1}}>
                        <Typography sx={{fontWeight:'bold', color: 'primary.main'}} gutterBottom variant="h5" component="div">

                            {index === 0 && <Filter1Icon style={{ fontSize: 100 }} />}   
                            {index === 1 && <Filter2Icon style={{ fontSize: 100 }} />}   
                            {index === 2 && <Filter3Icon style={{ fontSize: 100 }} />}   
                            {index === 3 && <Filter4Icon style={{ fontSize: 100 }} />}   

                        </Typography>
                    </Box>

                    <Typography sx={{fontSize: '.85rem'}} variant="body2" color="primary.main">
                        {title}
                    </Typography>                
                </Box>
            </Box>
        )
    }


    // useEffect(() => {},[

    // ]);

    return (
        <Container maxWidth="lg">
           
            <Grid container sx={{width: 1, mx:"auto", mb:8}} columnSpacing={{ xs: 0, sm: 3 }} rowSpacing={5} >

                {lang.aboutList.map( (item, index) => (
                    <Grid item key={index} xs={12} sm={6} lg={3} >
                        <BlockCard title={item} index={index} />
                    </Grid>
                ))}
            </Grid>
           
            <Box sx={{mx: 'auto', maxWidth: 'lg', mb: 8, p:1 }} >
                <Typography align="center" variant="body2" component="h6" sx={{mb:1, textTransform: 'none',  fontSize: '.9rem',  color: 'primary.light' }}>
                    {lang.aboutLongDescription}
                </Typography>
            </Box>

        </Container>
    )
}


export default TheProject;

