const constants = {

    macroBeneficiares: [
        'international_organizations', 'NGOs', 'no_profit', 'youth', 'women', 'over_60', 'protected_categories', 'citizens_individuals', 'freelancers_independent_professionals', 'training_institutions', 'large_companies', 'SMES', 'startups', 'public_institutions', 'trade_associations_and_chamber_of_commerce', 'others'
    ],

    macroSectors: [
        'aid_and_support_to_enterprises', 
        'agriculture_and_fishing', 'banking', 'citizenship', 'combating_poverty', 'constructions', 
        'creative_industries', 
        'culture_and_arts', 
        'employment', 
        'education_and_training', 
        'environment_and_sustainability', 
        'food_and_beverage', 'international_cooperation', 'ict_digitalization_and_innovation', 'humanitarian_actions_and_civil_protection', 'manufacturing_industry', 'mass_media', 'migration', 'health', 'real_estate', 'research_and_development', 'security_and_justice', 'social_inclusion', 'social_services', 'sports', 'territory_support', 'tourism', 'transportation', 'trade', 'urban_and_rural_areas',
    ],


    typology: ['national', 'regional'],
    fundingSource: [
        'european_regional_development_fund',
        'cohesion_fund',
        'european_social_fund',
        'just_transition_fund',
        'EU_solidarity_fund'
    ],

    areas: [
        {
            "code": "IT",
            "regions": [
                {
                    "code": "abruzzo",
                    "name": "Abruzzo"
                },
                {
                    "code": "basilicata",
                    "name": "Basilicata"
                },
                {
                    "code": "calabria",
                    "name": "Calabria"
                },
                {
                    "code": "campania",
                    "name": "Campania"
                },
                {
                    "code": "emilia",
                    "name": "Emilia-Romagna"
                },
                {
                    "code": "friuli",
                    "name": "Friuli-Venezia Giulia"
                },
                {
                    "code": "lazio",
                    "name": "Lazio"
                },
                {
                    "code": "liguria",
                    "name": "Liguria"
                },
                {
                    "code": "lombardia",
                    "name": "Lombardia"
                },
                {
                    "code": "marche",
                    "name": "Marche"
                },
                {
                    "code": "molise",
                    "name": "Molise"
                },
                {
                    "code": "piemonte",
                    "name": "Piemonte"
                },
                {
                    "code": "puglia",
                    "name": "Puglia"
                },
                {
                    "code": "sardegna",
                    "name": "Sardegna"
                },
                {
                    "code": "sicilia",
                    "name": "Sicilia"
                },
                {
                    "code": "toscana",
                    "name": "Toscana"
                },
                {
                    "code": "trentino",
                    "name": "Trentino-Alto Adige"
                }, {
                    "code": "umbria",
                    "name": "Umbria"
                },
                {
                    "code": "aosta",
                    "name": "Valle d'Aosta"
                }, {
                    "code": "veneto",
                    "name": "Veneto"
                }
            ]
        }, {
            "code": "RO",
        }, {
            "code": "LT",
        }, {
            "code": "GR",
        }
    ]
}


export default constants;
