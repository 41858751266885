import { Search, Send } from "@mui/icons-material";
import { Box, Checkbox, FormControlLabel, FormGroup, IconButton, InputBase, InputLabel, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import lang from '../lang';
import constants from "../constants";
import helper from "../helper";

const {getArrayLabel} = helper
const {typology, fundingSource } = constants

const styleBox = {
    width: '100%',
    maxWidth: '320px',
    p: 2
}


const BlockFilterIcons = { 
    width : '32px',
    height : '32px',
    m : 0,
    p: 0
}

function BlockFilter(props) {


    const { currentLanguage } = props;
	lang.setLanguage(currentLanguage.code);


    const navigate = useNavigate();
    const { search } = useLocation();

    const query = React.useMemo(() => new URLSearchParams(search), [search]);

    //const [loading, setLoading] = useState(true);
    const [anchorEl, setAnchorEl] = useState(null);
    const [textSearch, setTextSearch] = useState(query.get('search') ? query.get('search') : '');

    const [loading , setLoading] = useState(true);
    const [countries, setCountries] = useState(undefined);
    const [macroBeneficiaries, setMacroBeneficiaries] = useState([]);
    const [macroSectors, setMacroSectors] = useState([]);

    const filter = {
        search: query.get('search') ? query.get('search') : null,
        who: query.get('who') ? query.get('who').split(',') : [],
        where: query.get('where') ? query.get('where') : currentLanguage.code.toUpperCase() ,
        regions:  query.get('regions') ? query.get('regions').split(',') : [],
        what: query.get('what') ? query.get('what').split(',') : [],
        type: query.get('type') ?? null,
        fundingSource: query.get('fundingSource') ? query.get('fundingSource') : null,
        status: query.get('status') ? query.get('status') : 'all'
    }


    function refresForSearch(f) {


        console.log(f);

        ///setFilter(f);

        if (filter.search !== f.search && f.search > 0 && f.search <= 3) {
            return;
        }

        //setFilter(f);
        //setData(f, 1);

        let url = '/' + lang.getLanguage() + '/search-calls?';

        for (const key in f) {
            if (Object.hasOwnProperty.call(f, key)) {

                if (f[key]) {

                    const value = f[key].toString().trim();

                    if (['who', 'where', 'what', 'status', 'search', 'fundingSource', 'type', 'regions'].includes(key) && value) {
                        url += `${key}=${value}&`;
                    }
                }
            }
        }

        url += `page=1`;

        //console.log(url);

        setAnchorEl(null);
        navigate(url);
    }


    useEffect(() => {

        setCountries(constants.areas);

        setMacroBeneficiaries( constants.macroBeneficiares.map( e => ({ code : e, label : lang.macro.beneficiares[e] })).sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase())));
        setMacroSectors(constants.macroSectors.map( e => ({ code : e, label : lang.macro.sectors[e] })).sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase())));

        setLoading(false);

    }, []);


    let ca = null;
    let regionLabel = null;

    if (countries){
        ca = countries.find(e => e.code === filter.where);

        regionLabel = ca ? getArrayLabel(ca.regions, filter.regions) : lang.all_regions_fem


    }

    
    return (

        loading ? null :
        <Box sx={{ ...styleBox, overflowY: 'auto' }}>

            <Box sx={{  color: 'primary.main', borderBottom: 1, py: 2 }}>

                <Box component="form" sx={{ display: 'flex', alignItems: 'center' }}>

                    <InputLabel sx={{ p: '3px', color: 'primary.main' }} aria-label="menu">
                        <Search />
                    </InputLabel>

                    <InputBase sx={{ ml: 1, flex: 1, color: 'primary.main' }}

                        value={textSearch}
                        placeholder={lang.searchForEuropeanCalls}

                        onChange={event => {
                            event.preventDefault();
                            const { value } = event.currentTarget;
                            setTextSearch(value);
                        }}

                        onKeyDown={event => {

                            if (event.key === 'Enter') {
                                event.preventDefault();
                                refresForSearch({ ...filter, search: textSearch });
                            }
                        }}
                    />

                    <IconButton type="button" sx={{ color: 'primary.main', p: '10px' }} aria-label="search" onClick={event => {
                        // alert(textSearch);     
                        event.preventDefault();
                        refresForSearch({ ...filter, search: textSearch });

                    }}>
                        <Send />
                    </IconButton>
                </Box>
            </Box>



            <List sx={{ width: '100%', position: "relative" }}
                component="nav"
                aria-labelledby="nested-list-subheader"
            >

                <ListItem disablePadding>

                    <ListItemButton sx={{ color: 'primary.main' }} id="btnWho" onClick={event => setAnchorEl(event.currentTarget)} >
                        <ListItemIcon sx={{ p: '3px', color: 'primary.main' }}  >
                            <Box component='img' src="/assets/filterIcons-1.svg" alt ={lang.whoAreYou} sx={BlockFilterIcons} /> 
                        </ListItemIcon>
                        <ListItemText component='div' 
                            primary={lang.whoAreYou} 
                            secondary={<Box sx={{ maxWidth: '250px', color: 'primary.main' }}><Typography component='div' sx={{fontWeight: 'bold'}} variant="body2" >{getArrayLabel(macroBeneficiaries, filter.who)}</Typography></Box>} />
                    </ListItemButton>

                    <Menu
                        open={Boolean(anchorEl) && anchorEl.id === 'btnWho'}
                        anchorEl={anchorEl}
                        onClose={() => setAnchorEl(null)}

                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}

                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}

                    >
                        <FormGroup>

                            {[...macroBeneficiaries.slice(1), macroBeneficiaries[0]].map((option, index) => (

                                <FormControlLabel sx={{ px: 2 }}
                                    key={index}
                                    control={<Checkbox color="success" />}
                                    checked={Boolean(filter.who.find(e => e === option.code))}
                                    onChange={(event) => {

                                        event.preventDefault();

                                        let who = [...filter.who];

                                        if (event.target.checked) {
                                            who.push(option.code);
                                        }
                                        else {
                                            who = who.filter(e => e !== option.code)
                                        }

                                        refresForSearch({
                                            ...filter,
                                            who
                                        })
                                    }}

                                    label={<ListItemText component='div' primary={option.label} />} />

                            ))}
                        </FormGroup>

                    </Menu>

                </ListItem>


                <ListItem disablePadding>

                    <ListItemButton sx={{ color: 'primary.main' }} id="btnWhere" onClick={event => setAnchorEl(event.currentTarget)}>
                        <ListItemIcon sx={{ p: '3px', color: 'primary.main' }} >
                            <Box component='img' src="/assets/filterIcons-2.svg" alt ={lang.whereDoYouLive} sx={BlockFilterIcons} /> 
                        </ListItemIcon>
                        <ListItemText component='div' primary={lang.whereDoYouLive} 
                            secondary={<Box sx={{ maxWidth: '250px', color: 'primary.main' }}><Typography component='div' sx={{fontWeight: 'bold'}} variant="body2" >{ filter.where ? lang.countryArray[filter.where] : lang.all}</Typography></Box>} />
                    </ListItemButton>

                    <Menu
                        open={Boolean(anchorEl) && anchorEl.id === 'btnWhere'}
                        anchorEl={anchorEl}
                        onClose={() => setAnchorEl(null)}

                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}

                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >

                        {countries && countries.map((option, index) => (

                            <MenuItem key={index} onClick={(e) => {

                                e.preventDefault();

                                refresForSearch({
                                    ...filter,
                                    where: option.code === 'ALL' ? undefined : option.code,
                                    regions: []
                                });


                                setAnchorEl(null);
                            }}>

                                { option.code === 'ALL' ? option.name : lang.countryArray[option.code] } 

                            </MenuItem>
                        ))}
                    </Menu>

                </ListItem>

                {ca && ca.regions &&  (
                    <ListItem disablePadding   >
                        <ListItemButton sx={{ color: 'primary.main' }} id="btnRegions" onClick={event => {
                            setAnchorEl(event.currentTarget);
                        }} >
                            <ListItemIcon sx={{ p: '3px', color: 'primary.main' }} >
                                <Box component='img' src="/assets/filterIcons-3.svg" alt ={lang.regions} sx={BlockFilterIcons} /> 
                            </ListItemIcon>
                            <ListItemText primary={lang.regions} 
                                secondary={<Box sx={{ maxWidth: '250px', color: 'primary.main' }}><Typography component='div' sx={{fontWeight: 'bold'}} variant="body2" >{regionLabel === lang.all ? lang.all_regions_fem : regionLabel}</Typography></Box>} 
                                
                                />
                        </ListItemButton>


                        <Menu
                            open={Boolean(anchorEl) && anchorEl.id === 'btnRegions'}
                            anchorEl={anchorEl}
                            onClose={() => setAnchorEl(null)}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}

                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                        >
                            <FormGroup>
                                {ca.regions.map((option, index) => (
                                    <FormControlLabel sx={{ px: 2 }}
                                        key={index}
                                        control={<Checkbox color="success" />}
                                        checked={Boolean(filter.regions.find(e => e === option.code))}
                                        onChange={(event) => {

                                            event.preventDefault();

                                            let regions = [...filter.regions];

                                            if (event.target.checked) {
                                                regions.push(option.code);
                                            }
                                            else {
                                                regions = regions.filter(e => e !== option.code)
                                            }

                                            const f = {
                                                ...filter,
                                                regions
                                            }

                                            refresForSearch(f)

                                        }}

                                        label={<ListItemText primary={option.name} />} />
                                ))}
                            </FormGroup>
                        </Menu>

                    </ListItem>
                )}

                <ListItem disablePadding>
                    <ListItemButton sx={{ color: 'primary.main' }} id="btnWhat" onClick={event => {setAnchorEl(event.currentTarget);}} >
                        <ListItemIcon sx={{ p: '3px', color: 'primary.main' }} >
                            <Box component='img' src="/assets/filterIcons-4.svg" alt ={lang.whatAreYouInterestedIn} sx={BlockFilterIcons} /> 
                        </ListItemIcon>
                        <ListItemText primary={lang.whatAreYouInterestedIn} secondary={<Box sx={{ maxWidth: '250px', color: 'primary.main' }}><Typography component='div' sx={{fontWeight: 'bold'}} variant="body2" >{getArrayLabel(macroSectors, filter.what)}</Typography></Box>} />
                    </ListItemButton>

                    <Menu
                        open={Boolean(anchorEl) && anchorEl.id === 'btnWhat'}
                        anchorEl={anchorEl}
                        onClose={() => setAnchorEl(null)}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}

                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >

                        <FormGroup>

                            {macroSectors.map((option, index) => (

                                <FormControlLabel sx={{ px: 2 }}
                                    key={index}
                                    control={<Checkbox color="success" />}
                                    checked={Boolean(filter.what.find(e => e === option.code))}
                                    onChange={(event) => {

                                        event.preventDefault();

                                        let what = [...filter.what];

                                        if (event.target.checked) {
                                            what.push(option.code);
                                        }
                                        else {
                                            what = what.filter(e => e !== option.code)
                                        }

                                        const f = {
                                            ...filter,
                                            what
                                        }

                                        refresForSearch(f)
                                    }}

                                    label={<ListItemText primary={option.label} />} />

                            ))}
                        </FormGroup>
                    </Menu>

                </ListItem>


                <ListItem disablePadding>
                    <ListItemButton sx={{ color: 'primary.main' }} id="btnType" onClick={event => {
                        //console.log(event.currentTarget.id);
                        setAnchorEl(event.currentTarget);
                    }} >
                        <ListItemIcon sx={{ p: '3px', color: 'primary.main' }} >
                            <Box component='img' src="/assets/filterIcons-5.svg" alt ={lang.type} sx={BlockFilterIcons} /> 
                        </ListItemIcon>
                        <ListItemText primary={lang.type} secondary={<Box sx={{ maxWidth: '250px', color: 'primary.main' }}><Typography sx={{fontWeight: 'bold'}} variant="body2">{filter.type ? lang.typologyArray[filter.type] : lang.all}</Typography></Box>} />
                    </ListItemButton>

                    <Menu
                        open={Boolean(anchorEl) && anchorEl.id === 'btnType'}
                        anchorEl={anchorEl}
                        onClose={() => setAnchorEl(null)}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}

                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >

                        {typology && [...typology, 'ALL' ].map((option, index) => (
                            <MenuItem key={index} onClick={(e) => {

//                                e.preventDefault();

                                refresForSearch({
                                    ...filter,
                                    type: option === 'ALL' ?  null : option
                                });

                                setAnchorEl(null);
                            }}>
                                {option === 'ALL' ? lang.all : lang.typologyArray[option]}
                            </MenuItem>
                        ))}
                    </Menu>


                </ListItem>


                <ListItem disablePadding>
                    <ListItemButton sx={{ color: 'primary.main' }} id="btnFundingSource" onClick={event => setAnchorEl(event.currentTarget)} >
                        <ListItemIcon sx={{ p: '3px', color: 'primary.main' }} >
                            <Box component='img' src="/assets/filterIcons-6.svg" alt ={lang.funding_source} sx={BlockFilterIcons} /> 
                        </ListItemIcon>
                        <ListItemText primary={lang.funding_source} secondary={<Box sx={{ maxWidth: '250px', color: 'primary.main' }}><Typography sx={{fontWeight: 'bold'}} variant="body2">{filter.fundingSource ? lang.fundings[filter.fundingSource] : lang.all}</Typography></Box>} />
                    </ListItemButton>

                    <Menu
                        open={Boolean(anchorEl) && anchorEl.id === 'btnFundingSource'}
                        anchorEl={anchorEl}
                        onClose={() => setAnchorEl(null)}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}

                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >

                        {fundingSource && [...fundingSource, 'ALL'].map((option, index) => (

                            <MenuItem key={index} onClick={(e) => {

                                refresForSearch({
                                    ...filter,
                                    fundingSource:option === 'ALL' ? null : option
                                });

                                setAnchorEl(null);
                            }}>
                                {option === 'ALL' ? lang.all : lang.fundings[option]}
                            </MenuItem>
                        ))}

                    </Menu>


                </ListItem>

                <ListItem>
                    <ListItemIcon sx={{ p: '3px', color: 'primary.main' }}>
                        <Box component='img' src="/assets/filterIcons-7.svg" alt ={lang.status} sx={BlockFilterIcons} />
                    </ListItemIcon>

                    <Box sx={{ display: 'flex', flexDirection: 'column', color: 'primary.main' }}>
                        <ListItemText primary={lang.status} />

                        <ToggleButtonGroup
                            color="primary"
                            value={filter.status ? filter.status : 'all'}
                            exclusive
                            onChange={(event, status) => {
                                event.preventDefault();
                                refresForSearch({ ...filter, status: status ? status : 'all' })
                            }}

                            aria-label={lang.status}
                        >
                            <ToggleButton sx={{ color: '#999', p: 1 }} value="all" aria-label="left aligned">
                                <Typography sx={{fontWeight: 'bold'}} variant="body2" >{lang.all}</Typography>
                            </ToggleButton>

                            <ToggleButton sx={{ color: '#999', p: 1 }} value="open" aria-label="centered">
                                <Typography sx={{fontWeight: 'bold'}} variant="body2" >{lang.open}</Typography>
                            </ToggleButton>

                            <ToggleButton sx={{ color: '#999', p: 1 }} value="close" aria-label="right aligned">
                                <Typography sx={{fontWeight: 'bold'}} variant="body2" >{lang.close}</Typography>
                            </ToggleButton>

                        </ToggleButtonGroup>

                    </Box>
                </ListItem>

            </List>

        </Box>


    )
}


export default BlockFilter;