import React, { useEffect } from 'react';

import { Box, Typography, Grid, Stack, Button } from '@mui/material';

//import { styled } from '@mui/material/styles';

import config from '../../config';
import lang from '../lang';

//import LeftMenu from '../modules/leftmenu';

import Header from '../modules/header';
import Footer from '../modules/footer';
import withRoot from '../modules/withRoot';


import helper from '../helper';
//const axios = require('axios').default;

const {ProductHeroLayoutPage, Background, sxMarginTop} = helper;

const sxBackground = {
	backgroundImage: `url('${config.hostCDN}/CitizensVoiceBanner.png')`,
	backgroundColor: 'tranparent', // Average color of the background image.
	backgroundPosition: 'center',
}


function CitizensVoice(props) {

	const { currentLanguage } = props;
	lang.setLanguage(currentLanguage.code);


	useEffect(() => {
		document.title = 'Reference - ' + lang.citizensVoice
		window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
		
	}, []);


	async function downloadCitizensVoice(){

		const url = '/dummy.pdf';
		const link = document.createElement("a");

		link.download = url;
		link.href = url;
		link.click(); 
	}


	return (

		<>
			<Header {...props} />
			<Box sx={sxMarginTop} id="citizens-voice">

				<Box id='banner' >
					<ProductHeroLayoutPage>

						<Background sx={sxBackground} />
						{/* <LeftMenu {...props} /> */}

						<Typography align="center" variant="h3" component="h1" sx={{ color: 'primary.main' }}>
							{lang.citizensVoice}
						</Typography>

					</ProductHeroLayoutPage>
				</Box>

				<Box sx={{pt: 6, m:0}}>

					<Box sx={{ mb: 8 }} >
						<Typography align="center" variant="h5" component="h5" sx={{ mb: 1, textTransform: 'uppercase', fontSize: '.8rem', fontStyle: 'italic', color: 'primary.light' }}>
							{lang.citizensVoice}
						</Typography>

						<Typography align="center" variant="h3" component="h2" sx={{ mb: 3, color: 'primary.light' }}>
							{lang.yourOpinionMatters}
						</Typography>
					</Box>

					<Box id="dos-and-don" sx={{
						width: '100%',
						backgroundImage: `url('${config.hostCDN}/PolicyReccomendationsBackground.png')`,
						backgroundColor: 'tranparent', //Average color of the background image.
						backgroundPosition: '-30px 100%',
						backgroundSize: 'auto',
						backgroundRepeat: 'no-repeat',
						minHeight: '600px',
						padding: 5
						
					}} >


						{ true ? 
							<Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', p:2 }}>
								<img style={{ width: '40%', marginBottom: '140px' }} src={config.hostCDN + '/csv1.jpg'} alt='Reference' />
							</Box>

							:
							<Grid sx={{mx: 'auto'}} maxWidth={'md'} container spacing={0}>

								<Grid item xs={12} md={7} >
									<Typography variant="h5" component="h2" sx={{ mb: 3, color: 'primary.light' }}>
										{lang.yourOpinionMatters}
									</Typography>

									<Typography variant="body2" gutterBottom sx={{ mb: 3, color: 'primary.light', lineHeight: '2rem' }}>
									
									</Typography>

									<Box sx={{py: 4}}>

										<Stack direction="row" spacing={2}>
											<Button size="large" color="secondary" variant="contained">{lang.readCitizensVoice}</Button>


											

										</Stack>
									</Box>
								</Grid>

								<Grid item xs={12} md={5} >
									<Box sx={{display:'flex', justifyContent: 'center', alignItems: 'flex-end' }}>
										<img style={{width: '100%', margin: 'auto'}} src={config.hostCDN + '/CitizensVoiceRight.png'} alt='Reference' />
									</Box>						
								</Grid>		
							</Grid>
						}


					</Box>
				</Box>							
			</Box>
			<Footer {...props} />
		</>
	)
}


export default withRoot(CitizensVoice);