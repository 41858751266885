import React, { useEffect} from 'react';

import { Box, Typography, Grid,  Button } from '@mui/material';

import config from '../../config';
import lang from '../lang';

import Header from '../modules/header';
import Footer from '../modules/footer';
import withRoot from '../modules/withRoot';

import helper from '../helper';


const axios = require('axios').default;


const { ProductHeroLayoutPage, Background, sxMarginTop } = helper;

const sxBackground = {
	backgroundImage: `url('${config.hostCDN}/PolicyReccomendationsBanner.png')`,
	backgroundColor: 'tranparent', // Average color of the background image.
	backgroundPosition: 'center',
}

const titleStyle = { 
	color: 'primary.light', 
	mb: 2, 
	fontWeight: 700, 
	fontSize: {xs : '1.4rem', sm: '1.8rem'}
};



function PolicyReccomendations(props) {

	const { currentLanguage } = props;
	lang.setLanguage(currentLanguage.code);
				
						
	//const [pdfOpen, setPdfOpen] = useState(false);


	async function downloadCatalog(){

		const { key: username, secret: password } = config.auth
        let { data: token } = await axios.post(config.apiUrl + '/api/auth/access_token', { username, password })


        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token.token}`
        }
        

        let { data } = await axios.put(config.apiUrl + '/api/tools/counters/dl_catalogs', {}, {headers})

		if (data){
			
			const link = document.createElement("a");
			link.download = config.hostCDN + '/catalogs' + lang.catalogFile;
			link.href = config.hostCDN + '/catalogs' + lang.catalogFile;
			link.click();
		}	
	}

	useEffect(() => {
		document.title = lang.policyReccomendations + ' - ' + lang.myFundsApp

		helper.createMetaInfo(
			lang.policyReccomendations,
			lang.metaDescription,
			window.location.protocol + '//' + window.location.host + '/' + lang.getLanguage() + '/policyReccomendations')
	

		window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
	}, [] );


	return (

		<>
			<Header {...props} />
			<Box sx={{...sxMarginTop}} id="policy-reccomendations">

				<Box id='banner' >
					<ProductHeroLayoutPage>
						<Background sx={sxBackground} />
						<Typography align="center" variant="h3" component="h1" sx={{p:1, color: 'primary.main', fontSize: { xs:'2rem', md: '2.5rem', lg: '3rem'} }}>
							{lang.policyReccomendations}
						</Typography>

					</ProductHeroLayoutPage>
				</Box>

				<Box sx={{px: {xs : 1 , sm : 2 }, py: 6, m: 0, mx: 'auto', maxWidth: 'lg' }}>

					<Box sx={{ mb: 3 }} >
						<Typography variant="h4" component="h2" sx={titleStyle}>
							{lang.ourReccomendations}
						</Typography>
					</Box>

					<Grid container  maxWidth={'lg'}  sx={{mx: 'auto'}}>

						<Grid item xs={12} sx={{mb:3}}>
							<Typography variant="h5" component="h2" sx={{ color: 'primary.light' }}>
								{lang.readPolicyReccomendations}
							</Typography>	
						</Grid>

						<Grid sx={{display: {xs: 'none', md: 'flex' } }} item xs={12} md={9}>
							<Box sx={{width: '100%', height: '1200px'}}>
								<Box component={'object'} data={config.hostCDN + '/catalogs' + lang.catalogFile} type="application/pdf" width="100%" height="100%" />
							</Box>
						</Grid>

						<Grid item xs={12} md={3}>

							<Box sx={{w:'100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', mb:3, p: 1 }}>

								<Box component='img' sx={{ width: { xs : '100%', sm: '60%'}, mb: '40px', mx: 'auto' }} src={config.hostCDN + '/PolicyReccomendationsRight.png'} alt='Reference' />

								<Box sx={{ textAlign: 'center', mb: 2 }}>

									<Button onClick={downloadCatalog}

										size="large"
										color="secondary"
										variant="contained">{lang.downloadFile}

									</Button>
								</Box>
							</Box>
						</Grid>

					</Grid>



				</Box>
			</Box>
			<Footer {...props} />
		</>
	)
}


export default withRoot(PolicyReccomendations);