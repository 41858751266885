import React, { Fragment } from 'react';


import { Box, Container, Grid, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import lang from '../lang';
import { width } from '@mui/system';
import config from '../../config';


function WhiteBlock(props) {

    const { title = 'Title', body } = props;


    return (
        <Box sx={{
            maxWidth: { sm: '285px'} ,
            minHeight: { sm: '368px'},
            mx: 'auto', 
            border: 2,
            borderColor: 'primary.light',
            borderRadius: 5,
            p:2,
            textAlign: 'center'
            }}>

            <Typography  variant="h5" component="h4" sx={{p:0, fontWeight:'bold', mb: 2, color: 'primary.light'  }}>
                {title}
            </Typography>

            <Typography dangerouslySetInnerHTML={{ __html: body }}  variant="body" component="div" sx={{p:0, fontSize: '.8rem', color: 'primary.light' }} />  

        </Box>
    )
}


function BlueBlock(props) {

    const { title = 'Title', body } = props;

    return (
        <Box sx={{
            maxWidth: { sm: '285px'} ,
            minHeight: { sm: '368px'},
            mx: 'auto', 
            border: 2,
            borderColor: 'primary.light',
            backgroundColor : 'primary.light',
            borderRadius: 5,
            p:2,
            textAlign: 'center'
        }}>

            <Typography  variant="h5" component="h4" sx={{p:0, fontWeight:'bold', mb: 2, color: 'primary.main' }}>
                {title}
            </Typography>

            <Typography dangerouslySetInnerHTML={{ __html: body }} variant="body" component="div" sx={{p:0, fontSize: '.8rem', color: 'primary.main' }} />  

        </Box>
    )
}


function CircleBlock() {

    return (
        <Box maxWidth={'160px'} sx={{
            width: '100%',
            border: 2,
            borderColor: 'primary.light',
            backgroundColor : 'primary.light',
            borderRadius: '50%',
            p:5,
            textAlign: 'center'
            }}>
            <WorkspacePremiumIcon sx={{ color: 'primary.main', fontSize: 70 }} />
        </Box>
    )
}



function Results(props) {


    const {titleStyle} = props;

    titleStyle.color = 'primary.light';  

    return (
        <Container maxWidth='lg' sx={{pt: 5, mb: 5}}>


                <Typography  variant="h4" component="h2" sx={{...titleStyle, textAlign: { xs: 'left', sm : 'center'} }}>
                    {lang.results}
                </Typography>

                <Box sx={{display : { xs: 'block', md: 'none'}}} >

                    <Grid maxWidth='sm' sx={{ mx : { sm: 'auto'} }} container spacing={1} rowSpacing={3} >
                        <Grid item xs={12} sm={6}>
                            <WhiteBlock title={lang.resultsList[0].name} body={lang.resultsList[0].description} />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <BlueBlock title={lang.resultsList[1].name} body={lang.resultsList[1].description} />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <BlueBlock title={lang.resultsList[2].name} body={lang.resultsList[2].description} />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <WhiteBlock title={lang.resultsList[3].name} body={lang.resultsList[3].description} />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <WhiteBlock title={lang.resultsList[4].name} body={lang.resultsList[4].description} />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <BlueBlock title={lang.resultsList[5].name} body={lang.resultsList[5].description} />
                        </Grid>
                    
                    </Grid>
                </Box>



                <Box sx={{ display: { xs : 'none', md : 'block'}}}>

                    
                    <Grid container maxWidth='md' sx={{ mx : { md: 'auto'} }} spacing={3} >                            
             
                        <Grid item md={12}>
                            <Box sx={{display: 'flex', justifyContent: 'center'}} >
                                <WhiteBlock title={lang.resultsList[0].name} body={lang.resultsList[0].description} />
                            </Box>
                        </Grid>
             
                        
                        <Grid item md={4}>
                            <BlueBlock title={lang.resultsList[1].name} body={lang.resultsList[1].description} />
                        </Grid>
                        <Grid item md={4}>
                            <Box sx={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center',  alignItems: 'flex-end'}}>
                                <img style={{ width: '100%' }} src={config.hostCDN + '/result-top.png'} alt='Reference' />
                            </Box>
                        </Grid>
                        <Grid item md={4}>
                            <BlueBlock title={lang.resultsList[2].name} body={lang.resultsList[2].description} />
                        </Grid>

                        <Grid item md={12}  > 
                            <Box sx={{display: 'flex', justifyContent: 'center'}} >
                                <CircleBlock  />
                            </Box>
                        </Grid>
                  

                        <Grid item md={4}>
                            <WhiteBlock title={lang.resultsList[3].name} body={lang.resultsList[3].description} />
                        </Grid>
                        <Grid item md={4}>
                            <Box sx={{width: '100%', display: 'flex', justifyContent: 'center',  alignItems: 'flex-start'}}>
                                <img style={{ width: '100%' }} src={config.hostCDN + '/result-down.png'} alt='Reference' />
                            </Box>
                        </Grid>
                        <Grid item md={4}>
                            <WhiteBlock title={lang.resultsList[4].name} body={lang.resultsList[4].description} />    
                        </Grid>


                        <Grid item md={12}>
                            <Box sx={{display: 'flex', justifyContent: 'center'}} >
                                <BlueBlock title={lang.resultsList[5].name} body={lang.resultsList[5].description} />    
                            </Box>
                        </Grid>

                    </Grid>


                    

                </Box>


        </Container>
    )
}


export default Results;

