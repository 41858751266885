import React, { Fragment, useEffect, useState, useMemo } from 'react';

import {Link as ReactRouterLink, useParams,useLocation,matchRoutes, useNavigate} from "react-router-dom";
import { HashLink} from 'react-router-hash-link' 

import { Box, List, ListItem, ListItemText, Grid, Typography, Skeleton, Link, Accordion, AccordionSummary, AccordionDetails, Button, useMediaQuery } from '@mui/material';

import { Euro, DateRange, ExpandMore  } from '@mui/icons-material';


import config from '../../config';
import lang from '../lang';

import Header from '../modules/header';
import Footer from '../modules/footer';

import withRoot from '../modules/withRoot';
import GotoUp from '../modules/gotoUp';

import axios from 'axios';
import constants from '../constants';
import helper from '../helper';

	
const { sxMarginTop, createMetaInfo } = helper;

const listItemTextCss = {
    borderLeft: '3px solid #1b2766',
    fontSize: '.7rem',
    lineHeight: '20px',
    pl: '15px'
}



function SimpleBlock(props) {

    const {
        title = undefined,
        body = '/',
        icon = undefined,
        call = null,
        link = false
    } = props;


    const borderRadius = '5px 5px 0 0';
    const borderTopColor = 'primary.light';
    const backgroundColor = 'transparent';


    return (
        <Fragment>

            {title && (
                <Typography variant="h5" component="h5" sx={{ mb: 1, color: 'primary.black' }}>
                    {title}
                </Typography>
            )}

            <Box boxShadow={2}
                sx={{
                    backgroundColor,
                    border: 'none',
                    borderTop: '3px solid',
                    borderTopColor,
                    borderRadius,
                    p: 3,

                    display: 'flex',
                    alignItems: 'center',
                    ...props.sx
                }}>

                {icon}

                {call ? (
                    
                    link ? 
                        <Typography variant="body" component="div" sx={{ p: 0, color: 'primary.light', overflow:'hidden', textOverflow:'ellipsis' }}>{body}</Typography> :
                        <Typography variant="body" component="div" sx={{ p: 0, color: 'primary.light', overflow:'hidden', textOverflow:'ellipsis' }} dangerouslySetInnerHTML={{ __html: `${body}`}}></Typography>
                    )
                    :
                    <Skeleton variant="text" width={'100%'} sx={{ minWidth: '150px' }} />
                }

            </Box>
        </Fragment>
    )
}


function IndexTitle(props) {

    const {
        id = 'id_' + Math.random(),
        title = undefined,
        sx = {}
    } = props;

    return (
        <Box sx={{...sx, display: 'inline-block'}}  >

            {title && (
                <Typography id={id} variant="h2" component="h2" sx={{ fontSize : { xs: '1.3rem', md: '2rem' }, fontWeight: 'normal', mb: 1, color: 'primary.black' }}>
                    {title}
                </Typography>
            )}

            <Box sx={{ height: '5px', backgroundColor: 'primary.light' }}></Box>

        </Box>
    )
}




function Call(props) {


    const { currentLanguage } = props;
	lang.setLanguage(currentLanguage.code);


    const routes = [{ path: "/:lang/call/:id/:name"}]
    const location = useLocation()
    const url = matchRoutes(routes, location)[0].pathname;


    const { id } = useParams();
    const [call, setCall] = useState(null);

    const navigate = useNavigate();




    useEffect(() => {

        //document.title = lang.myFundsApp + ' - ' + lang.about

		createMetaInfo( 
            lang.myFundsApp, 
            lang.metaDescription,
            window.location.protocol + '//' + window.location.host + '/' + lang.getLanguage() + '/home')

        async function loadData() {

            const { key: username, secret: password } = config.auth
            const  { data: token } = await axios.post(config.apiUrl + '/api/auth/access_token', { username, password })
            try {

                const response = await axios.get(config.apiUrl + '/api/proposals/' + encodeURIComponent(id), {
                    params : {},
                    headers :   {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token.token}`
                    }
                })

                if (response){

                    const { proposal } = response.data;
                 
                   
                    const geographic_area = constants.areas.find( element => element.code === proposal.geographic_area[0]);
                    geographic_area.name = lang.countryArray[proposal.geographic_area[0]];
                    
                    const regions = [];
                    if( proposal.regions && proposal.regions.length == 0 && geographic_area.code === "IT" ){
                        regions.push( lang.all_region )                     
                                                
                    }else{
                        for (const region of proposal.regions) {
                     
                            const t = geographic_area.regions.find( element => element.code === region);
    
                            if (t){
                                regions.push( t.name )
                            }
                        }
                    }
                    


                    const {title, link, deadline, program, funding_source, lender, 
                            startdate, financial_endowment, 
                            description, elegibility_criteria, how_to_apply, beneficiary, sector
                        } = proposal;

                        
                    document.title = title + ' - ' + lang.myFundsApp
                    //console.log('startdate', startdate);


                    const deadlineDate = (deadline !== null && deadline !== '') ? new Date(deadline) : undefined;
                    const startdateDate = (startdate !== null && startdate !== '') ? new Date(startdate) : undefined;

                    //console.log(new Date(deadline).toLocaleDateString("it-IT"));

                    const formatter = new Intl.NumberFormat('it-IT', {
                        style: 'currency',
                        currency: 'EUR'
                    });

                    setCall({

                        title,
                        program, 
                        funding_source : lang.fundings[funding_source] ?? '',
                        lender,
                        beneficiary,
                        geographic_area: geographic_area ? geographic_area.name : 'ND' ,
                        regions,
                        sector,
                        link,

                        opening : startdateDate ? startdateDate.toLocaleDateString("it-IT") : 'ND',
                        closing: deadlineDate ? deadlineDate.toLocaleDateString("it-IT") : lang.nd,

                        financing : (financial_endowment && financial_endowment.overall) ? formatter.format(financial_endowment.overall) : 'ND',
                        contributions : (financial_endowment && financial_endowment.proposal) ? formatter.format(financial_endowment.proposal) : 'ND',

                        elegibilityCriteria : elegibility_criteria || /*undefined*/ '...',
                        howtoApply: how_to_apply || /*undefined*/ '...',
                        description : description || /*undefined*/ '...',
                    });
                }

                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

            } catch (error) {

                console.log(error);

                navigate('/404');
            }
    
        }
       

        loadData();

    }, [id]);



    function IndexBox(){

        const matches = useMediaQuery(theme => theme.breakpoints.up('md'));
        const [expanded, setExpanded] = useState(matches);

        useEffect(() => {
            setExpanded(matches);
        }, [matches]);

        const indexLists = useMemo( () => [
            {
                name: lang.generalInformations,
                code: 'generalInformations'
            }, {
                name: lang.funding,
                code: 'funding'
            }, {
                name: lang.sector,
                code: 'sector'
            }, {
                name: lang.beneficiary,
                code: 'beneficiary'
            }, {
                name: lang.elegibilityCriteria,
                code: 'elegibilityCriteria'
            }, {
                name: lang.howtoApply,
                code: 'howtoApply'
            },{
                name: lang.description,
                code: 'description'
            },{
                name: lang.dates,
                code: 'deadlines'
            },{
                name: lang.source,
                code: 'link'
            }
        ], []);

    
        return (
            <Accordion expanded={expanded} onChange={(event, isExpanded) => setExpanded(isExpanded)}>
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <Typography variant="h5" component="h2" sx={{ textTransform: 'uppercase', pl: 2, m: 1, color: 'primary.light' }}>
                        {lang.index}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>

                    <List sx={{ }} component="nav" >

                        {indexLists.map((item, index) =>(
                            <ListItem key={index} >

                                <HashLink                
                                    to={'#' + item.code}
                                    scroll={el => { el.scrollIntoView(true); window.scrollBy({left:0, top: -200, behavior: 'smooth'}) }}    
                                    >
                                    <ListItemText sx={listItemTextCss} primary={
                                        <Typography variant="body2" component="span" sx={{ fontWeight: 'bold', color: '#000' }}>
                                            {item.name}
                                        </Typography>
                                    } />
                                </HashLink>
                            </ListItem>                            
                        ))}
                    </List>

                </AccordionDetails>
            </Accordion>
        )
    }

    return (

        <>
            <Header {...props} />
            
            <Box component="section" sx={sxMarginTop} id="index">

                <Grid m={'0 auto'} maxWidth={'lg'} container spacing={0}>
                    
                    <Grid item xs={12} md={3} >
                        <IndexBox />
                    </Grid>


                    <Grid item xs={12} md={9} >

                        <Box sx={{ borderLeft: { xs: 'none', md : 1 },  width: '100%', p: { xs: 2, md : 5 } }}>

                            <Box>           

                                <Box sx={{mb: 8}}>

                                    <IndexTitle sx={{mb: 4}} id="generalInformations" title= {call ? call.title : <Skeleton />} />

                                    <Box sx={{width: '100%', mb: 5}}>
                                        <SimpleBlock call={call} title={lang.program} body={call && call.program} />
                                    </Box>                   


                                    {call && call.funding_source && 
                                        <Box sx={{width: '100%', mb: 5}}>
                                            <SimpleBlock call={call} title={lang.funding_source} body={call.funding_source} />
                                        </Box>  
                                    }
                                    

                                    <Box sx={{width: '100%', mb: 5}}>
                                        <SimpleBlock sx={{display: 'inline-block'}} call={call} title={lang.geographicArea} body={call && call.geographic_area } />
                                    </Box>

                                    {call && call.regions && (
                                        <Box sx={{width: '100%', mb: 5}}>
                                            <Typography variant="h5" component="h5" sx={{  mb: 1, color: 'primary.black' }}>
                                                {lang.regions}
                                            </Typography>

                                            <Grid container sx={{ width: '100%' }} spacing="20" >
                                                {call && call.regions.map((item, index) => (
                                                    <Grid key={index} item >
                                                        <SimpleBlock call={call} body={item} />
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </Box>
                                    )}
                                </Box>


                                {call && (call.financing || call.contributions) &&
                                    <Box sx={{mb: 8}} >
                                        <IndexTitle sx={{mb: 4}}  id="funding" title={lang.funding} />
                                        <Grid container sx={{ width: '100%' }} spacing="20" >

                                            {call && call.financing &&    
                                                <Grid item >
                                                    <SimpleBlock call={call} title={lang.overallFunding} icon={<Euro sx={{ fontSize: 48, mr: 1, color: 'primary.light' }} />} body={call.financing.replace('€', '').trim()} />
                                                </Grid>
                                            }

                                            {call && call.contributions && 
                                                <Grid item >
                                                    <SimpleBlock call={call} title={lang.budget} icon={<Euro sx={{ fontSize: 48, mr: 1, color: 'primary.light' }} />} body={call.contributions.replace('€', '').trim()} />
                                                </Grid>
                                            }

                                        </Grid>
                                    </Box>
                                }


                                {call && call.sector &&
                                    <Box sx={{mb: 8}} >
                                        <IndexTitle sx={{mb: 4}} id="sector" title={lang.sector} />
                                        <SimpleBlock call={call} body={call.sector} />
                                    </Box>
                                }

                                {call && call.description &&    
                                    <Box sx={{mb: 8}}  >
                                        <IndexTitle sx={{mb: 4}} id="description" title={lang.description} />
                                        <SimpleBlock call={call} body={call.description} />
                                    </Box>
                                }


                                {call && call.beneficiary &&
                                    <Box sx={{mb: 8}}  >
                                        <IndexTitle sx={{mb: 4}}  id="beneficiary" title={lang.beneficiares} />
                                        <SimpleBlock call={call} body={call.beneficiary} />
                                    </Box>
                                }

                               
                                
                                {call && call.elegibilityCriteria  &&
                                    <Box sx={{mb: 8}}  >
                                        <IndexTitle  sx={{mb: 4}} id="elegibilityCriteria" title={lang.elegibilityCriteria} />
                                        <SimpleBlock call={call} body={call.elegibilityCriteria} />
                                    </Box>
                                }


                                {call && call.howtoApply &&    
                                    <Box sx={{mb: 8}}  >
                                        <IndexTitle sx={{mb: 4}} id="howtoApply" title={lang.howtoApply} />
                                        <SimpleBlock call={call} body={call.howtoApply} />
                                    </Box>
                                } 
                                
                               
                                
                                <Box sx={{mb: 8}}  >
                                    <IndexTitle sx={{mb: 4}} id="deadlines" title={lang.dates} />

                                    <Grid container sx={{ width: '100%' }} spacing="20" >
                                        {call && call.opening && 
                                            <Grid item  >
                                                <SimpleBlock call={call} icon={<DateRange sx={{ fontSize: 48, mr: 1, color: 'primary.light' }} />} title={lang.opening} body={call.opening} />
                                            </Grid>
                                        }
                                        
                                        {call && call.closing && 
                                            <Grid item  >
                                                <SimpleBlock call={call} icon={<DateRange sx={{ fontSize: 48, mr: 1, color: 'primary.light' }} />} title={lang.closing} body={call.closing} />
                                            </Grid>
                                        }
                                    </Grid>
                                </Box>    
                                
                                

                                {call && call.link &&
                                    <Box sx={{mb: 8}}  >
                                        <IndexTitle sx={{mb: 4}} id="link" title={lang.source} />
                                        <SimpleBlock link={true} call={call} body={call && <Link target={'_blank'} sx={{color:'primary.light'}} href={call.link} title={call.link}>{call.link}</Link>} />
                                    </Box>
                                }


                                <Button size='large' sx={{mb : {xs : 1 , mb: 0} }} color='success' variant='contained' component={ReactRouterLink} to={'/' + currentLanguage.code + '/home#contactsRef'}>{lang.contacts}</Button>

                            </Box>
                        </Box>
                    </Grid>

                </Grid>

                <GotoUp to={url + '#index' }  />                  
            </Box>

            <Footer {...props} />
        </ >
    )
}


export default withRoot(Call);