import React, {useEffect} from 'react';

import { Box, Typography } from '@mui/material';
import { useNavigate} from "react-router-dom";
import Header from '../modules/header';
import Footer from '../modules/footer';
import UnsubscribeIcon from '@mui/icons-material/Unsubscribe';
import withRoot from '../modules/withRoot';
import Button from '@mui/material/Button';

import lang from '../lang';

import helper from '../helper';

const {sxMarginTop} = helper;


function Unsubscribed(props) {
    
    useEffect(() => {
        document.title = 'Reference - Unsubscribed'
    }, []);
   
    const navigate = useNavigate();
    
    return (

        <div>
            <Header {...props} />
            <Box style={{...sxMarginTop, textAlign:'center'}}>
                <Typography align="center" variant="h3" component="h1" sx={{ mt:20, color: 'primary.dark' }}>
                    {lang.unsubscribedTitle}
                </Typography>
                <UnsubscribeIcon sx={{ m:0, fontSize:250, color:"error.dark",}}/>
                <Typography align="center" variant="body" component="h3" sx={{ mb:2, color: 'primary.dark' }}>
                    {lang.unsubscribedMessage}
                </Typography>
               <Button variant="contained" 
               sx={{ marginBottom:"2%", width:"20%", heigth:"auto%", backgroundColor:"#1b2766", color:"#fff"}}
               onClick={() => {navigate('/en/home')}}
        
               >{lang.home}</Button> 
            </Box>
            <Footer  {...props} />
            
        </div>
    )
}


export default withRoot(Unsubscribed);