import React, { useState, useEffect, Fragment } from 'react';
import config from '../config';

import { Box, Button, InputAdornment, Skeleton, Stack, TextareaAutosize } from '@mui/material';

import { Container, Select, InputLabel, MenuItem, TextField } from '@mui/material';
import { Snackbar, Alert } from '@mui/material';

import constants from '../front/constants.js'
import lang from './language'
import { useParams } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // importa lo stile


const axios = require('axios').default;

function Detail(props) {
    console.log("props", props)

    const { id } = useParams();

   
    const [elegibility_criteria, setElegibility_criteria] = useState('');
    const [how_to_apply, setHow_to_apply] = useState('');
    const [description, setDescription] = useState('');
    const [macroBeneficiaries, setMacroBeneficiaries] = useState([]);
    const [areas, setAreas] = useState([]);
    const [regions, setRegions] = useState([]);
    const [macroSectors, setMacroSectors] = useState([]);

    const [proposal, setProposal] = useState(null);

    const [openSnackbar, setOpenSnackbar] = useState(false);

    const [action, setAction] = useState('');

    //const [id, setId] = useState(id);

    const funding_source_array = [];

    for (const code in lang.fundings) {

        if (Object.hasOwnProperty.call( lang.fundings, code)) {
            const name = lang.fundings[code];

            const t = {
                code, 
                name
            }
            
            funding_source_array.push(t) ;
        }
    } 



    function onSubmitAction(e) {
        e.preventDefault();

        //return console.log(proposal);

        const { title, geographic_area, regions, macroSectors, sector, macroBeneficiaries, beneficiary, link,
            financial_endowment, lender, program, type, funding_source } = proposal;
            
        const params = {
            program,
            title,
            funding_source,
            lender,
            type,
            geographic_area,
            regions,
            financial_endowment,
            sector,
            macroSectors,
            beneficiary,
            macroBeneficiaries,

            deadline: new Date(proposal.deadline),
            startdate: new Date(proposal.startdate),
            link,

            elegibility_criteria : elegibility_criteria,
            how_to_apply : how_to_apply,
            description: description
        }

        //console.log(params);

        const token = sessionStorage.getItem('token');
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }



        function closeAction(res, a) {

            if (a === 'apply') {

                if (res.data) {
                    setOpenSnackbar(true);
                    //setId(res.data._id)
                }
            }
            else {
                props.history.goBack();
            }
        }

        if (id === 'new') {
            //console.log(params);
            //console.log(action);

            axios.post(config.apiUrl + '/api/proposals', params, {
                headers
            }).then(function (response) {
                closeAction(response, action)
            }).catch(function (error) {

                console.log(error);

                const newurl = window.location.protocol + "//" +
                    window.location.host + '/back';

                props.history.push(newurl);
            });

        }
        else {
            axios.patch(config.apiUrl + '/api/proposals/' + id, params, {
                headers
            }).then(function (response) {
                closeAction(response, action);
            }).catch(function (error) {
                console.log(error);

                const newurl = window.location.protocol + "//" +
                    window.location.host + '/back';

                props.history.push(newurl);


                //window.history.pushState({}, null, newurl);
            });
        }
    }


    useEffect(() => {

        async function loadData() {

            const params = {
                sort: 'name'
            }

            const token = sessionStorage.getItem('token');

            const headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }

            let areaTemp = [];

          


            const tb = [];
            for (const code in lang.macro.beneficiares) {

                if (Object.hasOwnProperty.call(lang.macro.beneficiares, code)) {
                    const name = lang.macro.beneficiares[code];

                    const t = {
                        code,
                        name
                    }

                    tb.push(t);
                }
            }

            //console.log(tb);
            setMacroBeneficiaries(tb);

            

            // try {
            //     const { data } = await axios.get(config.apiUrl + '/api/sectors', {
            //         params,
            //         headers
            //     });

            //     setSectors([...data.data]);

            // } catch (error) {
            //     console.log(error);
            // }

            const ts = [];
            for (const code in lang.macro.sectors) {

                if (Object.hasOwnProperty.call(lang.macro.sectors, code)) {
                    const name = lang.macro.sectors[code];

                    const t = {
                        code,
                        name
                    }

                    ts.push(t);
                }
            }

            setMacroSectors(ts);

            //const { id } = props.match.params;
            //setId(id);


            if (id !== 'new') {
                try {
                    const { data } = await axios.get(config.apiUrl + '/api/proposals/' + id, {
                        headers
                    });
                    
                    //const {beneficiaries:p_beneficiaries, geographic_area} = data.proposal;
                   
                    const beneficiary = data.proposal.beneficiary ?? data.proposal.beneficiaries[0];
                    const sector = data.proposal.sector ?? data.proposal.sectors[0];

                    const a = data.proposal.geographic_area[0];

                    const date = data.proposal.deadline ? new Date(data.proposal.deadline) : undefined;
                    const startdate = data.proposal.startdate ? new Date(data.proposal.startdate) : undefined;

                    delete data.proposal.beneficiaries;
                    delete data.proposal.sectors;
                  
                    const p = {
                        ...data.proposal,
                        beneficiary,
                        sector,

                        'deadline': date ? date.getFullYear() + '-' + ("0" + (date.getMonth() + 1)).slice(-2) + '-' + ("0" + date.getDate()).slice(-2) : undefined,
                        'startdate': startdate ? startdate.getFullYear() + '-' + ("0" + (startdate.getMonth() + 1)).slice(-2) + '-' + ("0" + startdate.getDate()).slice(-2) : undefined
                    }
                 
                  

                    const t = areaTemp.filter(e => {
                        return e.code === a;
                    });

                    const reg = t.length ? t[0].regions : [];

                    //console.log(p);

                    setRegions(reg);
                    let unique_macroSectors = [ ...new Set(p.macroSectors)];
                    p.macroSectors = unique_macroSectors;
                    setProposal(p);
                    setElegibility_criteria(p.elegibility_criteria);
                    setHow_to_apply(p.how_to_apply);
                    setDescription(p.description);
                } catch (error) {
                    console.log(error);
                }
            }
            else {
                setProposal({})
            }


        }

        loadData();
       
    }, []);


    console.log(proposal);

    return (
        <Fragment>

            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={4000}
                open={openSnackbar}
                onClose={e => setOpenSnackbar(false)}
            >
                <Alert severity="success" sx={{ mt: 7, width: '100%' }}>Data have been stored</Alert>
            </Snackbar>

            <Container maxWidth="lg" sx={{ mt: 10 }} >

                {proposal ? (
                    <Box sx={{ flexGrow: 1, border: "1px solid #ccc", p: 4 }}>

                        <form onSubmit={onSubmitAction}>


                            {/** program */}
                            <Box sx={{ width: '100%', mb: 3 }} >
                                <InputLabel sx={{ mb: 1, fontWeight: 'bold' }} htmlFor="program">{lang.program}</InputLabel>
                                <TextField
                                    id="program"
                                    name="program"
                                    variant="standard"
                                    sx={{ width: '100%' }}
                                    value={proposal.program}
                                    onChange={(e) => {
                                        setProposal({
                                            ...proposal,
                                            program: e.target.value
                                        })
                                    }}
                                />
                            </Box>

                            {/** title */}
                            <Box sx={{ width: '100%', mb: 3 }} >
                                <InputLabel sx={{ mb: 1, fontWeight: 'bold' }} htmlFor="title">{lang.title}</InputLabel>
                                <TextField
                                    id="title"
                                    name="title"
                                    required
                                    multiline
                                    variant="standard"
                                    rows={3}
                                    sx={{ width: '100%' }}
                                    defaultValue={proposal.title}
                                    onChange={(e) => {
                                        setProposal({
                                            ...proposal,
                                            title: e.target.value
                                        })
                                    }}
                                />
                            </Box>

                            {/** lender */}
                            <Box sx={{ width: '100%', mb: 3 }} >
                                <InputLabel sx={{ mb: 1, fontWeight: 'bold' }} htmlFor="lender">{lang.lender}</InputLabel>
                                <TextField
                                    id="lender"
                                    name="lender"
                                    variant="standard"
                                    sx={{ width: '100%' }}
                                    value={proposal.lender}
                                    onChange={(e) => {
                                        setProposal({
                                            ...proposal,
                                            lender: e.target.value
                                        })
                                    }}
                                />
                            </Box>

                            {/** FONTE DI FINANZIAMENTO*/}
                            <Box sx={{ width: '100%', mb: 3 }} >
                                <InputLabel sx={{ mb: 1, fontWeight: 'bold' }} htmlFor="funding_source">{lang.funding_source}</InputLabel>

                                <Select
                                    id="funding_source"
                                    name="funding_source"
                                    value={proposal.funding_source ?? 'none'}
                                    onChange={(e) => {
                                        setProposal({
                                            ...proposal,
                                            funding_source: e.target.value
                                        })
                                    }}
                                    variant="standard"
                                    sx={{ width: '100%' }}
                             
                                >

                                    {funding_source_array.map((option, index) => (
                                        <MenuItem key={index} value={option.code}>
                                            {option.name}
                                        </MenuItem>
                                    ))}

                                </Select>
                            </Box>


                            {/*geographic_area*/}
                            <Box sx={{ display: 'flex', width: '100%' }} >

                                <Box sx={{ width: '52%' }} >
                                    <InputLabel sx={{ mb: 1, fontWeight: 'bold' }} htmlFor="geographic_area">{lang.areas}</InputLabel>

                                    <Select
                                        id="geographic_area"
                                        name="geographic_area"
                                        value={proposal.geographic_area ?? 'all'}
                                        onChange={(e) => {
                                            setProposal({
                                                ...proposal,
                                                geographic_area: e.target.value
                                            })
                                        }}
                                        variant="standard"
                                        sx={{ width: '96%' }}
                                        required
                                    >
                                    
                                    {constants.areas.map((option, index) => (
                                            
                                        <MenuItem key={index} value={option.code}>                                               
                                            {lang.countryArray[option.code]}
                                        </MenuItem>

                                    ))}

                                    </Select>
                                </Box>



                                <Box sx={{ width: '48%' }} >
                                    <InputLabel sx={{ mb: 1, fontWeight: 'bold' }} htmlFor="title">{lang.regions}</InputLabel>
                                    <Select
                                        id="regions"
                                        select       
                                        multiple                                 
                                        name="regions"
                                        value={proposal.regions ?? []}
                                        onChange={(e) => {
                                            setProposal({
                                                ...proposal,
                                                regions: e.target.value
                                            })
                                        }}
                                        variant="standard"
                                        sx={{ width: '100%', mb: 3 }}
                                    >

                                        <MenuItem key={'key_region_all'} value=''></MenuItem>
                                        {
                                            constants.areas.map((option) =>  {                                      
                                                                             
                                                
                                           
                                                if(option.regions && option.code == proposal.geographic_area){
                                                    return option.regions.map((value)=>{
                                                        
                                                       return( <MenuItem key={'key_region_' + value.code} value={value.code}>
                                                            {value.name}
                                                        </MenuItem>
                                                    )})
                                                  
                                                }
                                              
                                            })
                                        }

                                    </Select>
                                </Box>
                            </Box>


                            {/** Funding*/}
                            <Box sx={{ width: '100%', mb: 6, display: 'flex' }} >

                                <Box sx={{ mr: 3 }} >
                                    <InputLabel sx={{ mb: 1, fontWeight: 'bold' }} htmlFor="overallFunding">{lang.overallFunding}</InputLabel>

                                    <TextField
                                        id="overallFunding"
                                        type="text"
                                        variant="standard"
                                        name="overallFunding"
                                       
                                        
                                        value={(proposal.financial_endowment && parseFloat(proposal.financial_endowment.overall) && !isNaN(proposal.financial_endowment.overall)) ? proposal.financial_endowment.overall : ''}

                                        onChange={(e) => {

                                            setProposal({
                                                ...proposal,
                                                financial_endowment: {
                                                    ...proposal.financial_endowment,
                                                    overall: e.target.value
                                                }
                                            })
                                            console.log("e.target.value",e.target.value)

                                        }}

                                        sx={{ width: 220 }}

                                        InputLabelProps={{
                                            shrink: true,
                                        }}

                                        InputProps={{
                                            inputProps: { min: 0 },
                                            startAdornment: <InputAdornment position="start">€</InputAdornment>,
                                        }}

                                    />
                                </Box>


                                <Box  >
                                    <InputLabel sx={{ mb: 1, fontWeight: 'bold' }} htmlFor="budget">{lang.budget}</InputLabel>

                                    <TextField
                                        id="budget"
                                        type="text"
                                        variant="standard"
                                        name="budget"
                                       

                                        value={(proposal.financial_endowment && proposal.financial_endowment.proposal && !isNaN(proposal.financial_endowment.proposal)) ? proposal.financial_endowment.proposal :  ''}

                                        onChange={(e) => {

                                            setProposal({
                                                ...proposal,
                                                financial_endowment: {
                                                    ...proposal.financial_endowment,
                                                    proposal: e.target.value
                                                }
                                            })
                                        }}

                                        sx={{ width: 220 }}

                                        InputLabelProps={{
                                            shrink: true,
                                        }}

                                        InputProps={{
                                            inputProps: { min: 0 },
                                            startAdornment: <InputAdornment position="start">€</InputAdornment>,
                                        }}
                                    />
                                </Box>
                            </Box>


                            {/** macro sectors */}
                            <Box sx={{ width: '100%', mb: 3 }} >
                                <InputLabel sx={{ mb: 1, fontWeight: 'bold' }} htmlFor="macroSectors">{lang.sectorsTags}</InputLabel>
                                <Select
                                    id="macroSectors"
                                    name="macroSectors"
                                    multiple
                                    value={proposal.macroSectors ?? []}
                                    onChange={(e) => {
                                        
                                        setProposal({
                                            ...proposal,
                                            macroSectors: e.target.value
                                            
                                        })
                                    }}
                                    variant="standard"

                                    fullWidth
                                   

                                >

                                    {macroSectors.map((option) => (
                                        <MenuItem key={'key_sector_' + option.code || Math.random()} value={option.code}>
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Box>

                            {/** sector */}
                            <Box sx={{ width: '100%', mb: 6 }} >
                                <InputLabel sx={{ mb: 1, fontWeight: 'bold' }} htmlFor="sector">{lang.sector}</InputLabel>
                                <TextField
                                    id="sector"
                                    name="sector"
                                    variant="standard"
                                    sx={{ width: '100%' }}
                                    value={proposal.sector}
                                    onChange={(e) => {
                                        setProposal({
                                            ...proposal,
                                            sector: e.target.value
                                        })
                                    }}
                                />
                            </Box>



                            {/** macro beneficiaries */}
                            <Box sx={{ width: '100%', mb: 3 }} >
                                <InputLabel sx={{ mb: 1, fontWeight: 'bold' }} htmlFor="macroBeneficiaries">{lang.beneficiariesTags}</InputLabel>
                                <Select
                                    id="macroBeneficiaries"
                                    name="macroBeneficiaries"
                                    multiple
                                    value={proposal.macroBeneficiaries ?? []}
                                    variant="standard"
                                    onChange={(e) => {
                                        setProposal({
                                            ...proposal,
                                            macroBeneficiaries: e.target.value
                                        })
                                    }}
                                    fullWidth
                                  
                                >

                                    {macroBeneficiaries.map((option, index) => (

                                        <MenuItem key={index} value={option.code}>
                                            {option.name}
                                        </MenuItem>

                                    ))}
                                </Select>
                            </Box>

                            {/* beneficiary */}
                            <Box sx={{ width: '100%', mb: 6 }} >
                                <InputLabel sx={{ mb: 1, fontWeight: 'bold' }} htmlFor="beneficiary">{lang.beneficiary}</InputLabel>
                                <TextField
                                    id="beneficiary"
                                    name="beneficiary"
                                    variant="standard"
                                    sx={{ width: '100%' }}
                                    value={proposal.beneficiary}
                                    onChange={(e) => {
                                        setProposal({
                                            ...proposal,
                                            beneficiary: e.target.value
                                        })
                                    }}
                                />
                            </Box>

                            {/* elegibilityCriteria */}
                            <Box sx={{ width: '100%', mb: 5 }} >
                                <InputLabel sx={{ mb: 1, fontWeight: 'bold' }} htmlFor="elegibilityCriteria">{lang.elegibilityCriteria}</InputLabel>
                                <ReactQuill
                                    id='elegibilityCriteria'
                                    name="elegibilityCriteria"

                                    placeholder={lang.elegibilityCriteria}
                                    style={{ width: '100%'}}
                                    value={elegibility_criteria}
                                    onChange={(content, delta, source, editor) => {
                                        // setProposal({
                                        //     ...proposal,
                                        //     elegibility_criteria: editor.getHTML()
                                        // })
                                        setElegibility_criteria(editor.getHTML());
                                    }}
                                />

                            </Box>


                            {/* howtoApply */}
                            <Box sx={{ width: '100%', mb: 5 }} >
                                <InputLabel sx={{ mb: 1, fontWeight: 'bold' }} htmlFor="howtoApply">{lang.howtoApply}</InputLabel>
                                <ReactQuill
                                    id='howtoApply'
                                    name="howtoApply"
                                    placeholder={lang.howtoApply}
                                    style={{ width: '100%' }}
                                    value={how_to_apply}
                                    onChange={(content, delta, source, editor) => {
                                       
                                            
                                            setHow_to_apply(editor.getHTML())
                                       
                                    }}
                                />
                            </Box>


                            {/** description */}
                            <Box sx={{ width: '100%', mb: 5 }} >
                                <InputLabel sx={{ mb: 1, fontWeight: 'bold' }} htmlFor="description">{lang.description}</InputLabel>

                                <ReactQuill
                                    id='description'
                                    name="description"
                                    placeholder={lang.description}
                                    style={{ width: '100%' }}
                                    value={description}
                                    onChange={(content, delta, source, editor) => {
                                        setDescription(editor.getHTML());
                                    }}
                                />
                            </Box>




                            {/** deadline*/}
                            <Box sx={{ width: '100%', mb: 6, display: 'flex' }} >

                                <Box sx={{ mr: 3 }} >
                                    <InputLabel sx={{ mb: 1, fontWeight: 'bold' }} htmlFor="startdate">{lang.startdate}</InputLabel>

                                    <TextField
                                        id="startdate"
                                        type="date"
                                        variant="standard"
                                        name="startdate"

                                        value={proposal.startdate ?? ''}

                                        onChange={(e) => {

                                            setProposal({
                                                ...proposal,
                                                startdate: e.target.value
                                            })

                                        }}

                                        sx={{ width: 220 }}

                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Box>




                                <Box  >
                                    <InputLabel sx={{ mb: 1, fontWeight: 'bold' }} htmlFor="deadline">{lang.deadline}</InputLabel>

                                    <TextField
                                        id="deadline"
                                        type="date"
                                        variant="standard"
                                        name="deadline"
                                        

                                        value={proposal.deadline ?? ''}

                                        onChange={(e) => {

                                            setProposal({
                                                ...proposal,
                                                deadline: e.target.value
                                            })

                                        }}

                                        sx={{ width: 220 }}

                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Box>
                            </Box>


                            {/** link */}
                            <Box sx={{ width: '100%', mb: 6 }} >
                                <InputLabel sx={{ mb: 1, fontWeight: 'bold' }} htmlFor="link">{lang.link}</InputLabel>
                                <TextField
                                    id="link"
                                    name="link"
                                    variant="standard"
                                    rows={3}
                                    sx={{ width: '100%' }}
                                    value={proposal.link}
                                    onChange={(e) => {
                                        setProposal({
                                            ...proposal,
                                            link: e.target.value
                                        })
                                    }}
                                />
                            </Box>


                            <Box component="div" sx={{

                                display: 'flex',
                                justifyContent: 'space-between',
                                p: 1
                            }}>

                                <Button variant="contained" href='/back/proposals/'>{lang.back}</Button>

                                <div>

                                    <Button sx={{ marginRight: '10px' }} onClick={e => setAction('apply')} color="warning" variant="contained" type='submit'>{lang.apply}</Button>

                                    {/* <Button sx={{ marginRight: '10px' }} color="warning" variant="contained"  onClick={(e) => {
                                        
                                        const {title, geographic_area, regions, sectors, link} = proposal;

                                        const params = {
                                            title,
                                            deadline : new Date(proposal.deadline),
                                            beneficiaries : [proposal.beneficiaries],
                                            geographic_area, 
                                            regions, 
                                            sectors,
                                            link
                                        }


                                        const token = sessionStorage.getItem('token');
                                        const headers = {  
                                            'Content-Type': 'application/json', 
                                            'Authorization': `Bearer ${token}` 
                                        } 

                                    
                                        axios.patch(config.apiUrl + '/api/proposals/' + id, params, {
                                            headers
                                        } ).then(function (response) {
                                            setOpenSnackbar(true);
                                        }).catch(function (error) {
                                            console.log(error);
                                        }); 

                                    }}>{lang.apply}</Button>



                                    <Button color="success" variant="contained" onClick={(e) => {
                                        
                                        const {title, geographic_area, regions, sectors, link} = proposal;

                                        const params = {
                                            title,
                                            deadline : new Date(proposal.deadline),
                                            beneficiaries : [proposal.beneficiaries],
                                            geographic_area, 
                                            regions, 
                                            sectors,
                                            link
                                        }


                                        const token = sessionStorage.getItem('token');
                                        const headers = {  
                                            'Content-Type': 'application/json', 
                                            'Authorization': `Bearer ${token}` 
                                        } 



                                        axios.patch(config.apiUrl + '/api/proposals/' + id, params, {
                                            headers
                                        }).then(function (response) {
                                            props.history.goBack();
                                        }).catch(function (error) {
                                            console.log(error);
                                        }); 


                                    }} >{lang.save}</Button>   */}
                                </div>

                            </Box>

                        </form>
                    </Box>
                ) : (
                    <Box sx={{ flexGrow: 1, border: "1px solid #ccc", p: 4 }}>
                        <Stack spacing={1}>
                            <Skeleton variant="text" />
                            <Skeleton variant="circular" width={40} height={40} />
                            <Skeleton variant="rectangular" width={210} height={118} />
                        </Stack>
                    </Box>
                )
                }


            </Container>

        </Fragment>
    )
}


export default Detail;
