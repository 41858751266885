import React, { useState, useEffect } from 'react';


import {Box,Toolbar, Typography, Button, Select, MenuItem, FormControl} from '@mui/material';
// import {FormGroup, FormControlLabel, Checkbox, FormLabel} from '@mui/material';

import AddCircleIcon from '@mui/icons-material/AddCircle';


import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';


//import lang from './language';
import ItemList from './itemlist';

import config from '../config';
import constants from '../front/constants';


const axios = require('axios').default;

function Main(props) {

    const {data, metadata} = props.data;

    //console.log(metadata);

    const [maps, setMaps] = useState({
        areas: new Map(),
        beneficiaries: new Map(),
        sectors : new Map(),
        year: '',
    });
   


    function onChangePage(e, p){
        props.onChangePage(p);
        //console.log(p);

        
    }

    useEffect(  () => {

        async function fetchData() {
         
            const token = sessionStorage.getItem('token');

            const params = { 
                sort : 'name'
            }

            const headers = {  
                'Content-Type': 'application/json', 
                'Authorization': `Bearer ${token}` 
            } 



            const beneficiaries = new Map();
            const {data:beneficiariesData} = await axios.get(config.apiUrl + '/api/beneficiaries', { 
                params,
                headers 
            });
    
            //console.log(data.data);
            for (const item of beneficiariesData.data) {
                beneficiaries.set( item.code, item.name);
            }
    
    
            
            const areas = new Map();
            

            for (const item of constants.areas) {
                areas.set( item.code, item.name);
            }


            const sectors = new Map();
            const {data:sectorsData} = await axios.get(config.apiUrl + '/api/sectors', { 
                params,
                headers
            });

            for (const item of sectorsData.data) {
                sectors.set( item.code, item.name);
            }


            setMaps({
                ...maps,
                beneficiaries,
                areas,
                sectors
            })

            props.onChangePage(1);
        }

          
        fetchData();

    }, [] );


    const query = new URLSearchParams(window.location.search);
    const page = parseInt(query.get('page') || '1', 10);



    return (
        
        <div>
            <Box sx={{ flexGrow: 1, borderBottom: '1px solid #ccc' }}>
                <Toolbar>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        Proposal
                    </Typography>

                    <Stack spacing={2} sx={{ mr: 2}}>                  
                        <Pagination count={metadata.pages} page={page} variant="outlined" color="primary" onChange={onChangePage} />
                    </Stack>
                    

                    <Button variant="contained" href="/back/proposals/new"  ><AddCircleIcon sx={{mr:1}} /> Add new</Button>


                    {/* <FormControl>
                        <Select
                            id="order"
                            name="order"

                            fullWidth
                            onChange={ e => { console.log(e); } }
                            
                            >
                            
                            <MenuItem key='all' value='all'>{lang.all}</MenuItem>

                            {[1,2,3,4,5,6].map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}

                        </Select>
                    </FormControl> */}
                   
                </Toolbar>
            </Box>
            <Box p='1' style={{padding: '10px', height: '83vh', overflowY: 'scroll' }}>
                
                {data && data.map( (item, index) => (
                    <ItemList key={index} maps={maps} data={item}></ItemList>
                ))}

            </Box>
           
        </div>
    
    );
}



export default Main;
