import { Button, Fab } from '@mui/material';
import React, { useEffect, useRef } from 'react';

import lang from '../lang';
import { ArrowUpward } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';

const rightLink = {
    '&:hover': {
        bgcolor: "primary.main",
        color: 'primary.light',
        boxShadow: 3,
    }
};



function GotoUp() {

    const navigate = useNavigate();
    const {pathname} = useLocation();

    const myButtonRef = useRef(null);

    useEffect(() => {


        function handleScroll() {

            if (myButtonRef.current){
                if (window.scrollY > 200) {
                    myButtonRef.current.style.display = 'flex';
                } else {
                    myButtonRef.current.style.display = 'none';

                }
            }
        }

        window.addEventListener('scroll', handleScroll);
    }, []);


    return (
        <Fab 
            id='goto-up'   
            ref={myButtonRef} 
            title={lang.gotoUp} 
            component={Button} 
            onClick={ ()=> { navigate(pathname); window.scrollTo({top: 0, left: 0, behavior: 'smooth'})}} 
                sx={{display: 'none', position: 'fixed', bottom: '5%', right : '5%', color: 'primary.main', bgcolor:'primary.light',...rightLink}} >
            <ArrowUpward  sx={{fontSize: 32}} />
        </Fab>
    )
}


export default GotoUp;