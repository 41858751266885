import React, {useEffect, useMemo, useState} from 'react';
import {
    //Switch,
    Routes,
	Route,
    useLocation,
    useParams,
    Navigate,
    useNavigate
} from "react-router-dom";


import lang from './lang';
import Home from './views/home';
import Unsubscribed from './views/unsubscribed';
import About from './views/about';
import Media4You from './views/media4YouRev3';

import NotFound from './views/notFound';
//import UnderConstruction from './views/underConstruction';
import Glossary from './views/glossary';

//import ReactGA from 'react-ga';
import ReactGA from 'react-ga4';

import CitizensVoice from './views/citizensVoice';
import PolicyReccomendations from './views/policyReccomendations';
import Call from './views/call';


import Privacy from './views/privacy';
import SearchCalls from './views/searchCalls';
import Media4YouContent from './views/media4YouContent';
import { useCookies } from 'react-cookie';
import { sha256 } from 'js-sha256';
import Cookies from './views/cookies';



function Front(props){

    const { lang:langCode = undefined } = useParams();

    lang.setLanguage(langCode ?? (localStorage.getItem('lang') ?? 'it'));
    
    localStorage.setItem('lang', lang.getLanguage().toString());    

    const flags =  useMemo( () =>[
        {
            code : 'en',
            image : 'https://flagcdn.com/w160/gb.png',  
            name : 'English'
        },{
            code : 'it',
            image : 'https://flagcdn.com/w160/it.png',
            name : 'Italiano'
        },{
            code : 'ro',
            image : 'https://flagcdn.com/w160/ro.png',
            name : 'Română'
        },{
            
            code : 'gr',
            image : 'https://flagcdn.com/w160/gr.png',
            name : 'Ελληνικά'
        },{
            
            code : 'lt',
            image : 'https://flagcdn.com/w160/lt.png',
            name : 'Lietuvių'
        }
    ], []);

    const findIndex = (l) => flags.findIndex( e => e.code === l);
    
    const [currentLanguage, setCurrentlanguage] = useState(flags[ findIndex(lang.getLanguage().toString()) ]);

    
    props = {
        ...props,
        flags,
        currentLanguage,
        setCurrentlanguage
    }


   // const navigate = useNavigate();
    const { pathname, hash } = useLocation();
    const [cookies, setCookie] = useCookies(['userId']);


  
    useEffect(() => {

   
        if (!cookies.userId){

            const userId = sha256('' +  Math.random() + Math.floor(Date.now() / 1000));
            
            const expires = new Date();
            expires.setDate(new Date().getDate() + 366);

            setCookie('userId', userId, { 
                path: '/',
                expires
            });
        }

        //ReactGA.initialize('UA-8971783-48');
        //ReactGA.pageview(window.location.pathname + window.location.search);

        ReactGA.initialize('G-C475XEWZ7N');

        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: "Reference" });


      }, []); 


    useEffect(() => {

        //console.log('hash', hash);


        if (hash === '') {
            window.scrollTo({top: 0, behavior: 'smooth'});
        }
       
        else {

            const id = hash.replace('#', '');
            const element = document.getElementById(id);

            
            if (element){

                const header = document.getElementById('header');
                
                let h = 65;
                if (header){
                    h = Math.ceil( header.getBoundingClientRect().height);
                }
                
                const p = element.getBoundingClientRect();
                const y = p.top + window.scrollY - h;
                
                window.scrollTo({top: y, behavior: 'smooth'});
                
            }
            else{
                window.scrollTo({top: 0, behavior: 'smooth'}); 
            }
            
        }

    },[hash]);

    return(

        <Routes>
            <Route exact path="/" element={ <Navigate to={`/${lang.getLanguage().toString()}/home`}/>} ></Route> 

            <Route exact path="/:lang/home" element={ <Home {...props} />}></Route>

            <Route exact path="/:lang/search-calls" element={<SearchCalls {...props} />}></Route>

            <Route exact path="/:lang/call/:id/:name" element={ <Call {...props} />}></Route>    

            <Route exact path="/:lang/media-4-you" element={  <Media4You {...props}  />}></Route>

            <Route exact path="/:lang/media-4-you/:view"  element={ <Media4YouContent {...props} />}></Route>

            <Route exact path="/:lang/citizens-voice" element={ <CitizensVoice {...props} />}></Route>

            <Route exact path="/:lang/cohesion-policy-approach/" element={ <PolicyReccomendations {...props} />} ></Route>


            <Route exact path="/:lang/about" element={<About {...props} />}></Route>

            <Route exact path="/:lang/glossary" element={ <Glossary {...props} />}></Route>

            <Route exact path="/:lang/privacy" element={ <Privacy {...props} />}></Route>

            <Route exact path="/:lang/cookies" element={ <Cookies {...props} />}></Route>
            
            <Route exact path="/:lang/unsubscribed" element={ <Unsubscribed {...props} />}></Route> 

            <Route exact path="*" element={ <NotFound {...props} />}>	</Route>

        </Routes>
    )
}

export default Front;