
const config = {
    apiUrl : process.env.REACT_APP_API_URL,
    hostCDN : process.env.REACT_APP_HOST_CDN,
    auth : {
        key : process.env.REACT_APP_AUTH_KEY,
        secret : process.env.REACT_APP_AUTH_SECRET
    },

    privacy : '/privacy'
}


export default config;