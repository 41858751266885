//import {enUS, itIT } from '@material-ui/data-grid';
import LocalizedStrings from 'react-localization';

const language = 'en';

const strings = new LocalizedStrings({

	en: {
		countryArray : {
			//'UK' : 'United Kindom',
			'IT' : 'Italy',
			'GR' : 'Greece',
			'RO' : 'Romania',
			'LT' : 'Lithuania',
			'other' : 'Other'
		},
		add_element : 'Add new element', 

		age: 'Age',
		all: 'All',
		apply : 'Apply',
		areas : 'Area',


		back : 'Back',
        beneficiary : 'Beneficiary',
		beneficiariesTags : 'Beneficiaries (macro)',
		budget : 'Budget for project',


		cancel : 'Cancel',
		clock : 'Clock',
		contributions : 'Contributions',

		deadline : 'Deadline',

		description : 'Descrizione',
		delete : 'Delete',
		delete_question : 'Are you sure you want delete this element?',
		doctor : 'Doctor',
		doctors : 'Doctors',

		elegibilityCriteria : 'Elegibility Criteria',
		email : 'Email',
		edit : 'Edit',
		errors :{
			409 : 'Username or email already used'
		},
		exit_to_app : "Exit to app",
		exit_to_app_question : "Are you sure you want to exit?",

        filter : 'Filter',
		financing : 'Financing',
		forgot_password : "Forgot password?",
		funding_source : 'Funding source',
		fundings : {
			'european_regional_development_fund' : 'European Regional Development Fund',
			'cohesion_fund' : 'Cohesion Fund',
			'european_social_fund' : 'European Social Fund',
			'just_transition_fund' : 'Just Transition Fund',
			'EU_solidarity_fund' : 'EU Solidarity Fund'
		},
		
		goto : 'Go to',
		howtoApply : 'How To Apply',

		invalid_credentials: "Invalid credentials",

		lender : 'Lender',
		link : 'Link',

		macro: {
			beneficiares : {
				"international_organizations" : "International organizations",
				"NGOs" : "Non-governmental organizations NGOs",
				"no_profit" : "Non-profit organizations",
				"youth" : "Youth",
				"women" : "Women",
				"over_60" : "Over 60",
				"protected_categories" : "Protected categories",
				"citizens_individuals" : "Citizens/individuals",
				"freelancers_independent_professionals" : "Freelancers/ Independent professionals",
				"training_institutions" : "Training institutions",
				"large_companies" : "Large companies",
				"SMES" : "SMES",
				"startups" : "Startups",
				"public_institutions" : "Public Institutions",
				"trade_associations_and_chamber_of_commerce" : "Trade associations and chamber of commerce",
				"others" : "Others"
			},
			
			sectors: {
				"aid_and_support_to_enterprises" : "Aid and support to enterprises",
				"agriculture_and_fishing" : "Agriculture and fishing",
				"banking" : "Banking",
				"citizenship" : "Citizenship ",
				"combating_poverty" : "Combating poverty",
				"constructions" : "Constructions",
				"creative_industries" : "Creative industries",
				"culture_and_arts" : "Culture and arts",
				"employment" : "Employment ",
				"education_and_training" : "Education and training",
				"environment_and_sustainability" : "Environment and sustainability",
				"food_and_beverage" : "Food & beverage",
				"international_cooperation" : "International Cooperation",
				"ict_digitalization_and_innovation" : "ICT, digitalization and innovation",
				"humanitarian_actions_and_civil_protection" : "Humanitarian actions and civil protection",
				"manufacturing_industry" : "Manufacturing industry",
				"mass_media" : "Mass Media",
				"migration" : "Migration",
				"health" : "Health",
				"real_estate" : "Real Estate",
				"research_and_development" : "Research & Development",
				"security_and_justice" : "Security & Justice",
				"social_inclusion" : "Social Inclusion",
				"social_services" : "Social Services",
				"sports" : "Sports",
				"territory_support" : "Territory Support",
				"tourism" : "Tourism",
				"transportation" : "Transportation",
				"trade" : "Trade",
				"urban_and_rural_areas" : "Urban and rural areas",
			}
		},


		name : 'Name',
		no : "No",

		ok : 'Ok',
		overallFunding : 'Overall funding',

		program : 'Program',
		
		recents : 'Recents',
		refresh: 'Refresh',
		regions : 'Regions',
		
		request_password : 'Request password',
		reset_password_error : 'The user was not found',
		reset_password_success : 'The Password has been changed',
		
		save : 'Save',
		search : "Search...",
		
		sector : 'Sector',
		sectorsTags : 'Sectors (macro)',

		see_more : "See more",
		send : 'Send',
		sign_in: "Sign In",
		startdate : 'Start date',

		tests : 'Tests',
		title : 'Title',

		user : 'User',
		username : 'Username',

		year : 'Year',
		yes : "Yes"
	},

	it: {
		countryArray : {
			//'UK' : 'United Kindom',
			'IT' : 'Italia',
			'GR' : 'Grecia',
			'RO' : 'Romania',
			'LT' : 'Lituania',
			'other' : 'Altri'
		},
		add_element : 'Aggiuungi un nuovo elemento', 
		macro: {

			beneficiares : {
				"international_organizations" : "Organizzazione internazionale",
				"NGOs" : "Organizzazione non governativa (ONG)",
				"no_profit" : "Organizzazione senza scopo di lucro",
				"youth" : "Giovane",
				"women" : "Donna",
				"over_60" : "Over 60",
				"protected_categories" : "Categoria protetta",
				"citizens_individuals" : "Cittadino/persona fisica",
				"freelancers_independent_professionals" : "Libero professionista",
				"training_institutions" : "Ente di formazione",
				"large_companies" : "Grande azienda",
				"SMES" : "Piccola e media impresa (PMI)",
				"startups" : "Startup",
				"public_institutions" : "Pubblica amministrazione (PA)",
				"trade_associations_and_chamber_of_commerce" : "Associazione di categoria e camera di commercio",
				"others" : "Altro"
			},
			
			sectors: {
				"aid_and_support_to_enterprises" : "Aiuto alle imprese",
				"agriculture_and_fishing" : "Agricoltura e pesca",
				"banking": "Settore bancario",
				"citizenship" : "Cittadinanza",
				"combating_poverty" : "Lotta alla povertà",
				"constructions" : "Edilizia",
				"creative_industries" : "Industrie creative",
				"culture_and_arts" : "Cultura e arte",
				"employment" : "Occupazione",
				"education_and_training" : "Educazione e formazione",
				"environment_and_sustainability" : "Ambiente e sostenibilità",
				"food_and_beverage" : "Ristorazione",
				"international_cooperation" : "Cooperazione internazionale",
				"ict_digitalization_and_innovation" : "ICT, digitalizzazione e innovazione",
				"humanitarian_actions_and_civil_protection" : "Azioni umanitarie e protezione civile",
				"manufacturing_industry" : "Industria manifatturiera",
				"mass_media" : "Mass media",
				"migration" : "Migrazione",
				"health" : "Salute",
				"real_estate" : "Settore immobiliare ",
				"research_and_development" : "Ricerca & sviluppo (R&S)",
				"security_and_justice" : "Sicurezza e giustizia",
				"social_inclusion" : "Inclusione sociale",
				"social_services" : "Servizi sociali",
				"sports" : "Sport",
				"territory_support" : "Sostegno al territorio",
				"tourism" : "Turismo",
				"transportation" : "Trasporti",
				"trade" : "Commercio",
				"urban_and_rural_areas" : "Centri urbani e aree rurali"
			}
			
		},
		age: 'Età',
		all: 'Tutti',
		apply : 'Applica',
		areas : 'Area',

		back : 'Indietro',
        beneficiaries : 'Beneficiari',

		cancel : 'Annulla',
		clock : 'Orologio',

		deadline : 'deadline',

		delete : 'Cancella',
		delete_question : 'Vuoi cancellare l\'elemento?',
		doctor : 'Dottore',
		doctors : 'Dottori',

		email : 'Email',
		edit : 'Modifica',
		errors :{
			409 : 'Utente o email gia utilizzati'
		},

		exit_to_app : "Uscita dall'app",
		exit_to_app_question : "Sei sicuro di voler uscire all'app?",

        filter : 'Filtri',
		forgot_password : "Password dimenticata?",
		goto : 'Vai a',
		invalid_credentials: "Credenziali invalide",

		link : 'Link',

		name : 'Nome',
		no : "No",
		

		ok : 'Ok',

		patient	: 'Paziente',
		patients : 'Pazienti',

		recents : 'Recenti',
		refresh: 'Refresh',
		regions: 'Regioni',
		request_password : 'Richiedi password',
		reset_password_error : 'L\'utente non è stato trovato.',
		reset_password_success : 'La password è stata cambiata.',

		save : 'Salva',

		search : "Cerca...",
		sectors : 'Settori',
		see_more : "Vai alla Pagina",
		send : 'Invia',
		sign_in: "Accedi",

		tests : 'Analisi',
		title : 'Titolo',

		user : 'Utente',
		username : 'Username',
		
		year : 'Anno',
		yes : "Si"
	}
});


//strings.locale = language === 'it' ? itIT : enUS; 
strings.setLanguage(language);

export default strings
